import React from 'react'
import { useTranslation } from 'react-i18next'

interface ShuttleListFooterProps {
    total: number
}

const ShuttleListFooter = (props: ShuttleListFooterProps) => {
    const { total } = props
    const { t } = useTranslation()
    return (
        <div className={styles.container}>
            {total} {total === 1 ? t('shuttleListFooterItem') : t('shuttleListFooterItems')}
        </div>
    )
}

const styles = {
    container: 'border-t border-gray-300 py-2 px-4 text-sm text-gray-500'
}

export default ShuttleListFooter