import { countries as allCountries } from 'countries-list'
import _ from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Button } from '../common/Button'
import Input from '../common/Input/Input'
import { validatePassword } from '../common/Input/InputPasswordValidator'
import { Modal } from '../common/Modal'
import PinCodeLayer from '../common/PinCodeLayer'
import Text from '../common/Text'
import { getBrowserLanguage } from '../common/utils'
import { useFormInput } from '../hooks/HandleChange'
import { useLoading } from '../hooks/loading'
import { loginCompleted } from '../login/actions'
import { notify } from '../notification/actions'
import { useAuth } from '../auth/amplify'

export type UseForm = {
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const generatePhoneNumberCountries = (): { value: string, label: string }[] => {
    //@ts-ignore
    return _.flatMap(Object.keys(allCountries).map(k => ({ countryCode: k, ...allCountries[k] })), (country) => {
        return country.phone.split(",").map((phone: string) => ({
            value: `+${phone}`, label: `${country.countryCode} (+${phone})`
        }))
    })
}

export function fromPhoneNumberPrefixToCountry(phoneNumber: string): { value: string, label: string } | undefined {
    const prefixes = generatePhoneNumberCountries()
    return prefixes.find(prefix => phoneNumber.indexOf(prefix.value) === 0)
}

export function getPrefixAndPhoneFromPhoneNumber(phoneNumber?: string): { prefix: string, phone: string } | undefined {
    const prefixes = generatePhoneNumberCountries()
    if (!phoneNumber)
        return { prefix: '', phone: '' }
    const country = prefixes.find(prefix => phoneNumber.indexOf(prefix.value) === 0)
    return { prefix: country?.value || '', phone: phoneNumber.replace(country?.value || '', '') }
}

export function Phone(props: { country: UseForm, phone: UseForm, label?: string, placeholder?: string }) {
    const { t } = useTranslation()

    const { label, placeholder, country, phone } = props
    return (<Input
        id="phone"
        name="phone"
        type="tel"
        autoComplete="phone"
        pattern={"[0-9]*"}
        required
        label={label}
        placeholder={placeholder}
        dropdown={{
            id: 'country',
            label: 'Country',
            emptyLabel: t('registrationCountry'),
            name: 'country',
            //@ts-ignore
            options: generatePhoneNumberCountries(),
            props: { required: true, value: country.value, onChange: country.onChange }
        }}
        inputClasses="pl-32"
        value={phone.value}
        onChange={phone.onChange}
    />)
}

export interface RegistrationUser {
    email: string,
    password: string,
    phone: string,
    country: string
}

export function Registration(props: {
}) {
    const dispatch = useDispatch()
    const { loading, execute } = useLoading()
    const resendLoadingHook = useLoading()
    const phone = useFormInput()
    const country = useFormInput()
    const { t } = useTranslation()
    const { userStatus, signUp, userShouldConfirmCredentials, username, setUsername, password, setPassword, confirmSignUp, resendCode } = useAuth()
    const [isPasswordValid, setIsPasswordValid] = useState(false)

    const [showPrivacy, setShowPrivacy] = useState(false)
    const [showTermsOfUse, setShowTermsOfUse] = useState(false)

    const locale = getBrowserLanguage() === 'it' ? 'it' : 'en'

    const onPinFilled = useCallback(async (code: string) => {
        try {
            await execute(() => confirmSignUp(code))
        } catch (error: any) {
            dispatch(notify('error', error.code))
        }

    }, [confirmSignUp, dispatch, execute])

    useEffect(() => {
        if (userStatus === "loggedIn")
            dispatch(loginCompleted())
    }, [userStatus, dispatch])

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await execute(() => signUp(country.value + phone.value))
        } catch (error: any) {
            dispatch(notify('error', error.code))
        }
    }

    return (
        <>
            <h2 className="mt-6 text-center text-3xl text-gray-900">{t('signup')}</h2>
            <div className="relative">
                <PinCodeLayer
                    showTransition={userShouldConfirmCredentials()}
                    digits={6}
                    pending={loading || resendLoadingHook.loading}
                    onResend={useCallback(async () => {
                        try {
                            await resendLoadingHook.execute(() => resendCode())
                        } catch (error: any) {
                            dispatch(notify('error', error.code))
                        }
                    }, [resendCode, dispatch, resendLoadingHook])}
                    onFilled={onPinFilled}
                    help={t('insertRegistrationCode')}
                />
                <form className="mt-8 space-y-4" action="#" method="POST" onSubmit={onSubmit}>
                    <Input
                        id="email-address"
                        label={t('email')}
                        type="email"
                        placeholder={t('emailPlaceholder')}
                        autoFocus
                        autoComplete="email"
                        required
                        value={username}
                        inputClasses={'lowercase'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(e.target.value.toLowerCase())
                        }}
                    />

                    <Input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        required
                        label={t('password')}
                        placeholder={t('passwordPlaceholder')}
                        passwordVisibility
                        value={password}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const currentPassword = e.target.value
                            setPassword(currentPassword)
                            setIsPasswordValid(validatePassword(currentPassword))
                        }}
                    >
                        <Input.PasswordValidator
                            password={password}
                        />
                    </Input>
                    <Phone label={t('mobilePhone')} placeholder={t('phonePlaceholder')} country={country} phone={phone} />
                    <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                            <input
                                required
                                id="privacy"
                                aria-describedby="privacy-description"
                                name="privacy"
                                type="checkbox"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor="privacy" className="font-medium text-gray-700">
                                {t('privacy')}
                            </label>
                            <p id="privacy-description" className="text-brand-500">
                                <span className="text-gray-500">{t('readThe')} </span> <a href="/" onClick={(e) => { setShowPrivacy(true); e.preventDefault() }} target="_blank" rel="noreferrer">{t('privacyConditions')}</a>
                            </p>
                        </div>
                    </div>
                    <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                            <input
                                required
                                id="terms-of-use"
                                aria-describedby="terms-of-use-description"
                                name="terms-of-use"
                                type="checkbox"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded"
                            />
                        </div>
                        <div className="ml-3 text-sm">
                            <label htmlFor="terms-of-use" className="font-medium text-gray-700">
                                {t('iAcceptRegistrationTermsOfUse')}
                            </label>
                            <p id="terms-of-use-description" className="text-brand-500">
                                <span className="text-gray-500">{t('readThe')} </span> <a href="/" onClick={(e) => { setShowTermsOfUse(true); e.preventDefault() }} target="_blank" rel="noreferrer">{t('registrationTermsOfUse')}</a>
                            </p>
                        </div>
                    </div>
                    <div>
                        <Button disabled={!isPasswordValid} text={t('register')} color="primary" full pending={loading} />
                    </div>
                </form>
            </div>
            <div className="relative my-4">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">{t('or')}</span>
                </div>
            </div>
            <div>
                <NavLink to={'/login'} className="button">
                    {t('alreadyRegisteredLogin')}
                </NavLink>
            </div>
            <Modal open={showPrivacy} onClose={() => setShowPrivacy(false)} size="4xl">
                <Text type={'privacy'}
                    locale={locale} />
            </Modal>
            <Modal open={showTermsOfUse} onClose={() => setShowTermsOfUse(false)} size="4xl">
                <Text type={'terms-of-use'}
                    locale={locale} />
            </Modal>
        </>
    )
}

export default Registration