import React, { useState } from 'react'
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import clsx from 'clsx';
import { ShuttleActions, ShuttleList } from './';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

export interface ItemPropsMapI {
    id: string,
    title: string,
    subtitle: string
}

interface ShuttleProps {
    items: any[],
    selected: any[],
    onChange: (selected: string[]) => void,
    itemPropsMap?: ItemPropsMapI
    disabled?: boolean,
    availableTitle?: string,
    selectedTitle?: string,
}

const defaultItemPropsMap = { id: 'id', title: 'title', subtitle: 'subtitle' }

const Shuttle = (props: ShuttleProps) => {
    const {
        items = [],
        selected = [],
        disabled = false,
        onChange,
        availableTitle,
        selectedTitle,
        itemPropsMap = defaultItemPropsMap,
    } = props
    const available = _.differenceWith(items, selected, (item, itemId) => item[itemPropsMap.id] === itemId)
    const selectedItems = _.intersectionWith(items, selected, (item, itemId) => item[itemPropsMap.id] === itemId)
    const [list, setList] = useState<1 | 2>(1)

    const { t } = useTranslation()
    const availableListTitle = availableTitle || t('shuttleAvailableListTitle')
    const selectedListTitle = selectedTitle || t('shuttleSelectedListTitle')

    return (
        <>
            <div className={styles.tabs}>
                <button
                    className={clsx(styles.tabsButton, { [styles.tabsButtonActive]: list === 1 })}
                    type="button"
                    onClick={() => setList(1)}
                >
                    {availableListTitle}
                </button>
                <button
                    className={clsx(styles.tabsButton, { [styles.tabsButtonActive]: list === 2 })}
                    type="button"
                    onClick={() => setList(2)}
                >
                    {selectedListTitle}
                </button>
            </div>
            <div className={styles.container}>
                <div className={clsx(styles.stage, {
                    'transform translate-x-[calc(-100%+68px)]': list === 2
                })}>
                    <ShuttleList
                        items={available}
                        title={availableListTitle}
                        disabled={disabled}
                        itemPropsMap={itemPropsMap}
                        onClick={(id: string) => onChange(selected.concat([id]))}
                        icon={<PlusIcon className={styles.icon} />}
                    />
                    <ShuttleActions
                        onAddAll={() => onChange(items.map(item => item[itemPropsMap.id]))}
                        onRemoveAll={() => onChange([])}
                        totalAvailable={available.length}
                        totalSelected={selectedItems.length}
                        disabled={disabled}
                    />
                    <ShuttleList
                        items={selectedItems}
                        title={selectedListTitle}
                        disabled={disabled}
                        itemPropsMap={itemPropsMap}
                        onClick={(id: string) => onChange(selected.filter(item => item !== id))}
                        icon={<MinusIcon className={styles.icon} />}
                    />
                </div>
            </div>
        </>
    )
}

const styles = {
    container: 'w-full overflow-hidden sm:w-auto',
    stage: 'grid grid-cols-[calc(100%-58px),48px,calc(100%-58px)] sm:grid-cols-[1fr,48px,1fr] transition',
    tabs: 'grid grid-cols-2 mb-2 border border-gray-300 rounded overflow-hidden sm:hidden',
    tabsButton: 'py-2 text-sm text-gray-700',
    tabsButtonActive: 'bg-gray-50',
    icon: 'h-6 w-6',
}

export default Shuttle