import React, { useEffect, useState } from 'react'

export function useFormInput(defaultValue = '', trigger?: boolean) {
    const [value, setValue] = useState('')

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(e.target.value)
    }

    function resetValue() {
        setValue(defaultValue)
    }

    useEffect(() => {
        setValue(defaultValue)
    }, [defaultValue, trigger])

    return {
        value,
        onChange: handleChange,
        reset: resetValue
    }
}