import React from 'react'
import { EmojiSadIcon } from '@heroicons/react/outline'
import { NoItem } from '../../common'
import { Panel } from '../../panels/actions'
import { serialNumberLength } from '../../hooks/adminPanels'
import { PanelsListItem } from '../../panels/PanelsListItem'

type AdminPanelResultsProps = {
    title: string,
    serialNumber: string,
    panels: Panel[],
    loading: boolean,
}

export function AdminPanelResults(props: AdminPanelResultsProps) {
    const { title, serialNumber, panels, loading } = props
    return (
        <>
            {panels.map(panel => <PanelsListItem key={panel._id} panel={panel} propagateAuth={false} />)}
            <NoItem
                show={!loading && panels.length === 0 && serialNumber.length === serialNumberLength}
                additionalClasses='m-4 sm:m-6 bg-white'
                icon={EmojiSadIcon}
                title={title}
            />
        </>
    )
}