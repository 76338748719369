import React, { useState, ComponentPropsWithoutRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import InputHelp from './InputHelp'
import InputAlert from './InputAlert'
import InputPasswordValidator from './InputPasswordValidator'

type InputType = 'text' | 'password' | 'email' | 'tel' | 'search'
type DropdownOption = { label: string, value: string }

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
    containerClass?: string,
    dropdown?: {
        id: string,
        label: string,
        name: string,
        options: DropdownOption[],
        emptyLabel?: string,
        props?: any
    },
    label?: string,
    icon?: JSX.Element,
    iconClass?: string,
    inputClasses?: string,
    invalid?: boolean,
    optional?: boolean,
    passwordVisibility?: boolean,
    type: InputType,
    prepend?: string,
    show?: boolean
}

interface Compounded extends React.ForwardRefExoticComponent<InputProps & React.RefAttributes<HTMLInputElement>> {
    Help: typeof InputHelp;
    Alert: typeof InputAlert;
    PasswordValidator: typeof InputPasswordValidator;
}

const Input = React.forwardRef((props, ref) => {
    const {
        children,
        containerClass,
        dropdown,
        icon,
        iconClass,
        id,
        inputClasses,
        invalid,
        label,
        optional,
        passwordVisibility,
        type,
        prepend,
        show = true,
        ...otherProps
    } = props
    const [passwordVisible, setPasswordVisible] = useState(false)
    const { t } = useTranslation()
    const setInputType = (type: InputType) => {
        if (passwordVisibility && type === 'password' && passwordVisible) {
            return 'text'
        }
        return type
    }
    
    const labelClassName = clsx(
        'block text-sm font-medium text-gray-700'
    )

    const inputClassName = clsx(
        "focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 disabled:opacity-30 disabled:cursor-not-allowed rounded-md shadow-sm",
        prepend && 'rounded-none shadow-none rounded-r-md',
        icon && "pl-10",
        inputClasses && inputClasses,
        otherProps.readOnly && 'bg-gray-50',
        invalid && 'border-red-600'
    )

    const iconClassName = clsx(
        'absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none',
        iconClass && iconClass
    )

    return show ? (
        <div className={containerClass}>
            {label && (
                <div className="flex justify-between mb-1">
                    <label htmlFor={id} className={labelClassName}>
                        {label}
                    </label>
                    {optional && (
                        <span className="text-sm text-gray-500">
                            {t('optional')}
                        </span>
                    )}
                </div>
            )}

            <div className={clsx('relative', prepend && 'flex rounded-md shadow-sm')}>
                {icon && (
                    <div className={iconClassName}>
                        {icon}
                    </div>
                )}

                {passwordVisibility && (
                    <button type="button" className="absolute inset-y-0 right-0 pr-3 flex items-center z-10" onClick={() => setPasswordVisible(!passwordVisible)}>
                        {passwordVisible && <EyeIcon className="h-5 w-5 text-gray-400" />}
                        {!passwordVisible && <EyeOffIcon className="h-5 w-5 text-gray-400" />}
                    </button>
                )}

                {dropdown && (
                    <div className="absolute inset-y-0 left-0 flex items-center">
                        <label htmlFor={dropdown.id} className="sr-only">
                            {dropdown.label}
                        </label>
                        <select
                            id={dropdown.id}
                            name={dropdown.name}
                            className="focus:ring-brand-500 focus:border-brand-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                            {...dropdown.props}
                        >
                            {dropdown.emptyLabel && <option value="">{dropdown.emptyLabel}</option>}
                            {dropdown.options.map((option: DropdownOption, index) => <option key={index} value={option.value}>{option.label}</option>)}
                        </select>
                    </div>
                )}

                {prepend && (
                    <span className={clsx('inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm whitespace-nowrap', props.disabled && 'opacity-30 cursor-not-allowed')}>{prepend}</span>
                )}

                <input
                    aria-invalid={invalid}
                    ref={ref}
                    id={id}
                    className={inputClassName}
                    type={setInputType(type)}
                    {...otherProps}
                />
            </div>
            {children}
        </div>
    ) : null
}) as Compounded

Input.Help = InputHelp
Input.Alert = InputAlert
Input.PasswordValidator = InputPasswordValidator

export default Input