const configs = {
    production: {
        Auth: {
            "region": "eu-west-1",
            "userPoolId": "eu-west-1_ioXeo1FvK",
            "userPoolWebClientId": "4ohjvus3a2q85ipm74l6miqo9t",
            "federationTarget": "COGNITO_USER_POOLS",
            "authenticationFlowType": 'USER_PASSWORD_AUTH',
            "identityPoolId": "eu-west-1:a59a15ca-c8fa-48e7-b159-49ce793c9124"  
        },
        Analytics: {
            AWSPinpoint: {
                "region": "eu-west-1",
                "appId": "304900064a9049339793fc1377974534"
            }
        }
    },
    staging: {
        Auth: {
            "region": "eu-west-1",
            "userPoolId": "eu-west-1_33G4Hc1zP",
            "userPoolWebClientId": "135tchmesqc99qhlvtep7cp8cr",
            "federationTarget": "COGNITO_USER_POOLS",
            "authenticationFlowType": 'USER_PASSWORD_AUTH',
            "identityPoolId": "eu-west-1:a2494632-f069-42de-9e63-cf85ad270669"
        },
        Analytics: {
            AWSPinpoint: {
                "region": "eu-west-1",
                "appId": "ddfb1ce5aa874ab993d30db8a2d58816"
            }
        }
    }
}

export default configs[process.env.REACT_APP_AUTH_CONFIG]