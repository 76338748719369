import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabPills } from '../common/TabPills'
import { Button } from '../common/Button'

const panelScreenImg = require('../images/visitor-dashboard/secureweb.jpg').default
const certificationImg = require('../images/visitor-dashboard/certification.jpg').default
const loyaltyImg = require('../images/visitor-dashboard/loyalty.jpg').default
const demoKitImg = require('../images/visitor-dashboard/demo-kit.jpg').default

export function Features() {
    const { i18n, t } = useTranslation()
    const locale = i18n.language === 'it' ? 'it' : 'en'

    return (
        <div className="flex flex-col items-center px-3 py-3 sm:px-6 sm:py-6 bg-white rounded-xl shadow">
            <header className="text-center w-9/12">
                <h2>
                    <span className="mb-2 block uppercase text-brand-500 text-base font-bold">{t('visitorDashboardFeaturesTitleDesc')}</span>
                    {t('visitorDashboardFeaturesTitle')}
                </h2>
            </header>
            <div className="w-full mt-8">
                <TabPills
                    tabs={[
                        { name: t('visitorDashboardFeatures1Tab') },
                        { name: t('visitorDashboardFeatures2Tab') },
                        { name: t('visitorDashboardFeatures3Tab') },
                        { name: t('visitorDashboardFeatures4Tab') },
                    ]}
                >
                    <div className="grid grid-cols-1 sm:grid-cols-[35%,1fr] gap-16 items-center">
                        <div className="aspect-w-1 aspect-h-1">
                            <img src={panelScreenImg} alt="" />
                        </div>
                        <div>
                            <h4 className="mb-4">{t('visitorDashboardFeatures1Title')}</h4>
                            <p className="text-gray-600">{t('visitorDashboardFeatures1Desc')}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-[35%,1fr] gap-16 items-center">
                        <div className="aspect-w-1 aspect-h-1">
                            <img src={certificationImg} alt="" />
                        </div>
                        <div>
                            <h4 className="mb-4">{t('visitorDashboardFeatures2Title')}</h4>
                            <p className="text-gray-600">{t('visitorDashboardFeatures2Desc')}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-[35%,1fr] gap-16 items-center">
                        <div className="aspect-w-1 aspect-h-1">
                            <img src={loyaltyImg} alt="" />
                        </div>
                        <div>
                            <h4 className="mb-4">{t('visitorDashboardFeatures3Title')}</h4>
                            <p className="text-gray-600">{t('visitorDashboardFeatures3Desc')}</p>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-[35%,1fr] gap-16 items-center">
                        <div className="aspect-w-1 aspect-h-1">
                            <img src={demoKitImg} alt="" />
                        </div>
                        <div>
                            <h4 className="mb-4">{t('visitorDashboardFeatures4Title')}</h4>
                            <p className="text-gray-600">{t('visitorDashboardFeatures4Desc')}</p>
                            <div className="mt-4">
                                <Button as="a" target="_blank" rel="noopener" href={process.env.PUBLIC_URL + `/documents/flyer-demokit-${locale}.pdf`} text={t('visitorDashboardFeatures4Action')} />
                            </div>
                        </div>
                    </div>
                </TabPills>
            </div>
        </div>
    )
}