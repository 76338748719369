import { combineReducers } from 'redux'
import { combineEpics } from 'redux-observable'
import { discardNotification$ } from '../../notification/epic'
import { notificationReducer as notifications } from '../../notification/reducer'

export const appReducers = combineReducers({
    notifications
})

export const rootEpics = combineEpics(
    discardNotification$
)