import React, { FC } from 'react';
import clsx from 'clsx';

type Props = {
    full?: boolean
    otherClasses?: string,
    show?: boolean 
}

const CardFooter: FC<Props> = (props) => {
    const { full, children, otherClasses = '', show = true } = props
    const classes = clsx(!full && 'px-4 sm:px-6 py-2', otherClasses)

    if (!show) return null
    
    return (
        <div className={classes}>
            {children}
        </div>
    )
}

export default CardFooter