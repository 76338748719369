import { useDispatch } from 'react-redux'
import { useRequest } from '../hooks/request'
import { useQuery } from 'react-query'
import { Panel, Service } from '../panels/actions'
import { notify } from '../notification/actions'

export interface GatewayService extends Service {
    panel: Pick<Panel, 'name' | 'serialNumber' | 'connection'>,
}

export const useServices = () => {
    const reduxDispatch = useDispatch()
    const requestParams = {
        path: `/users/services`
    }
    const { request: fetchGateways } = useRequest<GatewayService[]>(requestParams)
    const result = useQuery(
        [`get-services`, requestParams],
        fetchGateways,
        {
            enabled: true,
            staleTime: 15 * 1000,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onError: () => {
                reduxDispatch(notify('error', 'servicesLoadError', 3000))
            }
        }
    )

    return {
        services: result.data?.body,
        isLoading: result.isLoading
    }
}