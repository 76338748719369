import React, { VFC } from 'react'
import { ChipIcon, VolumeUpIcon, CubeIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { BaseMenuNav } from '../../../common/Menu/BaseMenuNav'
import MenuBticinoWorld from './MenuBticinoWorld'

interface Props {
    setSidebarOpen: (open: boolean) => void
}

export const MenuNav: VFC<Props> = (props) => {
    const { t } = useTranslation()

    return <BaseMenuNav
        menu={[
            {
                name: t('panels'),
                icon: ChipIcon,
                navlink: '/panels'
            },
            {
                name: t('loquendo'),
                icon: VolumeUpIcon,
                navlink: '/loquendo'
            },
            {
                name: t('downloads'),
                icon: CubeIcon,
                navlink: '/downloads'
            }
        ]}
        setSidebarOpen={(open) => props.setSidebarOpen(open)}
    >
        <MenuBticinoWorld />
    </BaseMenuNav>
}
