import React from 'react'
import clsx from 'clsx'
import { GiftIcon, AcademicCapIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { Avatar } from '../common/Avatar'
import { certificationMapper, certificationColorMapper, getInitials } from './utils'
import { InformationCircleIcon } from '@heroicons/react/solid'
import Popper from '../common/Popper'
import { useHistory } from 'react-router-dom'

type Props = {
    title?: string
    subtitle?: string,
    certification?: number
    certificationScaled?: number
    score?: number
    avatar?: string
    certificationLevelDetailLabel?: string
}

export function ProfileHeader(props: Props) {
    const { title = '-', subtitle = '-', certification = 0, certificationScaled = undefined, score, avatar, certificationLevelDetailLabel } = props
    const { t } = useTranslation()

    const initials = title ? getInitials(title) : '-'

    let avatarSrc = avatar

    const certIconClass = (level: number) => (clsx(
        'h-8 md:h-10 mr-2 rounded p-1 text-[rgba(255,255,255,0.7)]',
        certificationColorMapper[level]
    ))

    const certificationLevelLabel = (level: number) => certificationMapper[level]

    const hasScaledCertification = typeof certificationScaled !== 'undefined'

    const history = useHistory()

    const handleWhyAreDifferentLink = (e) => {
        e.preventDefault()
        history.push('/training')
    }

    return (
        <div className="overflow-none overscroll-none pb-4 sm:pb-0 border-b border-gray-200 bg-white">
            <div className="relative bg-brand-600 h-24" />
            <div className={clsx("px-4 md:px-16", !hasScaledCertification && 'lg:h-20')}>
                <div className="flex-none md:flex">
                    <Avatar
                        size={32}
                        type="circular"
                        initials={initials}
                        src={avatarSrc}
                        alt="profile image"
                        otherClasses="relative -top-16 left-2/4 md:left-auto transform -translate-x-2/4 md:transform-none border-4 border-white"
                    />
                    <div className="-mt-12 md:mt-2 md:ml-3 flex flex-col md:flex-row md:flex-1">
                        <div className="md:px-4 w-full md:w-auto text-center md:text-left">
                            <p className="text-2xl font-bold text-gray-700 truncate">{title}</p>
                            <p className="text-sm text-gray-500 truncate">{subtitle}</p>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-[repeat(2,auto)] grid-rows-[auto,auto] lg:grid-rows-none gap-x-8 gap-y-2">
                            {score !== undefined && (
                                <div className='flex flex-col'>
                                    <span className='text-[0.7rem] uppercase mb-1 text-gray-400'>{t('loyaltyProgram')}</span>
                                    <div className="flex flex-row items-center">
                                        <GiftIcon className="h-8 md:h-10 flex-0 p-1 mr-2 rounded text-white bg-brand-500" />
                                        <div className="text-right md:text-left flex-1">
                                            <p className="mb-1 md:text-xl font-bold text-gray-700 leading-none md:leading-none">{score || 0}</p>
                                            <p className="leading-none text-xs text-gray-400 truncate">{t('points')}</p>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='flex flex-col'>
                                <span className='text-[0.7rem] uppercase mb-1 text-gray-400'>{t('certification')}</span>
                                <div className="grid grid-cols-1 md:grid-cols-[repeat(2,auto)] gap-y-2 md:gap-x-8 md:gap-y-0 items-center">
                                    <div className="flex flex-row items-center">
                                        <AcademicCapIcon className={certIconClass(certification)} />
                                        <div className="text-right md:text-left flex-1">
                                            <p className="mb-1 md:text-xl font-bold text-gray-700 !leading-none">{t(certificationLevelLabel(certification))}</p>
                                            <p className="leading-none text-xs text-gray-400 truncate">{certificationLevelDetailLabel ? certificationLevelDetailLabel : t('company')}</p>
                                        </div>
                                    </div>
                                    {hasScaledCertification && (
                                        <>
                                            <div className="flex flex-row items-center">
                                                <AcademicCapIcon className={certIconClass(certificationScaled)} />
                                                <div className="text-right md:text-left flex-1">
                                                    <p className="mb-1 md:text-xl font-bold text-gray-700 !leading-none">{t(certificationLevelLabel(certificationScaled))}</p>
                                                    <p className="leading-none text-xs text-gray-400 truncate">{t('scoreBased')}</p>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            {hasScaledCertification && (
                                <div className="lg:row-start-2 lg:col-start-2">
                                    <Popper
                                        content={
                                            <div>
                                                <p>{t('companyLevelWhyAreDifferentText')}</p>
                                                <p>
                                                    {t('companyLevelWhyAreDifferentClickHereText')}
                                                    <a className='text-brand-300' href='/' onClick={handleWhyAreDifferentLink}>
                                                        {t('companyLevelWhyAreDifferentClickHereLink')}
                                                    </a>
                                                </p>
                                            </div>
                                        }
                                    >
                                        <div className='flex items-center justify-center text-[0.8rem] pt-2 w-full'>
                                            <InformationCircleIcon className="w-4 mr-1 text-gray-500" />
                                            <span className="leading-[0] text-gray-500">{t('companyLevelWhyAreDifferent')}</span>
                                        </div>
                                    </Popper>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}