import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './en.json'
import it from './it.json'
import sv from './sv.json'
import cs from './cs.json'
import da from './da.json'
import nlNL from './nl.json'
import de from './de.json'
import el from './el.json'
import hu from './hu.json'
import pl from './pl.json'
import sk from './sk.json'
import sr from './sr.json'
import ru from './ru.json'
import pt from './pt.json'
import fr from './fr.json'
import nb from './nb.json'
import ar from './ar.json'
import es from './es.json'

import detector from 'i18next-browser-languagedetector'

// the translations
// (tip move them in a JSON file and import them)
let resources = {}

if (process.env.NODE_ENV === 'production') {
    resources = {
        en: { translation: en },
        it: { translation: it },
        sv: { translation: sv },
        cs: { translation: cs },
        da: { translation: da },
        'nl-NL': { translation: nlNL },
        nl: { translation: nlNL },
        de: { translation: de },
        el: { translation: el },
        hu: { translation: hu },
        pl: { translation: pl },
        sk: { translation: sk },
        ru: { translation: ru },
        sr: { translation: sr },
        pt: { translation: pt },
        fr: { translation: fr },
        ar: { translation: ar },
        nb: { translation: nb },
        es: { translation: es },
    }
} else {
    resources = {
        en: {
            translation: en
        },
        it: {
            translation: it
        }
    }
}



const option = {
    // order and from where user language should be detected
    order: ['querystring', 'navigator'],
    // keys or params to lookup language from
    lookupQuerystring: 'lang'
}

i18n
    .use(initReactI18next)      // passes i18n down to react-i18next
    .use(detector)
    .init({
        resources,
        detection: option,
        returnEmptyString: false,
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: 'en',      // use en if detected lng is not available
        keySeparator: false,    // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false  // react already safes from xss
        }
    })

export default i18n
