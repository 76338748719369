import { useCallback, useState, useMemo } from "react"
import { useDispatch } from "react-redux"
import { useMutation, useQuery } from "react-query"
import { createResource, fetchResource } from "../../../hooks/utils"
import { notify } from "../../../notification/actions"
import { useAuth } from "../../../auth"
interface LoquendoLicense {
  _id: string
  id: string
  alreadyRegistered: boolean
  registeredAt: string
  userId: string,
  voices: string[],
  voicesCount: number,
};

const LICENSE_PATH = "licenses"

const fetchOptions = {
  refetchOnWindowFocus: false,
  retry: false
}

const useLoquendo = () => {
  const { getIdToken } = useAuth()
  const dispatch = useDispatch()
  const [license, setLicense] = useState<LoquendoLicense>()

  const fetchLicenseFn = useCallback(
    async () => {
      const token = await getIdToken()
      return await fetchResource<LoquendoLicense[]>(LICENSE_PATH, token)
    },
    [getIdToken]
  )

  const { isFetching, isSuccess, isError } = useQuery<LoquendoLicense[], ErrorConstructor>(
    'loquendo-fetch',
    fetchLicenseFn,
    {
      onSuccess: (data) => {
        if (data && data.length > 0) setLicense(data[0])
      },
      onError: () => {
        dispatch(notify('error', 'loquendoFetchError', 3000))
      },
      ...fetchOptions
    }
  )

  const registerLoquendoFn = useCallback(
    async (id: string) => {
      const token = await getIdToken()
      return await createResource<LoquendoLicense>(`${LICENSE_PATH}`, token, {
        id,
      })
    },
    [getIdToken]
  )

  const registerLoquendoMutation = useMutation(
    "loquendo-registration",
    registerLoquendoFn,
    {
      onSuccess: (data) => {
        if (data) {
          setLicense(data)
        }
        dispatch(notify("info", "loquendoRegistrationSuccess", 3000))
      },
      onError: (error: any) => {
        let errorMessage = 'loquendoRegistrationError'
        if (error.name === '409') {
          errorMessage = 'loquendoRegistrationConflitError'
        }
        dispatch(notify("error", errorMessage, 3000))
      },
    }
  )

  const registerLoquendo = useCallback(
    async (id: string) => {
      try {
        await registerLoquendoMutation.mutateAsync(id)
      } catch { }
    },
    [registerLoquendoMutation]
  )

  const isRegistered = useMemo(() => license && license.alreadyRegistered,
    [license]
  )

  return {
    isFetching,
    isSuccess,
    isError,
    license,
    registerLoquendo,
    isRegistering: registerLoquendoMutation.isLoading,
    isRegistered,
  } as const
}

export default useLoquendo
