import { ActionType, createAction } from 'typesafe-actions'
import { Company, UserCompanyProfile, UserRole } from './selectors'

export const LOAD_PROFILE = 'load_profile'
export const LOAD_COMPANY_PROFILE = 'load_company_profile'
export const LOAD_PROFILE_COMPLETED = 'load_profile_completed'
export const LOAD_PROFILE_FAILED = 'load_profile_failed'
export const LOAD_COMPANY_PROFILE_COMPLETED = 'load_company_profile_completed'
export const LOAD_COMPANY_PROFILE_FAILED = 'load_company_profile_failed'
export const START_UPLOADING_PROFILE_IMAGE = 'start_uploading_profile_image'
export const UPLOAD_PROFILE_IMAGE_COMPLETE = 'upload_profile_image_complete'
export const RELOAD_PROFILE = 'reload_profile'
export const INVITE_COMPANY_MEMBER = 'invite_company_member'
export const REMOVE_COMPANY_MEMBER = 'remove_company_member'
export const UPDATE_COMPANY_MEMBER = 'update_company_member'
export const INVITE_COMPANY_MEMBER_COMPLETED = 'invite_company_member_completed'
export const REMOVE_COMPANY_MEMBER_COMPLETED = 'remove_company_member_completed'
export const UPDATE_COMPANY_MEMBER_COMPLETED = 'update_company_member_completed'
export const INVITE_COMPANY_MEMBER_ERROR = 'invite_company_member_error'
export const REMOVE_COMPANY_MEMBER_ERROR = 'remove_company_member_error'
export const UPDATE_COMPANY_MEMBER_ERROR = 'update_company_member_error'
export const ACCEPT_COMPANY_INVITE = 'accept_company_invite'
export const REJECT_COMPANY_INVITE = 'reject_company_invite'
export const ACCEPT_REJECT_COMPANY_INVITE_ERROR = 'accept_reject_company_invite_error'

export interface Avatar {
    content: Buffer,
    type: string
}

const marketingCategories = ['tcn'] as const

export type MarketingCategory = typeof marketingCategories[number]
export type MarketingAcceptance = {
    enabled: boolean,
    updatedAt: string
}
export type UserMarketing = Partial<Record<MarketingCategory, MarketingAcceptance>>

export interface UserInvitation extends Pick<Company, 'businessName' | 'logoUrl'> {
    companyId: string
    email: string
    invitedAt: string
    lastResendAt: string
    role: UserRole,
    sentInvitations: number
}

export interface InviteCompanyMember {
    companyId: string, 
    email: string,
    aclEnabled?: boolean
}

export interface RemoveCompanyMember {
    type: string, 
    companyId: string, 
    email: string
}

export interface UpdateCompanyMember {
    companyId: string, 
    memberId: string,
    role?: string
    aclEnabled?: boolean
    panels?: string[]
}

export const loadProfile = createAction(LOAD_PROFILE)

export const loadCompanyProfile = createAction(LOAD_COMPANY_PROFILE, (action) => {
    return (companyId: string) => action({ companyId })
})

export const loadProfileFailed = createAction(LOAD_PROFILE_FAILED, (action) => {
    return (error?: string) => action({ error })
})

export const loadProfileCompleted = createAction(LOAD_PROFILE_COMPLETED, (action) => {
    return (userProfile: UserCompanyProfile) => action(userProfile)
})

export const loadCompanyProfileFailed = createAction(LOAD_COMPANY_PROFILE_FAILED, (action) => {
    return (error?: string) => action({ error })
})

export const loadCompanyProfileCompleted = createAction(LOAD_COMPANY_PROFILE_COMPLETED, (action) => {
    return (companyProfile: Company) => action(companyProfile)
})

export const startUploadProfileImage = createAction(START_UPLOADING_PROFILE_IMAGE, (action) => {
    return (content: Buffer, type: string) => action({ content, type })
})

export const inviteCompanyMember = createAction(INVITE_COMPANY_MEMBER, (action) => {
    return (inviteCompanyMemberDetails: InviteCompanyMember) => action({ ...inviteCompanyMemberDetails })
})

export const removeCompanyMember = createAction(REMOVE_COMPANY_MEMBER, (action) => {
    return (removeCompanyMemberDetails: RemoveCompanyMember) => action({ ...removeCompanyMemberDetails })
})

export const updateCompanyMember = createAction(UPDATE_COMPANY_MEMBER, (action) => {
    return (updateCompanyMemberDetails: UpdateCompanyMember) => action({ ...updateCompanyMemberDetails })
})

export const inviteCompanyMemberCompleted = createAction(INVITE_COMPANY_MEMBER_COMPLETED)

export const removeCompanyMemberCompleted = createAction(REMOVE_COMPANY_MEMBER_COMPLETED)

export const updateCompanyMemberCompleted = createAction(UPDATE_COMPANY_MEMBER_COMPLETED)

export const inviteCompanyMemberError = createAction(INVITE_COMPANY_MEMBER_ERROR)

export const removeCompanyMemberError = createAction(REMOVE_COMPANY_MEMBER_ERROR)

export const updateCompanyMemberError = createAction(UPDATE_COMPANY_MEMBER_ERROR)

export const uploadProfileImageComplete = createAction(UPLOAD_PROFILE_IMAGE_COMPLETE)

export const reloadProfile = createAction(RELOAD_PROFILE)

export const acceptCompanyInvite = createAction(ACCEPT_COMPANY_INVITE, (action) => {
    return (companyId: string) => action({ companyId })
})

export const rejectCompanyInvite = createAction(REJECT_COMPANY_INVITE, (action) => {
    return (companyId: string) => action({ companyId })
})

export const acceptRejectCompanyInviteError = createAction(ACCEPT_REJECT_COMPANY_INVITE_ERROR)

export type LoadProfileActions = ActionType<typeof loadProfile | typeof loadProfileFailed | typeof loadProfileCompleted | typeof startUploadProfileImage 
    | typeof uploadProfileImageComplete | typeof reloadProfile | typeof inviteCompanyMember | typeof removeCompanyMember | typeof updateCompanyMember | typeof loadCompanyProfile
    | typeof loadCompanyProfileFailed | typeof loadCompanyProfileCompleted | typeof acceptCompanyInvite | typeof rejectCompanyInvite | typeof inviteCompanyMemberCompleted 
    | typeof removeCompanyMemberCompleted | typeof updateCompanyMemberCompleted | typeof inviteCompanyMemberError | typeof removeCompanyMemberError | typeof updateCompanyMemberError
    | typeof acceptRejectCompanyInviteError>
