import React, { useState, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { CompanyRole, getCompanyRole } from '../../profile/selectors'
import Feedback from '../Feedback/Feedback'

export const MenuFeedback: VFC = () => {
    const { t } = useTranslation()
    const [feedBackShow, setFeedBackShow] = useState(false)
    const userAccessType = useSelector(getCompanyRole)
    return [CompanyRole.Admin].includes(userAccessType) ?
        <>
            <div className="bg-gray-900 flex items-center px-4 py-2 border-t border-gray-800">
                <button type="button" className="flex items-center text-gray-200 hover:text-white text-sm" onClick={() => setFeedBackShow(!feedBackShow)}>
                    <span className="w-8 flex justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                    </span>
                    <span className="ml-4">{t('leaveAFeedback')}</span>
                </button>
            </div>
            <Feedback show={feedBackShow} onClose={() => setFeedBackShow(false)} />
        </> : null
}