import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useDispatch } from 'react-redux'
import { Button } from '../../common/Button'
import { useUserMutation } from '../../hooks/createCompanyWizard'
import { useLoading } from '../../hooks/loading'
import { notify } from '../../notification/actions'
import { reloadProfile } from '../actions'
import { FirstStepsWrapper } from './FirstStepsWrapper'
import Input from '../../common/Input/Input'
import { SubmitHandler, useForm } from 'react-hook-form'
import _ from 'lodash'
import { State } from '../../configureStore'
import { getUserCompanyProfile, UserCompanyProfile } from '../selectors'

type Props = {
    name?: string
    surname?: string
}

export const FirstStepsPersonalData: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation()
    const { update } = useUserMutation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const { register, handleSubmit, formState } = useForm<Props>({ defaultValues: props, mode: 'onChange' });

    const onSubmit: SubmitHandler<FormData> = async (form) => {
        const body = _.omitBy(form, _.isUndefined)
        try {
            await execute(() => update(body))
            dispatch(reloadProfile())
        } catch (error: any) {
            setError('userUpdateError')
            dispatch(notify('error', 'userUpdateError', 5000))
        }
    }

    return (
        <FirstStepsWrapper pending={loading} error={error}>
            <p className="uppercase text-sm text-brand">{t('firstStepPersonalDataHeader')}</p>
            <p className="text-xl">{t('firstStepPersonalDataTitle')}</p>
            <form
                className='flex flex-col max-w-xs mt-8 gap-4 mx-auto'
                onSubmit={handleSubmit(onSubmit)}
            >
                <Input
                    containerClass="col-span-6 sm:col-span-3"
                    id="name"
                    name="name"
                    label={t('registrationName')}
                    type="text"
                    ref={register({ required: true })}
                    required
                />
                <Input
                    containerClass="col-span-6 sm:col-span-3"
                    id="surname"
                    name="surname"
                    label={t('registrationSurname')}
                    type="text"
                    ref={register({ required: true })}
                    required
                />
                <Button
                    color="primary"
                    size="xl"
                    text={t('update')}
                    role="submit"
                    disabled={!formState.isValid}
                />
            </form>
        </FirstStepsWrapper>
    )
}

export function mapStateToProps(state: State) {
    const profile = getUserCompanyProfile(state) as UserCompanyProfile
    return {
        name: profile.name,
        surname: profile.surname
    }
}

export default connect(mapStateToProps)(FirstStepsPersonalData)
