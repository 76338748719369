import React, { VFC, Fragment, useState } from 'react'
import { Dialog } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import Rating from '../Rating'
import { Button } from '../Button'
import Select from '../Select'
import { useDispatch } from 'react-redux'
import { sendFeedback } from './actions'
import { Modal } from '../Modal'

type Props = {
    question?: string,
    questionFeature?: string
    show: boolean,
    onClose: () => void
}

type FeedbackState = {
    feature: string,
    value: number,
    comment: string
}

const options = [
    {
        label: 'feedbackFeatureNameGeneral',
        value: 'general'
    },
    {
        label: 'feedbackFeatureNameLoquendo',
        value: 'loquendo'
    },
    {
        label: 'feedbackFeatureNamePanelsList',
        value: 'panels-list'
    },
    {
        label: 'feedbackFeatureNameAccount',
        value: 'account'
    },
    {
        label: 'feedbackFeatureNameCompany',
        value: 'company'
    },
    {
        label: 'feedbackFeatureNameDocuments',
        value: 'documents'
    }
]

const Feedback: VFC<Props> = (props) => {
    const { question, questionFeature, onClose, show } = props
    const [feedback, setFeedback] = useState<FeedbackState>({
        feature: questionFeature || options[0].value,
        value: 0,
        comment: ''
    })
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const isDisabled = () => {
        if (feedback.value === 0 || (feedback.value <= 3 && feedback.comment?.trim() === '')) {
            return true
        }
        return false
    }
    return (
        <Modal open={show} onClose={onClose} size="lg">
            <div>
                {/* START Header */}
                <div className="px-12 py-5">
                    <h2 className="text-gray-800 text-center text-3xl font-semibold">{t('feedbackFormTitle')}</h2>
                </div>
                {/* STOP Header */}
                {/* START Body */}
                <div className="mt-3 text-center sm:mt-5">
                    {question ? (
                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            {question}
                        </Dialog.Title>
                    ) :
                        <>
                            <Select
                                label={t('feedbackHintFeature')}
                                otherLabelClasses="text-center"
                                id="feature"
                                name="feature"
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    setFeedback({
                                        ...feedback,
                                        feature: e.target.value
                                    })
                                }}
                            >
                                {options.map(({ label, value }) => (
                                    <option key={value} value={value}>{t(label)}</option>
                                ))}
                            </Select>
                        </>
                    }
                    <Rating
                        className="mt-4 mx-2"
                        onRate={(value) => {
                            setFeedback({
                                ...feedback,
                                value: value
                            })
                        }}
                    />
                    <div className="mt-4">
                        <label htmlFor="comment" className="sr-only">
                            {t('comment')}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <textarea
                                id="about"
                                name="about"
                                rows={2}
                                className="max-w-lg shadow-sm block w-full focus:ring-brand-500 focus:border-brand-500 sm:text-sm border border-gray-300 rounded-md"
                                defaultValue={''}
                                onChange={(e) => setFeedback({
                                    ...feedback,
                                    comment: e.target.value
                                })}
                            />
                            {!feedback.value || feedback.value >= 4 ? (
                                <p className="mt-2 text-sm text-gray-500">{t('feedbackComment')}</p>
                            ) : (
                                <p className="mt-2 text-sm font-semibold  text-red-500">{t('feedbackCommentBad')}</p>
                            )}
                        </div>
                    </div>

                </div>
                {/* STOP Body */}
            </div>
            {/* START Footer */}
            <div className="mt-5 sm:mt-6 grid grid-cols-1 grid-rows-2 gap-3 grid-flow-row-dense">
                <Button
                    color="primary"
                    disabled={isDisabled()}
                    full
                    text={t('sendFeedback')}
                    type="button"
                    onClick={() => {
                        dispatch(sendFeedback(feedback.feature, feedback.value, feedback.comment))
                        onClose()
                    }}
                />
                <Button
                    full
                    text={t('maybeLater')}
                    type="button"
                    onClick={() => onClose()}
                />
            </div>
            {/* STOP Footer */}
        </Modal>
    )
}

export default Feedback