import React, { Fragment, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { Link, useHistory } from 'react-router-dom'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { Avatar } from '../common/Avatar'
import { State } from '../configureStore'
import { getProfile, joinCompanyInvitations } from './selectors'
import { logout } from '../logout/actions'
import { Button } from '../common/Button'
import { Spinner } from '../common/Spinner'
import { acceptCompanyInvite, rejectCompanyInvite } from './actions'

type JoinCompanyProps = PropsFromRedux & {}

export const JoinCompany: React.VFC<JoinCompanyProps> = (props) => {
    const { invitations = [], profile, onLogout, onAccept, onReject, pending } = props
    const { t } = useTranslation()
    const history = useHistory()
    const invitationsRef = useRef<number>()

    useEffect(() => {
        invitationsRef.current = invitations.length
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (invitationsRef.current === 1 && invitations.length === 0) {
            history.push('/')
        }
    }, [invitations.length, history])

    const onAcceptHandler = (companyId: string) => () => onAccept(companyId)
    const onRejectHandler = (companyId: string) => () => {
        onReject(companyId)
        if (invitationsRef.current && invitationsRef.current > 1) {
            invitationsRef.current--
        }
    }

    return (
        <div aria-label='join-company' className="m-auto max-w-4xl relative">
            <Spinner show={pending} containerClass='bg-gray-50' size={16} />
            {invitations.length > 0 ? (
                <>
                    <div className={clsx('text-center my-4 sm:my-8 lg:my-12', invitations.length <= 1 && 'hidden')}>
                        {t('youHaveReceived')} <span className="bg-brand text-white inline-block w-6 h-6 rounded-full">{invitations.length}</span> {t('invitations')}<br /> {t('onlyOneAccepted')}
                    </div>
                    <Tab.Group>
                        <Tab.List className={clsx("grid gap-x-8 grid-flow-col auto-cols-fr mb-12", invitations.length <= 1 && 'hidden')}>
                            {invitations.map(invitation => (
                                <Tab key={invitation.companyId} as={Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={clsx('uppercase border-b-4 border-gray-100 px-4 py-2', selected && 'border-brand-500')}
                                        >
                                            Join <span className="text-brand">{invitation.businessName}</span>
                                        </button>
                                    )}
                                </Tab>
                            ))}
                        </Tab.List>
                        <Tab.Panels>
                            {invitations.map(invitation => {
                                return (
                                    <Tab.Panel key={invitation.businessName}>
                                        <div className="flex flex-col items-center" key={invitation.businessName}>
                                            <div className='flex flex-col items-center gap-2 mb-4'>
                                                <Avatar size={32} type='circular' src={invitation.logoUrl} otherClasses="shadow-lg border-4 border-white" />
                                                <span className='uppercase text-sm text-brand mt-2'>{invitation.businessName}</span>
                                            </div>
                                            <div className="flex flex-col items-center text-center gap-4">
                                                <h1 className="text-2xl font-bold">{t('joinCompanyTitle')} <span className="text-brand">SecureWeb</span></h1>
                                                <p className="text-xl font-bold">{t('joinCompanyAs')} <span className='text-brand'>{profile.email}</span></p>
                                                <p className="max-w-2xl text-xl">{t('joinCompanyDesc')}<br /><span className="font-bold border-b-2 pb-1 border-brand-500">{t('joinCompanyAlert')}</span></p>
                                                <div className='flex flex-col gap-4 mt-8'>
                                                    <Button color="primary" size='2xl' text={t('joinCompanyAccept')} onClick={onAcceptHandler(invitation.companyId)} disabled={pending} />
                                                    <Button text={t('reject')} onClick={onRejectHandler(invitation.companyId)} link disabled={pending} />
                                                </div>
                                            </div>
                                        </div>
                                    </Tab.Panel>
                                )
                            })}
                        </Tab.Panels>
                    </Tab.Group>
                </>
            ) : (
                <div className="flex flex-col items-center">
                    <div className="flex flex-col items-center text-center gap-4">
                        <h1 className="text-2xl font-bold">{t('joinCompanyEmptyTitle')}</h1>
                        <p className="text-xl font-bold">{t('loggedInAs')} <span className='text-brand'>{profile.email}</span></p>
                        <p className="max-w-2xl text-xl">{t('joinCompanyEmptyDesc1')}<br />{t('joinCompanyEmptyDesc2')}</p>
                        <div className='flex flex-col gap-4 mt-8'>
                            <Button color="primary" size='2xl' text={t('logout')} onClick={onLogout} />
                            <Link to="/dashboard">{t('ignore')}</Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export function mapStateToProps(state: State) {
    return {
        profile: getProfile(state),
        invitations: joinCompanyInvitations(state),
        pending: state.profile.loading
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onLogout() {
            dispatch(logout())
        },
        onAccept(companyId: string) {
            dispatch(acceptCompanyInvite(companyId))
        },
        onReject(companyId: string) {
            dispatch(rejectCompanyInvite(companyId))
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(JoinCompany)