import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom"
import { Button } from '../../common/Button'
import { skipCompanyStep } from './actions'
import { FirstStepsWrapper } from './FirstStepsWrapper'

const FirstStepsCompany = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const history = useHistory()

    const createCompanyHandler = () => {
        dispatch(skipCompanyStep())
        history.push('/createCompany')
    }
    
    return (
        <FirstStepsWrapper>
            <p className="uppercase text-sm text-brand">{t('firstStepsCompanyHeader')}</p>
            <p className="text-xl">{t('firstStepsCompanyTitle')}</p>
            <p></p>
            <div className='flex flex-col max-w-xs mt-8 gap-4 mx-auto'>
                <Button color="primary" size="xl" text={t('firstStepsCompanyPrimaryAction')} as="button" onClick={() => createCompanyHandler()} />
                <Button link text={t('firstStepsCompanySecondaryAction')} onClick={() => dispatch(skipCompanyStep())} />
            </div>
        </FirstStepsWrapper>
    )
}

export default FirstStepsCompany