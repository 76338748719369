import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Input from '../../common/Input/Input'
import Select from '../../common/Select'
import BrandsChoice, { Brand } from './BrandsChoice'
import { UseFormMethods } from 'react-hook-form'
import { isItalian } from '../../billing/utils'
import { Company } from '../../profile/selectors'
import { generatePhoneNumberCountries } from '../../registration/Registration'
import { testCuu, testEmail, testPhone } from '../../common/utils'
import DistributorsNote from './DistributorsNote'
import DistributorChoice from './DistributorChoice'
import { availableCountries, getRegionsByCountry } from './utils'
import { useDistributors } from '../../hooks/createCompanyWizard/useDistributors'
import { useDebounce } from '../../hooks/utils'

export default function InfoCompany(props: {
    brands?: Array<Brand>,
    distributors?: Company[],
    vatNumber: string,
    vatNumberCountry: string,
    country: string,
    useFormProps: UseFormMethods<Record<string, any>>
}) {
    const { t } = useTranslation()
    const { brands, useFormProps, country } = props
    const {
        register,
        unregister,
        errors,
        getValues,
        setValue,
        trigger,
        control,
        watch
    } = useFormProps
    const [distributorBusinessName, setDistributorBusinessName] = useState('')
    const debouncedDistributorBusinessName = useDebounce(distributorBusinessName)
    const watchCountry = watch('country', country)
    const watchProvince = watch('province', '')

    const { areaDistributors } = useDistributors({
        businessName: debouncedDistributorBusinessName,
        country: watchCountry,
        //TODO Verify country source
        province: watchCountry === 'IT' ? watchProvince : undefined
    })

    const regions = getRegionsByCountry(watchCountry)

    // eslint-disable-next-line
    const setDefaultUser = useCallback(async () => {
        trigger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDefaultUser()
    }, [setDefaultUser])

    useEffect(() => {
        setValue('country', props.country)
    }, [props.country, setValue])

    useEffect(() => {
        /*
        * Tip: unregister('fiscalCode')
        * Issue: used unregister in order to avoid any kind
        * of validation due to a previous step in case of no
        * vat but choosing an extra EU country 
        */
        if (!isItalian(watchCountry))
            unregister('fiscalCode')
    }, [watchCountry, unregister])

    useEffect(() => {
        setValue('vatNumber', props.vatNumber)
    }, [props.vatNumber, setValue])

    const isCuuValid = (cuu: string) => {
        return !isItalian(getValues('country')) || (testEmail(getValues('pec')) && !cuu) || testCuu(cuu)
    }

    const isPecValid = (pec: string) => {
        return !isItalian(getValues('country')) || (testCuu(getValues('cuu')) && !pec) || testEmail(pec)
    }

    const isPhoneValid = (phone: string) => {
        return testPhone(getValues('phone')) || testPhone(phone)
    }

    const isItalianFreelance = () => isItalian(watchCountry) && !props.vatNumber
    const isItalianCompany = () => isItalian(watchCountry) && props.vatNumber

    return (<>
        <div data-testid="installer-company-data" className="p-4 space-y-10">
            <div>
                <h3 className="text-lg leading-6 text-brand-500 pb-2 border-b border-gray-100">
                    {t('registrationCountry')}
                </h3>
                <div className="mt-6 space-y-4">
                    <Select
                        data-testid="select-a-country"
                        required
                        containerClass=""
                        id="country"
                        name="country"
                        ref={register({ required: true })}
                    >
                        <option value="">{t('selectACountry')}</option>
                        {availableCountries.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                    </Select>
                </div>
            </div>
            <div>
                <h3 className="text-lg leading-6 text-brand-500 pb-2 border-b border-gray-100">
                    {t('registrationCompanyInformation')}
                </h3>
                <p className="text-ms pt-4 leading-6">
                    {t('registrationCompanyInformationHint')}
                </p>
                <div className="mt-6 space-y-4">
                    <Input type="text"
                        show={!isItalianFreelance()}
                        label={t('registrationCompany')}
                        name={'businessName'}
                        data-testid="registration-businessName"
                        ref={register({ required: true })}
                        id="companyname"
                        required
                    />
                    <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                        <Input type="text"
                            label={t('registrationCity')}
                            name={'city'}
                            ref={register({ required: true })}
                            id="city"
                            required
                        />
                        <Input type="text"
                            label={t('registrationAddress')}
                            name={'address'}
                            ref={register({ required: true })}
                            id="address"
                            required
                        />
                    </div>
                    <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                        <Select
                            data-testid="registration-province"
                            required
                            containerClass=""
                            id="province"
                            name="province"
                            label={t('registrationProvince')}
                            ref={register({ required: true })}
                        >
                            <option value="">{t('selectAProvince')}</option>
                            {regions ? regions.map(o =>
                                <option
                                    data-testid={`${o.shortCode.toLowerCase().replace(/\s/g, '-')}`}
                                    key={o.shortCode}
                                    value={o.shortCode}>{o.name}
                                </option>) : null
                            }
                        </Select>
                        <Input
                            type="text"
                            ref={register({
                                required: true
                            })}
                            name={'zipCode'}
                            label={t('registrationZipCode')}
                            id="zipcode" />
                    </div>
                    <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                        <Input type="email"
                            label={t('registrationCompanyEmail')}
                            ref={register({
                                required: true,
                                validate: testEmail
                            })}
                            // onChange={(e) => onChange('email', e.target.value)}
                            name={'email'}
                            id="email">
                            {errors.email && (
                                <Input.Alert>
                                    {t('invalidEmail')}
                                </Input.Alert>
                            )}
                        </Input>
                        <Input
                            id="company-phone-number"
                            name="phoneNumber"
                            type="text"
                            required
                            label={t('registrationPhone')}
                            dropdown={{
                                id: 'country',
                                label: 'Country',
                                emptyLabel: t('registrationCountry'),
                                name: 'country',
                                options: generatePhoneNumberCountries(),
                                props: { required: true, name: 'countryPhoneNumber', ref: register() }
                            }}
                            ref={register({
                                required: true,
                                validate: isPhoneValid
                            })}
                            inputClasses="pl-32"
                        >  {errors.phoneNumber && (
                            <Input.Alert>
                                {t('invalidPhone')}
                            </Input.Alert>
                        )}
                        </Input>
                    </div>
                    {!!props.vatNumber ? <div className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                        <Input
                            prepend={props.vatNumberCountry}
                            type="text"
                            disabled={true}
                            name="vatNumber"
                            label={t('registrationVatNumber')}
                            ref={register({ required: true })}
                            id="vatnumber" />
                    </div> : null}
                    <Input type="text"
                        show={isItalianFreelance()}
                        containerClass='sm:grid sm:grid-cols-1 sm:gap-4 sm:items-start sm:pt-5'
                        ref={register({ required: true })}
                        name={'fiscalCode'}
                        label={t('registrationFiscalCode')}
                        id="fiscalcode"
                        pattern="^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$"
                        maxLength={16}
                        inputClasses={'uppercase'}
                    />
                </div>
            </div>
            {isItalianCompany() ? <div>
                <h3 className="text-lg leading-6 text-brand-500 pb-2 border-b border-gray-100">
                    {t('registrationBillingInformation')}
                </h3>
                <div className="mt-6">
                    <div
                        className="sm:grid sm:grid-cols-2 sm:gap-4 sm:items-start">
                        <Input type="email"
                            label={`${t('registrationPec')} (${t('alternativeOption').toLowerCase()})`}
                            invalid={errors.hasOwnProperty('pec')}
                            ref={register({
                                validate: isPecValid
                            })}
                            name="pec"
                            id="pec">
                            {errors.pec && (
                                <Input.Alert>
                                    {t('invalidPec')}
                                </Input.Alert>
                            )}
                        </Input>
                        <Input type="text"
                            invalid={errors.hasOwnProperty('cuu')}
                            label={`${t('registrationCuu')} (${t('alternativeOption').toLowerCase()})`}
                            name="cuu"
                            ref={register({
                                validate: isCuuValid
                            })} id="cuu">
                            {errors.cuu && (
                                <Input.Alert>
                                    {t('invalidCuu')}
                                </Input.Alert>
                            )}
                        </Input>
                    </div>
                </div>
            </div> : null}
            <div>
                <h3 className="text-lg leading-6 font-medium text-brand-500 pb-2 border-b border-gray-100">
                    {t('registrationAdditionalInformation')}
                </h3>
                <div className="mt-6">
                    <DistributorChoice
                        show={watchCountry !== 'IT' || watchProvince}
                        control={control}
                        onSearch={setDistributorBusinessName}
                        distributors={areaDistributors}
                        invalid={!!errors.distributor}
                    />
                    <DistributorsNote
                        show={watch('distributor')?.value === 'none'}
                        invalid={!!errors.distributorsNote}
                        ref={register({ required: true })}
                    />
                    <BrandsChoice
                        show={!!brands?.length}
                        useFormProps={props.useFormProps}
                        brands={brands}
                        optional={true}
                    />
                </div>
            </div>
        </div>
    </>)
}