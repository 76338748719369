import { useEffect, useState } from "react";

const getLanguageFromNavigator = () => (navigator.languages && navigator.languages[0]) || navigator.language

export const useBrowserLanguage = () => {
    const [language, setLanguage] = useState<string>()

    useEffect(() => {
        setLanguage(getLanguageFromNavigator())
    }, [])

    return language
}