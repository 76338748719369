import React from 'react'
import { Button } from '../common/Button'
import clsx from 'clsx'
import { CheckIcon } from '@heroicons/react/outline'

export interface LevelFeature {
    name: string,
    description?: string,
    items?: string[],
    buttonTitle?: string,
    buttonOnClick?: () => void,
    buttonColor?: "white" | "primary" | "secondary" | undefined
}

export default function LevelFeatures(props: {
    features: LevelFeature[]
}) {
    const { features } = props
    let featureId = 0
    let itemId = 0
    const listClasses = clsx('space-y-6 md:space-y-0 md:grid md:gap-y-10 md:divide-x md:divide-gray-100 px-4 sm:px-6', {
        'md:grid-cols-1': features.length === 1,
        'md:grid-cols-2': features.length === 2,
        'md:grid-cols-3': features.length === 3,
        'md:grid-cols-4': features.length === 4,
        'md:grid-cols-5': features.length === 5
    })

    return <dl className={listClasses}>
        {features.map((feature) => (
            <div key={`levelFeature-${++featureId}`} className="relative py-4">
                <dt>
                    <p className={clsx('text-lg leading-6 font-medium text-gray-900 px-4',
                        (!features[0].items || features[0].items.length === 0) && 'text-center')
                    }>{feature.name}</p>
                </dt>
                <dd className={clsx('mt-2 text-gray-500 px-2',
                    (!features[0].items || features[0].items.length === 0) && 'text-center')}>
                    {feature.description || null}
                    {feature.items && feature.items.map((item) => (
                        <div key={`levelItem-${++itemId}`} className="flex flex-auto">
                            <div>
                                <CheckIcon className="mr-3 h-5 w-5 text-green-400" aria-hidden="true" />
                            </div>
                            <span>{item}</span>
                        </div>
                    ))}

                    {(feature.buttonTitle && feature.buttonColor && feature.buttonOnClick) ?
                        <div className="mt-2 sm:mt-6">
                            <Button
                                color={feature.buttonColor}
                                text={feature.buttonTitle}
                                type="button"
                                onClick={feature.buttonOnClick}
                            />
                        </div> : null}
                </dd>
            </div>
        ))}
    </dl>
}