import { ActionType, createAction } from 'typesafe-actions'
import { notify } from '../notification/actions'

export const SAVE_LICENSES = 'save_licenses'
export const SAVE_VOICES = 'save_voices'
export const SELECT_LICENSE = 'select_license'
export const ADD_LICENSE = 'add_license'
export const GET_LICENSE = 'get_license'
export const SAVE_LICENSE = 'save_license'
export const SAVE_LICENSE_COMPLETE = 'save_license_complete'
export const CANCEL_SELECTED_LICENSE = 'cancel_selected_license'
export const CHANGE_LICENSE = 'change_license'
export const INVALID_LICENSE = 'invalid_license'
export const RELOAD_LICENSES = 'reload_licenses'

export interface License {
    id: string,
    alreadyRegistered: boolean,
    voiceCount: number,
    userId?: string,
    voices: string[],
    registeredBy?: string
}

export const saveLicenses = createAction(SAVE_LICENSES, (action) => {
    return (licenses: License[]) => action(licenses)
})

export const saveVoices = createAction(SAVE_VOICES, (action) => {
    return (voices: string[]) => action(voices)
})

export const selectLicense = createAction(SELECT_LICENSE, (action) => {
    return (license: License) => action(license)
})

export const addLicense = createAction(ADD_LICENSE)

export const cancelSelectedLicense = createAction(CANCEL_SELECTED_LICENSE)

export const getLicense = createAction(GET_LICENSE, (action) => {
    return (id: string) => action(id)
})

export const saveLicense = createAction(SAVE_LICENSE, (action) => {
    return (license: License, mode: 'add' | 'edit') => action({ license, mode })
})

export const saveLicenseComplete = createAction(SAVE_LICENSE_COMPLETE, (action) => {
    return (license: License) => action(license)
})

export const changeLicense = createAction(CHANGE_LICENSE, (action) => {
    return (key: string, value?: string | object) => action({
        key,
        value
    })
})

export const invalidLicense = createAction(INVALID_LICENSE)

export const reloadLicenses = createAction(RELOAD_LICENSES)

export type LicensesActions = ActionType<typeof saveLicenses | typeof selectLicense | typeof addLicense | typeof getLicense
    | typeof saveLicense | typeof saveLicenseComplete | typeof cancelSelectedLicense | typeof changeLicense
    | typeof saveVoices | typeof notify | typeof invalidLicense | typeof reloadLicenses>
