import clsx from 'clsx'
import React from 'react'

export interface ShuttleListItemI {
    [index: string]: string
    id: string,
    title: string,
    subtitle: string
}

interface ShuttleListItemsProps {
    id: string,
    title: string,
    subtitle: string,
    icon: React.ReactNode,
    onClick: (id: string) => void,
    disabled: boolean
}

const ShuttleListItem = (props: ShuttleListItemsProps) => {
    const { id, title, subtitle, onClick, icon, disabled } = props

    return (
        <div className={styles.item} key={id}>
            <div className={clsx({ 'opacity-30': disabled })}>
                <span className={styles.title}>{title}</span>
                <span className={styles.subtitle}>{subtitle}</span>
            </div>
            <div className={styles.action} >
                <button
                    data-testid="add-remove"
                    className={styles.button}
                    type="button"
                    onClick={() => onClick(id)}
                    disabled={disabled}
                >
                    {icon}
                </button>
            </div>
        </div>
    )
}

const styles = {
    container: 'bg-gray-50 overflow-x-hidden overflow-y-auto',
    item: 'grid grid-cols-[1fr,32px] items-center px-4 py-2',
    action: 'flex items-center justify-center',
    title: 'block',
    subtitle: 'block text-sm text-gray-500',
    button: 'rounded-full p-2 text-gray-400 border-dashed border-gray-300 border bg-white hover:bg-brand-500 hover:text-white disabled:cursor-default disabled:opacity-30 disabled:hover:bg-white disabled:hover:text-gray-400',
}

export default ShuttleListItem