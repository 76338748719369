import React, {FC } from 'react'

const logo = require('../../../images/logo-proalarm.svg').default

const FullBodyLayout: FC = (props) => {
    const { children } = props
    return (
        <div className="h-screen flex flex-col items-center justify-center bg-gray-50">
            <header className="w-full flex items-center justify-center border border-transparent border-b-gray-100 bg-gray-800 mb-auto">
                <img src={logo} alt="ProAlarm logo" className='h-16' />
            </header>
            <main role="main" className='w-full flex-1 flex flex-col items-center justify-center'>
                {children}
            </main>
            <footer />
        </div>
    )
}

export default FullBodyLayout