import { Epic } from 'redux-observable'
import { ActionTypes, State } from '../configureStore'
import { from, Observable, of } from 'rxjs'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { LOGIN_COMPLETED } from '../login/actions'
import { notify } from '../notification/actions'
import { AmplifySession } from '../panels/epics'
import {
    ADD_LICENSE,
    CHANGE_LICENSE,
    invalidLicense,
    License,
    LicensesActions,
    RELOAD_LICENSES,
    saveLicense,
    saveLicenseComplete,
    saveLicenses,
    saveVoices,
    SELECT_LICENSE,
    selectLicense
} from './licenseActions'
import { AjaxResponse } from 'rxjs/ajax'
import { isActionOf, isOfType } from 'typesafe-actions'
import { noop } from '../common/actions'
import { OPEN_MODAL_VOICE } from './voicesActions'

const LICENSES_URI = 'licenses'
const VOICES_URI = 'voices'


export const getLicenses$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<License[]>
    }> = (action$, state$, { currentSession, fetchJson }) =>
    action$.pipe(
        filter(act => act.type === LOGIN_COMPLETED || act.type === RELOAD_LICENSES),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res =>
                        fetchJson(LICENSES_URI, { authorization: `Bearer ${res.idToken.jwtToken}` })
                            .pipe(
                                map(response => saveLicenses(response)),
                                catchError(error => of(notify('error', 'licensesLoadError', 3000, error.status)))
                            )
                    ))
        }))

export const getLicense$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<License>
    }> = (action$, state$, { currentSession, fetchJson }) =>
    action$.pipe(
        filter(isOfType(CHANGE_LICENSE)),
        filter(act => act.payload.key === 'id' && typeof act.payload.value === 'string' && act.payload.value.length === 10),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    filter(() => state$.value.licenses.value.every((v) => v.id !== action.payload.value)),
                    mergeMap(res =>
                        fetchJson(`${LICENSES_URI}/${action.payload.value}`, { authorization: `Bearer ${res.idToken.jwtToken}` })
                            .pipe(
                                map(response => selectLicense(response)),
                                catchError(error => error.status === 404 ? of(noop()) :
                                    of(notify('error', 'licenseLoadError', 3000, error.status)))
                            )
                    ))
        }))

export const invalidLicense$: Epic<ActionTypes,
    ActionTypes,
    State> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(CHANGE_LICENSE)),
        filter(act => act.payload.key === 'id' && typeof act.payload.value === 'string' && act.payload.value.length < 10),
        map(action => invalidLicense()))

export const getAvailableVoices$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<string[]>
    }> = (action$, state$, { currentSession, fetchJson }) =>
    action$.pipe(
        filter(act => act.type === SELECT_LICENSE || act.type === ADD_LICENSE ||  act.type === OPEN_MODAL_VOICE),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res =>
                        fetchJson(VOICES_URI, { authorization: `Bearer ${res.idToken.jwtToken}` })
                            .pipe(
                                map(response => saveVoices(response)),
                                catchError(error => of(notify('error', 'voicesLoadError', 3000, error.status)))
                            )
                    ))
        }))

export const saveLicense$: Epic<ActionTypes,
    LicensesActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>,
        put: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post, put }) =>
    action$.pipe(
        filter(isActionOf(saveLicense)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                        const headers = {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${res.idToken.jwtToken}`
                        }
                        if (action.payload.mode === 'add') {
                            return post(LICENSES_URI,
                                action.payload.license,
                                headers)
                                .pipe(
                                    mergeMap(response => of(saveLicenseComplete(response.response), notify('info', 'licenseAddSuccess'))),
                                    catchError(error => of(notify('error', 'licenseAddError', 3000, error.status)))
                                )
                        } else {
                            const { id, voices } = action.payload.license
                            return put(`${LICENSES_URI}/${id}`,
                                {
                                    voices
                                },
                                headers)
                                .pipe(
                                    mergeMap(response => of(saveLicenseComplete(action.payload.license), notify('info', 'licenseUpdateSuccess'))),
                                    catchError(error => of(notify('error', 'licenseUpdateError', 3000, error.status)))
                                )
                        }
                    }))
        }))
