import { ActionType, createAction } from 'typesafe-actions'

export const LOAD_LOYALTYPROGRAM = 'load_loyaltyprogram'
export const LOAD_LOYALTYPROGRAM_COMPLETED = 'load_loyaltyprogram_completed'
export const LOAD_LOYALTYPROGRAM_FAILED = 'load_loyaltyprogram_failed'
export interface Rank {
    position: number
    userId: number,
    points: number,
    year: number,
    you?: boolean,
    certificationLevel: number
}

export interface RankWithPrize extends Rank {
    thumb?: string,
    prize?: string
}
export interface Ranking {
    [key: string]: Array<Rank>
}

export const loadLoyaltyProgram = createAction(LOAD_LOYALTYPROGRAM)


export const loadLoyaltyProgramCompleted = createAction(LOAD_LOYALTYPROGRAM_COMPLETED, (action) => {
    return (data: Ranking) => action(data)
})

export const loadLoyaltyProgramFailed = createAction(LOAD_LOYALTYPROGRAM_FAILED, (action) => {
    return (error: string) => action({ error })
})

export type LoyaltyProgramActions = ActionType<typeof loadLoyaltyProgram | typeof loadLoyaltyProgramCompleted | typeof loadLoyaltyProgramFailed>
