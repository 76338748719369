import { createReducer } from "typesafe-actions"
import * as Actions from './actions'
import * as LogoutActions from '../logout/actions'
import { UserGroups } from "../hooks/amplify"

export interface LoginState {
    signingIn: boolean
    signedIn: boolean,
    checking: boolean
    groups: UserGroups[]
}

export const defaultLoginState: LoginState = { signingIn: false, signedIn: false, checking: true, groups: [] }

export const loginReducer = createReducer(defaultLoginState)
    .handleAction(Actions.login, (state, action) => ({
        ...state,
        signingIn: true
    }))
    .handleAction(Actions.loginFailed, (state, action) => ({
        ...state,
        signingIn: false,
    }))
    .handleAction(Actions.loginCompleted, (state, action) => ({
        signingIn: false,
        signedIn: true,
        checking: false,
        groups: []
    }))
    .handleAction(Actions.checkLogin, (state, action) => ({
        ...state,
        checking: true
    }))
    .handleAction(Actions.checkLoginComplete, (state, action) => ({
        ...state,
        checking: false
    }))
    .handleAction(LogoutActions.logoutCompleted, (state, action) => defaultLoginState)
    .handleAction(Actions.userGroupsRemove, (state, action) => ({
        ...state,
        groups: []
    }))
    .handleAction(Actions.userGroups, (state, action) => ({
        ...state,
        groups: [...action.payload]
    }))