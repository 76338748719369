import { UseFormMethods } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import React from 'react'

interface Props extends Pick<UseFormMethods, 'register'> {
    show: boolean
}

export function AdminNotes(props: Props) {
    const { t } = useTranslation()
    const { show, register } = props
    return show ? <div className="col-span-6">
        <label htmlFor="note" className="block text-sm font-medium text-gray-700 ">
            {t('notes')}
        </label>
        <div className="mt-1">
            <textarea
                rows={5}
                maxLength={500}
                id="note"
                name="note"
                className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                ref={register}
            />
        </div>
    </div> : null
}