import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Input from '../../common/Input/Input'
import Card from '../../common/Card/Card'
import { Button } from '../../common/Button'
import { useLoading } from '../../hooks/loading'
import { notify } from '../../notification/actions'
import { useFormInput } from '../../hooks/HandleChange'
import { validatePassword } from '../../common/Input/InputPasswordValidator'
import { NewPassword } from '../../common/NewPassword'
import { useAuth } from '../../auth/amplify'

export function UserProfileChangePassword(props: {}) {
    const { t } = useTranslation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()
    const { changePassword } = useAuth()

    const { reset: resetOldPassword, ...oldPassword } = useFormInput()
    const { reset: resetNewPassword, ...newPassword } = useFormInput()

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        try {
            await execute(async () => changePassword(
                oldPassword.value, newPassword.value
            ))
            resetOldPassword()
            resetNewPassword()
            dispatch(notify('info', t('passwordChanged')))
        } catch (error: any) {
            dispatch(notify('error', error.code))
        }
    }

    return (<div className="mt-10 sm:mt-0 md:grid md:grid-cols-4 md:gap-6">
        <div className="md:col-span-1">
            <div className="px-1 md:px-4">
                <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profileSectionChangePasswordTitle')}</h3>
                <p className="mt-1 text-sm text-gray-600">{t('profileSectionChangePasswordSubtitle')}</p>
            </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-3">
            <form onSubmit={onSubmit}>
                <Card pending={loading}>
                    <Card.Body>
                        <div className="grid grid-cols-1 gap-4">
                            <Input
                                id="oldPassword"
                                containerClass="col-span-1"
                                name="oldPassword"
                                type="password"
                                autoComplete="new-password"
                                label={t('changePasswordOldPassword')}
                                required
                                placeholder={t('passwordPlaceholder')}
                                passwordVisibility
                                {...oldPassword}
                            />
                            <NewPassword label={t('changePasswordNewPassword')} newPassword={newPassword} />
                        </div>
                    </Card.Body>
                    <Card.Footer>
                        <div className="flex items-center justify-end">
                            <Button type="submit" color="primary" text={t('update')}
                                disabled={!validatePassword(newPassword.value) || !validatePassword(oldPassword.value)}
                                pending={loading} />
                        </div>
                    </Card.Footer>
                </Card>
            </form>
        </div>
    </div>

    )
}
