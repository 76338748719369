import _ from 'lodash'

export type StepType = {
    id: 'vatNumber' | 'companyInfo' | 'confirmation'
    title: string
    subtitle: string
    status: 'current' | 'complete' | 'upcoming' | 'skip'
}

export function getDefaultsSteps(): StepType[] {
    return defaultStepsState.map((step, index) => ({
        ...step,
        title: `step${index + 1}`,
        status: index === 0 ? 'current' : 'upcoming'
    }))
}

export const defaultStepsState: StepType[] = [
    {
        id: 'vatNumber',
        title: '',
        subtitle: 'createCompanyVatNumberSubtitle',
        status: 'upcoming'
    },
    {
        id: 'companyInfo',
        title: '',
        subtitle: 'createCompanyInfoSubtitle',
        status: 'upcoming'
    },
    {
        id: 'confirmation',
        title: '',
        subtitle: 'createCompanyConfirmationSubtitle',
        status: 'upcoming'
    }
]

export const currentStep = (steps: StepType[]) => steps.find(s => s.status === 'current')?.id || steps[0].id
export const isFirstStep = (steps: StepType[]) => steps.findIndex(s => s.id === currentStep(steps)) === 0
export const isLastStep = (steps: StepType[]) => steps.findIndex(s => s.id === currentStep(steps)) === steps.length - 1
export const getBackOrBackToDashboard = (steps: StepType[]): 'backToDashboard' | 'back' => isFirstStep(steps) ? 'backToDashboard' : 'back'
export const getNextLabelButton = (steps: StepType[]): 'next' | 'save' | 'backToDashboard' => {
    switch (currentStep(steps)) {
        case 'vatNumber':
            return 'next'
        case 'companyInfo':
            return 'save'
        case 'confirmation':
            return 'backToDashboard'
    }
}

export const nextStep = (steps: StepType[], setSteps: (steps: StepType[]) => void, existingCompany: boolean) => {
    const currentIndex = steps.findIndex(s => s.id === currentStep(steps))
    const updatedSteps = _.cloneDeep(steps)
    if (currentStep(steps) === 'vatNumber') {
        const detailsIndex = updatedSteps.findIndex(step => step.id === 'companyInfo')
        updatedSteps[detailsIndex].status = !existingCompany ? 'upcoming' : 'skip'
    }
    updatedSteps[currentIndex].status = 'complete'
    const nextStepIndex = updatedSteps.findIndex(step => step.status === 'upcoming')
    updatedSteps[nextStepIndex].status = 'current'
    setSteps(updatedSteps)
}

export const previousStep = (steps: StepType[], setSteps: (steps: StepType[]) => void) => {
    const currentIndex = steps.findIndex(s => s.id === currentStep(steps))
    const updatedSteps = _.cloneDeep(steps)
    updatedSteps[currentIndex].status = 'upcoming'
    const prevStepIndex = _.findLastIndex(updatedSteps, step => step.status === 'complete')
    updatedSteps[prevStepIndex].status = 'current'
    setSteps(updatedSteps)
}