import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AcademicCapIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { CompanyMember } from './selectors'
import { removeCompanyMember, updateCompanyMember } from './actions'
import { certificationMapper } from './utils'
import { Modal } from '../common/Modal'
import { CompanyMembersACLManagement } from './CompanyMembersACLManagement'
import { CompanyMembersMenuDropDown } from './CompanyMembersMenuDropDown'

type CompanyMembersItemProps = {
    companyId: string
    member: CompanyMember
    canEdit: boolean
}

function getSpanClassName(status: string) {
    return clsx('inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        status === 'active' && 'bg-green-100 text-green-800',
        status === 'pending' && 'bg-yellow-100 text-yellow-800',
        status === 'rejected' && 'bg-red-100 text-red-800'
    )
}

function getSvgCertificationLevelClassName(certificationLevel: number) {
    return clsx('h-8 w-8 rounded',
        certificationLevel === 4 && 'bg-green-500 text-white',
        certificationLevel === 3 && 'bg-yellow-500 text-white',
        certificationLevel === 2 && 'bg-gray-500 text-white',
        certificationLevel === 1 && 'bg-red-500 text-white',
        certificationLevel === 0 && 'bg-gray-200 text-white'
    )
}

export function CompanyMembersItem(props: CompanyMembersItemProps) {
    const { t } = useTranslation()
    const { companyId, member, canEdit } = props
    const [showAcl, setShowAcl] = useState(false)
    const dispatch = useDispatch()
    const isActive = member.status === 'active'
    const isAdmin = member.role === 'admin'

    const cancelEditHandler = () => {
        setShowAcl(false)
    }

    const onAclSubmit = ({ aclEnabled, panels }: { aclEnabled: boolean, panels: string[] }) => {
        dispatch(updateCompanyMember({
            companyId: companyId,
            memberId: member.id,
            aclEnabled,
            panels
        }))
        cancelEditHandler()
    }

    return <div
        className="grid grid-rows-[min-content,1fr] grid-cols-[1fr,40px] sm:grid-cols-[80px,1fr,15%,15%,20%,40px] items-center py-3"
        role="row">
        <Modal
            open={showAcl}
            size="4xl"
            title={t('aclTitle')}
            modalClass="!pr-0 !pl-0 !pb-0"
            onClose={() => { }}
        >
            <CompanyMembersACLManagement
                memberId={member.id}
                aclEnabled={!!member.aclEnabled}
                onSubmit={onAclSubmit}
                onClose={cancelEditHandler} />
        </Modal>
        <div
            className="px-4 py-4 sm:py-0 sm:px-6 whitespace-nowrap text-sm text-gray-900 sm:row-start-auto row-start-5 order-4 sm:order-none"
            role="cell">
            <span
                className={getSpanClassName(member.status) + ' max-w-xs'}>
                {t(`memberStatus${member.status}`)}
            </span>
        </div>
        <div
            className="px-4 sm:px-6 whitespace-nowrap text-sm text-gray-500 order-0 row-start-1 sm:row-start-auto col-span-2 sm:col-auto"
            role="cell">
            <div className="flex flex-1 flex-col text-sm text-gray-900">
                <div className="overflow-ellipsis text-brand-500 capitalize">{member.name} {member.surname}</div>
                <div className="overflow-ellipsis subheader">{member.email}</div>
            </div>
        </div>
        <div
            className="px-4 sm:px-6 mb-2 sm:mb-0 whitespace-nowrap text-sm text-gray-500 order-1 sm:order-none col-span-3 sm:col-auto row-start-2 sm:row-start-auto"
            role="cell">
            {member.status === 'active' ?
                t(`role${member.role}`) : null
            }
        </div>
        <div
            className="px-4 sm:px-6 mb-2 sm:mb-0 whitespace-nowrap text-sm text-gray-500 order-1 sm:order-none col-span-3 sm:col-auto row-start-3 sm:row-start-auto"
            role="cell">
            {member.aclEnabled ?
                `${member.panelsVisibleRestrictedCount || 0} ${t('panels')}` : null
            }
        </div>
        <div className="px-4 sm:px-6 text-sm text-gray-500 order-3 sm:order-none row-start-4 sm:row-start-auto"
            role="cell">
            {member.status === 'active' ?
                <div className="flex items-center">
                    <AcademicCapIcon className={getSvgCertificationLevelClassName(member.certificationLevel)} />
                    <span className="ml-4">
                        {t(`${certificationMapper[member.certificationLevel]}`).toUpperCase()}
                    </span>
                </div>
                : null}
        </div>
        <div className="text-center order-2 sm:order-none sm:row-auto" role="cell">
            <CompanyMembersMenuDropDown
                canEdit={canEdit}
                isActive={isActive}
                isAdmin={isAdmin}
                onRemoveClick={() => {
                    if (window.confirm(isActive ? t('removeCompanyMemberConfirmation') : t('deleteCompanyMemberInvitationConfirmation'))) {
                        dispatch(removeCompanyMember({
                            type: isActive ? 'member' : 'invitation',
                            companyId: companyId,
                            email: member.email
                        }))
                    }
                }}
                onRoleChangeClick={() => {
                    dispatch(updateCompanyMember({
                        companyId: companyId,
                        memberId: member.id,
                        role: isAdmin ? 'employee' : 'admin'
                    }))
                }}
                onAclClick={() => setShowAcl(true)}
            />
        </div>
    </div>
}