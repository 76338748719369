import { ActionType, createAction } from 'typesafe-actions'

export const LOGOUT = 'logout'
export const LOGOUT_COMPLETED = 'logout_completed'
export const LOGOUT_FAILED = 'logout_failed'

export const logout = createAction(LOGOUT)

export const logoutFailed = createAction(LOGOUT_FAILED, (action) => {
    return (error: string) => action({ error })
})

export const logoutCompleted = createAction(LOGOUT_COMPLETED)

export type LogoutActions = ActionType<typeof logout | typeof logoutFailed | typeof logoutCompleted>