import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Dispatch } from 'redux'
import { State } from '../../configureStore'
import { connect, useDispatch } from 'react-redux'
import { readAllPrices } from '../../billing/actions'
import { useLocation } from 'react-router'
import { getGatewayPrices } from './selectors'
import { purchaseGatewayLicense } from './actions'
import { ModalDialog } from '../../common/ModalDialog'
import { CompanyBillingContainer } from '../../profile/CompanyBilling'
import { CubeTransparentIcon } from '@heroicons/react/outline'
import { Button } from '../../common/Button'
import { canManageStore, getBillingId } from '../../profile/selectors'
import { isLoadingBillingPrices, isPurchasing } from '../../billing/selectors'
import { Spinner } from '../../common/Spinner'
import { AlertInfo, AlertWarning } from '../../common/Alert/Alert'
import { generateAmplifyAnalyticsTags } from '../../auth/amplify'
import { getTaxStamp } from '../selectors'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const CURRENCY_DECIMALS = 2

interface Price {
    type: string
    subType: string,
    id: string
    productId: string
    applicableForPeripherals?: Array<string>
    subTotalAmount: number
}

interface Props {
    open: boolean,
    canManageStore: boolean,
    panelSerialNumber?: string
    peripheralType?: string
    subType?: string
    peripheralMacAddress?: string
    billingId?: string
    purchasing?: boolean
    taxRate?: number
    taxStamp?: number
    currency?: string
    prices?: Array<Price>
    loadingPrices?: boolean,
    invoiceUrl?: string
    onClose: () => void,
    onBuy: (productId: string, description: string, invoiceDescription: string, confirmationMessage: string, panelSerialNumber: string, peripheralType: string, peripheralMacAddress: string, subType: string) => void
}

const getPriceString = (price: number, currency: string = '', decimals: number = 2): string => {
    return `${price.toFixed(decimals)} ${currency}`
}

export function ModalGatewayLicense(props: Props) {
    const { t } = useTranslation()
    const {
        taxRate = 0,
        taxStamp = 0,
        currency = '',
        prices = [],
        invoiceUrl,
        billingId,
        purchasing,
        loadingPrices,
        onBuy
    } = props

    const query = useQuery()
    let panelSerialNumber = query.get('panelSerialNumber') || props.panelSerialNumber
    let peripheralType = query.get('peripheralType') || props.peripheralType
    let subType = query.get('subType') || props.subType || 'default'
    let peripheralMacAddress = query.get('peripheralMacAddress') || props.peripheralMacAddress
    const getDescription = () => `${t('gatewayHTTPLicense')} - ${t(`${peripheralType}`)}`
    const dispatch = useDispatch()
    // Used also billingId in order to dispach again the readAllPrices
    useEffect(() => {
        if (props.open && billingId) {
            dispatch(readAllPrices())
        }
    }, [billingId, dispatch, props.open])

    const getCatalogPrice = () => {
        return prices.find(p => p.type === 'gateway-http' && p.applicableForPeripherals && p.applicableForPeripherals.includes(peripheralType as string) && p.subType === subType) ||
            prices.find(p => p.type === 'gateway-http' && !p.applicableForPeripherals)
    }

    let price
    let productId = ''
    const catalogPrice = getCatalogPrice()
    if (catalogPrice) {
        price = catalogPrice.subTotalAmount
        productId = catalogPrice.productId
    }

    let subtotal
    let subtotalString
    let taxes
    let taxesString
    let total
    let taxStampString
    let totalString
    let showTaxStamp = false
    if (price !== undefined) {
        subtotal = 1 * price
        subtotalString = getPriceString(price, currency, CURRENCY_DECIMALS)
        taxStampString = getPriceString(taxStamp, currency, CURRENCY_DECIMALS)

        taxes = subtotal * taxRate / 100
        taxesString = getPriceString(taxes, currency, CURRENCY_DECIMALS)

        total = 0
        if (subtotal) {
            total = subtotal + taxes + taxStamp
            showTaxStamp = !!taxStamp
        }
        totalString = getPriceString(total, currency, CURRENCY_DECIMALS)
    }
    const canBuy = price !== undefined && !invoiceUrl
    const canPay = invoiceUrl && total && total > 0
    const nothingToPay = invoiceUrl && total === 0

    return (<ModalDialog
        open={props.open}
        onClose={props.onClose}
        title={t('buyLicense')}
        pending={Boolean(false)}
    >
        {!props.canManageStore ?
            !loadingPrices && (<div className="space-y-2 px-6 py-4">
                <AlertWarning title={t('alertWarningTitle')} message={t('companyOrUerNotAllowed')} />
            </div>) :
            <>
                <div className="flex-1 overflow-y-auto">
                    <Spinner show={loadingPrices} containerClass="static h-full" />
                    {!loadingPrices && (<div className="space-y-2 px-6 py-4">
                        {!billingId ? <>
                            <AlertInfo message={t('registrationRequried')} />
                            <CompanyBillingContainer isModal={true} />
                        </> : <button
                            className="block relative rounded-lg border border-gray-300 bg-white shadow-sm px-4 py-2 cursor-pointer w-full hover:border-gray-400 sm:flex sm:justify-between sm:items-center">
                            <div className="flex flex-auto items-center">
                                <CubeTransparentIcon className="h-5 w-5" aria-hidden="true" />
                                <div className="flex-1 min-w-0 text-left ml-2">
                                    <span className="absolute inset-0" aria-hidden="true" />
                                    <p className="text-sm font-medium text-gray-900">{t('gatewayHTTPLicense')}</p>
                                    <p className="text-sm text-gray-500 truncate">{t(`${peripheralType}`)} / {t(`${subType}`)}</p>
                                </div>
                            </div>
                            <div id="server-size-0-description-1"
                                className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                <div className="font-medium text-gray-900">{subtotalString}</div>
                            </div>
                            <div className="absolute -inset-px rounded-lg border-2 pointer-events-none border-brand-500"
                                aria-hidden="true" />
                        </button>
                        }
                    </div>)}
                </div>

                {/* Action buttons */}
                {!billingId ? null :
                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 space-y-4 bg-gray-50 ">
                        <div className="space-y-2">
                            <div className="flex items-center justify-between">
                                <span className="text-base text-gray-500">{t('checkoutSubtotal')}</span>
                                <span className="text-base ">{subtotalString}</span>
                            </div>
                            {taxRate !== 0 ? <div className="flex items-center justify-between">
                                <span className="text-base text-gray-500">{`${t('checkoutTaxes')} (${taxRate}%)`}</span>
                                <span className="text-base ">{taxesString}</span>
                            </div> : null}
                            {showTaxStamp ?
                                <div className="flex items-center justify-between space-x-1">
                                    <span className="text-base text-gray-500">{`${t('taxStamp')}`}</span>
                                    <span className="text-base flex-none">{taxStampString}</span>
                                </div> : null}
                            <hr />
                            <div className="flex items-center justify-between">
                                <span className="text-lg">{t('checkoutTotal')}</span>
                                <span className="text-lg font-bold">{totalString}</span>
                            </div>
                        </div>
                        <AlertInfo message={t('cartFooterNote')} />
                        <div className="space-x-3 flex items-center justify-between">
                            <Button
                                text={canPay ? t('close') : t('cancel')}
                                onClick={props.onClose}
                                size="sm"
                            />
                            {canBuy ? <Button
                                disabled={!!purchasing}
                                pending={!!purchasing}
                                color="primary"
                                text={t('buy')}
                                {...generateAmplifyAnalyticsTags(`action:buy_license,product=gateway-${subType}`)}
                                onClick={() => onBuy(productId, getDescription(), t('invoiceDescription'), t('taxStamp'), t('purchaseConfirmation'), panelSerialNumber || '', peripheralType || '', peripheralMacAddress || '', subType)}
                            /> : null}
                            {canPay ? <a
                                className="btn-link btn-just-icon facebookIcon"
                                color="primary"
                                href={invoiceUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <Button
                                    color="primary"
                                    disabled={purchasing}
                                    text={t('pay')}
                                />
                            </a> : null}
                            {nothingToPay ?
                                <Button
                                    color="primary"
                                    disabled={true}
                                    text={t('paid')}
                                />
                                : null}
                        </div>
                    </div>
                }
            </>
        }
    </ModalDialog>)
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onBuy(productId: string, description: string, invoiceDescription: string, taxStampDescription: string, confirmationMessage: string, panelSerialNumber: string, peripheralType: string, peripheralMacAddress: string, subType: string) {
            if (window.confirm(confirmationMessage)) {
                if (subType === 'avigilon')
                    description += ' - Avigilon'
                dispatch(purchaseGatewayLicense(productId, description, panelSerialNumber, peripheralType, peripheralMacAddress, invoiceDescription, taxStampDescription, subType))
            }
        }
    }
}

export function mapStateToProps(state: State) {
    return {
        billingId: getBillingId(state),
        canManageStore: canManageStore(state),
        purchasing: isPurchasing(state),
        taxRate: parseFloat(state.billing.tax.percentage || '0'),
        taxStamp: getTaxStamp(state),
        currency: '€',
        loadingPrices: isLoadingBillingPrices(state),
        prices: getGatewayPrices(state),
        invoiceUrl: state.billing.invoiceUrl
    }
}

export const ModalGatewayLicenseContainer = connect(mapStateToProps, mapDispatchToProps)(ModalGatewayLicense)