import _ from 'lodash'
// @ts-ignore
import italia from '@ksenia/italia'
import countryRegions, { Region, Country as RegionCountry } from 'country-region-data'
import { TFunction } from 'i18next'
import { belongsToEurope } from '../../billing/utils'
import { countries } from 'countries-list'

type ItalianRegion = {
    nome: string,
    capoluoghi: Array<string>,
    province: Array<string>,
}

export const getRegionsByCountry = (country: string): Array<Region> => {
    if (country === 'IT') {
        return italia.regioni.map((region: ItalianRegion) => region.capoluoghi.map(cap => ({
            shortCode: cap,
            name: region.nome + ' - ' + cap
        }))).flat() || []
    } else {
        const matchCountry = (currCountry: RegionCountry) => currCountry.countryShortCode === country
        const foundCountry = _.find(countryRegions, matchCountry) || { regions: [] }
        return foundCountry.regions.map((region) => ({
            name: region.name,
            shortCode: region.name
        }))
    }
}

export const getBrandsList = (t: TFunction) => ([
    { name: 'Ksenia', code: 'ksenia' },
    { name: 'Ajax', code: 'ajax' },
    { name: 'Bentel', code: 'bentel' },
    { name: 'Elkron', code: 'elkron' },
    { name: 'Inim', code: 'inim' },
    { name: 'Jablotron', code: 'jablotron' },
    { name: 'Risco', code: 'risco' },
    { name: 'Tecnoalarm', code: 'tecnoalarm' },
    { name: 'Satel', code: 'satel' },
    { name: 'DSC', code: 'dsc' },
    { name: 'Combivox', code: 'combivox' },
    { name: 'Vanderbilt', code: 'vanderbilt' },
    { name: 'Aritech', code: 'aritech' },
    { name: 'Elmo', code: 'elmo' },
    { name: 'AVS', code: 'avs' },
    { name: 'Paradox', code: 'paradox' },
    { name: 'Urmet', code: 'urmet' },
    { name: t('other'), code: 'other' }
])

export const getVatCountry = (country: string) => country === 'GR' ? 'EL' : country

export const countryCouldHaveVatNumber = (country: string): boolean => {
    return belongsToEurope(country) || ['SM', 'MC'].includes(country)
}

export const availableCountries = _.sortBy(Object.keys(countries)
    .map((key: string) => ({
        label: countries[key as keyof typeof countries].native,
        value: key
    })), 'label')