import React, { useEffect, useState } from 'react'
import { ModalDialog } from '../common/ModalDialog'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../common/Spinner'
import { Button } from '../common/Button'
import clsx from 'clsx'
import { CompanyBillingContainer } from '../profile/CompanyBilling'
import { BillingForm } from '../billing/types'
import { Avatar } from '../common/Avatar'
import { State } from '../configureStore'
import { Dispatch } from 'redux'
import { connect, useDispatch } from 'react-redux'
import { getPurchasableVoices, getPurchasedVoices } from './voicesSelectors'
import { getLoquendoVoicePrice } from './licenseSelectors'
import { createBillingProfile, readAllPrices } from '../billing/actions'
import { purchaseVoices } from './voicesActions'
import { canManageStore, getBillingId } from '../profile/selectors'
import { AlertInfo, AlertWarning } from '../common/Alert/Alert'
import { generateAmplifyAnalyticsTags } from '../auth/amplify'
import { getTaxStamp } from './selectors'
import { isLoadingBillingPrices, isPurchasing } from '../billing/selectors'

interface Props {
    open: boolean,
    availableVoices: Array<string>
    voices?: Array<string>
    price?: number
    taxRate?: number
    taxStamp?: number
    currency?: string
    invoiceUrl?: string
    billingId?: string
    pending: boolean
    purchasing: boolean
    canManageStore: boolean
    onClose: () => void
    onRegister: (user: BillingForm) => void
    onBuyVoices: (voices: Array<string>, description: string, invoiceDescription: string, taxStampDescription: string, confirmationMessage: string) => boolean
}

const CURRENCY_DECIMALS = 2

interface TranslatedVoice {
    voiceCode: string,
    voice: string,
    language: string
}

export function mapVoice(voiceCode: string, tranlate: (key: string) => string): TranslatedVoice {
    const [lang, voice] = voiceCode.split('_')
    return { voiceCode, voice, language: tranlate(lang) }
}

function getPriceString(price: number, currency: string = '', decimals: number = 2): string {
    return `${currency} ${price.toFixed(decimals)}`
}

export function ModalPurchaseVoice(props: Props) {
    const {
        taxRate = 0,
        taxStamp = 0,
        currency = '',
        price,
        pending,
        invoiceUrl,
        billingId,
        canManageStore,
        purchasing
    } = props
    const { t } = useTranslation()
    const [voices] = useState((props.voices || []).map(v => mapVoice(v, t)) as Array<TranslatedVoice>)
    const [selectedVoices, setSelectedVoices] = useState<Array<TranslatedVoice>>([])
    const translatedVoices = props.availableVoices.map(v => mapVoice(v, t)).filter(tv => !voices.some(v => v.voiceCode === tv.voiceCode))
    const [finalized, setFinalized] = useState(false)

    const dispatch = useDispatch()
    useEffect(() => {
        if (props.open && billingId) {
            dispatch(readAllPrices())
        }
    }, [billingId, dispatch, props.open])

    useEffect(() => {
        setSelectedVoices([])
        setFinalized(false)
    }, [props.open])

    const toggleVoice = (v: TranslatedVoice) => {
        if (selectedVoices.some((el) => el.voiceCode === v.voiceCode)) {
            setSelectedVoices(selectedVoices.filter(el => el.voiceCode !== v.voiceCode))
        } else {
            setSelectedVoices([...selectedVoices, v])
        }
    }

    const isVoiceSelected = (v: TranslatedVoice) => {
        return selectedVoices.some((el) => el.voiceCode === v.voiceCode)
    }

    let taxesString, priceString: string, taxStampString, subtotalString, totalString
    let showTaxStamp = false
    if (price) {
        priceString = getPriceString(price, currency, CURRENCY_DECIMALS)
        taxStampString = getPriceString(taxStamp, currency, CURRENCY_DECIMALS)

        const subtotal = selectedVoices.length * price
        subtotalString = getPriceString(subtotal, currency, CURRENCY_DECIMALS)

        const taxes = subtotal * taxRate / 100
        taxesString = getPriceString(taxes, currency, CURRENCY_DECIMALS)

        let total = 0
        if(subtotal) {
            total = subtotal + taxes + taxStamp
            showTaxStamp = !!taxStamp
        }
        totalString = getPriceString(total, currency, CURRENCY_DECIMALS)
    }

    const onBuyVoicesHandler = () => {
        return props.onBuyVoices(
            selectedVoices.map(v => v.voiceCode),
            selectedVoices.map(v => `${v.voice} (${v.language})`).join(', '),
            t('invoiceDescription'),
            t('taxStamp'),
            t('purchaseConfirmation')
        )
    }

    const userShouldPay = !!invoiceUrl

    return (<ModalDialog
        open={props.open}
        onClose={props.onClose}
        title={t('buyLicense')}
    >
        <div className="flex-1 overflow-y-auto">
            <Spinner show={pending} containerClass="!static h-full" />
            {!pending && !canManageStore && (<div className="space-y-2 px-6 py-4">
                <AlertWarning title={t('alertWarningTitle')} message={t('companyOrUerNotAllowed')} />
            </div>)}
            {!pending && canManageStore && (
                <div className="space-y-2 px-6 py-4">
                    {!billingId ? <CompanyBillingContainer
                        isModal={true}
                    /> :
                        translatedVoices.filter((v) => !finalized || isVoiceSelected(v)).map((v) => {
                            const classes = clsx('absolute -inset-px rounded-lg border-2 pointer-events-none', isVoiceSelected(v) ? 'border-brand-500' : 'border-transparent')
                            return (
                                <button
                                    key={v.voiceCode}
                                    className="block relative rounded-lg border border-gray-300 bg-white shadow-sm px-4 py-2 cursor-pointer w-full hover:border-gray-400 sm:flex sm:justify-between sm:items-center"
                                    onClick={() => {
                                        !finalized && toggleVoice(v)
                                    }}>
                                    <div className="flex flex-auto items-center">
                                        <Avatar
                                            size={8}
                                            src={require(`../images/flags/${v.voiceCode.substr(0, 3)}.svg`)?.default}
                                            alt={v.language}
                                            aria-hidden
                                        />
                                        <div className="flex flex-auto items-center ml-2 text-left">
                                            <div className="text-sm">
                                                <p id="server-size-0-label"
                                                    className="capitalize font-medium text-gray-900">
                                                    {v.voice}
                                                    <span
                                                        className="sm:inline capitalize text-gray-500">{` / ${v.language}`}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="server-size-0-description-1"
                                        className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                                        <div className="font-medium text-gray-900">{priceString}</div>
                                    </div>
                                    <div className={classes} aria-hidden="true" />
                                </button>
                            )
                        })}
                </div>
            )}
        </div>

        {/* Action buttons */}
        {!billingId || !canManageStore ? null :
            <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6 space-y-4 bg-gray-50 ">
                <div className="space-y-2">
                    <div className="flex items-center justify-between">
                        <span className="text-base text-gray-500">{t('checkoutSubtotal')}</span>
                        <span className="text-base ">{subtotalString}</span>
                    </div>
                    {taxRate !== 0 ?
                        <div className="flex items-center justify-between">
                            <span className="text-base text-gray-500">{`${t('checkoutTaxes')} (${taxRate}%)`}</span>
                            <span className="text-base ">{taxesString}</span>
                        </div> : null}
                    {showTaxStamp ?
                        <div className="flex items-center justify-between space-x-1">
                            <span className="text-base text-gray-500">{`${t('taxStamp')}`}</span>
                            <span className="text-base flex-none">{taxStampString}</span>
                        </div> : null}

                    <hr />
                    <div className="flex items-center justify-between">
                        <span className="text-lg">{t('checkoutTotal')}</span>
                        <span className="text-lg font-bold">{totalString}</span>
                    </div>
                </div>
                <AlertInfo message={t('cartFooterNote')} />
                <div className="space-x-3 flex justify-between items-center">
                    <Button onClick={props.onClose} text={t('cancel')} size="sm" type="button" />
                    {!userShouldPay ?
                        <Button
                            size="base"
                            color="primary"
                            disabled={selectedVoices.length <= 0 || pending || purchasing}
                            onClick={() => onBuyVoicesHandler() && setFinalized(true)}
                            text={t('buy')}
                            pending={purchasing}
                            type="button"
                            {...generateAmplifyAnalyticsTags('action:buy_license,product:loquendo')}
                        /> : <a
                            className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-brand-600 hover:bg-brand-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 disabled:opacity-50"
                            href={invoiceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >{t('pay')}</a>}
                </div>
            </div>
        }
    </ModalDialog>
    )
}

export function mapStateToProps(state: State) {
    return {
        billingId: getBillingId(state),
        canManageStore: canManageStore(state),
        voices: getPurchasedVoices(state),
        availableVoices: getPurchasableVoices(state),
        price: getLoquendoVoicePrice(state),
        taxRate: parseFloat(state.billing.tax.percentage || '0'),
        taxStamp: getTaxStamp(state),
        currency: '€',
        invoiceUrl: state.billing.invoiceUrl,
        pending: state.licenses.pending || isLoadingBillingPrices(state),
        purchasing: isPurchasing(state)
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onBuyVoices(voices: Array<string>, description: string, invoiceDescription: string, taxStampDescription: string, confirmationMessage: string): boolean {
            if (window.confirm(confirmationMessage)) {
                dispatch(purchaseVoices(voices, description, invoiceDescription, taxStampDescription))
                return true
            }
            return false
        },
        onRegister(user: BillingForm) {
            dispatch(createBillingProfile(user))
        }
    }
}

export const ModalPurchaseVoiceContainer = connect(mapStateToProps, mapDispatchToProps)(ModalPurchaseVoice)
