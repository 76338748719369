import _ from 'lodash'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from "react-hook-form";
import Card from '../../common/Card/Card'
import { UserCompanyProfile } from './../selectors'
import { Button } from '../../common/Button'
import { useLoading } from '../../hooks/loading';
import { useUserMutation } from '../../hooks/createCompanyWizard';
import { reloadProfile } from '../actions';
import { notify } from '../../notification/actions';
import { Toggle } from '../../common/Toggle';
import { Modal } from '../../common/Modal';
import Text from '../../common/Text';

type Props = UserCompanyProfile

type FormData = {
    tcn: boolean,
};

export function UserProfileFormPrivacy(props: Props) {
    const { marketing = {} } = props
    const { i18n, t } = useTranslation()
    const { update } = useUserMutation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()
    const defaultValues: FormData = {
        tcn: marketing?.tcn?.enabled || false
    }
    const { handleSubmit, control } = useForm<FormData>({ defaultValues });

    const [showPrivacy, setShowPrivacy] = useState(false)
    const [showTermsOfUse, setShowTermsOfUse] = useState(false)

    const locale = (i18n.language === 'it' || i18n.language === 'it-IT') ? 'it' : 'en'

    const onSubmit: SubmitHandler<FormData> = async (form) => {
        const marketingObj: { [key: string]: { enabled: boolean } } = _.cloneDeep(marketing)
        Object.entries(form).forEach(([key, value]) => {
            marketingObj[key] = { enabled: value }
        })

        try {
            await execute(() => update({ marketing: marketingObj }))
            dispatch(reloadProfile())
        } catch (error: any) {
            dispatch(notify('error', 'userUpdateError', 5000))
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card pending={loading}>
                <Card.Body full otherClasses="divide-y divide-gray-100">
                    {/* Mandatory: Privacy */}
                    <div className="flex items-center justify-center px-5 py-4">
                        <span className="flex-grow flex flex-col">
                            <span className="text-sm font-medium text-gray-900">{t('userPrivacyPolicyTitle')}</span>
                            <span className="text-sm text-gray-500"></span>
                        </span>
                        <div className="ml-3 text-sm">
                            <p id="privacy-description" className="text-brand-500">
                                <span className="text-gray-500">{t('readThe')} </span> <a href="/" onClick={(e) => { setShowPrivacy(true); e.preventDefault() }} target="_blank" rel="noreferrer">{t('privacyConditions')}</a>
                            </p>
                        </div>
                    </div>
                    {/* Mandatory: Terms */}
                    <div className="flex items-center justify-center px-5 py-4">
                        <span className="flex-grow flex flex-col">
                            <span className="text-sm font-medium text-gray-900">{t('userTermsTitle')}</span>
                            <span className="text-sm text-gray-500"></span>
                        </span>
                        <div className="ml-3 text-sm">
                            <p id="terms-of-use-description" className="text-brand-500">
                                <span className="text-gray-500">{t('readThe')} </span> <a href="/" onClick={(e) => { setShowTermsOfUse(true); e.preventDefault() }} target="_blank" rel="noreferrer">{t('registrationTermsOfUse')}</a>
                            </p>
                        </div>
                    </div>
                    {/* Optionsl: TCN */}
                    <div className="flex items-center justify-center px-5 py-4">
                        <span className="flex-grow flex flex-col">
                            <span className="text-sm font-medium text-gray-900">{t('userPrivacyTCNTitle')}</span>
                            <span className="text-sm text-gray-500">{t('userPrivacyTCNSubtitle')}</span>
                        </span>
                        <Toggle name="tcn" control={control} />
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="flex items-center justify-end">
                        <Button type="submit" color="primary" text={t('update')} pending={loading} />
                    </div>
                </Card.Footer>
            </Card>
            <Modal open={showPrivacy} onClose={() => setShowPrivacy(false)} size="4xl">
                <Text type={'privacy'}
                    locale={locale} />
            </Modal>
            <Modal open={showTermsOfUse} onClose={() => setShowTermsOfUse(false)} size="4xl">
                <Text type={'terms-of-use'}
                    locale={locale} />
            </Modal>
        </form>
    )
}