import { ActionsObservable, Epic } from 'redux-observable'
import { ActionTypes } from '../configureStore'
import { discardNotify, NotificationActions, notify } from './actions'
import { filter, delay, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from } from "rxjs"

export const discardNotification$: Epic<ActionTypes,
    NotificationActions,
    any> = (action$, state$) => action$.pipe(
    filter(isActionOf(notify)),
    mergeMap(action => {
        return from(ActionsObservable.of(discardNotify())
            .pipe(
                delay(action.payload.duration)
            ))
    })
)
