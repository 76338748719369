import { ActionType, createAction } from 'typesafe-actions'

export const NOTIFY = 'notify'
export const DISCARD_NOTIFY = 'discard_notify'

export const notify = createAction(NOTIFY, (action) => {
    return (type: 'error' | 'info', message: string, duration: number = 3000, errorCode?: number) => action({type, message, duration, errorCode})
})

export const discardNotify = createAction(DISCARD_NOTIFY)

export type NotificationActions = ActionType<typeof notify | typeof discardNotify>
