
import React from 'react'
import {
    ExclamationIcon,
    InformationCircleIcon
} from '@heroicons/react/outline'
import clsx from 'clsx'

interface AlertWarningProps {
    title?: string,
    message: string,
    containerClass?: string
}

export function AlertWarning(props: AlertWarningProps) {
    const { containerClass = '', title, message } = props
    return (
        <div className={clsx('rounded-md bg-yellow-50 p-4', containerClass)}>
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                </div>
                <div className="ml-3 text-left">
                    {title && <h3 className="text-sm font-medium text-yellow-800 mb-2">{title}</h3>}
                    <div className="text-sm text-yellow-700">
                        <p>{message}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface AlertInfoProps {
    message: string,
    containerClass?: string
}

export function AlertInfo(props: AlertInfoProps) {
    const { message, containerClass = '' } = props
    return <div className={clsx('rounded-md bg-blue-50 p-4', containerClass)}>
        <div className="flex">
            <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">{message}</p>
            </div>
        </div>
    </div>
}
