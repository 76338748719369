import React, { ReactElement } from 'react';
import clsx from 'clsx';

interface Props {
    children: ReactElement | string
    className?: string;
}

const InputHelp = (props: Props): JSX.Element => {
    const {children, className} = props
    const classes = clsx('mt-2 text-sm text-gray-500', className)
    return (
        <p className={classes}>
            {children}
        </p>
    )
}

export default InputHelp