import React, { useState } from 'react'
import { usePopper } from 'react-popper'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Popover } from '@headlessui/react'
import { DotsVerticalIcon } from '@heroicons/react/solid'

export type ItemProps = {
    label: string
    onClick?: () => void,
    icon?: (props: React.ComponentProps<'svg'>) => JSX.Element,
    disabled?: boolean
}

type MenuDropDownProps = {
    items: Array<ItemProps | ItemProps[]>,
    buttonClass?: string
}

const MenuDropDownItem: React.VFC<ItemProps> = (props) => (
    <>
        <button
            type="button"
            className={clsx(
                'flex items-center px-4 py-2 text-sm w-full disabled:opacity-50 whitespace-nowrap hover:bg-gray-50',
            )}
            onClick={() => props.onClick && props.onClick()}
            disabled={props?.disabled}
        >
            {props.icon && <props.icon className="flex-grow-0 flex-shrink-0 mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />}
            {props.label}
        </button>
    </>
)

const MenuDropDown: React.VFC<MenuDropDownProps> = (props) => {
    const { items = [], buttonClass } = props
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>()
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>();
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom-start' })
    const { t } = useTranslation()
    const isNested = items.some((item) => Array.isArray(item))
    const buttonClasses = clsx('group bg-transparent rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-brand-500', buttonClass && buttonClass)
    const panelClasses = clsx(
        'mt-2 min-w-[14rem] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10',
        {
            'divide-y divide-gray-100': isNested,
        }
    )
    return (
        <Popover as="div" className="relative inline-block text-left">
            <Popover.Button className={buttonClasses} ref={setReferenceElement}>
                <span className="sr-only">{t('openMenu')}</span>
                <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Popover.Panel ref={setPopperElement} style={styles.popper} className={panelClasses} {...attributes.popper}>
                {items.map((item, index) => {
                    if (Array.isArray(item)) {
                        return (
                            <div key={index} className="py-1">
                                {item.map((item) => (
                                    <MenuDropDownItem {...item} key={item.label} />
                                ))}
                            </div>
                        )
                    } else { }
                    return (
                        <div key={item.label} className="py-1">
                            <MenuDropDownItem {...item} />
                        </div>
                    )
                })}
            </Popover.Panel>
        </Popover>
    )
}

export { MenuDropDown }
