import React from 'react'
import clsx from 'clsx'

export default function IconTitleSubtitle(props: {
    generalClasses?: string
    icon?: any
    iconUpperClasses?: string
    title?: string
    titleClasses?: string
    subtitle?: string
    subtitleUpperClasses?: string
    subtitleLowerClasses?: string
    'data-testid'?: string
}) {
    const { generalClasses, icon, iconUpperClasses, title, titleClasses, subtitle, subtitleUpperClasses, subtitleLowerClasses } = props

    const innerGeneralClasses = clsx(
        "text-center",
        generalClasses && generalClasses
    )

    const innerIconUpperClasses = clsx(
        'inline-block',
        iconUpperClasses && iconUpperClasses
    )

    const innerTitleClasses = clsx(
        "mt-8 text-4xl text-black-500",
        titleClasses && titleClasses
    )

    const innerSubtitleUpperClasses = clsx(
        "break-word max-w-md mx-auto my-2",
        subtitleUpperClasses && subtitleUpperClasses
    )

    const innerSubtitleLowerClasses = clsx(
        "break-normal text-gray-500",
        subtitleLowerClasses && subtitleLowerClasses
    )

    return (<>
        <div className={innerGeneralClasses}>
            {icon ?
                <div className={innerIconUpperClasses}>
                    {icon}
                </div>
                : null}
            {title ?
                <h2 className={innerTitleClasses} data-testid={props['data-testid']}>{title}</h2>
                : null}
            {subtitle ?
                <p className={innerSubtitleUpperClasses}>
                    <span className={innerSubtitleLowerClasses}>
                        {subtitle}
                    </span>
                </p>
                : null}
        </div>
    </>)
}