import { Amplify, Analytics, Auth } from "aws-amplify"
import config from '../aws-exports'
import _ from "lodash"
import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from "react"
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { useTranslation } from "react-i18next"
import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth'

if (process.env.NODE_ENV === 'development') {
    Amplify.Logger.LOG_LEVEL = 'DEBUG'
}

if (process.env.REACT_APP_BRAND === 'ksenia') {
    //disable analytics in local development run
    Amplify.configure(process.env.NODE_ENV === 'development' ? _.omit(config, 'Analytics') : config)
}

export const useAnalytics = () => {
    const { i18n } = useTranslation()

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development') {
            Analytics.autoTrack('pageView', {
                enable: true,
                type: 'SPA',
                getUrl: () => window.location.href
            })
            Analytics.autoTrack('event', {
                enable: true
            })
            Analytics.updateEndpoint({
                demographic: {
                    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
                }
            })
        }
    }, [])

    useEffect(() => {
        if (process.env.NODE_ENV !== 'development' && i18n.language) {
            Analytics.updateEndpoint({
                demographic: {
                    locale: i18n.language
                }
            })
        }
    }, [i18n.language])
}

export const generateAmplifyAnalyticsTags = (attrs: string) => ({
    'data-amplify-analytics-on': "click",
    'data-amplify-analytics-name': "click",
    'data-amplify-analytics-attrs': attrs
})

export const getCurrentAuthConfig = () => config.Auth

//used by epics
export type CurrenSessionParameters = Parameters<typeof Auth.currentSession>
export type CurrentAuthenticatedUserParameters = Parameters<typeof Auth.currentAuthenticatedUser>

export const currentSession = () => Auth.currentSession()
export const currentAuthenticatedUser = (...params: CurrentAuthenticatedUserParameters) => Auth.currentAuthenticatedUser(...params)

const AmplifyAuthContext = createContext<typeof Auth | null>(null)

export type UserStatuses = 'unknown' | 'passwordResetRequired' | 'loggedIn' | 'unconfirmed'
export type UserGroups = 'sales' | 'helpdesk'

export type ContextType = {
    userStatus: UserStatuses
    username: string,
    password: string,
    setUsername: (username: string) => void,
    setPassword: (password: string) => void,
    signUp: (phoneNumber: string) => Promise<void>
    signIn: () => Promise<void>
    signOut: () => Promise<void>,
    currentSession: () => Promise<CognitoUserSession>
    confirmSignUp: (code: string) => Promise<void>
    resendCode: () => Promise<void>
    cancelForgotPassword: () => void,
    forgotPassword: () => Promise<void>
    forgotPasswordSubmit: (code: string, newPassword: string) => Promise<void>,
    passwordResetCompleted: () => void,
    requestPasswordReset: () => void,
    userShouldConfirmCredentials: () => boolean,
    verifyUserAttributeSubmit: (code: string) => Promise<void>,
    updatePhoneWithVerificationRequest: (phone: { newNumber: string, oldNumber?: string }) => Promise<void>,
    changePassword: (oldPassword: string, newPassword: string) => Promise<void>,
    getIdToken: () => Promise<string>,
    getUserGroups: () => Promise<UserGroups[]>
    authClass: AuthClass
}

export const useAmplifyAuth = (): ContextType => {
    const [userStatus, setUserStatus] = useState<UserStatuses>('unknown')
    const [username, setUsername] = useState<string>('')
    const [password, setPassword] = useState<string>('')
    const auth = useContext(AmplifyAuthContext)

    if (!auth) {
        throw new Error('Amplify Auth consumer must be used within an Amplify provider')
    }

    useEffect(() => {
        const check = async () => {
            await auth.currentSession()
                .then(() => {
                    setUserStatus('loggedIn')
                })
                .catch(() => {
                    setUserStatus('unknown')
                })
        }
        check()
    }, [setUserStatus, auth])

    const verifyUserAttributeSubmit = async (code: string) => {
        await auth.verifyCurrentUserAttributeSubmit('phone_number', code)
    }

    const updatePhoneWithVerificationRequest = async (phone: { newNumber: string, oldNumber?: string }) => {
        const cognitoUser = await auth.currentAuthenticatedUser()
        await auth.updateUserAttributes(cognitoUser, {
            phone_number: phone.newNumber,
            'custom:validated_phone': phone.oldNumber
        })
        await auth.verifyUserAttribute(cognitoUser, 'phone_number')
    }

    const changePassword = async (oldPassword: string, newPassword: string) => {
        const cognitoUser = await auth.currentAuthenticatedUser()
        await auth.changePassword(cognitoUser, oldPassword, newPassword)
    }

    const signUp = useCallback(
        async (phoneNumber: string) => {
            await auth.signUp({
                username,
                password,
                attributes: {
                    phone_number: phoneNumber
                }
            })
            setUserStatus('unconfirmed')
        },
        [auth, username, password]
    )

    const confirmSignUp = useCallback(
        async (code: string) => {
            await auth.confirmSignUp(username, code)
            await auth.signIn(username, password)
            setUserStatus('loggedIn')
        },
        [auth, username, password]
    )

    const resendCode = useCallback(
        async () => {
            await auth.resendSignUp(username)
        },
        [auth, username]
    )

    const forgotPassword = useCallback(
        async () => {
            await auth.forgotPassword(username)
        },
        [auth, username]
    )
    const forgotPasswordSubmit = useCallback(
        async (code: string, newPassword: string) => {
            await auth.forgotPasswordSubmit(username, code, newPassword)
        },
        [auth, username]
    )

    const requestPasswordReset = () => {
        setUserStatus('passwordResetRequired')
    }

    const passwordResetCompleted = useCallback(() => {
        setUserStatus('unknown')
    }, [])

    const signIn = useCallback(async () => {
        try {
            await auth.signIn(username, password)
            setUserStatus('loggedIn')
        } catch (e: any) {
            if (e.code === 'PasswordResetRequiredException') {
                setUserStatus('passwordResetRequired')
            } else if (e.code === 'UserNotConfirmedException') {
                setUserStatus('unconfirmed')
            }
            else throw e
        }
    }, [auth, username, password])

    const signOut = useCallback(async () => {
        setUserStatus('unknown')
        try {
            await auth.signOut()
        } catch (e) { }
    }, [auth, setUserStatus])

    const cancelForgotPassword = useCallback(() => setUserStatus('unknown'), [])

    const userShouldConfirmCredentials = useCallback(() => userStatus === 'unconfirmed', [userStatus])

    const currentSession = useCallback(() => auth.currentSession(), [auth])

    const getUserGroups = useCallback(async () => {
        try {
            const user = await auth.currentAuthenticatedUser();
            return user.signInUserSession.accessToken.payload["cognito:groups"] || []
        } catch (error: any) {
            return []
        }
    }, [auth])

    const getIdToken = useCallback(async () => {
        return (await currentSession()).getIdToken().getJwtToken()
    }, [currentSession])

    return {
        userStatus,
        username,
        setUsername,
        password,
        setPassword,
        signUp,
        signIn,
        signOut,
        currentSession,
        confirmSignUp,
        resendCode,
        cancelForgotPassword,
        forgotPassword,
        forgotPasswordSubmit,
        requestPasswordReset,
        passwordResetCompleted,
        userShouldConfirmCredentials,
        updatePhoneWithVerificationRequest,
        verifyUserAttributeSubmit,
        getUserGroups,
        getIdToken,
        changePassword,
        authClass: auth
    }
}

export const AuthContext = createContext<ContextType | null>(null)

export const useAuth = () => {
    return useContext(AuthContext) as ContextType
}

const Consumer = ({ children }: PropsWithChildren<{}>) => {
    const context = useContext(AmplifyAuthContext)
    if (context === null) {
        throw new Error('CountConsumer must be used within an Amplify provider')
    }
    const auth = useAmplifyAuth()
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const AuthWrapper = ({ children }: PropsWithChildren<{}>) => <AmplifyAuthContext.Provider value={Auth}>
    <Consumer>{children}</Consumer>
</AmplifyAuthContext.Provider>