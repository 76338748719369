import React from 'react'
import clsx from 'clsx'

const DistributorsNote = React.forwardRef((props: {
    ref: any,
    invalid?: boolean
    show?: boolean
}, ref: any) => {
    const { show = true, invalid } = props
    return show ? <textarea
        aria-label={'distributorsNote'}
        ref={ref}
        rows={5}
        maxLength={500}
        id="distributorsNote"
        data-testid="distributorsNote"
        name="distributorsNote"
        className={clsx('shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md', invalid && 'border-red-500 ')}
    /> : null
})

export default DistributorsNote