import React, { ReactElement, useState } from 'react'
import { Popover } from '@headlessui/react'
import { usePopper } from 'react-popper'
import clsx from 'clsx'

type PopperProps = {
    content: string | ReactElement,
    panelClass?: string
}

const Popper: React.FC<PopperProps> = (props) => {
    const { children, content, panelClass } = props
    let [buttonRef, setButtonRef] = useState<HTMLButtonElement | null>(null)
    let [popperElement, setPopperElement] = useState<HTMLDivElement | null>()
    let { styles, attributes: { popper } } = usePopper(buttonRef, popperElement)
    const arrow = "after:w-[0] after:h-[0] after:border-t-[0] after:border-r-[0.5rem] after:border-b-[0.5rem] after:border-l-[0.5rem] after:border-t-transparent after:border-r-transparent after:border-b-gray-800 after:border-l-transparent after:absolute after:bottom-full after:left-1/2 after:-translate-x-1/2"
    const arrowPosition = () => {
        switch (popper && popper['data-popper-placement']) {
            case 'top':
                return 'after:top-full after:rotate-180'
            default:
                return 'after:bottom-full'
        }
    }
    const panelClassName = clsx(
        "bg-gray-800 text-white text-sm px-4 py-2 shadow rounded-md max-w-xs mt-2 z-50",
        arrow,
        arrowPosition(),
        panelClass
    )
    return (
        <Popover>
            <Popover.Button ref={setButtonRef}>
                {children}
            </Popover.Button>
            <Popover.Panel
                className={panelClassName}
                ref={setPopperElement}
                style={styles.popper}
                {...popper}
            >
                {content}
            </Popover.Panel>
        </Popover>
    )
}

export default Popper