import _ from 'lodash'
import { createReducer } from "typesafe-actions"
import * as Actions from './actions'
import * as LoginActions from '../login/actions'
import * as LogoutActions from '../logout/actions'
import { Panel } from './actions'

export interface Filters {
    connectionType?: string,
    connectionStatus?: string,
    versionMin?: string
    versionMax?: string
}
export interface PanelsState {
    value: Actions.Panel[],
    loading: boolean,
    search: string,
    sortedBy: 'name' | 'description',
    pageLimit: string
    page?: number,
    filters?: Filters
}

export const panelsInitialState: PanelsState = {
    value: [],
    loading: false,
    search: '',
    sortedBy: 'name',
    pageLimit: '10',
    page: undefined,
    filters: undefined
}

export const panelsReducer = createReducer(panelsInitialState)
    .handleAction(Actions.savePanels, (state, action) => ({
        ...state,
        loading: false,
        value: action.payload
    }))
    .handleAction(Actions.loadPanels, (state, action) => ({
        ...state,
        loading: true,
    }))
    .handleAction(LoginActions.loginCompleted, (state, action) => ({
        ...state,
        loading: true,
    }))
    .handleAction(Actions.reloadPanels, (state, action) => ({
        ...state,
        loading: true,
    }))
    .handleAction(Actions.loadPanelsError, (state, action) => ({
        ...state,
        loading: false,
    }))
    .handleAction(Actions.searchPanels, (state, action) => ({
        ...state,
        search: action.payload
    }))
    .handleAction(Actions.deletePanelComplete, (state, action) => ({
        ...state,
        value: state.value.filter(p => p.mac !== action.payload)
    }))
    .handleAction(Actions.savePanelComplete, (state, action) => {
        let newArray = _.cloneDeep(state.value)
        const index = newArray.findIndex((e) => e.mac === action.payload.mac)
        index === -1 ? newArray.push(action.payload) : newArray[index] = { ...newArray[index], ...action.payload }
        return {
            ...state,
            value: newArray
        }
    }
    )
    .handleAction(Actions.panelActivationComplete, (state, action) => {
        let newArray = _.cloneDeep(state.value)
        newArray = newArray.map((panel) => panel.mac === action.payload.mac ? {
            ...panel,
            activation: action.payload.activation
        } : panel)
        return {
            ...state,
            value: newArray
        }
    }
    )
    .handleAction(Actions.addFilters, (state, action) => ({
        ...state,
        filters: action.payload
    }))
    .handleAction(Actions.removeFilters, (state) => {
        let { filters, ...others } = _.cloneDeep(state)
        return others
    })
    .handleAction(LogoutActions.logoutCompleted, (state, action) => panelsInitialState)
    .handleAction(Actions.changePageLimit, (state, action) => ({
        ...state,
        pageLimit: action.payload
    }))
    .handleAction(Actions.changePage, (state, action) => ({
        ...state,
        page: action.payload
    }))

export const selectedPanelsInitialState = {}

export const addedPanelState: Panel = {
    name: '',
    description: '',
    serialNumber: '',
    connection: {
        type: '',
        status: 'OFFLINE'
    },
    location: {
        type: 'Point',
        coordinates: [0, 0]
    }
}

export const selectedPanelReducer = createReducer(selectedPanelsInitialState)
    .handleAction(Actions.selectPanel, (state, action) => action.payload)
    .handleAction(Actions.addPanel, (state, action) => addedPanelState)
    .handleAction(Actions.savePanelComplete, (state, action) => selectedPanelsInitialState)
    .handleAction(Actions.cancelSelectedPanel, (state, action) => selectedPanelsInitialState)
    .handleAction(Actions.changePanel, (state, action) => ({
        ...state,
        [action.payload.key]: action.payload.value
    }))
    .handleAction(LogoutActions.logoutCompleted, (state, action) => selectedPanelsInitialState)
