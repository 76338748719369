import _ from 'lodash'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useForm, SubmitHandler } from "react-hook-form";
import Input from '../../common/Input/Input'
import Card from '../../common/Card/Card'
import { UserCompanyProfile } from './../selectors'
import { Button } from '../../common/Button'
import { useLoading } from '../../hooks/loading';
import { useUserMutation } from '../../hooks/createCompanyWizard';
import { reloadProfile } from '../actions';
import { notify } from '../../notification/actions';

type Props = UserCompanyProfile

type FormData = {
    name?: string,
    surname?: string,
    fiscalCode?: string
};

export function UserProfileForm(props: Props) {
    const { name, surname, company } = props
    const { t } = useTranslation()
    const { update } = useUserMutation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()
    const defaultValues: FormData = {
        name,
        surname,
        fiscalCode: company?.fiscalCode
    }
    const { register, handleSubmit, formState } = useForm<FormData>({ defaultValues, mode: 'onChange' });

    const onSubmit: SubmitHandler<FormData> = async (form) => {
        const body = _.omitBy(form, _.isUndefined)
        try {
            await execute(() => update(body))
            dispatch(reloadProfile())
        } catch (error: any) {
            dispatch(notify('error', 'userUpdateError', 5000))
        }
    }

    const hasFiscalCode = company && company.type === 'freelance'
    const fiscalCodeIsVisible = hasFiscalCode && company.country === "IT"

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card pending={loading}>
                <Card.Body>
                    <div className="grid grid-cols-6 gap-4">
                        <Input
                            containerClass="col-span-6 sm:col-span-3"
                            id="name"
                            name="name"
                            label={t('registrationName')}
                            type="text"
                            ref={register({ required: true })}
                            required
                        />
                        <Input
                            containerClass="col-span-6 sm:col-span-3"
                            id="surname"
                            name="surname"
                            label={t('registrationSurname')}
                            type="text"
                            ref={register({ required: true })}
                            required
                        />
                        {fiscalCodeIsVisible && (
                            <Input
                                containerClass="col-span-6 sm:col-span-3"
                                id="fiscalCode"
                                name="fiscalCode"
                                label={t('registrationFiscalCode')}
                                type="text"
                                ref={register}
                                disabled
                                defaultValue={company.fiscalCode}
                            />
                        )}
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="flex items-center justify-end">
                        <Button type="submit" color="primary" text={t('update')} disabled={!formState.isValid} pending={loading} />
                    </div>
                </Card.Footer>
            </Card>
        </form>
    )
}
