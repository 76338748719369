import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
import { Button, Checkbox } from '../common'

interface PanelDeleteProps {
    onDelete: () => void
}

export function PanelDelete(props: PanelDeleteProps) {
    const { onDelete } = props
    const [deleteWarningRead, setDeleteWarningRead] = useState(false)
    const { t } = useTranslation()

    const handleDeleteWarning = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDeleteWarningRead(e.target.checked)
    }

    return (
        <div className="p-[0.35rem] mt-4 bg-danger-pattern">
            <div className="bg-white p-4 rounded">
                <Disclosure>
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="pb-4 flex flex-row items-center justify-between text-sm w-full">
                                <span>{t('panelDeleteDisclaimerTitle')}</span>
                                {open ? <ChevronUpIcon width={18} /> : <ChevronDownIcon width={18} /> }
                            </Disclosure.Button>
                            <Disclosure.Panel className="text-gray-500 text-sm mb-3">
                                <p className='mb-3'>{t(`panelDeleteDisclaimer${process.env.REACT_APP_BRAND}`)}</p>
                                <Checkbox
                                    id="delete-warning"
                                    name="delete-warning"
                                    onChange={handleDeleteWarning}
                                    checked={deleteWarningRead}
                                    label={t('panelDeleteDisclaimerCheckbox')}
                                />
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
                <Button
                    full
                    text={t('deletePanel')}
                    type="button"
                    onClick={onDelete}
                    color="grey"
                    disabled={!deleteWarningRead}
                />
            </div>
        </div>
    )
}