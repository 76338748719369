import React from 'react'
import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { Company } from '../../profile/selectors'
import FormSelect from '../../common/FormSelect'
import { mapDistributorOptions } from '../../profile/CompanyProfile'

export default function DistributorChoice(props: {
    control: Control
    distributors?: Company[]
    onSearch?: (value: string) => void
    invalid?: boolean
    rules?: Object
    noOptionsMessage?: string
    helpMessage?: string
    isLoading?: boolean
    show?: boolean,
    containerClass?: string
}) {
    const { t } = useTranslation()
    const {
        control,
        distributors = [],
        onSearch,
        invalid,
        rules = { required: true },
        isLoading,
        helpMessage = t('distributorChoiceHelp'),
        show = true,
        containerClass = ''
    } = props


    return <FormSelect
        id="distributor"
        name="distributor"
        label={t('distributor')}
        noOptionsMessage={t('distributorInputSearchableEmpty')}
        loadingMessage={t('loading')}
        invalidMessage={t('requiredDistributor')}
        helpMessage={helpMessage}
        placeholder={t('select')}
        primaryColor="var(--color-brand-600)"
        maxMenuHeight={160}
        control={control}
        isMulti={false}
        isClearable={false}
        isLoading={isLoading}
        onInputChange={onSearch}
        options={distributors.map(mapDistributorOptions).concat({ label: t('noneAbove'), value: 'none' })}
        invalid={invalid}
        rules={rules}
        show={show}
        containerClass={containerClass}
    />
}