import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller, UseFormMethods } from 'react-hook-form'

export type Brand = {
    code: string,
    name: string
}

function BrandsInput(props: {
    brands: Brand[],
    onChange: (brands: string[]) => void
    optional?: boolean
}) {
    const { t } = useTranslation()

    const { brands, onChange, optional = false } = props

    const [sourceBrands, setBrands] = useState<Brand[]>([])
    const [selectedBrands, setSelectedBrands] = useState<Brand[]>([])

    useEffect(() => {
        setBrands(brands || [])
        setSelectedBrands([])
    }, [brands])

    const handleOnClick = useCallback((clicked: Brand) => {
        const findClicked = (selected: Brand) => clicked.code === selected.code
        const index = selectedBrands.findIndex(findClicked)
        let selected = selectedBrands
        if (index > -1) {
            selected.splice(index, 1)
        } else {
            selected = [...selected, clicked]
        }
        setSelectedBrands(selected)
        onChange(selected.map(b => b.code))
    }, [selectedBrands, onChange])

    return <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
            {t('registrationBrandsYouAreUsing')} {optional ? `(${t('optional').toLowerCase()})` : null}
        </label>
        <fieldset className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-2">
            <legend className="sr-only">{t('registrationBrandsYouAreUsing')}</legend>
            {sourceBrands.map((brand) => {
                return (
                    <label htmlFor={`brands-option-${brand.code}`} className="flex items-start text-gray-600 bg-gray-50 p-2 rounded-full" key={brand.code}>
                        <div className="flex items-center h-5" key={brand.code}>
                            <input onClick={() => { handleOnClick(brand) }} key={brand.code} value={brand.code} id={`brands-option-${brand.code}`} aria-describedby={brand.name} name="brands" type="checkbox" className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded" />
                        </div>
                        <div className="ml-3 text-sm">
                            {brand.name}
                        </div>
                    </label>
                )
            })}
        </fieldset>
    </div>
}

export default function BrandsChoice(props: {
    brands?: Brand[]
    useFormProps: UseFormMethods<Record<string, any>>
    optional?: boolean
    show?: boolean
}) {
    const { control } = props.useFormProps

    const { brands = [], optional = false, show = true } = props

    return show ? <>
        {brands.length ? <Controller
            control={control}
            name="actualBrands"
            render={({ onChange }) => <BrandsInput brands={brands} onChange={onChange} optional={optional} />}>
        </Controller> : null}
    </> : null
}