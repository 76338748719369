import React, { FC, forwardRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Spinner } from './Spinner'

interface Props extends React.ComponentPropsWithRef<'select'> {
    containerClass?: string,
    label?: string,
    otherLabelClasses?: string
    optional?: boolean,
    help?: string,
    pending?: boolean
    show?: boolean
}

const Select: FC<Props> = forwardRef<HTMLSelectElement, Props>((props, ref) => {
    const {
        children,
        containerClass,
        label,
        otherLabelClasses,
        optional,
        help,
        pending = false,
        show = true,
        ...otherProps
    } = props
    const { t } = useTranslation()
    const labelClasses = clsx(
        'flex-1 block text-sm font-medium text-gray-700',
        otherLabelClasses && otherLabelClasses
    )
    return show ? (
        <div className={containerClass}>
            {label && (
                <div className="flex justify-between mb-1">
                    <label htmlFor={otherProps.id} className={labelClasses}>
                        {label}
                    </label>
                    {optional && (
                        <span className="text-sm text-gray-500">
                            {t('optional')}
                        </span>
                    )}
                </div>
            )}
            <div className="relative">
                <Spinner show={pending} containerClass="z-10 pointer-events-none" size={4} />
                <select
                    disabled={pending}
                    className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-brand-500 focus:border-brand-500 sm:text-sm rounded-md disabled:opacity-30 disabled:cursor-not-allowed"
                    ref={ref}
                    {...otherProps}
                >
                    {children}
                </select>
            </div>
            {help && (
                <p className="mt-2 text-sm text-gray-500">
                    {help}
                </p>
            )}
        </div>
    ) : null
})

export default Select