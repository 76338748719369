import React, { VFC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { Avatar } from '../../../common/Avatar'
import { getAccountExternalLink } from '../../../config'
import { useAuth } from '../../../auth/msal'
import { useQueryClient } from 'react-query'

export const MenuProfile: VFC = () => {
    const { name, lastName, signOut } = useAuth()
    const client = useQueryClient()
    const { t } = useTranslation()

    const doLogout = useCallback(async () => {
        await signOut()
        client.clear()
        await client.invalidateQueries()
    }, [signOut, client])

    return (
        <div className="flex-shrink-0 flex flex-col border-t border-gray-800 bg-gray-900 py-2">
            <div className="flex items-center px-4 pb-2">
                <Avatar
                    size={8}
                    initials={`${name?.charAt(0)}${lastName?.charAt(0)}`}
                    type="circular"
                />
                <div className="ml-3">
                    <p className="text-sm font-medium text-brand-400">
                        <span className="hover:text-brand-300"><a rel="noreferrer" href={getAccountExternalLink()}>{`${name} ${lastName}`}</a></span>
                    </p>
                    <p className="text-xs font-medium text-gray-300 group-hover:text-white">
                        <NavLink className="hover:text-white" onClick={doLogout} to="/">{t('logout')}</NavLink>
                    </p>
                </div>
            </div>
        </div>
    )
}