import React from 'react'
import { Button } from '../common/Button'

export function VideoLink(props: {
    imageSrc: string
    imageAlt: string
    buttonTitle?: string
    buttonLink?: string
    title: string
    subtitle?: string
}) {
    const { imageSrc, imageAlt, buttonTitle, title, subtitle, buttonLink } = props

    return (
        <div className="relative shadow rounded-xl my-6 overflow-hidden">
            <div className="absolute inset-0">
                <img className="h-full w-full object-cover" src={imageSrc} alt={imageAlt} />
                <div className="absolute inset-0 bg-gradient-to-r from-gray-600 to-gray-500 mix-blend-multiply"></div>
            </div>
            <div className="relative px-6 py-12">
                {title ? <h1 className="break-word text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl max-w-md">
                    <span className="break-normal block text-left text-white">{title}</span>
                </h1> : null}
                {subtitle ?
                    <p className="mt-6 mx-auto text-left text-xl text-white">
                        {subtitle}
                    </p>
                    : null}
                {buttonTitle ?
                    <div className="mt-10">
                        <Button href={buttonLink}  color="primary" size="xl" as="a" target="_blank" rel="noreferrer" text={buttonTitle} />
                    </div>
                    : null}
            </div>
        </div>)
}