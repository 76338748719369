import React from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CreateCompanySvg } from '../images/visitor-dashboard/create-company.svg'
import { Button } from '../common/Button'
import { CheckCircleIcon, DotsCircleHorizontalIcon, XCircleIcon } from '@heroicons/react/outline'
import { CompanyStatus, CompanyType } from '../profile/selectors'

const CompanyVerificationStatusIcon = (props: {
    companyStatus: CompanyStatus
}) => {
    if (props.companyStatus === 'unverified')
        return <DotsCircleHorizontalIcon className="w-10 h-10 text-yellow-400" />
    if (props.companyStatus === 'verified')
        return <CheckCircleIcon className="w-10 h-10 text-green-400" />
    if (props.companyStatus === 'invalid')
        return <XCircleIcon className="w-10 h-10 text-red-700" />
    return null
}

const CompanyVerificationStatus = (props: {
    companyStatus: CompanyStatus
}) => {
    const { t } = useTranslation()
    const { companyStatus } = props
    return <div className="flex-1 bg-white p-2 rounded-md">
        <div className="flex flex-col items-center space-y-1">
            <CompanyVerificationStatusIcon companyStatus={companyStatus} />
            <h3 className="text-gray-900 text-sm font-bold">{t(companyStatus + 'CompanyDescription')}</h3>
            <p className="text-gray-500 text-sm">{t(companyStatus + 'CompanyHint')}</p>
        </div>
    </div>
}

export const CompanyCreationBanner = (props: {
    companyType: CompanyType
    companyStatus?: CompanyStatus
    onCreateCompany: () => void
}) => {
    const { companyStatus, onCreateCompany } = props
    const { t } = useTranslation()
    return (
        <div className="flex flex-col items-center bg-gray-50">
            <CreateCompanySvg className="w-1/3 lg:w-1/4 mx-auto" />
            <div className="flex-1 py-8 text-center lg:py-12 lg:text-left">
                <div className="px-4 sm:px-8 xl:pr-16">
                    <h1 className="text-2xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-4xl text-center">
                        <span className="block xl:inline">{t('createCompany')}</span>{' '}
                        <span className="block text-brand-500 xl:inline">{t('createCompanyClaim')}</span>
                    </h1>
                    <p className="mt-3 max-w-md mx-auto text-base sm:text-lg text-gray-500 md:max-w-3xl text-center">
                        {t('completeYourProfileHint')}
                    </p>
                    <div className="mt-8 lg:mt-10 text-center">
                        {!companyStatus ?
                            <div className="w-full">
                                <Button
                                    text={t('start')}
                                    color="primary"
                                    size="2xl"
                                    onClick={onCreateCompany}
                                />
                            </div>
                            : <CompanyVerificationStatus companyStatus={companyStatus} />}
                    </div>
                </div>
            </div>
        </div>
    )
}