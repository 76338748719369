import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { Button } from '../../common/Button'
import Pin from '../../common/Pin';
import { PhoneWithCountry } from '../../common/PhoneWithCountry'
import { FirstStepsWrapper } from './FirstStepsWrapper'
import { useLoading } from '../../hooks/loading';
import { reloadProfile } from '../../profile/actions'
import { notify } from '../../notification/actions'
import { useAuth } from '../../auth/amplify';

type FirstStepsPhoneProps = {
    currentPhone?: string
}

type FormData = {
    newNumber: string
}

export const FirstStepsPhone: React.FC<FirstStepsPhoneProps> = (props) => {
    const { currentPhone } = props
    const [step, setStep] = useState<'phone' | 'pin'>('phone')
    const { t } = useTranslation()
    const { handleSubmit, control } = useForm<FormData>({
        defaultValues: {
            newNumber: currentPhone
        }
    });
    const { updatePhoneWithVerificationRequest, verifyUserAttributeSubmit } = useAuth()
    const { execute, loading } = useLoading()
    const dispatch = useDispatch()
    const onSubmitHandler: SubmitHandler<FormData> = async ({ newNumber }) => {
        await execute(
            () => updatePhoneWithVerificationRequest({ newNumber, oldNumber: currentPhone })
        )
        setStep('pin')
    }


    const onPinFilledHandler = async (pin: string) => {
        try {
            await execute(() => verifyUserAttributeSubmit(pin))
            dispatch(reloadProfile())
        } catch (e: any) {
            dispatch(notify('error', e.code))
        }
    }

    return (
        <FirstStepsWrapper pending={loading}>
            <p className="uppercase text-sm text-brand">{t('firstStepPhoneHeader')}</p>
            <p className="text-xl">{t('firstStepPhoneTitle')}</p>
            <div className='mt-8 mx-auto'>
                {step === 'phone' && (
                    <form
                        autoComplete='off'
                        onSubmit={handleSubmit(onSubmitHandler)}
                        className="flex justify-center items-center gap-x-2">
                        <PhoneWithCountry
                            control={control}
                            name="newNumber"
                            id="phone-number-berification"
                            rules={{ required: true }}
                        />
                        <Button text="Invia SMS" color="primary" />
                    </form>
                )}
                {step === 'pin' && (
                    <Pin
                        onFilled={(pin) => onPinFilledHandler(pin)}
                        help={t('codeViaSmsVerificationHelp')}
                        digits={6}
                    />
                )}
            </div>
        </FirstStepsWrapper>
    )
}
