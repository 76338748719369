import { useCallback } from 'react'
import { CompanyStatus, getUserCompanyProfile, UserCompanyProfile, UserMarketing } from '../../profile/selectors'
import { useMutation } from 'react-query'
import { useSelector } from 'react-redux'
import { USERS_PATH, COMPANIES_PATH, createResource, updateResource } from '../utils'
import { useAuth } from '../../auth'

export type CreateInstallerCompany = {
    type: 'installer'
    businessName: string
    address: string
    zipCode: string
    city: string
    country: string
    province: string
    email: string
    phoneNumber: string
    vatNumber?: string
    pec?: string
    cuu?: string
    distributors?: string[]
    actualBrands?: string[]
    note?: string
}

export type CreateFreelanceCompany = {
    type: 'freelance'
    country: string
    province: string
    fiscalCode?: string
    distributors?: string[]
    actualBrands?: string[]
    note?: string
}

export type CreateCompany = CreateInstallerCompany | CreateFreelanceCompany

type UpdateInstallerCompany = Partial<Omit<CreateInstallerCompany, 'type' | 'country' | 'vatNumber' | 'distributors' | 'actualBrands'>>
type ConfirmFreelanceCompany = CreateFreelanceCompany & { status: CompanyStatus }
type UpgradeFreelanceCompany = CreateInstallerCompany

export type UpdateCompany = UpdateInstallerCompany | ConfirmFreelanceCompany | UpgradeFreelanceCompany

const createCompany = (authToken: string) => async (params: { body: CreateCompany }) => {
    return await createResource(COMPANIES_PATH, authToken, params.body)
}

const updateCompany = (authToken: string) => async (params: { id: string, body: UpdateCompany }) => {
    return await updateResource(`${COMPANIES_PATH}/${params.id}`, authToken, params.body)
}

const requestToJoinTeam = (authToken: string) => async (params: { id: string }) => {
    return await createResource(`${COMPANIES_PATH}/${params.id}/request-member-acceptance`, authToken, {})
}

export const useCompanyMutation = () => {

    const { getIdToken } = useAuth()
    const profile: UserCompanyProfile = useSelector(getUserCompanyProfile) as UserCompanyProfile

    const createFn = useCallback(async (body: CreateCompany) => {
        const token = await getIdToken()
        return createCompany(token)({
            body: {
                ...body
            }
        })
    }, [getIdToken])

    const updateFn = useCallback(async (body: UpdateCompany) => {
        const token = await getIdToken()
        return updateCompany(token)({ id: profile.company?._id as string, body })
    }, [getIdToken, profile])

    const requestToJoinTeamFn = useCallback(async (id: string) => {
        const token = await getIdToken()
        return requestToJoinTeam(token)({ id })
    }, [getIdToken])

    const createMutation = useMutation(createFn)
    const create = (body: CreateCompany) => createMutation.mutateAsync(body)

    const updateMutation = useMutation(updateFn)
    const update = (body: UpdateCompany) => updateMutation.mutateAsync(body)

    const createRequestToJoinMutation = useMutation(requestToJoinTeamFn)
    const requestToJoin = (id: string) => createRequestToJoinMutation.mutateAsync(id)

    return { create, update, requestToJoin }
}

export type UpdateUser = {
    name?: string
    surname?: string
    marketing?: UserMarketing,
    certificationLevel?: number
}

export const updateUser = (authToken: string) => async (params: { id: string, body: UpdateUser }) => {
    return await updateResource(`${USERS_PATH}/${params.id}`, authToken, params.body)
}

export const useUserMutation = () => {
    const { getIdToken } = useAuth()
    const profile: UserCompanyProfile = useSelector(getUserCompanyProfile) as UserCompanyProfile

    const updateFn = useCallback(async (body: UpdateUser) => {
        const token = await getIdToken()
        return updateUser(token)({ id: profile._id as string, body })
    }, [getIdToken, profile])

    const updateMutation = useMutation(updateFn)
    const update = (body: UpdateUser) => updateMutation.mutateAsync(body)

    return { update }
}