import React from 'react'
import Pin from '../common/Pin'
import { Transition } from '@headlessui/react'

export function PinCodeLayer(props: {
    showTransition?: boolean
    digits?: number
    help?: string
    pending?: boolean
    onFilled: (code: string) => void
    onResend?: () => void
}) {
    const { showTransition, digits, help, pending, onFilled, onResend } = props

    return (
        <Transition
            show={showTransition || false}
            enter="transition-opacity transition-transform origin-center duration-300 absolute w-full h-full top-0 left-0 bg-gray-50 z-20 linear"
            enterFrom="opacity-0 transform scale-150"
            enterTo="opacity-100 transform scale-100"
            leave="transition-opacity transition-transform origin-center duration-300 absolute w-full h-full top-0 left-0 bg-gray-50 z-20 linear"
            leaveFrom="opacity-100 transform scale-100"
            leaveTo="opacity-0 transform scale-150"
        >
            <div className="flex items-center justify-center absolute w-full h-full top-0 left-0 bg-gray-50 z-20">
                <Pin
                    pending={pending}
                    onFilled={onFilled}
                    onResend={() => {
                        if (onResend) {
                            onResend()
                        }
                    }}
                    help={help}
                    digits={digits}
                />
            </div>
        </Transition>)
}

export default PinCodeLayer