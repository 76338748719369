import React, { useEffect, useState } from 'react'
import { PanelsList } from '../../../panels/PanelsList'
import { ModalDialog } from '../../../common/ModalDialog'
import { Panel as PanelDetail } from '../../../panels/Panel'
import { useTranslation } from 'react-i18next'
import { Panel } from '../../../panels/actions'
import useLoquendo from '../hooks/useLoquendo'
import { usePanels } from '../../../hooks/usePanels'

export function BticinoList() {
    const { t } = useTranslation()

    const {
        createPanel,
        updatePanel,
        deletePanel,
    } = usePanels({ enabledQuery: false })

    const { isFetching: loadingVoices, license } = useLoquendo()

    const [selectedPanel, setSelectedPanel] = useState<Panel | null>(null)
    const [mode, setMode] = useState<'notvisible' | 'edit' | 'add'>('notvisible')

    useEffect(() => {
        if (mode === 'notvisible')
            setSelectedPanel(null)
    }, [mode, setSelectedPanel])

    const isEditingMode = selectedPanel && mode === 'edit'
    const modalOpen = mode !== 'notvisible'
    const saveMode = isEditingMode ? mode : 'add'
    const modalTitle = isEditingMode ? t('panelsEditTitle') : t('panelsAddTitle')

    return <>
        <h2 className="title-page">{t('panels')}</h2>
        <PanelsList
            showLoading={loadingVoices}
            voices={license?.voices}
            onEdit={(panel) => { setSelectedPanel(panel); setMode('edit') }}
            onAdd={() => { setMode('add') }}
        />
        <ModalDialog
            open={modalOpen}
            onClose={() => { setMode('notvisible') }}
            title={modalTitle}
        >
            <PanelDetail
                open={modalOpen}
                mode={saveMode}
                canEdit={true}
                canDelete={true}
                canActivate={false}
                {...(selectedPanel || {}) as Panel}
                onCancel={() => { setMode('notvisible') }}
                onSave={(panel: Panel) => {
                    const { serialNumber, mac, name, description, location } = panel
                    switch (saveMode) {
                        case 'add':
                            createPanel({
                                serialNumber,
                                name,
                                description,
                                location
                            })
                            break;
                        case 'edit':
                            updatePanel({
                                name,
                                description,
                                location,
                                mac
                            })
                            break;
                    }
                    setMode('notvisible')
                }}
                onDelete={() => {
                    if (selectedPanel && selectedPanel.mac) {
                        deletePanel(selectedPanel.mac)
                    }
                    setMode('notvisible')
                }}
            />
        </ModalDialog>
    </>
}