import { MailIcon, UserGroupIcon } from '@heroicons/react/outline'
import React, { useEffect } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps, useDispatch } from 'react-redux'
import { Button } from '../common/Button'
import Card from '../common/Card/Card'
import Input from '../common/Input/Input'
import { Toggle } from '../common/Toggle'
import { testEmail } from '../common/utils'
import { State } from '../configureStore'
import { inviteCompanyMember, loadCompanyProfile } from './actions'
import CompanyMembersList from './CompanyMembersList'
import { ProfileHeader } from './ProfileHeader'
import { getUserCompanyMembersOrdered, getUserCompanyProfile, isLoadingUserCompanyProfile, UserCompanyProfile } from './selectors'
import { getProfileSubTitle } from './utils'

type FormData = {
    email: string
    aclEnabled: boolean
}

export function CompanyMembers(props: PropsFromRedux & {}) {
    const { profile, members = [], loading } = props
    const { t } = useTranslation()

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(loadCompanyProfile(profile.company?._id || ''))
    }, [dispatch, profile.company?._id])

    const defaultValues = {
        email: '',
        aclEnabled: false
    }

    const { handleSubmit, control, register, reset, errors } = useForm<FormData>({ defaultValues })

    const doSubmit: SubmitHandler<FormData> = async (form) => {
        const { email, aclEnabled } = form
        if (window.confirm(t('inviteCompanyMemberConfirmation'))) {
            dispatch(inviteCompanyMember({
                companyId: profile.company ? profile.company?._id : '',
                email: email,
                aclEnabled: aclEnabled
            }))
            reset()
        }
    }

    return (
        <div>
            <ProfileHeader
                title={profile.company?.businessName}
                subtitle={getProfileSubTitle(profile)}
                certification={profile.company?.certificationLevel}
                certificationScaled={profile.company?.scaledCertificationLevel}
                score={profile.company?.loyaltyProgramScore}
                avatar={profile.company?.logoUrl}
            />
            <div className="page-container mt-36 md:mt-12">
                <Card pending={loading}>
                    <Card.Body full>
                        <div className="mb-4 sm:mb-8">
                            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full mt-4">
                                <UserGroupIcon className="text-gray-500 w-16" />
                            </div>
                            <div className="mt-3 text-center sm:mt-5">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    {t('addCompanyMembers')}
                                </h3>
                                <div className="mt-2 break-word max-w-ml mx-auto">
                                    <p className="text-sm text-gray-500 break-normal">
                                        {t('addCompanyMembersSubtitle1')}
                                    </p>
                                    <p className="text-sm text-gray-500 break-normal">
                                        {t('addCompanyMembersSubtitle2')}
                                    </p>
                                </div>
                            </div>
                            <form id="send-invitation-email-form" className="mx-auto flex items-center justify-center w-full sm:w-3/4 lg:w-2/4 mt-2 space-x-3" onSubmit={handleSubmit(doSubmit)}>
                                <div className="w-full my-2 mx-auto p-4 sm:p-0">
                                    <Input
                                        name={'email'}
                                        id="email"
                                        type="email"
                                        placeholder={t('emailPlaceholderInvitation')}
                                        icon={<MailIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                                        ref={register({
                                            required: true,
                                            validate: testEmail
                                        })}
                                        required
                                    >
                                        {errors.email && (
                                            <Input.Alert>
                                                {t('invalidEmail')}
                                            </Input.Alert>
                                        )}
                                    </Input>
                                    <div className="flex items-center justify-center py-6">
                                        <span className="flex-grow flex flex-col">
                                            <span className="text-sm font-medium text-gray-700">{t('aclEnabledInvitationTitle')}</span>
                                            <span className="text-sm font-medium text-gray-400">{t('aclEnabledInvitationSubtitle')}</span>
                                        </span>
                                        <Toggle name="aclEnabled" control={control} />
                                    </div>
                                    <div className="flex justify-center">
                                        <Button
                                            type="submit"
                                            text={t('sendInvitation')}
                                            color="primary"
                                            aria-invalid={true}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <CompanyMembersList
                            members={members}
                            companyId={profile.company?._id || ''}
                            showHeaders={true}
                            profileEmail={profile.email}
                        />
                    </Card.Body>
                </Card >
            </div>
        </div>
    )
}

export function mapStateToProps(state: State) {
    return {
        profile: getUserCompanyProfile(state) as UserCompanyProfile,
        loading: isLoadingUserCompanyProfile(state),
        members: getUserCompanyMembersOrdered(state)
    }
}

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(CompanyMembers)
