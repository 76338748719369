export const privacy = {
    it: {
        privacy: `INFORMATIVA AL TRATTAMENTO DEI DATI PERSONALI

I dati personali dell'utente sono utilizzati da Ksenia Security S.p.A., che ne è titolare per il trattamento, nel rispetto dei principi di protezione dei dati personali stabiliti dal Regolamento GDPR 2016/679.


MODALITÀ E FINALITÀ DEL TRATTAMENTO DATI

1) La informiamo che i dati verranno trattati con il supporto dei seguenti mezzi:

a) Mista - elettronica e cartacea

con le seguenti finalità:
- Adempimento di obblighi di legge connessi a rapporti commerciali
- Adempimento di obblighi fiscali o contabili
- Analisi statistiche
- Attività promozionali
- Erogazione del servizio prodotto
- Gestione dei fornitori (contratti, ordini, arrivi, fatture)
- Gestione del contenzioso (contratti, ordini, arrivi, fatture)
- Gestione della clientela (contratti, ordini, spedizioni e fatture)
- Offerta di beni e servizi attraverso mailing list
- Operazioni di trasporto merci
- Profilazione a fini promozionali
- Servizi a tutela di consumatori e utenti
- Servizi di controllo interno (della sicurezza, della produttività, della qualità dei servizi, dell'integrità patrimonio)

L'eventuale rifiuto nel consentire il trattamento dei dati comporta l'impossibilità di usufruire del servizio richiesto dall'utente.

Fatto salvo esplicito diniego da parte dell'interessato, i dati dell’utente saranno trattati anche per le seguenti finalità:
- invio di proposte e di comunicazioni commerciali a mezzo posta elettronica o SMS o fax, da parte sia di Ksenia Security S.p.A. e sia di società partner; indagini di mercato e statistiche, marketing, referenze su comunicazioni pubblicitarie (stampa, radio, TV, internet, ecc.), preferenze sui prodotti e verifica del grado di soddisfazione della clientela;
        

BASE GIURIDICA

2) Il conferimento dei dati è obbligatorio per tutto quanto è richiesto dagli obblighi legali e contrattuali e pertanto l'eventuale rifiuto a fornirli in tutto o in parte può dar luogo all'impossibilità di fornire i servizi richiesti.

La società tratta i dati facoltativi degli utenti in base al consenso, ossia mediante l’approvazione esplicita della presente policy privacy e in relazione alle modalità e finalità di seguito descritte.
                

CATEGORIE DI DESTINATARI

3) Ferme restando le comunicazioni eseguite in adempimento di obblighi di legge e contrattuali, tutti i dati raccolti ed elaborati potranno essere comunicati esclusivamente per le finalità sopra specificate alle seguenti categorie di interessati:
- Alte amministrazioni pubbliche;
- Autorità di vigilanza e controllo;
- Clienti ed utenti;
- Consulenti e liberi professionisti anche in forma associata;
- Imprese;
- Imprese di assicurazione;
- Persone autorizzate;
- Responsabili interni;
- Società che effettuano il servizio di logistica di magazzino e trasporto;
- Società e imprese;

Nella gestione dei suoi dati, inoltre, possono venire a conoscenza degli stessi le persone autorizzate e/o responsabili interni ed esterni individuati per iscritto da Ksenia Security ed ai quali sono state fornite specifiche istruzioni scritte circa il trattamento dei dati.
                

PERIODO DI CONSERVAZIONE

4) I dati obbligatori ai fini contrattuali e contabili sono conservati per il tempo necessario allo svolgimento del rapporto commerciale e contabile.

I dati di chi non acquista o usufruisce di prodotti/servizi, pur avendo avuto un precedente contatto con dei rappresentanti dell’azienda, saranno immediatamente cancellati o trattati in forma anonima, ove la loro conservazione non risulti altrimenti giustificata, salvo che sia stato acquisito validamente il consenso informato degli interessati relativo ad una successiva attività di promozione commerciale o ricerca di mercato.

Il periodo di conservazione dei dati è: 3 anni.
                

DIRITTI DELL’INTERESSATO

5) Ai sensi del Regolamento europeo 679/2016 (GDPR) e della normativa nazionale, l'interessato può, secondo le modalità e nei limiti previsti dalla vigente normativa, esercitare i seguenti diritti:
- richiedere la conferma dell'esistenza di dati personali che lo riguardano (diritto di accesso);
- conoscerne l'origine;
- riceverne comunicazione intelligibile;
- avere informazioni circa la logica, le modalità e le finalità del trattamento;
- richiederne l'aggiornamento, la rettifica, l'integrazione, la cancellazione, la trasformazione in forma anonima, il blocco dei dati trattati in violazione di legge, ivi compresi quelli non più necessari al perseguimento degli scopi per i quali sono stati raccolti;
- nei casi di trattamento basato su consenso, ricevere i propri dati forniti al titolare, in forma strutturata e leggibile da un elaboratore di dati e in un formato comunemente usato da un dispositivo elettronico;
- il diritto di presentare un reclamo all’Autorità di controllo.
        
6) Titolare del trattamento dei Suoi dati personali è Ksenia Security S.p.A.`,
        title: `Portale di configurazione e monitoraggio www.kseniaSecureWeb.com, app Ksenia PRO e app lares 4.0

Condizioni generali`,
        agree: "Accetto",
        note: "Ai sensi ed agli effetti di cui agli artt 1341 e 1342 del Codice Civile italiano il Cliente dichiara di approvare specificatamente le seguenti clausole 4,6,7,8,13."
    },
    en: {
        privacy: `PRIVACY POLICY

The personal data of the user are used by Ksenia Security S.p.A., which is the data controller for the data processing, in compliance with the principles of protection of personal data established by the GDPR 2016/679.
        

MODALITIES AND SCOPES OF DATA PROCESSING

1) We inform you that the data will be processed with the support of the following tools:

a) Mixed - electronic and paper
        
with the following scopes:
- Compliance with legal obligations related to commercial relationships
- Compliance with tax or accounting obligations
- Statistical analysis
- Promotional activities
- Service delivery
- Suppliers management (contracts, orders, arrivals, invoices)
- Litigation management (contracts, orders, arrivals, invoices)
- Customer management (contracts, orders, arrivals, invoices)
- Offer of goods and services through a mailing list
- Freight transport operations
- Profiling for promotional purposes
- Services to protect consumers and users
- Internal control services (security, productivity, quality of services, heritage integrity)
        
Any refusal to allow the processing of data implies the impossibility of using the service requested by the user.

Subject to explicit denial by the party concerned, the user's data will also be processed for the following purposes:
- sending of proposals and commercial communications by e-mail or SMS or fax, both from Ksenia Security S.p.A. and from partner companies; market surveys and statistics, marketing, references on advertising (press, radio, TV, internet, etc.), product preferences and verification of degree of customer satisfaction;


LEGAL BASIS

2) The provision of data is mandatory for all that is required by legal and contractual obligations and therefore any refusal to supply them in whole or in part may give rise to the impossibility of providing the services requested.

The company processes the user's optional data on the basis of consent, that is through the explicit approval of this privacy policy and in relation to the methods and purposes described below.
        

RECIPIENTS CATEGORIES

3) Without prejudice to communications made in compliance with legal and contractual obligations, all data collected and processed may be communicated exclusively for the purposes specified above to the following categories of interested parties:
- High public administrations
- Supervisory and control authorities;
- Customers and users;
- Consultants and freelancers also in associated form;
- Companies;
- Insurance companies;
- Authorized persons;
- Internal processors;
- Companies that make the warehouse and transport logistics service;

Furthermore, in the processing of your data, the authorized persons and / or internal and external processors, identified in writing by Ksenia Security and to whom specific written instructions on data processing have been provided, may be made aware of them.


STORAGE PERIOD

4) The mandatory data for contractual and accounting purposes are kept for the time necessary to carry out the commercial and accounting relationship.

The data of those who do not buy or use products / services, even if they have had a previous contact with company representatives, will be immediately canceled or processed anonymously, where their conservation is not otherwise justified, unless it has been validly acquired the informed consent of the data subjects concerning a subsequent commercial promotion or market research activity.

The data storage period is: 3 years.
                

RIGHTS OF THE DATA SUBJECT

5) Pursuant to European Regulation 679/2016 (GDPR) and national legislation, the data subject, in accordance with the procedures and within the limits established by current legislation, may exercise the following rights:
- request confirmation of the existence of personal data concerning him (right of access);
- know the origin of his data;
- receive intelligible communication;
- have information about the logic, the methods and the scopes of the processing;
- request the updating, rectification, integration, cancellation, transformation into anonymous form, blocking of data processed in violation of the law, including those no longer necessary for the pursuit of the purposes for which they were collected;
- in cases of consent-based processing, receive his data provided to the holder, in a structured and readable form by a data processor and in a format commonly used by an electronic device;
- the right to lodge a complaint with the Authority.

6) Ksenia Security S.p.A. is the owner of the processing of your personal data.`,
        title: `Portal of configuration and monitoring www.kseniasecureweb.com, app Ksenia PRO and app lares 4.0

General conditions`,
        agree: "I Accept",
        note: "Pursuant to and for the purposes of articles 1341 and 1342 of the Italian Civil Code, the Client specifically approves the following clauses 4,6,7,8,13."
    }
}