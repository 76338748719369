import React from 'react'
import clsx from 'clsx'
import { Tab } from '@headlessui/react'

interface TabProps {
    name: string
}
interface Props {
    children: JSX.Element[],
    tabs: TabProps[],
    onChange?: (index: number) => void
}

export function TabPills(props: Props) {
    const { children, tabs = [], onChange } = props
    const tabListClasses = clsx(
        'grid space-x-2 mb-4 sm:space-x-4 sm:mb-8 sm:justify-center',
        tabs.length === 1 && 'grid-cols-[repeat(1,minmax(min-content,max-content))]',
        tabs.length === 2 && 'grid-cols-[repeat(2,minmax(min-content,max-content))]',
        tabs.length === 3 && 'grid-cols-[repeat(3,minmax(min-content,max-content))]',
        tabs.length === 4 && 'grid-cols-[repeat(4,minmax(min-content,max-content))]',
        tabs.length === 5 && 'grid-cols-[repeat(5,minmax(min-content,max-content))]',
        tabs.length === 6 && 'grid-cols-[repeat(6,minmax(min-content,max-content))]',
        tabs.length === 7 && 'grid-cols-[repeat(7,minmax(min-content,max-content))]',
        tabs.length === 8 && 'grid-cols-[repeat(8,minmax(min-content,max-content))]'
    )
    return (
        <Tab.Group onChange={onChange}>
            <div className="w-full overflow-x-auto">
                <Tab.List className={tabListClasses}>
                    {tabs.map((tab, i) => (
                        <Tab
                            key={`${tab.name}-${i}`}
                            className={({ selected }) => clsx(
                                selected ? 'bg-brand-500 text-white' : 'text-gray-500 hover:text-gray-700',
                                'word px-3 py-2 font-medium text-sm rounded-md whitespace-nowrap sm_whitespace-normal'
                            )}
                        >
                            {tab.name}
                        </Tab>
                    ))}
                </Tab.List>
            </div>
            <Tab.Panels>
                {React.Children.map(children, (child) => (
                    <Tab.Panel>
                        {child}
                    </Tab.Panel>
                ))}
            </Tab.Panels>
        </Tab.Group>
    )
}