import clsx from 'clsx'
import React from 'react'

type SectionHeadingsProps = {
    title: string
    subtitle?: string,
    containerClass?: string,
    aside?: React.ReactElement | Array<React.ReactElement>
}

const SectionHeadings: React.VFC<SectionHeadingsProps> = (props) => {
    const { title, subtitle, aside, containerClass } = props
    const containerClassName = clsx(
        'pb-5 border-b border-gray-200',
        {
            'sm:flex sm:items-center sm:justify-between': aside
        },
        containerClass && containerClass
    )
    return (
        <div className={containerClassName}>
            <h3 className="text-lg leading-6">{title}</h3>
            {subtitle && (
                <p className="mt-2 max-w-4xl text-sm text-gray-500">{subtitle}</p>
            )}
            {aside && (
                <div className="mt-3 flex sm:mt-0 sm:ml-4 gap-x-2">
                    {aside}
                </div>
            )}
        </div>
    )
}

export default SectionHeadings