import React, { useEffect, useRef, useState, VFC, KeyboardEvent, ChangeEvent, useCallback } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Spinner } from './Spinner'

type Props = {
    onFilled: (value: string) => void;
    digits?: number;
    help?: string;
    additionalHelpClasses?: string;
    onResend?: () => void;
    pending?: boolean;
}

type Digits = Record<number, string>

const pinRegExp = (digits: number) => new RegExp(`^[0-9]{${digits}}$`)

const Pin: VFC<Props> = (props) => {
    const { onFilled, digits = 6, help, additionalHelpClasses, onResend, pending = false } = props
    const pinRefs = useRef<HTMLInputElement[]>([])
    const [pin, setPin] = useState<Digits | {}>({})
    const [pinRegex, setPinRegex] = useState<RegExp>(pinRegExp(digits))
    const [pinUsed, setPinUsed] = useState<boolean>(false)
    const { t } = useTranslation()

    useEffect(() => {
        pinRefs.current[0]?.focus()
    }, [])

    useEffect(() => {
        if (!pinUsed && Object.values(pin).filter(value => value && value.length).length === digits) {
            onFilled(Object.values(pin).join(''))
            setPinUsed(true)
        }
    }, [digits, pin, pinUsed, onFilled])

    useEffect(() => {
        setPinRegex(pinRegExp(digits))
    }, [digits])

    const pasteText = useCallback((text) => {
        if (text && pinRegex.test(text)) {
            setPin(text)
            setPinUsed(false)
            pinRefs.current[pinRefs.current.length - 1].focus()
        }
    }, [pinRegex, pinRefs])

    const helpClasses = clsx(
        'w-full text-center text-gray-500 text-sm',
        additionalHelpClasses && additionalHelpClasses
    )

    return (
        <div className="flex flex-col items-center justify-center">
            <Spinner show={pending} size={8} />
            <div className="flex space-x-2 py-2">
                {[...new Array(digits).keys()].map((i) => (
                    <input
                        value={(pin as Digits)[i] || ''}
                        disabled={pending}
                        pattern="[0-9]{1}"
                        data-testid={'pin' + i}
                        key={'pin' + i}
                        tabIndex={i}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            event.preventDefault()
                            const target = event.target as HTMLInputElement
                            const value = target.value
                            setPin({ ...pin, [i]: value })
                            setPinUsed(false)
                            if (i < (digits - 1) && value.length) {
                                pinRefs.current[i + 1].focus()
                            }
                        }}
                        onPaste={(event) => {
                            event.preventDefault()
                            pasteText(event.clipboardData.getData('Text'))
                        }}
                        onKeyDown={async (event: KeyboardEvent<HTMLInputElement>) => {
                            event.persist()
                            const target = event.target as HTMLInputElement
                            if (event.key === 'v' && event.ctrlKey) {
                                event.preventDefault()
                                try {
                                    const text = await navigator.clipboard.readText()
                                    pasteText(text)
                                } catch (e) {
                                }
                            }
                            if (!/([0-9]|(Backspace))/.test(event.key)) {
                                event.preventDefault()
                                return false
                            }
                            if (event.key === 'Backspace' && i >= 1 && !target.value) {
                                pinRefs.current[i - 1].focus()
                            }
                        }}
                        ref={(ref: HTMLInputElement) => pinRefs.current.length < digits && pinRefs.current.push(ref)}
                        maxLength={1}
                        type="text"
                        className="w-8 h-10 rounded p-0 text-center focus:ring-brand-500 focus:border-brand-500 disabled:bg-gray-100"
                        required
                        autoComplete="new-password"
                    />
                ))}
            </div>
            {help && <div className={helpClasses}>{help}</div>}
            {onResend &&
                <div className={clsx('text-center')}>
                    <button
                        disabled={pending}
                        type="button"
                        className="text-sm text-brand-600"
                        onClick={() => {
                            onResend()
                        }}>
                        {t('resend')}
                    </button>
                </div>
            }
        </div>
    )
}

export default Pin