import { Epic } from 'redux-observable'
import { ActionTypes, State } from '../../configureStore'
import { from, Observable, of } from 'rxjs'
import { catchError, filter, mergeMap } from 'rxjs/operators'
import { notify } from '../../notification/actions'
import { AmplifySession } from '../../panels/epics'
import { purchaseGatewayLicense, purchaseGatewayLicenseFailed, gatewayLicensePurchased } from './actions'
import { AjaxResponse } from 'rxjs/ajax'
import { isActionOf } from 'typesafe-actions'
import { getBillingId } from '../../profile/selectors'

const STORE_URI = '/store/invoices'

interface CreateInvoiceRequest {
    billingId: string
    invoiceDescription: string,
    taxStampDescription: string,
    items: Array<{
        productId: string
        quantity: number
        description: string
        metadata: {
            panelSerialNumber: string
            peripheralType: string
            peripheralMacAddress: string
            subType: string
        }
    }>
}

// interface Invoice {
//     url: string
// }

export const buyGatewayLicense$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post }) =>
    action$.pipe(
        filter(isActionOf(purchaseGatewayLicense)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                        const body: CreateInvoiceRequest = {
                            billingId: getBillingId(state$.value) || '',
                            invoiceDescription: action.payload.invoiceDescription,
                            taxStampDescription: action.payload.taxStampDescription,
                            items: [{
                                productId: action.payload.productId,
                                quantity: 1,
                                description: action.payload.description,
                                metadata: { ...action.payload.addictionalData }
                            }]
                        }
                        const headers = {
                            authorization: `Bearer ${res.idToken.jwtToken}`,
                            // eslint-disable-next-line
                            ['Content-Type']: 'application/json'
                        }
                        return post(STORE_URI, body, headers)
                            .pipe(
                                mergeMap(response => of(gatewayLicensePurchased(response.response.url), notify('info', 'purchaseGatewayLicenseSuccess'))),
                                catchError(error => of(purchaseGatewayLicenseFailed(), notify('error', 'purchaseGatewayLicenseFailed', 3000, error.status)))
                            )
                    }))
        }))