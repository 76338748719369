import { useState, useEffect } from 'react'
import { Company } from '../../profile/selectors'
import { useQuery } from 'react-query'
import { COMPANIES_PATH } from '../utils'
import { RequestParams, useRequest } from '../request'

const fetchOptions = {
    refetchOnWindowFocus: false
}

export const useExistingCompany = (params: { vatNumber?: string } = {}) => {

    const { vatNumber } = params

    const [existingCompany, setExistingCompany] = useState<{ businessName: string, _id: string, vatNumber: string } | null>(null)

    useEffect(() => {
        setExistingCompany(null)
    }, [vatNumber])

    const existingCompanyRequestParams: RequestParams = {
        path: COMPANIES_PATH,
        query: { vatNumber: vatNumber as string }
    }

    const { request: getExistingCompany } = useRequest<Company[]>(existingCompanyRequestParams)

    useQuery(
        [existingCompanyRequestParams],
        getExistingCompany,
        {
            enabled: !!vatNumber,
            onSuccess: (data) => {
                if (data && data.body.length) {
                    setExistingCompany(data.body[0])
                }
            },
            ...fetchOptions
        }
    )

    return {
        existingCompany
    }
}