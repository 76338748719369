import React, { VFC } from 'react'
import { privacy } from '../registration/privacy'
import { conditions } from '../registration/conditions'
import { useTranslation } from 'react-i18next'

type Props = {
    type: 'privacy' | 'terms-of-use'
    locale: 'en' | 'it'
}

const Text: VFC<Props> = (props) => {
    const { t } = useTranslation()

    const { type, locale } = props

    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="mb-4">{type === 'privacy' ? t('privacyConditionsUppercase') : type === 'terms-of-use' ? t('registrationTermsOfUseUppercase') : null}</h1>
            <textarea
                disabled
                rows={30}
                name="text"
                id="text"
                className="resize-none shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md">
                {type === 'privacy' ? (privacy[locale].privacy) : type === 'terms-of-use' ? (conditions[locale].conditions) : null}
            </textarea>
        </div>
    )
}

export default Text