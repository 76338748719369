import React, { VFC } from 'react'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { Navigation } from './BaseMenuNav'
import { ExternalLinkIcon } from '@heroicons/react/outline'

interface Props extends Navigation {}

export const MenuNavButton: VFC<Props> = (props) => {
    const { name, onClick, navlink } = props
    const location = useLocation()

    const isCurrent = (itemPath?: string) => {
        return itemPath === location.pathname
    }

    return (
        <button
            key={name}
            onClick={() => onClick ? onClick() : undefined}
            className={clsx(
                isCurrent(navlink) ? 'bg-brand-600 text-white' : 'text-gray-100 hover:bg-gray-700 hover:text-white',
                'group w-full flex items-center px-2 py-2 text-sm font-medium rounded-md',
                `${!props.icon && 'px-12'}`
            )}
            type="button"
        >
            {props.icon && <props.icon
                className={clsx(
                    isCurrent(navlink) ? 'text-white' : 'text-gray-400',
                    'mr-3 flex-shrink-0 h-6 w-6'
                )}
                aria-hidden="true"
            />}
            <div className="first-letter:capitalize">{name}</div>
            {!!props.externalLink && <ExternalLinkIcon className={clsx(
                isCurrent(navlink) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                'ml-1 flex-shrink-0 h-4 w-4 mb-1'
            )} />}
        </button>
    )
}