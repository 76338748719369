import _ from 'lodash'
import React, { useEffect, useState, VFC } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '../common/Button'
import { Modal } from '../common/Modal'
import Pin from '../common/Pin'
import { Spinner } from '../common/Spinner'
import { useFormInput } from '../hooks/HandleChange'
import { useLoading } from '../hooks/loading'
import { notify } from '../notification/actions'
import { reloadProfile } from '../profile/actions'
import { getPrefixAndPhoneFromPhoneNumber, Phone } from '../registration/Registration'
import { WizardHeaderStepper } from './WizardHeaderStepper'
import { useAuth } from '../auth/amplify'

type Props = {
    show: boolean,
    pending?: boolean
    phone?: string
    onClose: () => void,
    onError?: (errorDescription: string) => void,
    onSuccess: () => void
}

type StepType = {
    id: 'phone' | 'code'
    title: string
    subtitle: string
    status: 'current' | 'complete' | 'upcoming'
}

const defaultStepsState: StepType[] = [
    {
        id: 'phone',
        title: 'step1',
        subtitle: 'mobileNumberVerificationStepNumberSubtitle',
        status: 'current'
    },
    {
        id: 'code',
        title: 'step2',
        subtitle: 'mobileNumberVerificationStepCodeSubtitle',
        status: 'upcoming'
    }
]

const MobileNumberVerification: VFC<Props> = (props: Props) => {
    const currentPhone = getPrefixAndPhoneFromPhoneNumber(props.phone)
    const phoneNumber = useFormInput(currentPhone?.phone, props.show)
    const phoneNumberPrefix = useFormInput(currentPhone?.prefix, props.show)
    const [pin, setPin] = useState('')
    const { loading, execute } = useLoading()
    const { t } = useTranslation()
    const [steps, setSteps] = useState<StepType[]>(defaultStepsState)
    const dispatch = useDispatch()

    useEffect(() => {
        setSteps(defaultStepsState)
    }, [props.show, props.phone])

    const currentStep = () => steps.find(s => s.status === 'current')?.id || steps[0].id
    const isFirstStep = () => steps.findIndex(s => s.id === currentStep()) === 0
    const isLastStep = () => steps.findIndex(s => s.id === currentStep()) === steps.length - 1
    const getBackOrClose = (): 'close' | 'back' => isFirstStep() ? 'close' : 'back'
    const getNextOrSend = (): 'next' | 'send' => isLastStep() ? 'send' : 'next'
    const { updatePhoneWithVerificationRequest, verifyUserAttributeSubmit } = useAuth()

    const previousStep = () => {
        const currentIndex = steps.findIndex(s => s.id === currentStep())
        const updatedSteps = _.cloneDeep(steps)
        updatedSteps[currentIndex].status = 'upcoming'
        updatedSteps[currentIndex - 1].status = 'current'
        setSteps(updatedSteps)
    }

    const nextStep = () => {
        const currentIndex = steps.findIndex(s => s.id === currentStep())
        const updatedSteps = _.cloneDeep(steps)
        updatedSteps[currentIndex].status = 'complete'
        updatedSteps[currentIndex + 1].status = 'current'
        setSteps(updatedSteps)
    }

    const onHandleBack = () => {
        if (isFirstStep()) {
            onClose()
        } else {
            previousStep()
        }
    }

    const onError = (error: string) => {
        dispatch(notify('error', error))
        props.onError && props.onError(error)
    }

    const onSuccess = () => {
        dispatch(notify('info', 'mobileNumberVerified'))
        dispatch(reloadProfile())
        props.onSuccess()
    }

    const onHandleNext = async () => {
        const newNumber = phoneNumberPrefix.value + phoneNumber.value
        try {
            if (isFirstStep()) {
                await execute(() => updatePhoneWithVerificationRequest({ newNumber, oldNumber: props.phone }))
                nextStep()
            } else {
                await execute(() => verifyUserAttributeSubmit(pin))
                onSuccess()
            }
        } catch (e) {
            onError(e.code)
        }
    }

    const { onClose, show, pending } = props

    return (
        <Modal open={show} onClose={onClose} size="2xl">
            <WizardHeaderStepper steps={steps} />
            <form className="mt-6 h-48 flex flex-col justify-between items-center sm:mt-12"
                action="#" method="POST" onSubmit={(e) => {
                    e.preventDefault()
                    onHandleNext()
                }}>
                {pending || loading ?

                    <Spinner containerClass='bg-transparent !static' /> :
                    <>
                        {currentStep() === 'phone' ? <div className="max-w-xs flex flex-col">
                            <Phone
                                label={t('mobilePhone')}
                                placeholder={t('phonePlaceholder')}
                                country={phoneNumberPrefix}
                                phone={phoneNumber}
                            />
                        </div> : null}
                        {currentStep() === 'code' ? <>
                            <Pin
                                key={'pinId'}
                                onFilled={setPin}
                                help={t('codeViaSmsVerificationHelp')}
                                digits={6}
                            />
                        </> : null}
                    </>
                }
                <div className="flex w-full justify-between">
                    <Button type="button" text={t(getBackOrClose())} color="white" size="lg"
                        disabled={pending || loading} onClick={onHandleBack} />
                    <Button type="submit" text={t(getNextOrSend())} color="primary" size="lg"
                        pending={pending || loading} />
                </div>
            </form>
        </Modal>
    )
}

export default MobileNumberVerification