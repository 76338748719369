import React from 'react'
import { useTranslation } from 'react-i18next'
import { Avatar as AvatarType } from './actions'
import { InformationCircleIcon } from '@heroicons/react/solid'
import { Avatar } from '../common/Avatar'
import { Link } from 'react-router-dom'

interface Props {
    avatarUrl?: string
    couldChange: boolean
    allowedUser: boolean
    onSubmit: (file: AvatarType) => void
}

function NotAllowedAlert() {
    const { t } = useTranslation()

    return (
        <div className="flex rounded-md bg-blue-50 p-4">
            <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div className="text-sm text-blue-700 flex-1 md:flex md:justify-between ml-3">
                <p>{t('companyLogoHelpText')}</p>
                <p className="mt-3 text-sm md:mt-0 md:ml-6">
                    <Link to="/training" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">{t('details')}<span aria-hidden="true">&rarr;</span></Link>
                </p>
            </div>
        </div>
    )
}

export function CompanyLogo(props: Props) {
    const { avatarUrl, onSubmit, couldChange, allowedUser } = props

    let avatarSrc = avatarUrl

    const { t } = useTranslation()
    const handleOnChangeAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files
        if (files && files?.length > 0) {
            const reader = new FileReader()
            reader.onload = (event) => {
                if (event && event.target) {
                    return onSubmit({
                        type: files[0].type,
                        content: event.target.result as Buffer
                    })
                }
            }
            reader.readAsArrayBuffer(files[0])
        }
    }

    return (
        <div className="md:grid md:grid-cols-4 md:gap-6">
            <div className="md:col-span-1">
                <div className="px-1 md:px-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('logo')}</h3>
                    <p className="mt-1 text-sm text-gray-600">{t('profileHelpTitle')}</p>
                </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-3">
                <form noValidate autoComplete="false">
                    <div className="shadow overflow-hidden sm:rounded-md">
                        <div className="px-4 py-5 bg-white sm:p-6">
                            {couldChange ?
                                (
                                    <div>
                                        <div className="flex items-center">
                                            <Avatar
                                                size={14}
                                                type="circular"
                                                src={avatarSrc}
                                                otherClasses="bg-gray-100"
                                            />
                                            {allowedUser ? <>
                                                <input
                                                    id="logo-input"
                                                    className="opacity-0 overflow-hidden absolute z-[-1] w-[0.1px] h-[0.1px]"
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={handleOnChangeAvatar}
                                                />
                                                <label htmlFor="logo-input" className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500 cursor-pointer">{t('change')}</label>
                                            </> : null}
                                        </div>
                                        {allowedUser ? <p className="border-t mt-2 pt-2 text-xs text-gray-400">{t('uploadProfileImageHelp')}</p> : null}
                                    </div>
                                ) : (
                                    <NotAllowedAlert />
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
