import { useCallback, useState } from "react"
import { useIsFetching, useMutation, useQuery, useQueryClient } from "react-query"
import { getMsalClientId } from "../../config"
import { useBrowserLanguage } from "../../hooks/language"
import { generateError } from "../../hooks/utils"
import { ContextType, useAuth } from "../../auth/msal"

type DocumentType = 'TermsOfUse' | 'PrivacyPolicy'

type ActionRequired = 'update' | 'consent'

export type Consent = {
    type: DocumentType
    url: string //html extension url
    version: string
    date?: string //empty if end user never consented to this document
    actionRequired?: ActionRequired //present if first or new consent required
}

interface TermAndConditionsHook {
    loading: boolean
    consents: Consent[]
    consent: () => Promise<void>
}

const fetchOptions = {
    refetchOnWindowFocus: false,
    retry: false
}

type AllConsentsResponseItem = {
    document: DocumentType,
    html: string
    date?: string
    version?: string
    action: {
        type: ActionRequired | 'none'
        version: string
        html: string
    }
}

type AllConsentsResponse = AllConsentsResponseItem[]

const buildHeaders = (accessToken: string) => ({
    Authorization: `Bearer ${accessToken}`
})

const addLanguageQuery = (url: string, language?: string) => {
    if (language) {
        return url + `&language=${language.substring(0, 2)}`
    }
    return url
}

export const GET_CONSENT_PATH = 'eliot/termsandconditions/allconsents'
export const STORE_CONSENT_PATH = 'eliot/termsandconditions/consent'

const fetchAllConsents = async (accessToken: string, language?: string) => {
    let url = `${GET_CONSENT_PATH}?appId=${getMsalClientId()}`
    url = addLanguageQuery(url, language)
    return fetch(url, {
        method: 'GET',
        headers: buildHeaders(accessToken)
    }).then(response => {
        if (!response.ok) {
            generateError(response)
        }
        return Promise.resolve(response.json() as Promise<AllConsentsResponse>)
    })
}

type StoreConsentType = 'ToU' | 'Privacy_Policy'

const toUpdateTypeMap = {
    'TermsOfUse': 'ToU',
    'PrivacyPolicy': 'Privacy_Policy'
}

const storeConsent = (accessToken: string, language?: string) => async (type: StoreConsentType) => {
    let url = `${STORE_CONSENT_PATH}?appId=${getMsalClientId()}&documentType=${type}`
    url = addLanguageQuery(url, language)
    return fetch(url, {
        method: 'POST',
        headers: buildHeaders(accessToken)
    }).then(response => {
        if (!response.ok) {
            generateError(response)
        }
        return Promise.resolve()
    })
}

const mapConsent = (response: AllConsentsResponseItem): Consent => {
    let mapped: Consent = {
        type: response.document,
        url: response.html,
        version: response.version || '',
        date: response.date
    }
    if (response.action.type !== 'none') {
        mapped.actionRequired = response.action.type
        mapped.version = response.action.version
        mapped.url = response.action.html
    }
    return mapped
}

export const useTermsAndConditions = (): TermAndConditionsHook => {

    const queryClient = useQueryClient()
    const auth = useAuth() as ContextType
    const language = useBrowserLanguage()
    const [consents, setConsents] = useState<Consent[]>([])

    const fetchAllConsentsFn = useCallback(async () => {
        return auth.getAccessToken()
            .then(accessToken => fetchAllConsents(accessToken as string, language))
    }, [auth, language])

    const storeConsentFn = useCallback(async (type: DocumentType) => {
        return auth.getAccessToken()
            .then(accessToken => storeConsent(accessToken as string, language)(toUpdateTypeMap[type] as StoreConsentType))
    }, [auth, language])

    const storeConsentMutation = useMutation(storeConsentFn, {
        onSettled: () => {
            queryClient.invalidateQueries('terms-and-conditions-all')
        }
    })

    const consent = useCallback(async () => {
        const mutations: Promise<any>[] = []
        consents.forEach(c => {
            if (c.actionRequired) {
                mutations.concat(storeConsentMutation.mutateAsync(c.type).catch(() => { }))
            }
        })
        return Promise.all(consents).then(() => { })
    }, [consents, storeConsentMutation])

    useQuery(
        ['terms-and-conditions-all'],
        fetchAllConsentsFn,
        {
            enabled: !!language,
            onSuccess: (data) => {
                setConsents(data.map(mapConsent))
            },
            ...fetchOptions
        }
    )

    const fetching = useIsFetching(['terms-and-conditions-all'])

    return {
        loading: fetching > 0 || storeConsentMutation.isLoading,
        consents,
        consent
    }
}