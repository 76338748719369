import { createSelectorCreator, defaultMemoize } from 'reselect'
import { State } from '../configureStore'
import moment from 'moment'
import { isEqual } from 'lodash'
import { BillingState } from './reducer'
import { BillingForm } from './types'
import { getBillingId, getUserCompanyProfile } from '../profile/selectors'

const createSelector = createSelectorCreator(
    defaultMemoize,
    isEqual
)

export interface UserMetadata {
    id?: string
    username?: string
    cuu?: string
    pec?: string
    companyId?: string
}

export interface StripeProfile {
    description: string
    email: string
    phone: string
    name: string
    address: {
        city: string
        country: string
        line1: string
        postal_code: string
        state: string
    }
    tax_ids?: {
        data: Array<{
            value: string
        }>
    },
    metadata?: UserMetadata
}

const getBilling = (state: State) => state.billing
const getStripeProfile = (state: State) => state.billing.profile
const getInvoices = (state: State) => state.billing.invoices || []

export const getOrdersHistory = createSelector(
    getInvoices, (invoices: Array<any>) => invoices.map(i => ({
        id: i.id,
        date: moment.unix(i.created).format('DD-MM-YYYY'),
        description: i.lines.data.map((d: { description: string }) => d.description).join(', ') || '',
        invoiceUrl: i.hosted_invoice_url,
        total: `${parseInt(i.total, 10) / 100} €`,
        paid: i.paid,
        status: i.status
    }))
)

const getRegistrationProfileForm = createSelector(
    getUserCompanyProfile,
    (profile): BillingForm => {
        return {
            email: profile?.company?.email || '',
            phone: profile?.company?.phoneNumber || '',
            address: profile?.company?.address || '',
            zipCode: profile?.company?.zipCode || '',
            province: profile?.company?.province || '',
            city: profile?.company?.city || '',
        }
    })

const getStripeProfileForm = createSelector(
    getStripeProfile,
    (profile): BillingForm => {
        return {
            email: profile?.email || '',
            phone: profile?.phone || '',
            city: profile?.address.city || '',
            address: profile?.address.line1 || '',
            zipCode: profile?.address.postal_code || '',
            province: profile?.address.state || '',
        }
    })

export const getBillingProfileForm = createSelector(
    getBillingId, getStripeProfileForm, getRegistrationProfileForm,
    (billingId, stripe, prefilled): BillingForm => {
        if (billingId)
            return stripe 
        return prefilled
    })

export const isPurchasing = createSelector(
    getBilling,
    (billing: BillingState) => !!billing.purchasing
)

export const isLoadingBillingProfile = createSelector(
    getBilling,
    (billing: BillingState) => !!billing.loadingProfile
)

export const isLoadingBillingInvoices = createSelector(
    getBilling,
    (billing: BillingState) => !!billing.loadingInvoices
)

export const isLoadingBillingPrices = createSelector(
    getBilling,
    (billing: BillingState) => !!billing.loadingPrices
)