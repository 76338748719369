import React, { FC } from 'react';
import clsx from 'clsx';

type Props = {
    full?: boolean;
    otherClasses?: string;
}

const CardBody: FC<Props> = (props) => {
    const {children, full, otherClasses} = props
    const classes = clsx(!full && 'px-4 py-5 sm:p-6', otherClasses)
    return (
        <div className={classes}>
            {children}
        </div>
    )

}

export default CardBody