import { useCallback, useState } from 'react'


export const useLoading = (): {
    loading: boolean
    execute: <T = void>(fn: () => Promise<T>) => Promise<void>
} => {
    const [loading, setLoading] = useState(false)
    const execute = useCallback(
        async (fn) => {
            setLoading(true)
            try {
                await fn()
                setLoading(false)
            } catch (e) {
                setLoading(false)
                throw e
            }
        },
        []
    )
    return {
        loading,
        execute
    }
}