
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Disclosure } from '@headlessui/react'
import { AcademicCapIcon, ChevronUpIcon, GlobeIcon } from '@heroicons/react/outline'
import { FetchTalentLmsLoginLink } from '../../hooks/talentLms'
import { getBrowserLanguage } from '../utils'
import { notify } from '../../notification/actions'
import { useDispatch, useSelector } from 'react-redux'
import { companyStatus } from '../../profile/selectors'
import { generateAmplifyAnalyticsTags } from '../../auth/amplify'

const MenuKseniaWorld: React.VFC = () => {
    const { t } = useTranslation()

    const { fetch } = FetchTalentLmsLoginLink()
    const dispatch = useDispatch()
    const isCompanyVerified = useSelector(companyStatus) === 'verified'
    const handleClickTalentLms = async (e: any) => {
        e.preventDefault()
        try {
            const result = await fetch({
                language: getBrowserLanguage()
            })
            //@ts-ignore
            window.open(result.loginKey, "_blank")
        } catch (error: any) {
            dispatch(notify('error', 'fetchTalentLmsLoginKeyError', 5000))
        }
    }

    return (
        <Disclosure defaultOpen>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex justify-between w-full py-3 pb-0 px-2">
                        <span className="uppercase text-gray-500 text-xs">{t('kseniaWorld')}</span>
                        <ChevronUpIcon
                            className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-brand-300`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-0 pt-4 pb-2 text-sm text-gray-100">
                        <ul className="space-y-2">
                            {isCompanyVerified &&
                                <li>
                                    <a
                                        className="w-full flex items-center pl-2 pr-1 py-2 text-left text-sm border-l-[6px] border-yellow-600 hover:bg-yellow-600"
                                        target="_blank" rel="noreferrer" onClick={handleClickTalentLms}
                                        href="/#/panels"
                                        {...generateAmplifyAnalyticsTags("action:navigate,location:talent")}
                                    >
                                        <AcademicCapIcon className='mr-3 flex-shrink-0 h-6 w-6' />
                                        <span className="flex-1">{t('talentLMS')}</span>
                                    </a>
                                </li>
                            }
                            <li>
                                <a
                                    className="w-full flex items-center pl-2 pr-1 py-2 text-left text-sm border-l-[6px] border-[#DA291C] hover:bg-[#DA291C]"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.kseniasecurity.com/"
                                    {...generateAmplifyAnalyticsTags("action:navigate,location:ksenia_b2c")}
                                >
                                    <svg className='mr-3 flex-shrink-0 h-6 w-6' fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.265 13.09c-.364-.26-1.453-.8-3.25-1.621-1.154-.542-1.732-1.125-1.732-1.751 0-1.104.77-1.643 2.31-1.643.749 0 1.753.215 3.015.626l.813-2.204C19.945 6 18.64 5.74 17.508 5.74c-1.39 0-2.567.324-3.507.95-1.07.735-1.603 1.773-1.603 3.134 0 1.427.577 2.573 1.732 3.416.15.109 1.24.648 3.228 1.623 1.155.561 1.733 1.232 1.733 1.988 0 .626-.3 1.103-.877 1.428-.47.26-1.047.388-1.732.388-.14 0-.285-.006-.432-.016L8.528 9.846a2.027 2.027 0 0 0-.898-.628L14.194 2h-3.507L4.5 8.98 14.518 21h1.964c1.476 0 2.716-.368 3.764-1.08 1.177-.8 1.754-1.925 1.754-3.33-.002-1.448-.58-2.615-1.735-3.5Z" fill="currentColor" /><path d="M4.5 2H2v15.833h2.5V2Z" fill="currentColor" /></svg>
                                    <span className="flex-1">{t('kseniaHome')}</span>
                                </a>
                            </li>
                            {isCompanyVerified &&
                                <li>
                                    <a
                                        className="w-full flex items-center pl-2 pr-1 py-2 text-left text-sm border-l-[6px] border-purple-500 hover:bg-purple-500"
                                        target="_blank"
                                        rel="noreferrer"
                                        href="http://www.kseniadns.com/"
                                        {...generateAmplifyAnalyticsTags("action:navigate,location:ksenia_dns")}
                                    >
                                        <GlobeIcon className='mr-3 flex-shrink-0 h-6 w-6' />
                                        <span className="flex-1">{t('kseniaDNS')}</span>
                                    </a>
                                </li>
                            }
                        </ul>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default MenuKseniaWorld