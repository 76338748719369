import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CompanyCreationBanner } from './CompanyCreationBanner'
import { VideoLink } from './VideoLink'
import { State } from '../configureStore'
import { connect, ConnectedProps } from 'react-redux'
import {
    companyStatus, companyType,
    getUserPhoneNumber,
    isLoadingUserCompanyProfile
} from '../profile/selectors'
import { Spinner } from '../common/Spinner'
import MobileNumberVerification from './MobileNumberVerification'
import { Features } from './Features'
import { useHistory } from "react-router-dom"

type PropsFromRedux = ConnectedProps<typeof connector>

type DashboardProps = PropsFromRedux & {}

export function Dashboard(props: DashboardProps) {
    const { i18n, t } = useTranslation()
    const history = useHistory()

    const [mobilePhoneVerificationShow, setMobilePhoneVerificationShow] = useState(false)
    const { companyStatus, companyType, pending, phoneNumber } = props

    const i18nVideoUrl = (i18n.language === 'it')
        ? 'https://www.youtube.com/watch?v=re7-Xr1J0SU'
        : 'https://www.youtube.com/watch?v=QT3Y9rU74KM'

    return (<div className="page-container">
        {pending ?
            <Spinner />
            :
            <>
                <CompanyCreationBanner
                    companyType={companyType}
                    companyStatus={companyStatus}
                    onCreateCompany={() => {
                        history.push('/createCompany')
                    }}
                />
                <VideoLink
                    imageSrc={require('../images/visitor-dashboard/video-background.jpg').default}
                    imageAlt='dashboard image'
                    buttonTitle={t('watchVideo')}
                    buttonLink={i18nVideoUrl}
                    title={t('dashboardTitle')}
                    subtitle={t('dashaboardSubTitle')}
                />
                <Features />
                <MobileNumberVerification
                    show={mobilePhoneVerificationShow}
                    phone={phoneNumber}
                    onSuccess={() => {
                        setMobilePhoneVerificationShow(false)
                    }}
                    onClose={() => {
                        setMobilePhoneVerificationShow(false)
                    }}
                />
            </>
        }
    </div>)
}

export function mapStateToProps(state: State) {
    return {
        companyStatus: companyStatus(state),
        companyType: companyType(state),
        pending: isLoadingUserCompanyProfile(state),
        phoneNumber: getUserPhoneNumber(state)
    }
}

const connector = connect(mapStateToProps)

export default connector(Dashboard)
