import { Epic, combineEpics } from 'redux-observable'
import { ActionTypes, State } from '../configureStore'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from, Observable, of } from 'rxjs'
import { notify } from '../notification/actions'
import { AmplifySession } from '../panels/epics'
import { loadLoyaltyProgram, loadLoyaltyProgramCompleted, loadLoyaltyProgramFailed, Ranking } from './actions'

const LOYALTYPROGRAM_URI = 'users/loyaltyprogram'
export const LOYALTYPROGRAM_YEAR = '2024'

export const loadLoyaltyProgram$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<Ranking>
    }> = (action$, state$, { currentSession, fetchJson }) =>
        action$.pipe(
            filter(isActionOf(loadLoyaltyProgram)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res =>
                            fetchJson(`${LOYALTYPROGRAM_URI}/${LOYALTYPROGRAM_YEAR}`, { authorization: `Bearer ${res.idToken.jwtToken}` })
                                .pipe(
                                    map(response => loadLoyaltyProgramCompleted(response)),
                                    catchError(error => of(loadLoyaltyProgramFailed(error.status.toString()),
                                        notify('error', 'loyaltyProgramLoadError', 3000, error.status)))
                                )
                        ))
            }))

export default combineEpics(loadLoyaltyProgram$)