import React, { forwardRef } from 'react'
import clsx from 'clsx'

type RadioOptionPropsType = {
    id: string,
    label: string
    description?: string,
    default?: boolean
}

interface RadioPropsType extends React.ComponentPropsWithRef<'input'> {
    label?: string,
    help?: string,
    options: RadioOptionPropsType[]
    horizontal?: boolean
    containerClass?: string
    show?: boolean
}

const Radio = forwardRef<HTMLInputElement, RadioPropsType>((props, ref) => {
    const {
        label,
        help,
        options = [],
        horizontal = false,
        containerClass = '',
        show = true,
        ...inputProps
    } = props
    const listClasses = clsx('space-y-4', horizontal && 'sm:flex sm:items-center sm:space-y-0 sm:space-x-10')
    return show ? (
        <div className={containerClass}>
            {label && (
                <label className="block text-sm font-medium text-gray-700">{label}</label>
            )}
            {help && (
                <p className="text-sm text-gray-500">{help}</p>
            )}
            <fieldset className="mt-4">
                <div className={listClasses}>
                    {options.map((option) => (
                        <div key={option.id} className="flex items-center">
                            <input
                                id={'option' + option.id}
                                type="radio"
                                defaultChecked={option.id === inputProps.defaultValue}
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 disabled:opacity-30 disabled:cursor-not-allowed"
                                ref={ref}
                                value={option.id}
                                {...inputProps}
                            />
                            <label htmlFor={option.id} className="ml-3 block text-sm font-medium text-gray-700">
                                {option.label}
                            </label>
                        </div>
                    ))}
                </div>
            </fieldset>
        </div>
    ) : null
})

export default Radio
