import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from "react-router-dom"
import { Button } from "../common/Button"

interface ElevenListOverlayProps {
    show?: boolean
    action?: boolean
}

const ElevenListOverlay = (props: ElevenListOverlayProps) => {
    const { show, action } = props
    const { t } = useTranslation()

    if (!show) {
        return null
    }

    return (
        <div className='flex flex-col justify-center items-center absolute inset-0 z-50 bg-white bg-opacity-90 fx-glass'>
            <h4 className="text-center mb-2 text-gray-700">{t('panelsVisitorsNeedCompanyInfoTitle')}</h4>
            {action ? <>
                <p className="mb-8 text-gray-500">{t('panelsVisitorsNeedCompanyInfoSubtitle')}</p>
                <Button as={Link} text={t('panelsVisitorsNeedCompanyAction')} size="xl" color="primary" to='/createCompany' />
            </> : null}
        </div>
    )
}

export default ElevenListOverlay