import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchIcon, EmojiSadIcon, XIcon } from '@heroicons/react/outline'
import Fuse from 'fuse.js'
import Card from '../common/Card/Card'
import Input from './Input/Input'
import { NoItem } from './NoItem'

interface ListSearchableProps<T> {
    data: T[]
    keys: Array<Fuse.FuseOptionKey>
    render: (data: T) => React.ReactNode,
    options?: Fuse.IFuseOptions<T>
    placeholder?: string,
}

const ListSearchable = <T extends {}>(props: ListSearchableProps<T>) => {
    const { data, render, placeholder, keys } = props
    const [term, setTerm] = useState('')
    const { t } = useTranslation()
    const isSearching = term !== ''

    const fuse = useMemo(() => {
        const options = {
            threshold: 0.2,
            minMatchCharLength: 2,
            keys
        }
        return new Fuse(data, options)
    }, [data, keys])
    
    const results = React.useMemo(() => {
        return fuse.search(term)
    }, [fuse, term])

    const items = isSearching ? results.map(i => i.item) : data

    const resetHandler = () => setTerm('')

    return (
        <Card>
            <Card.Header full>
                <div className="relative">
                    <Input
                        inputClasses="border-0 shadow-none focus:ring-2 focus:ring-inset focus:ring-gray-100 py-5 pr-16 sm:pl-16 rounded-b-none md:text-base"
                        type="text"
                        name="downloads-search"
                        id="downloads-search"
                        placeholder={placeholder || t('typeSomethingToSearch')}
                        value={term}
                        onChange={ev => setTerm(ev.target.value)}
                        icon={<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                        autoComplete="off"
                        iconClass="pl-4 sm:pl-6"
                    />
                    <button className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-300 hover:text-gray-500" type="button" onClick={resetHandler}><XIcon className='w-6' /></button>
                </div>
            </Card.Header>
            <Card.Body full otherClasses="divide-y divide-gray-100">
                {(isSearching && results.length === 0) ? <NoItem icon={EmojiSadIcon} title={t('listSearchableNoItemTitle')} additionalClasses="m-4" /> : items.map((item) => render(item))}
            </Card.Body>
        </Card>
    )
}

export default ListSearchable