import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { paramsToObject } from '../../common/utils';

export const useAdminPanelForm = () => {
    const [serialNumber, setSerialNumber] = useState('')
    const { search } = useLocation();

    useEffect(() => {
        const searchParams = new URLSearchParams(search);
        const searchParamsObj = paramsToObject(searchParams)
        if (searchParamsObj.hasOwnProperty('serialNumber')) {
            setSerialNumber(searchParamsObj.serialNumber)
        }
    }, [search])

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSerialNumber(e.currentTarget.value)
    }

    const onSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        const target = e.target as typeof e.target & {
            serialNumber: { value: string };
        };
        setSerialNumber(target.serialNumber.value)
    }

    return {
        serialNumber,
        onChange,
        onSubmit
    } as const
}