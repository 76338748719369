import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { getBrowserLanguage, getFindAnInstallerUrl } from '../common/utils'
import LevelFeatures, { LevelFeature } from './LevelFeatures'
import CeralaccaIconTitleSubtitle from './CeralaccaIconTitleSubtitle'
import LevelFeaturesCard from './LevelFeaturesCard'
import { FetchTalentLmsLoginLink } from '../hooks/talentLms'
import { notify } from '../notification/actions'
import { connect, useDispatch } from 'react-redux'
import { companyStatus } from '../profile/selectors'
import { State } from '../configureStore'

export function Training(props: {
    verifiedCompany?: boolean
}) {
    const { t } = useTranslation()
    const history = useHistory()

    const { fetch } = FetchTalentLmsLoginLink()
    const dispatch = useDispatch()

    const handleClickTalentLms = async () => {
        try {
            const result = await fetch({
                language: getBrowserLanguage()
            })
            //@ts-ignore
            window.open(result.loginKey, "_blank")
        } catch (error: any) {
            dispatch(notify('error', 'fetchTalentLmsLoginKeyError', 5000))
        }
    }

    const generalFeatures: LevelFeature[] = [
        {
            name: t('presenceOnSearchInstallers'),
            description: t('presenceOnSearchInstallersText'),
            buttonTitle: t('moreInfo'),
            buttonOnClick: () => window.open(getFindAnInstallerUrl(), "_blank"),
            buttonColor: 'white',
        },
        {
            name: t('freeCourses'),
            description: t('freeCoursesText'),
            ...(props.verifiedCompany ? {
                buttonTitle: t('goToTalentLMS'),
                buttonOnClick: handleClickTalentLms,
                buttonColor: 'primary'
            } : {})
        },
        {
            name: t('participationToThePrizeDraw'),
            description: t('participationToThePrizeDrawText'),
            buttonTitle: t('moreInfo'),
            buttonOnClick: () => history.push("loyalty/leaderboards"),
            buttonColor: 'white',
        }
    ]

    const levelSelectedFeatures: LevelFeature[] = [
        {
            name: t('howToGetIt'),
            items: [t('levelSelectedHowGet1'), t('levelSelectedHowGet2')]
        },
        {
            name: t('presenceOnSearchInstallers'),
            items: [t('levelSelectedSearch1'), t('levelSelectedSearch2')]
        },
        {
            name: t('howToKeepIt'),
            items: [t('levelSelectedHowKeep1')]
        }
    ]

    const levelSilverFeatures: LevelFeature[] = [
        {
            name: t('howToGetIt'),
            items: [t('levelSilverHowGet1'), t('levelSilverHowGet2'), t('levelSilverHowGet3')]
        },
        {
            name: t('presenceOnSearchInstallers'),
            items: [t('levelSilverSearch1'), t('levelSilverSearch2')]
        },
        {
            name: t('howToKeepIt'),
            items: [t('levelSilverKeep1')]
        }
    ]

    const levelGoldFeatures: LevelFeature[] = [
        {
            name: t('howToGetIt'),
            items: [t('levelGoldHowGet1'), t('levelGoldHowGet2'), t('levelGoldHowGet3')]
        },
        {
            name: t('presenceOnSearchInstallers'),
            items: [t('levelGoldSearch1'), t('levelGoldSearch2')],
        },
        {
            name: t('howToKeepIt'),
            items: [t('levelGoldKeep1')]
        }
    ]

    const levelSpecialistFeatures: LevelFeature[] = [
        {
            name: t('howToGetIt'),
            items: [t('levelSpecialistHowGet1'), t('levelSpecialistHowGet2'), t('levelSpecialistHowGet3'), t('levelSpecialistHowGet4')]
        },
        {
            name: t('presenceOnSearchInstallers'),
            items: [t('levelSpecialistSearch1'), t('levelSpecialistSearch2')]
        },
        {
            name: t('howToKeepIt'),
            items: [t('levelSpecialistKeep1')]
        }
    ]

    return <>
        <div className='page-container'>
            <div className='text-center mx-8'>
                <span className='uppercase text-brand-500'>{t('kseniaTrainingFeaturesTitleDesc')}</span>
                <h3 className=''>{t('kseniaTrainingFeaturesTitle1')}<br />{t('kseniaTrainingFeaturesTitle2')}</h3>
            </div>
            <div className='grid grid-cols-2 sm:grid-cols-4 items-center justify-center gap-4 mt-8 max-w-3xl mx-auto'>
                <CeralaccaIconTitleSubtitle icon={require('../images/ceralacca_rosso.png').default} title={t('registrationInstaller')} subtitle={t('levelSelected')} />
                <CeralaccaIconTitleSubtitle icon={require('../images/ceralacca_argento.png').default} title={t('registrationInstaller')} subtitle={t('levelSilver')} />
                <CeralaccaIconTitleSubtitle icon={require('../images/ceralacca_gold.png').default} title={t('registrationInstaller')} subtitle={t('levelGold')} />
                <CeralaccaIconTitleSubtitle icon={require('../images/ceralacca_verde.png').default} title={t('registrationInstaller')} subtitle={t('levelSpecialist')} />
            </div>
            <div className='my-8'>
                <LevelFeatures features={generalFeatures} />
            </div>
            <LevelFeaturesCard title={`${t('level')} ${t('levelSelected')}`} img={require('../images/ceralacca_rosso.png').default} alt={`${t('level')} ${t('levelSelected')}`} features={levelSelectedFeatures} />
            <LevelFeaturesCard title={`${t('level')} ${t('levelSilver')}`} img={require('../images/ceralacca_argento.png').default} alt={`${t('level')} ${t('levelSilver')}`} features={levelSilverFeatures} />
            <LevelFeaturesCard title={`${t('level')} ${t('levelGold')}`} img={require('../images/ceralacca_gold.png').default} alt={`${t('level')} ${t('levelGold')}`} features={levelGoldFeatures} />
            <LevelFeaturesCard title={`${t('level')} ${t('levelSpecialist')}`} img={require('../images/ceralacca_verde.png').default} alt={`${t('level')} ${t('levelSpecialist')}`} features={levelSpecialistFeatures} />
        </div>
    </>
}

export function mapStateToProps(state: State) {
    return {
        verifiedCompany: companyStatus(state) === 'verified'
    }
}

export default connect(mapStateToProps)(Training)