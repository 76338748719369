import { ActionType, createAction } from "typesafe-actions"
import { reloadLicenses } from "./licenseActions"

export const OPEN_MODAL_VOICE = 'open_modal_voice'
export const CLOSE_MODAL_VOICE = 'close_modal_voice'
export const PURCHASE_VOICES = 'purchase_voices'
export const PURCHASE_VOICES_FAILED = 'purchase_voices_failed'
export const VOICES_PURCHASED = 'voices_purchased'

export const openModalVoice = createAction(OPEN_MODAL_VOICE)

export const closeModalVoice = createAction(CLOSE_MODAL_VOICE)

export const purchaseVoices = createAction(PURCHASE_VOICES, (action) => {
    return (voices: Array<string>, description: string, invoiceDescription: string, taxStampDescription: string) => action({ voices, description, invoiceDescription, taxStampDescription })
})

export const purchaseVoicesFailed = createAction(PURCHASE_VOICES_FAILED)

export const voicesPurchased = createAction(VOICES_PURCHASED, (action) => {
    return (url: string) => action({ url })
})

export type VoicesActions = ActionType<typeof openModalVoice | typeof closeModalVoice |
    typeof purchaseVoices | typeof voicesPurchased | typeof purchaseVoicesFailed | typeof reloadLicenses>