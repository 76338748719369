import { createReducer } from 'typesafe-actions'
import {
    createBillingProfile,
    createBillingProfileCompleted,
    createBillingProfileFailed,
    openBillingProfile,
    openInvoices,
    Price,
    readAllPrices,
    readBillingProfileFailed,
    readInvoicesFailed,
    reloadInvoices,
    saveBillingProfile,
    saveInvoices,
    savePrices,
    Tax,
    TaxStamp,
    updateBillingProfile,
    updateBillingProfileFailed
} from './actions'
import { closeModalVoice, purchaseVoices, purchaseVoicesFailed, voicesPurchased } from '../services/voicesActions'
import {
    closeModalGatewayLicense,
    gatewayLicensePurchased,
    purchaseGatewayLicense,
    purchaseGatewayLicenseFailed
} from '../services/gatewayLicenses/actions'
import { logout } from '../logout/actions'
import { StripeProfile } from './selectors'

export interface BillingState {
    tax: Tax
    taxStamp?: TaxStamp
    prices: Array<Price>
    purchasing?: boolean
    loadingProfile?: boolean
    loadingInvoices?: boolean
    loadingPrices?: boolean
    invoiceUrl?: string,
    profile?: StripeProfile,
    invoices?: Array<object>
}

export const billingDefaultState: BillingState = {
    tax: {
        id: '',
        percentage: ''
    },
    prices: []
}

export const billingReducer = createReducer(billingDefaultState)
    .handleAction(savePrices, (state, action) => ({
        ...state,
        loadingPrices: false,
        ...action.payload
    }))
    .handleAction(logout, (state, action) => billingDefaultState)
    .handleAction(readAllPrices, (state, action) => ({
        ...state,
        loadingPrices: true
    }))
    .handleAction(purchaseVoices, (state, action) => ({
        ...state,
        purchasing: true
    }))
    .handleAction(purchaseVoicesFailed, (state, action) => ({
        ...state,
        purchasing: false
    }))
    .handleAction(voicesPurchased, (state, action) => ({
        ...state,
        purchasing: false,
        invoiceUrl: action.payload.url
    }))
    .handleAction(closeModalVoice, (state, action) => {
        const { invoiceUrl, ...other } = state
        return { ...other }
    })
    .handleAction(closeModalGatewayLicense, (state, action) => {
        const { invoiceUrl, ...other } = state
        return { ...other }
    })
    .handleAction(purchaseGatewayLicense, (state, action) => ({
        ...state,
        purchasing: true
    }))
    .handleAction(purchaseGatewayLicenseFailed, (state, action) => ({
        ...state,
        purchasing: false
    }))
    .handleAction(gatewayLicensePurchased, (state, action) => ({
        ...state,
        purchasing: false,
        invoiceUrl: action.payload.url
    }))
    .handleAction(saveBillingProfile, (state, action) => {
        return {
            ...state,
            purchasing: false,
            loadingProfile: false,
            profile: { ...state.profile, ...action.payload.profile }
        }
    })
    .handleAction(saveInvoices, (state, action) => {
        return {
            ...state,
            loadingInvoices: false,
            invoices: action.payload.invoices
        }
    })
    .handleAction(openBillingProfile, (state, action) => {
        return {
            ...state,
            loadingProfile: true
        }
    })
    .handleAction(openInvoices, (state, action) => {
        return {
            ...state,
            loadingProfile: true,
            loadingInvoices: true
        }
    })
    .handleAction(reloadInvoices, (state, action) => {
        return {
            ...state,
            loadingInvoices: true
        }
    })
    .handleAction(readInvoicesFailed, (state, action) => {
        return {
            ...state,
            loadingInvoices: false
        }
    })
    .handleAction(readBillingProfileFailed, (state, action) => {
        return {
            ...state,
            loadingProfile: false
        }
    })
    .handleAction(updateBillingProfile, (state, action) => {
        return {
            ...state,
            loadingProfile: true
        }
    })
    .handleAction(updateBillingProfileFailed, (state, action) => {
        return {
            ...state,
            loadingProfile: false
        }
    })
    .handleAction(createBillingProfile, (state, action) => {
        return {
            ...state,
            loadingProfile: true
        }
    })
    .handleAction(createBillingProfileCompleted, (state, action) => {
        return {
            ...state,
            loadingProfile: false
        }
    })
    .handleAction(createBillingProfileFailed, (state, action) => {
        return {
            ...state,
            loadingProfile: false
        }
    })
