import React, { useEffect } from 'react'
import { State } from '../configureStore'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ExternalLinkIcon, ShoppingCartIcon } from '@heroicons/react/outline'
import Card from '../common/Card/Card'
import { getOrdersHistory, isLoadingBillingInvoices } from '../billing/selectors'
import { NoItem } from '../common/NoItem'
import clsx from 'clsx'
import { getBillingId, getUserCompanyProfile, isLoadingUserCompanyProfile, UserCompanyProfile } from './selectors'
import { openInvoices } from '../billing/actions'
import { ProfileHeader } from './ProfileHeader'

interface Order {
    id: string
    date: string
    invoiceUrl: string
    description: string
    total: string
    paid: boolean
    status: string
}

function OrderItem(order: Order) {
    const { t } = useTranslation()

    function getSpanClassName(status: string) {
        return clsx('inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
            status === 'paid' && 'bg-green-100 text-green-800',
            status === 'open' && 'bg-yellow-100 text-yellow-800',
            status === 'void' && 'bg-gray-100 text-gray-800',
            status !== 'paid' && status !== 'open' && status !== 'void' && 'bg-red-100 text-red-800'
        )
    }

    function getSvgClassName(status: string) {
        return clsx('-ml-0.5 mr-1.5 h-2 w-2',
            status === 'paid' && 'text-green-400',
            status === 'open' && 'text-yellow-400',
            status === 'void' && 'text-gray-400',
            status !== 'paid' && status !== 'open' && status !== 'void' && 'text-red-400'
        )
    }


    return <div className="px-4 py-2 flex flex-wrap sm:grid sm:grid-cols-4col2full sm:gap-4 items-center">
        <div className="text-center mb-2 sm:mb-0">
            <span
                className={getSpanClassName(order.status) + ' max-w-xs'}>
                <svg className={getSvgClassName(order.status)} fill="currentColor" viewBox="0 0 8 8">
                    <circle cx={4} cy={4} r={3} />
                </svg>
                {t(`invoiceStatus${order.status}`)}
            </span>

        </div>
        <div className="w-full sm:w-auto">
            <div className="flex flex-1 flex-col text-sm text-gray-900">
                <div className="overflow-ellipsis text-brand-500 capitalize">{order.description}</div>
                <div className="overflow-ellipsis subheader">{order.date}</div>
            </div>
        </div>
        <div>
            <p className="flex flex-1 text-xl text-gray-900">{order.total}</p>
        </div>
        <div className="ml-auto sm:ml-0">
            <p className="text-sm font-medium text-brand-500 pl-4 cursor-pointer">
                <a
                    href={order.invoiceUrl} target="_blank" rel="noopener noreferrer"
                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                >
                    <ExternalLinkIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                    {t('viewInvoice')}
                </a>
            </p>
        </div>
    </div>
}

export function CompanyOrders(props: {
    profile: UserCompanyProfile,
    certificationLevel?: number,
    orders: Order[]
    loading?: boolean
}) {
    const { profile } = props
    const { t } = useTranslation()

    const dispatch = useDispatch()
    const billingId = useSelector(getBillingId)
    useEffect(() => {
        if (billingId) {
            dispatch(openInvoices())
        }
    }, [billingId, dispatch])
    let headerSubtitle
    if (profile.name) {
        headerSubtitle = profile.name + ' '
    }
    if (profile.surname) {
        headerSubtitle += profile.surname
    }
    return (
        <>
            <ProfileHeader
                title={profile.company?.businessName}
                subtitle={headerSubtitle}
                certification={profile.company?.certificationLevel}
                certificationScaled={profile.company?.scaledCertificationLevel}
                score={profile.company?.loyaltyProgramScore}
                avatar={profile.company?.logoUrl}
            />
            <div className="md:mt-12">
                <div className="page-container">
                    <Card pending={props.loading}>
                        <Card.Header full>
                            <div className="py-2">
                                <div className="px-4 font-bold">
                                    {t('ordersTitle')}
                                </div>
                                <div className="px-4 subheader">
                                    {t('ordersSubtitle')}
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body full>
                            {props.orders.length > 0 ?
                                <div className="flex flex-col">
                                    <div className="divide-y divide-gray-100">
                                        {props.orders.map((order, index) => <OrderItem key={index} {...order} />
                                        )}
                                    </div>
                                </div> : <NoItem icon={ShoppingCartIcon} title={t('noOrdersTitle')} subTitle={t('noOrdersSubtitle')} additionalClasses="m-4" />}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    )
}

export function mapStateToProps(state: State) {
    return {
        profile: getUserCompanyProfile(state) as UserCompanyProfile,
        loading: isLoadingUserCompanyProfile(state) || isLoadingBillingInvoices(state),
        orders: getOrdersHistory(state)
    }
}

export default connect(mapStateToProps)(CompanyOrders)
