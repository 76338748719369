import { useState, useEffect } from 'react'
import { Company } from '../../profile/selectors'
import { useIsFetching,  useQuery } from 'react-query'
import { COMPANIES_PATH} from '../utils'
import { RequestParams, useRequest } from '../request'

const fetchOptions = {
    refetchOnWindowFocus: false
}

const getBusinessName = (businessName: string | undefined) => (businessName && businessName.length > 2) ? businessName : ''

export const useDistributors = (params: { businessName?: string, vatNumber?: string, country?: string, province?: string, sortBy?: string } = {}) => {

    const { businessName, province, country, sortBy } = params

    const [areaDistributors, setAreaDistributors] = useState<Company[]>([])


    useEffect(() => {
        setAreaDistributors([])
    }, [country, province, sortBy])

    const areaDistributorsRequestParams: RequestParams = {
        path: COMPANIES_PATH,
        query: {
            type: 'distributor',
            status: 'verified',
            businessName: getBusinessName(businessName),
            country,
            province,
            sortBy
        }
    }

    const { request: getAreaDistributors } = useRequest<Company[]>(areaDistributorsRequestParams)

    useQuery(
        [areaDistributorsRequestParams],
        getAreaDistributors,
        {
            enabled: !!country,
            onSuccess: (data) => {
                if (data && data?.body) setAreaDistributors(data.body)
            },
            ...fetchOptions
        }
    )

    const fetching = useIsFetching([areaDistributorsRequestParams])

    return {
        areaDistributors,
        loading: fetching > 0
    }
}

