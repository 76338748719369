import React from 'react'
import Card from '../common/Card/Card'
import LevelFeatures, { LevelFeature } from './LevelFeatures'

export default function LevelFeaturesCard(props: {
    title: string,
    img: any,
    alt: string,
    features: LevelFeature[]
}) {
    const { title, img, alt, features } = props

    return <Card className='mb-12'>
        <Card.Header full>
            <div className='flex justify-between items-center px-4 sm:px-6'>
                <h3 className="text-lg leading-6 font-medium text-gray-900 py-5">{title}</h3>
                {img ? <div className="flex-shrink-0">
                    <img className="w-12" src={img} alt={alt} />
                </div> : null}
            </div>
        </Card.Header>
        <Card.Body full>
            <LevelFeatures features={features} />
        </Card.Body>
    </Card>
}

