import React, { VFC } from 'react'
import { Modal } from '../Modal'
import { Dialog } from '@headlessui/react'
import DataMatrix from 'react-datamatrix-svg'

type Props = {
    title?: string,
    serialNumber: string
    show: boolean,
    onClose: () => void
}

const Datamatrix: VFC<Props> = (props) => {
    const { title, serialNumber, onClose, show } = props
    return <Modal open={show} onClose={onClose} size="lg">
        <div>
            <div className="px-12 py-5">
                <h2 className="text-gray-800 text-center text-3xl font-semibold">{title}</h2>
            </div>
            <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    {serialNumber}
                </Dialog.Title>
                <DataMatrix
                    msg={serialNumber}
                    dim={500}
                    pal={['#000', '#FFF']}
                />
            </div>
        </div>
    </Modal >
}

export default Datamatrix