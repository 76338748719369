import React from 'react'
import clsx from 'clsx'
import DescriptionListHeader from './DescriptionListHeader'
import DescriptionListItem from './DescriptionListItem'

type Sub = {
    Header: typeof DescriptionListHeader
    Item: typeof DescriptionListItem
}

type DescriptionListProps = {
    containerClass?: string
}

const DescriptionList: React.FC<DescriptionListProps> & Sub = (props) => {
    const { children, containerClass = '' } = props
    const containerClasses = clsx('divide-y divide-gray-100', containerClass)
    return (
        <dl className={containerClasses}>
            {children}          
        </dl>
    )
}

DescriptionList.Item = DescriptionListItem
DescriptionList.Header = DescriptionListHeader

export default DescriptionList