import React, { VFC } from 'react'
import clsx from 'clsx'

interface Props {
    size?: 16 | 8 | 4
    show?: boolean
    noOverlay?: boolean
    inverted?: boolean
    containerClass?: string
}

export const Spinner: VFC<Props> = (props) => {
    const { size = 16, show = true, inverted = false, containerClass = "", noOverlay = false } = props
    const classes = clsx(
        "animate-spin ease-linear rounded-full border",
        size === 16 && "h-16 w-16 border-t-4",
        size === 8 && "h-8 w-8 border-t-2",
        size === 4 && "h-4 w-4 border-t-2",
        !inverted && "border-gray-200 border-t-brand-500",
        inverted && "border-white border-opacity-50"
    )

    const containerClasses = noOverlay ? '' : clsx(
        "absolute -inset-0",
        "flex items-center justify-center",
        "bg-opacity-90 bg-white z-50",
        containerClass
    )

    return show ?
        <div className={containerClasses}>
            <div data-testid="spinner" className={classes} />
        </div> : null
}
