import React from 'react';

type DescriptionListItemProps = {
    title: string
    subtitle?: string,
}

const DescriptionListHeader: React.VFC<DescriptionListItemProps> = (props) => {
    const { title, subtitle } = props
    return (
        <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
            {subtitle && <p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>}
        </div>
    )
}

export default DescriptionListHeader