import React, { VFC } from 'react'
import { DownloadIcon } from "@heroicons/react/outline"
import { formatBytes, formatContentType } from '../common/utils'
import { Button } from '../common'

type FileListItemProps = {
    title: string
    bytes: number
    contentType?: string
    onClick?: () => void
}

const buttonClasses = "flex flex-row sm:items-center sm:justify-between px-6 py-3 gap-y-2 sm:gap-y-0 sm:gap-x-4 hover:bg-gray-50 cursor-pointer w-full border-0 focus:ring-0 focus:ring-opacity-0 focus:ring-offset-0"

const FileListItem: VFC<FileListItemProps> = ({ title, bytes, contentType, onClick }) => {
    return (
        <Button as="button"
            otherButtonClasses={buttonClasses}
            onClick={onClick}
            title={title}>
            <div className='flex flex-row items-center p-1 w-full'>
                <div className='flex flex-col justify-center items-start gap-2 flex-grow'>
                    <span className="text-brand-500 font-bold text-clip sm:w-[80%] text-left">{title}</span>
                    <div className='flex flex-row items-center gap-2'>
                        {contentType ? <span className="uppercase h-4 inline-flex items-center rounded bg-gray-500 px-2 py-0.5 text-xs font-bold text-white">
                            {formatContentType(contentType)}
                        </span> : null}
                        <span className='text-xs text-gray-400 font-bold'>{formatBytes(bytes)}</span>
                    </div>
                </div>
                <span className="inline-flex sm:flex justify-start items-center rounded sm:p-1 gap-x-2">
                    <DownloadIcon className="w-6 h-6" />
                </span>

            </div>
        </Button>
    )
}

export default FileListItem