import React from 'react'
import { DownloadList } from './DownloadsList'

export function Certifications() {
    return <DownloadList
        type='certifications'
        alertLabel='certificationsEN50131'
        titleLabel='productsCertifications'
    />
}

export default Certifications