import React from "react"
import { SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Button, Input } from "../../../common"
import { Spinner } from "../../../common/Spinner"
import useLoquendo from "../hooks/useLoquendo"
import { LoquendoCard } from "./LoquendoCard"

interface LoquendoFormData {
  id: string
}

const LICENSE_LENGTH = 10

export function Loquendo() {
  const { control, register, handleSubmit, errors } = useForm()
  const { registerLoquendo, isRegistered, isRegistering, license, isFetching } = useLoquendo()
  const { t } = useTranslation()

  const licenseFromForm = useWatch<string>({
    control,
    name: "id",
  })

  const onSubmitHandler: SubmitHandler<LoquendoFormData> = ({ id }) => {
    registerLoquendo(id.toLowerCase())
  }

  return (
    <div className="h-screen flex flex-col items-center justify-center">
      {isFetching ? (
        <Spinner noOverlay={true} />
      ) : (
        <section className="flex flex-col gap-8 px-8 sm:px-0 sm:w-1/2">
          <header className="text-center">
            <h2 className="mb-2">{t('loquendoLicenseRegistrationTitle')}</h2>
            <p className="text-gray-500">
              {isRegistered ? t('loquendoLicenseRegistreredSubtitle') : t('loquendoLicenseRegistrationSubtitle')}
            </p>
          </header>
          <div className="flex flex-col justify-center items-center w-full">
            <LoquendoCard date={license?.registeredAt} license={isRegistered ? license?.id : licenseFromForm} />
          </div>
          {!isRegistered && (
            <form
              name="loquendo-license-registration"
              className="flex flex-col items-center gap-4 mt-4"
              onSubmit={handleSubmit(onSubmitHandler)}
            >
              <Input
                id="loquendo-license"
                name="id"
                autoComplete="off"
                containerClass="w-full"
                inputClasses="py-4 min-w-full sm:text-xl text-center"
                type="text"
                placeholder={t('loquendoLicenseInputPlaceholder')}
                maxLength={LICENSE_LENGTH}
                ref={register({
                  required: true,
                  pattern: /^[\w\d]+$/,
                  minLength: LICENSE_LENGTH,
                  maxLength: LICENSE_LENGTH,
                })}
              >
                {errors.id && (
                  <Input.Alert>
                    {t('loquendoLicenseInputError')}
                  </Input.Alert>
                )}
              </Input>
              <Button
                onClick={() => { }}
                text={t('loquendoLicenseSubmitLabel')}
                color="primary"
                size="xl"
                disabled={isRegistering}
                pending={isRegistering}
              />
            </form>
          )}
        </section>
      )}
    </div>
  )
}
