import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Button } from '../../common/Button'
import { useUserMutation } from '../../hooks/createCompanyWizard'
import { useLoading } from '../../hooks/loading'
import { notify } from '../../notification/actions'
import { reloadProfile } from '../actions'
import { FirstStepsWrapper } from './FirstStepsWrapper'

export const FirstStepsMarketing: React.FC = () => {
    const { t } = useTranslation()
    const { update } = useUserMutation()
    const { loading, execute } = useLoading()
    const dispatch = useDispatch()
    const [error, setError] = useState('')

    const marketingPreferenceHandler = (enabled: boolean) => async () => {
        try {
            await execute(() => update({
                marketing: {
                    tcn: { enabled }
                }
            }))
            dispatch(reloadProfile())
        } catch (error: any) {
            dispatch(notify('error', 'userUpdateError', 5000))
            setError(t('firstStepsMarketingError'))
        }
    }

    return (
        <FirstStepsWrapper pending={loading} error={error}>
            <p className="uppercase text-sm text-brand">{t('firstStepMarketingHeader')}</p>
            <p className="text-xl">{t('firstStepMarketingTitle')}</p>
            <p className='text-brand text-xl'>{t('firstStepMarketingSubTitle')}</p>
            <div className='flex flex-col max-w-xs mt-8 gap-4 mx-auto'>
                <Button color="primary" size="xl" text={t('firstStepMarketingAgree')} onClick={marketingPreferenceHandler(true)} />
                <Button link text={t('firstStepMarketingDisagree')} onClick={marketingPreferenceHandler(false)} />
            </div>
        </FirstStepsWrapper>
    )
}
