import { Epic, StateObservable, combineEpics } from 'redux-observable'
import { ActionTypes, State } from '../configureStore'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from, merge, Observable, of } from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import {
    BillingActions,
    createBillingProfile,
    createBillingProfileCompleted,
    createBillingProfileFailed,
    openBillingProfile,
    Prices,
    readBillingProfileFailed,
    saveBillingProfile,
    saveInvoices,
    savePrices,
    updateBillingProfile,
    updateBillingProfileFailed,
    readInvoicesFailed,
    READ_ALL_PRICES,
    openInvoices,
    CreateOrUpdateBillingActions
} from './actions'
import { notify } from '../notification/actions'
import { AmplifySession } from '../panels/epics'
import { getBrowserLanguage } from '../common/utils'
import { getBillingId } from '../profile/selectors'
import { getBillingProfileForm } from './selectors'
import _ from 'lodash'

const BILLING_PROFILE_URI = '/store/customers'
const PRICES_URI = '/store/prices'
const INVOICES_URI = '/store/orders'

export const readPrices$: Epic<ActionTypes,
    BillingActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<Prices>,
    }> = (action$, state$: StateObservable<State>, { currentSession, fetchJson }) => {
        return action$.pipe(
            filter((act) => act.type === READ_ALL_PRICES),
            filter(() => !!getBillingId(state$.value)),
            mergeMap(action =>
                from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const billingId = getBillingId(state$.value)
                            return fetchJson(`${PRICES_URI}?billingId=${billingId}`, { authorization: `Bearer ${res.idToken.jwtToken}` })
                                .pipe(
                                    map(response => savePrices(response)),
                                    catchError(error => of(notify('error', 'readPricesError', 3000, error.status)))
                                )
                        })
                    )
            )
        )
    }

function buildUserData(state: State, action: CreateOrUpdateBillingActions) {
    const defaultUserData = getBillingProfileForm(state)
    const filledUserData = _.omitBy(action.payload, v => !v)
    const userProfileData = {
        ...defaultUserData,
        ...filledUserData,
        language: getBrowserLanguage()
    }
    return userProfileData
}

export const createBillingProfile$: Epic<ActionTypes,
    BillingActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>,
    }> = (action$, state$, { currentSession, post }) =>
        action$.pipe(
            filter(isActionOf(createBillingProfile)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const headers = {
                                'Content-Type': 'application/json',
                                authorization: `Bearer ${res.idToken.jwtToken}`
                            }
                            const userProfileData = buildUserData(state$.value, action)
                            return post(BILLING_PROFILE_URI,
                                userProfileData,
                                headers)
                                .pipe(
                                    mergeMap(response => of(createBillingProfileCompleted(response.response.id), notify('info', 'billingProfileSuccess'))),
                                    catchError(error => of(createBillingProfileFailed('billingProfileError'), notify('error', 'billingProfileError', 3000, error.status)))
                                )
                        })
                    )
            })
        )

export const updateBillingProfile$: Epic<ActionTypes,
    BillingActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        put: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>,
    }> = (action$, state$, { currentSession, put }) => {
        return action$.pipe(
            filter(isActionOf(updateBillingProfile)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const billingId = getBillingId(state$.value)
                            const headers = {
                                'Content-Type': 'application/json',
                                authorization: `Bearer ${res.idToken.jwtToken}`
                            }
                            const userProfileData = buildUserData(state$.value, action)
                            return put(`${BILLING_PROFILE_URI}/${billingId}`,
                                { ...userProfileData },
                                headers)
                                .pipe(
                                    mergeMap(response => of(saveBillingProfile(response.response), notify('info', 'updateBillingProfileSuccess'))),
                                    catchError(error => of(updateBillingProfileFailed(), notify('error', 'updateBillingProfileError', 3000, error.status)))
                                )
                        })
                    )
            })
        )
    }

export const readBillingProfile$: Epic<ActionTypes,
    BillingActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<any>,
    }> = (action$, state$: StateObservable<State>, { currentSession, fetchJson }) => {
        return action$.pipe(
            filter(isActionOf(openBillingProfile)),
            mergeMap(action =>
                from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const billingId = getBillingId(state$.value)
                            return fetchJson(`${BILLING_PROFILE_URI}/${billingId}`,
                                { authorization: `Bearer ${res.idToken.jwtToken}` })
                                .pipe(
                                    mergeMap(customerResponse => of(saveBillingProfile(customerResponse))),
                                    catchError(error => of(
                                        notify('error', 'readBillingError', 3000, error.status),
                                        readBillingProfileFailed()))
                                )
                        })
                    )
            )
        )
    }


export const readInvoices$: Epic<ActionTypes,
    BillingActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<any>,
    }> = (action$, state$: StateObservable<State>, { currentSession, fetchJson }) => {
        return action$.pipe(
            filter(isActionOf(openInvoices)),
            mergeMap(action =>
                from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const billingId = getBillingId(state$.value)
                            return merge(
                                fetchJson(`${BILLING_PROFILE_URI}/${billingId}`,
                                    { authorization: `Bearer ${res.idToken.jwtToken}` })
                                    .pipe(
                                        mergeMap(customerResponse => of(saveBillingProfile(customerResponse))),
                                        catchError(error => of(
                                            notify('error', 'readBillingError', 3000, error.status),
                                            readBillingProfileFailed()))
                                    ),
                                fetchJson(`${INVOICES_URI}?customer=${billingId}`,
                                    { authorization: `Bearer ${res.idToken.jwtToken}` })
                                    .pipe(
                                        mergeMap(invoicesResponse => of(saveInvoices(invoicesResponse))),
                                        catchError(error => of(
                                            notify('error', 'readInvoicesError', 3000, error.status),
                                            readInvoicesFailed()))
                                    )
                            )
                        })
                    )))
    }

export const billingEpics = combineEpics(createBillingProfile$, readPrices$, readInvoices$, updateBillingProfile$, readBillingProfile$)