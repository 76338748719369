import React from 'react'
import { QrcodeIcon } from '@heroicons/react/solid'
import { Input } from '../../common'
import { serialNumberLength } from '../../hooks/adminPanels'

type AdminPanelFormProps = {
    serialNumber: string
    placeholder: string,
    onSubmit: (e: React.SyntheticEvent<HTMLFormElement>) => void
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function AdminPanelForm(props: AdminPanelFormProps) {
    const {serialNumber, placeholder, onSubmit, onChange} = props
    return (
        <form autoComplete="off" onSubmit={onSubmit}>
        <Input
            id="panel-serial-number"
            inputClasses={styles.input}
            type="text"
            name="serialNumber"
            placeholder={placeholder}
            autoComplete="off"
            minLength={serialNumberLength}
            maxLength={serialNumberLength}
            onChange={onChange}
            icon={<QrcodeIcon className={styles.icon} />}
            value={serialNumber}
            required
        />
    </form>
    )
}

const styles = {
    input: 'rounded-b-none border-gray-100 shadow-none sm:rounded-r-none sm:rounded-bl-md',
    icon: 'h-5 w-5 text-gray-400'
}