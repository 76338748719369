import React, { PropsWithChildren, useMemo } from 'react'
import { Route, RouteProps } from 'react-router-dom'
import { Spinner } from '../../../common/Spinner'
import FullBodyLayout from '../layouts/FullBodyLayout'
import { TermsAndConditionsForm } from '../TermsAndConditions/TermsAndConditionsForm'
import { useTermsAndConditions } from '../useTermsAndConditions'

export function PrivacyConsent(props: PropsWithChildren<RouteProps>) {
    const { children, ...rest } = props
    const { loading, consents, consent } = useTermsAndConditions()

    const isPrivacyUpdated = useMemo(() => {
        return consents.some((item) => item.actionRequired)
    }, [consents])

    return <Route
        {...rest}
        render={() => {
            if (loading) {
                return <FullBodyLayout><Spinner noOverlay={true} /></FullBodyLayout>
            }

            if (isPrivacyUpdated) {
                return (
                    <FullBodyLayout>
                        <TermsAndConditionsForm loading={loading} consents={consents} consent={consent} />
                    </FullBodyLayout>
                )
            } else {
                return children
            }
        }}
    />
}