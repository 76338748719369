import { UserCompanyProfile, UserRole } from './selectors'

export function companyCouldChangeAvatar(certificationLevel?: number): boolean {
    return !!certificationLevel && certificationLevel >= 3
}

export function allowedUser(role: UserRole) {
    return role === 'admin'
}

export const certificationMapper: { [key: number]: string } = {
    0: 'levelNone',
    1: 'levelSelected',
    2: 'levelSilver',
    3: 'levelGold',
    4: 'levelSpecialist'
}

export const certificationColorMapper: { [key: number]: string } = {
    0: 'bg-levelNone',
    1: 'bg-levelSelected',
    2: 'bg-levelSilver',
    3: 'bg-levelGold',
    4: 'bg-levelSpecialist'
}

export const getInitials = (name: string) => {
    const splittedName = name.trim().split(' ');
    const initials = splittedName.reduce((acc, curr, index) => {
        if (index === 0 || index === splittedName.length - 1) {
            acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
    }, '');
    return initials;
}

export const getProfileSubTitle = (profile: UserCompanyProfile) => {
    let subtitle
    if (profile.name)
        subtitle = profile.name + ' '
    if (profile.surname)
        subtitle += profile.surname
    return subtitle
}
