import React from 'react'
import clsx from 'clsx'

interface Props {
    image?: React.ReactElement<HTMLImageElement>,
    icon?: (props: React.ComponentProps<'svg'>) => JSX.Element,
    title: string,
    subTitle?: string,
    additionalClasses?: string
    show?: boolean
}

export function NoItem(props: Props) {
    const { show = true } = props
    const containerClasses = clsx(
        'relative block w-auto border-2 border-gray-100 border-dashed rounded-lg p-12 text-center',
        props.additionalClasses && props.additionalClasses
    )

    if (!show) {
        return null
    }

    return <div className={containerClasses}>
        {props.image && props.image}
        {props.icon && <props.icon className="mx-auto h-12 w-12 text-gray-200" /> }
        <h3 className="mt-2 text-sm font-bold text-gray-700">
            {props.title}
        </h3>
        {props.subTitle && (
            <p className="text-sm text-gray-400">{props.subTitle}</p>
        )}
    </div>
}