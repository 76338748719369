import { Configuration, RedirectRequest } from "@azure/msal-browser";

import { getMsalClientId } from "../../config";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: getMsalClientId(),
        authority: process.env.REACT_APP_AUTH_AUTHORITY,
        knownAuthorities: process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES ? process.env.REACT_APP_AUTH_KNOWN_AUTHORITIES.split(',') : [],
        redirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_AUTH_POST_LOGOUT_REDIRECT_URI,
        navigateToLoginRequestUrl: true
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
    scopes: []
};