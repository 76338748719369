import React, { ComponentPropsWithoutRef, forwardRef } from 'react'
import clsx from 'clsx'

interface CheckboxProps extends ComponentPropsWithoutRef<'input'> {
    label: string
    show?: boolean
    containerClass?: string
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
    const { label, show = true, containerClass = '', ...inputProps } = props
    return show ? (
        <div className={clsx(classes.container, containerClass)}>
            <div className={classes.inputContainer}>
                <input
                    ref={ref}
                    type="checkbox"
                    className={classes.input}
                    {...inputProps}
                />
            </div>
            <div className={classes.labelContainer}>
                <label htmlFor={inputProps.id} className={classes.label}>
                    {label}
                </label>
            </div>
        </div>
    ) : null
})

const classes = {
    container: 'relative flex items-start',
    inputContainer: 'flex items-center h-5',
    input: 'focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded',
    labelContainer: 'ml-3 text-sm',
    label: 'font-medium text-gray-700'
}

export { Checkbox }