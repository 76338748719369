import React from 'react'
import ReactDOM from 'react-dom'
import KseniaApp from './brand/ksenia/App'
import BticinoApp from './brand/bticino/App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import Playground from './Playground'
import { Provider } from 'react-redux'
import { store } from './configureStore'
import './translations/index'
import { isBticino } from './config'

if (process.env.REACT_APP_PLAYGROUND === 'true')
    ReactDOM.render(<Playground />, document.getElementById('root'))
else
    ReactDOM.render(
        <Provider store={store}>
            {isBticino()  ? <BticinoApp /> : <KseniaApp />}
        </Provider>,
        document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
if (!isBticino()) {
    serviceWorkerRegistration.register()
}
