import { useCallback } from 'react'
import { useMutation } from 'react-query'
import { fetchResource, toQueryString } from '../utils'
import { useAuth } from '../../auth'

const TALENT_LMS_LOGIN_LINK_PATH = "/talent-lms/login"

export type FetchLoginKeyMutation = {
    language: string
}

const fetchLoginKey = (authToken: string) => async (query: {language: string}) => {
    const queryString = toQueryString(query)
    return await fetchResource<string>(`${TALENT_LMS_LOGIN_LINK_PATH}?${queryString}`, authToken)
}

export const FetchTalentLmsLoginLink = () => {
    const { getIdToken } = useAuth()

    const fetchFn = useCallback(async (body: FetchLoginKeyMutation) => {
        const token = await getIdToken()
        return fetchLoginKey(token)(body)
    }, [getIdToken])

    const fetchMutation = useMutation(fetchFn)
    const fetch = (body: FetchLoginKeyMutation) => fetchMutation.mutateAsync(body)

    return { fetch }
}