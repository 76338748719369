import { Epic } from 'redux-observable'
import { ActionTypes, State } from '../../configureStore'
import { catchError, filter,  mergeMap } from 'rxjs/operators'
import { ActionType, isActionOf } from 'typesafe-actions'
import { from, Observable, of } from 'rxjs'
import { AjaxResponse } from 'rxjs/ajax'
import { sendFeedback } from './actions'
import { notify } from '../../notification/actions'
import { AmplifySession } from '../../panels/epics'

const SURVEYS_URI = '/surveys'
const SERVICE_NAME = 'secureweb-webapp'

export const sendFeedback$: Epic<ActionTypes,
    ActionType<typeof notify>,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>,
        getBuildVersion: () => string
    }> = (action$, state$, { currentSession, post, getBuildVersion }) =>
        action$.pipe(
            filter(isActionOf(sendFeedback)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const headers = {
                                'Content-Type': 'application/json',
                                authorization: `Bearer ${res.idToken.jwtToken}`
                            }
                            return post(SURVEYS_URI,
                                { ...action.payload, service_name: SERVICE_NAME, version: getBuildVersion() },
                                headers)
                                .pipe(
                                    mergeMap(response => of(notify('info', 'sendFeedbackSuccess'))),
                                    catchError(error => of(notify('error', 'sendFeedbackError', 3000, error.status)))
                                )
                        })
                    )
            })
        )