import '../../index.css'
import React from 'react'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import Login from '../../login/Login'
import Layout from '../../common/Layout'
import ElevenList from '../../panels/ElevenList'
import User from '../../profile/User/User'
import Company from '../../profile/Company'
import CompanyOrders from '../../profile/CompanyOrders'
import Services from '../../services/Services'
import Notifications from '../../notification/Notifications'
import Registration from '../../registration/Registration'
import AuthLayout from '../../common/AuthLayout'
import ForgotPassword from '../../forgotPassword/ForgotPassword'
import Dashboard from '../../dashboard/Dashboard'
import Loading from '../../loading/Loading'
import CreateCompanyWizard from '../../dashboard/createCompany/CreateCompanyWizard'
import { QueryClient, QueryClientProvider } from 'react-query'
import CompanyMembers from '../../profile/CompanyMembers'
import PricesAndCatalogs from '../../downloads/PricesAndCatalogs'
import { NotFound } from './notFound/NotFound'
import { Companies } from '../../admin/Companies/Companies'
import ProductsAndSoftware from '../../downloads/ProductsAndSoftware'
import Training from '../../training/Training'
import Certifications from '../../downloads/Certifications'
import Leaderboards from '../../loyaltyProgram/Leaderboards'
import LoyaltyInfo from '../../loyaltyProgram/LoyaltyInfo'
import * as Routing from '../../routing'
import { Users } from '../../admin/Users/Users'
import { ApplicationRole, CompanyRole } from '../../profile/selectors'
import { AdminPanels } from '../../admin/Panels/AdminPanels'
import { AuthWrapper, useAnalytics } from '../../auth/amplify'

const { PrivateRoute, OnlyPublicRoute } = Routing

const queryClient = new QueryClient()

export const publicRoutes = ['/login', '/register', '/forgot-password']

const backendRoles: ApplicationRole[] = [ApplicationRole.Sales, ApplicationRole.Helpdesk]
const loggedRoles: ApplicationRole[] = [ApplicationRole.Customer, ApplicationRole.Sales, ApplicationRole.Helpdesk]

const App = () => {

    useAnalytics()

    return (
        <QueryClientProvider client={queryClient}>
            <HashRouter>
                <AuthWrapper>
                    <Notifications />
                    <Routing.RedirectFromLogin>
                        <Routing.CompleteProfile>
                            <Routing.JoinCompany>
                                <Routing.RedirectFromRoot>
                                    <Switch>
                                        <Route exact path={publicRoutes}>
                                            <AuthLayout year={new Date().getFullYear().toString()}>
                                                <Switch>
                                                    <OnlyPublicRoute path="/login" exact><Login /></OnlyPublicRoute>
                                                    <OnlyPublicRoute path="/register"
                                                        exact><Registration /></OnlyPublicRoute>
                                                    <OnlyPublicRoute path="/forgot-password"
                                                        exact><ForgotPassword /></OnlyPublicRoute>
                                                </Switch>
                                            </AuthLayout>
                                        </Route>
                                        <Layout>
                                            <PrivateRoute path="/"
                                                allowedTo={[...loggedRoles, ApplicationRole.Unknown]}>
                                                <Loading>
                                                    <Switch>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/dashboard" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <Dashboard />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/createCompany" exact>
                                                            <Routing.CompanyRoleRoute allowedTo={[CompanyRole.Visitor]}>
                                                                <CreateCompanyWizard />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/profile" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <User />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/panels" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <ElevenList />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/orders" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <CompanyOrders />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/services" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <Services />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/store/buy/gateway-http" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <Services />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/company" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <Company />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/members" exact>
                                                            <Routing.CompanyRoleRoute allowedTo={[CompanyRole.Admin]}>
                                                                <CompanyMembers />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/training" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <Training />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/prices-and-catalogs" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <PricesAndCatalogs />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/certifications" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <Certifications />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/documentation-and-software" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <ProductsAndSoftware />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/loyalty/leaderboards" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee]}>
                                                                <Leaderboards />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={loggedRoles}
                                                            path="/loyalty/info" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <LoyaltyInfo />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={backendRoles}
                                                            path="/admin/companies" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <Companies />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={backendRoles}
                                                            path="/admin/users" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <Users />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <PrivateRoute
                                                            allowedTo={[ApplicationRole.Helpdesk]}
                                                            path="/admin/panels" exact>
                                                            <Routing.CompanyRoleRoute
                                                                allowedTo={[CompanyRole.Admin, CompanyRole.Employee, CompanyRole.Visitor]}>
                                                                <AdminPanels />
                                                            </Routing.CompanyRoleRoute>
                                                        </PrivateRoute>
                                                        <Route path="/404" render={() => <NotFound />} />
                                                        <Redirect to="/404" />
                                                    </Switch>
                                                </Loading>
                                            </PrivateRoute>
                                        </Layout>
                                    </Switch>
                                </Routing.RedirectFromRoot>
                            </Routing.JoinCompany>
                        </Routing.CompleteProfile>
                    </Routing.RedirectFromLogin>
                </AuthWrapper>
            </HashRouter>
        </QueryClientProvider>
    )
}

export default App
