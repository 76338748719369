import React, { VFC } from 'react'
import clsx from 'clsx'

interface Props {
    src?: string,
    alt?: string,
    size?: 32 | 14 | 12 | 10 | 8 | 6 | 4,
    type?: 'rounded' | 'circular',
    otherClasses?: string
    initials?: string
}

export const Avatar: VFC<Props & React.HTMLAttributes<HTMLElement>> = (props) => {
    const { src, alt, size = 10, type = 'rounded', otherClasses, initials, ...otherProps } = props
    
    const shouldShowInitials = (
        props.hasOwnProperty('initials') && (!props.hasOwnProperty('src') || !src))

    const classes = clsx(
        "bg-white inline-block",
        size === 32 && "h-32 w-32 rounded-xl",
        size === 14 && "h-14 w-14 rounded-md",
        size === 12 && "h-12 w-12 rounded-md",
        size === 10 && "h-10 w-10 rounded-md",
        size === 8 && "h-8 w-8 rounded-md",
        size === 6 && "h-6 w-6 rounded-md",
        size === 4 && "h-4 w-4 rounded",
        type === 'circular' && "rounded-full",
        shouldShowInitials && "inline-flex items-center justify-center bg-gray-800",
        otherClasses && otherClasses
    )

    const initialsClasses = clsx(
        "font-medium leading-none text-white uppercase",
        size >= 14 && "text-2xl",
        size === 12 && "text-lg",
        size === 8 && "text-sm",
        size <= 6 && "text-xs",
    )


    
    if (shouldShowInitials) {
        return (
            <span className={classes}>
                <span className={initialsClasses}>{initials}</span>
            </span>
        )
    }

    return <img src={src || require('../images/profile.png').default} alt={alt} {...otherProps}
    className={classes}/>
}
