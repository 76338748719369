import React, { useEffect, useMemo, useState, useRef } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { InputProps } from './Input/Input'
import InputHelp from './Input/InputHelp'
import InputAlert from './Input/InputAlert'
import { SearchIcon, ChevronDownIcon, XIcon } from '@heroicons/react/outline'

export type InputSearchableOption = {
    label: string
    value: string
}

type InputSearchableProps = Pick<InputProps, 'id' | 'label' | 'optional' | 'name' | 'required' | 'containerClass' | 'disabled' | 'placeholder' | 'invalid' | 'children' | 'onChange'> & {
    options: InputSearchableOption[]
    help?: string,
    defaultValue: string
    clearable?: boolean
    value?: string
    onSelect?: (value: string) => void
    onSearch?: (value: string) => void
    emptyOptionsMessage?: string
}

interface Compounded extends React.ForwardRefExoticComponent<InputSearchableProps & React.RefAttributes<HTMLInputElement>> {
    Help: typeof InputHelp
    Alert: typeof InputAlert
}

const InputSearchable = React.forwardRef((props, ref) => {
    const { id, help, label, options = [], optional, name, required, invalid, containerClass, defaultValue, placeholder, clearable, disabled, children, value, onSelect, emptyOptionsMessage, onSearch } = props
    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState<string>(defaultValue)
    const [search, setSearch] = useState('')
    const { t } = useTranslation()
    const searchRef = useRef<HTMLInputElement>(null)
    const buttonRef = useRef<HTMLButtonElement>(null)
    const listRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
        if (value) {
            setSelected(value)
        }
    }, [value])

    useEffect(() => {
        function eventHandler(event: WindowEventMap['mousedown']) {
            let target = event.target as HTMLElement

            if (open === false) {
                return false
            }

            if (buttonRef.current?.contains(target)) {
                return false
            }

            if (listRef.current === target) {
                return false
            }

            setOpen(false)
        }
        window.addEventListener('mousedown', eventHandler)
        return () => window.removeEventListener('mousedown', eventHandler)
    }, [open])

    useEffect(() => {
        if (onSearch) {
            onSearch(search)
        }
    }, [onSearch, search])

    const onClickHandler = () => {
        if (!open) {
            setOpen(true)
            searchRef.current?.focus()
        } else {
            setOpen(false)
        }
    }

    const resetInputSearch = () => {
        if (searchRef.current) {
            searchRef.current.value = ''
        }
    }

    const onClearHandler = () => {
        setSearch('')
        setSelected('')
        setOpen(false)
        resetInputSearch()
        onSelect && onSelect('')
    }

    const onOptionMouseDownHandler = (option: InputSearchableOption) => {
        setSelected(option.value)
        onSelect && onSelect(option.value)
        setSearch('')
        resetInputSearch()
    }

    const onSearchChangehandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.currentTarget.value)
    }

    const filteredOptions = useMemo(() => options.filter(({ label }) => label.toLowerCase().includes(search.toLowerCase())), [search, options])

    const currentOptions = search ? filteredOptions : options

    const fakeInputClasses = clsx(
        'grid grid-cols-[1fr,70px] px-3 py-2 pr-0 shadow-sm focus:ring-brand-500 focus:border-brand-500 w-full sm:text-sm border border-gray-300 rounded-md bg-white',
        disabled && 'opacity-30 cursor-default', invalid && 'border-red-500 '
    )

    const buttonClasses = clsx(
        'flex justify-center items-center flex-1',
        disabled && 'cursor-not-allowed'
    )

    const listClasses = clsx('absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm block', {
        'hidden': !open
    })

    return (
        <div className={clsx('leading-none', containerClass)}>
            {label && (
                <div className="flex justify-between mb-1">
                    <label htmlFor={id} className="block text-sm font-medium text-gray-700">
                        {label}
                    </label>
                    {optional && (
                        <span className="text-sm text-gray-500" id="email-optional">
                            {t('optional')}
                        </span>
                    )}
                </div>
            )}
            <div className="relative">
                <div className={fakeInputClasses}>
                    <div className="grid grid-cols-[35px,1fr]">
                        <div className="flex items-center">
                            <SearchIcon className="row-start-1 col-start-1 h-5 w-5 text-gray-500" />
                        </div>
                        {!search && !selected && (
                            <div className="flex items-center row-start-1 col-start-2 leading-none">{placeholder}</div>
                        )}
                        {!search && (
                            <div className="flex items-center row-start-1 col-start-2 leading-none">{options.find(option => option.value === selected)?.label}</div>
                        )}
                        <input
                            ref={searchRef}
                            type="text"
                            data-testid="inputSearchable"
                            className="bg-transparent ring-0 focus:ring-0 row-start-1 col-start-2 border-0 m-0 p-0 focus:border-0 outline-none"
                            onClick={onClickHandler}
                            onChange={(event) => onSearchChangehandler(event)}
                            disabled={disabled}
                        />
                    </div>
                    <div className="flex justify-center">
                        {clearable && (
                            <button disabled={disabled} onClick={onClearHandler} className={buttonClasses} type="button"><XIcon className="w-5 h-5" /></button>
                        )}
                        <span className=" border-r border-gray-200" />
                        <button ref={buttonRef} disabled={disabled} type="button" className={buttonClasses} onClick={onClickHandler}><ChevronDownIcon className="w-5 h-5" /></button>
                    </div>
                </div>
                <ul ref={listRef} className={listClasses}>
                    {currentOptions.length === 0 && search !== '' && (
                        <span className="text-gray-500 block text-center my-2">{t('inputSearchableEmpty')}</span>
                    )}
                    {currentOptions.length === 0 && search === '' && (
                        <span className="text-gray-500 block text-center my-2">{emptyOptionsMessage}</span>
                    )}
                    {currentOptions.map((option) => (<li onMouseDown={() => onOptionMouseDownHandler(option)} data-testid={option.value} key={option.value} className={clsx('cursor-pointer select-none py-2 px-3 hover:bg-gray-50', option.value === selected ? 'bg-brand-500 text-white hover:bg-brand-500 hover:text-white' : 'text-gray-900')}>{option.label}</li>))}
                </ul>
            </div>
            {help && (<InputHelp>{help}</InputHelp>)}
            {children}
            <input className="opacity-0 w-[0] h-[0] p-0 m-0 border-0 absolute" name={name} type="text" ref={ref} value={selected} required={required} readOnly />
        </div>
    )
}) as Compounded

InputSearchable.Help = InputHelp
InputSearchable.Alert = InputAlert

export { InputSearchable }