import React from 'react'
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/outline'

interface ShuttleActionsProps {
    onAddAll: () => void
    onRemoveAll: () => void
    totalAvailable: number
    totalSelected: number
    disabled: boolean
}

const ShuttleActions = (props: ShuttleActionsProps) => {
    const { disabled, totalAvailable, totalSelected } = props

    const isAddAllDisabled = totalAvailable === 0 || disabled
    const isRemoveAllDisabled = totalSelected === 0 || disabled
    
    return (
        <div className={styles.container}>
            <button
                className={styles.button}
                data-testid="add-all"
                type="button"
                onClick={props.onAddAll}
                disabled={isAddAllDisabled}
            >
                <ChevronDoubleRightIcon className={styles.icon} />
            </button>
            <button
                className={styles.button}
                data-testid="remove-all"
                type="button"
                onClick={props.onRemoveAll}
                disabled={isRemoveAllDisabled}
            >
                <ChevronDoubleLeftIcon className={styles.icon} />
            </button>
        </div>
    )
}

const styles = {
    container: 'flex flex-col items-center justify-center gap-4',
    button: 'text-gray-500 hover:text-brand-500 disabled:opacity-30 disabled:hover:text-gray-500 disabled:cursor-default',
    icon: 'w-6 h-6'
}

export default ShuttleActions