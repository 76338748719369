import { createReducer } from "typesafe-actions"
import * as Actions from './licenseActions'
import * as LogoutActions from '../logout/actions'
import { License } from './licenseActions'
import _ from 'lodash'

export interface LicensesState {
    value: Actions.License[],
    availableVoices: string[]
    search: string,
    pending: boolean
}

export const licensesInitialState: LicensesState = {
    value: [],
    availableVoices: [],
    search: '',
    pending: false
}

export const licensesReducer = createReducer(licensesInitialState)
    .handleAction(Actions.saveLicenses, (state, action) => ({
        ...state,
        value: action.payload,
        pending: false
    }))
    .handleAction(Actions.reloadLicenses, (state, action) => ({
        ...state,
        pending: true
    }))
    .handleAction(Actions.saveVoices, (state, action) => ({
        ...state,
        availableVoices: action.payload,
        pending: false
    }))
    .handleAction(Actions.saveLicenseComplete, (state, action) => {
        let newArray = _.cloneDeep(state.value)
        const index = newArray.findIndex((l) => l.id === action.payload.id)
        index === -1 ? newArray.push(action.payload) : newArray[index] = { ...newArray[index], ...action.payload }
        return {
            ...state,
            value: newArray,
            pending: false
        }
    })
    .handleAction(LogoutActions.logoutCompleted, (state, action) => licensesInitialState)


const selectedLicensesInitialState = {}

export const addedLicenseState: License = {
    id: '',
    alreadyRegistered: false,
    voiceCount: 0,
    voices: []
}

export const selectedLicenseReducer = createReducer(selectedLicensesInitialState)
    .handleAction(Actions.selectLicense, (state, action) => action.payload)
    .handleAction(Actions.addLicense, (state, action) => ({ ...addedLicenseState }))
    .handleAction(Actions.saveLicenseComplete, (state, action) => selectedLicensesInitialState)
    .handleAction(Actions.cancelSelectedLicense, (state, action) => selectedLicensesInitialState)
    .handleAction(Actions.changeLicense, (state, action) => ({ ...state, [action.payload.key]: action.payload.value }))
    .handleAction(LogoutActions.logoutCompleted, (state, action) => selectedLicensesInitialState)
    .handleAction(Actions.invalidLicense, (state, action) => ({ id: (state as any).id, alreadyRegistered: false, voiceCount: 0, voices: [] }))
