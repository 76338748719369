const rankPrizesImages = {
    overall1: require('../images/loyalty/overall1.png').default,
    overall2: require('../images/loyalty/overall2.png').default,
    overall3: require('../images/loyalty/overall3.png').default,
    selected: require('../images/loyalty/selected1.png').default,
    silver: require('../images/loyalty/silver1.png').default,
    gold: require('../images/loyalty/gold1.png').default,
    specialist: require('../images/loyalty/specialist1.png').default
}

export const rankPrizes: { [key: string]: Array<{ position: number, prize: string, thumb: string }>; } = {
    'overall': [
        { position: 1, prize: 'MacBook Air M1', thumb: rankPrizesImages.overall1 },
        { position: 2, prize: 'Apple iPhone 14 Pro', thumb: rankPrizesImages.overall2 },
        { position: 3, prize: 'DJI Mini 3', thumb: rankPrizesImages.overall3 }
    ],
    'specialist': [{ position: 1, prize: 'Xiaomi Scooter Pro 2', thumb: rankPrizesImages.specialist }],
    'gold': [{ position: 1, prize: 'Rayban Smart Glass', thumb: rankPrizesImages.gold }],
    'silver': [{ position: 1, prize: 'Dyson Pure Hot+Cool', thumb: rankPrizesImages.silver }],
    'selected': [{ position: 1, prize: 'Marshall Emberton', thumb: rankPrizesImages.selected }]
}

export const laresPoints = [
    { model: '16', points: 20 },
    { model: '96 wls', points: 40 },
    { model: '40', points: 40 },
    { model: '40 wls', points: 50 },
    { model: '140 wls', points: 100 },
    { model: '644 wls', points: 200 }
]