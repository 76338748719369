import React, { Fragment } from 'react'
import { XIcon } from '@heroicons/react/outline'
import { Dialog, Transition } from '@headlessui/react'
import { MenuNav, MenuLogo } from './MenuIndex'
import { MenuProfile } from './MenuProfileIndex'
import { MenuFeedback } from './MenuFeedback'
import { ApplicationRole, CompanyRole, CompanyType, UserCompanyProfile } from '../../profile/selectors'

interface Props {
    sidebarOpen: boolean,
    releaseNotesUrl: string,
    setSidebarOpen: (isOpen: boolean) => void
    user?: UserCompanyProfile
    companyType: CompanyType
    companyRole: CompanyRole
    applicationRoles: ApplicationRole[]
}

export function Menu(props: Props) {
    const { sidebarOpen, setSidebarOpen, applicationRoles, companyRole, companyType, user } = props
    return <>
        {/* Mobile Navigation */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
                as="div"
                static
                className="fixed inset-0 flex lg:hidden"
                open={sidebarOpen}
                onClose={setSidebarOpen}
            >
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 bg-gray-800">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-in-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in-out duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    onClick={() => setSidebarOpen(false)}
                                >
                                    <span className="sr-only">Close sidebar</span>
                                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </Transition.Child>
                        <div className="flex-shrink-0 flex items-center px-4">
                            <MenuLogo otherClass='h-8' />
                        </div>
                        <div className="mt-5 flex-1 h-0 overflow-y-auto">
                            <MenuNav
                                releaseNotesUrl={props.releaseNotesUrl}
                                companyType={companyType}
                                setSidebarOpen={setSidebarOpen}
                                applicationRoles={applicationRoles}
                                companyRole={companyRole}
                            />
                        </div>
                        <MenuFeedback />
                        <MenuProfile user={user} />
                    </div>
                </Transition.Child>
                <div className="flex-shrink-0 w-14" aria-hidden="true">
                    {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
            </Dialog>
        </Transition.Root>

        {/* Desktop Navigation */}
        <div className="hidden lg:flex lg:flex-shrink-0">
            <div className="flex flex-col w-72">
                <div className="flex flex-col flex-grow overflow-hidden bg-gray-800">
                    <div className="custom-scrollbar flex-1 overflow-y-auto">
                        <div className="flex items-center h-16 flex-shrink-0 px-4">
                            <MenuLogo otherClass='h-12' />
                        </div>
                        <MenuNav
                            releaseNotesUrl={props.releaseNotesUrl}
                            companyType={companyType}
                            setSidebarOpen={setSidebarOpen}
                            applicationRoles={applicationRoles}
                            companyRole={companyRole}
                        />
                    </div>
                    <div>
                        <MenuFeedback />
                        <MenuProfile user={user} />
                    </div>
                </div>
            </div>
        </div>
    </>
}
