import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Layout from '../../common/Layout'
import Notifications from '../../notification/Notifications'
import { BticinoList } from './BticinoList/BticinoList'
import { Loquendo } from './Loquendo/Loquendo'
import { PrivacyConsent } from './routing'
import '../../index.css'
import { BticinoAdminPanels } from './BticinoAdminPanels'
import { Authenticate } from './Authenticate'
import Products from './products/Products'
import { AuthWrapper } from '../../auth/msal'

const queryClient = new QueryClient()

export const publicRoutes = ['/login', '/register', '/forgot-password']

const App = () =>
    <QueryClientProvider client={queryClient}>
        <HashRouter>
            <AuthWrapper>
                <AuthenticatedTemplate>
                    <Notifications />
                    <PrivacyConsent>
                        <Switch>
                            <Layout>
                                <Switch>
                                    <Route
                                        path="/admin/panels" exact>
                                        <BticinoAdminPanels />
                                    </Route>
                                    <Route
                                        path="/" exact>
                                        <BticinoList />
                                    </Route>
                                    <Route
                                        path="/panels" exact>
                                        <BticinoList />
                                    </Route>
                                    <Route
                                        path="/loquendo" exact>
                                        <Loquendo />
                                    </Route>
                                    <Route
                                        path="/downloads" exact>
                                        <Products />
                                    </Route>
                                </Switch>
                            </Layout>
                        </Switch>
                    </PrivacyConsent>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Authenticate />
                </UnauthenticatedTemplate>
            </AuthWrapper>
        </HashRouter>
    </QueryClientProvider>

export default App