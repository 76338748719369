import { createReducer } from 'typesafe-actions'
import { logout } from '../logout/actions'
import * as Actions from './actions'

export interface LoyaltyProgramState {
    loading: boolean,
    ranking: Actions.Ranking
}

export const loyaltyProgramDefaultState: LoyaltyProgramState = {
    loading: false,
    ranking: {}
}

export const loyaltyProgramReducer = createReducer(loyaltyProgramDefaultState)
    .handleAction(logout, (state, action) => ({
        ...state,
        ranking: {}
    }))
    .handleAction(Actions.loadLoyaltyProgram, (state, action) => ({
        ...state,
        loading: true
    }))
    .handleAction(Actions.loadLoyaltyProgramFailed, (state, action) => ({
        ...state,
        loading: false,
    }))
    .handleAction(Actions.loadLoyaltyProgramCompleted, (state, action) => ({
        ...state,
        loading: false,
        ranking: action.payload
    }))