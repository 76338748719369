import React, { VFC, useState } from 'react'
import { StarIcon } from '@heroicons/react/solid'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

type Props = {
    className?: string,
    initialValue?: number,
    onRate?: (value: number) => void
}

const Rating: VFC<Props> = (props) => {
    const { className, initialValue = 0, onRate } = props
    const [value, setValue] = useState<number | null>(null)
    const { t } = useTranslation()

    const levels = [
        {
            label: t('veryBad'),
            rate: 1
        },
        {
            label: t('bad'),
            rate: 2
        },
        {
            label: t('okay'),
            rate: 3
        },
        {
            label: t('good'),
            rate: 4
        },
        {
            label: t('veryGood'),
            rate: 5
        }
    ]

    const classes = clsx('flex justify-center', className)

    return (
        <>
            <ul className={classes}>
                {levels.map(({ label, rate }) => {
                    const buttonClasses = clsx(
                        'flex flex-col items-center w-full text-gray-300 hover:text-brand-600',
                        (!value && rate <= initialValue) && 'text-brand-600',
                        value && rate <= value ? 'text-brand-600' : 'text-gray-400'
                    )
                    return (
                        <li key={rate} className="w-full">
                            <button
                                className={buttonClasses}
                                type="button"
                                onClick={() => {
                                    setValue(rate)
                                    onRate && onRate(rate)
                                }}
                            >
                                <StarIcon className="w-10" />
                                <span className="text-xs text-center leading-tight invisible sm:visible">
                                    {label}
                                </span>
                            </button>
                        </li>
                    )
                })}
            </ul>
        </>
    )
}

export default Rating

