import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Location, Panel } from './actions'
import { PanelsListHeader } from './PanelsListHeader'
import { PanelsListFooter } from './PanelsListFooter'
import { PanelsListItem } from './PanelsListItem'
import { Card, NoItem } from '../common'
import Map, { locationToPosition, validLocation } from './maps/Maps'
import usePanelsListTabs from './usePanelsListTabs'
import { usePanels } from '../hooks/usePanels'

interface PanelsListProps {
    showLoading?: boolean
    voices?: string[],
    featuresControl?: {
        map?: boolean,
        updateStateFilter?: boolean,
        propagateAuth?: boolean
    }
    onEdit: (panel: Panel) => void
    onDisplayQrCode?: (name: string, serialNumber: string) => void
    onAdd: () => void,
}

export function PanelsList(props: PanelsListProps) {
    const {
        featuresControl = {},
    } = props

    const {
        panels,
        loading,
        refetch,
        pagination,
        setSearch,
        search,
        setPageLimit,
        pageLimit,
        setPage,
        setFilters,
        removeFilters,
        filters
    } = usePanels({
        projection: 'full',
        paginated: true
    })

    const { map = false, updateStateFilter = false, propagateAuth = false } = featuresControl
    const { t } = useTranslation()
    const { tab, TabNav } = usePanelsListTabs(map)

    const showNoItem = (!search || search.length === 0) && (!filters)

    return (
        <>
            <TabNav />
            <Card pending={props.showLoading || loading} className={clsx('page-container', tab === 'map' && 'flex-1 flex flex-col')}>
                <Card.Header full>
                    <PanelsListHeader
                        updateStateFilter={updateStateFilter}
                        search={search}
                        filters={filters}
                        onFilter={setFilters}
                        onRemoveFilter={removeFilters}
                        onSearch={setSearch}
                        onReload={refetch}
                        onAdd={props.onAdd}
                    />
                </Card.Header>
                <Card.Body full otherClasses={clsx(tab === 'map' && 'flex-1 flex')}>
                    {tab === 'list' ?
                        <>
                            {panels.length > 0 ? <ul className="divide-y divide-gray-100 h-auto">
                                {panels.map((panel, index) => {
                                    return <li key={`panel-list-item-${index}`}>
                                        {<PanelsListItem
                                            panel={panel}
                                            onEdit={() => {
                                                props.onEdit(panel)
                                            }}
                                            onDisplayQrCode={props.onDisplayQrCode}
                                            propagateAuth={propagateAuth}
                                            voices={props.voices}
                                        />}
                                    </li>
                                })}
                            </ul> : showNoItem ? <NoItem image={<img src={require('../images/ksenia-box.svg').default} className="mx-auto h-20 object-contain" alt="" aria-hidden="true" />} title={t('noPanelsTitle')} subTitle={t('noPanelsSubtitle')} additionalClasses="m-4" /> : null}
                        </>
                        :
                        <Map
                            containerClassname="flex-1"
                            markers={panels
                                .filter(p => p.location && validLocation(p.location))
                                .map(p => (
                                    {
                                        ...locationToPosition(p.location as Location),
                                        panel: p
                                    }
                                ))
                            }
                            mode={'show'}
                            onEdit={props.onEdit}
                        />
                    }
                </Card.Body>
                <Card.Footer >
                    {pagination ? <PanelsListFooter
                        {...pagination}
                        onPageLimitChange={setPageLimit}
                        onPageChange={setPage}
                        pageLimit={pageLimit}
                    /> : null}
                </Card.Footer>
            </Card>
        </>
    )
}