import React from 'react'
import { Notification } from "./reducer"
import { useTranslation } from "react-i18next"
import { State } from '../configureStore'
import { connect } from 'react-redux'
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline'
import clsx from 'clsx'

export function Notifications(props: {
    notifications: Notification[]
}) {
    const { t } = useTranslation()
    const notifications = (props.notifications || [])
    return notifications.length > 0 ?
        <div className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-8 sm:items-start z-50">
            <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                {notifications.map((notification, index) => <div
                key={`notification-${index}`}
                className={clsx(
                    'w-full sm:w-96 rounded-md p-4 shadow-lg border-2 border-transparent',
                    notification.type === 'error' ? 'bg-red-50 border-red-200' : 'bg-green-50 border-green-200'
                )}
            >
                <div className="flex">
                    <div className="flex-shrink-0">
                        {notification.type === 'error' ?
                            <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
                            :
                            <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
                        }
                    </div>
                    <div className="ml-3">
                        <h3 className={clsx(
                            'text-sm font-bold',
                            notification.type === 'error' ? 'text-red-800' : 'text-green-800'
                        )}>{notification.type === 'error' ? t('error') : t('success')}</h3>
                        <div className={clsx(
                            'mt-2 text-sm',
                            notification.type === 'error' ? 'text-red-700' : 'text-green-700'
                        )}>
                            <p>{t(notification.message)}</p>
                            {notification.errorCode && <p className="mb-0">{`${t('errorCode')}: ${notification.errorCode}`}</p>}
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
        </div> : null
}

export function mapStateToProps(state: State) {
    return {
        notifications: state.notifications
    }
}

export default connect(mapStateToProps)(Notifications)
