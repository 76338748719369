import { useState } from 'react'
import { useQuery } from 'react-query'
import { PANELS_PATH } from '../utils'
import { useDispatch } from 'react-redux'
import { notify } from '../../notification/actions'
import { RequestParams, useRequest } from '../request'


export type AclPanel = {
    _id: string,
    name: string,
    serialNumber: string
}

const fetchOptions = {
    refetchOnWindowFocus: false
}

export const usePanelAcl = (userId: string) => {
    const dispatch = useDispatch()
    const [userPanels, setUserPanels] = useState<string[]>([])

    const requestParams: RequestParams = {
        path: PANELS_PATH,
        query: {
            userId,
            simulateAclEnabled: 'true'
        }
    }

    const { request: getAclPanels } = useRequest<AclPanel[]>(requestParams)

    const { isFetching } = useQuery(
        [requestParams],
        getAclPanels,
        {
            onSuccess: (data) => {
                if (data && data?.body) {
                    setUserPanels(data.body.map(panel => panel._id))
                }
            },
            onError: () => {
                dispatch(notify('error', 'panelLoadError', 3000))
            },
            ...fetchOptions
        }
    )

    return {
        userPanels,
        loading: isFetching
    }
}
