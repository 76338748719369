import { ActionType, createAction } from 'typesafe-actions'


export const SEND_FEEDBACK = 'send_feedback'

export const FEEDBACK = 'send_feedback'

export const sendFeedback = createAction(SEND_FEEDBACK, (action) => {
    return (featureName: string, value: number, description?: string) => {
        return action({
            feature_name: featureName,
            level: value,
            description
        })
    }
})

export type FeedbackActions = ActionType<typeof sendFeedback>
