import { useEffect, useState } from "react"

export const COMPANIES_PATH = '/companies'
export const USERS_PATH = '/users'
export const PANELS_PATH = '/users/panels'

export const generateError = (response: Response) => {
    const error = new Error(response.statusText)
    error.name = response.status.toString()
    throw error
}

export const toQueryString = (obj: { [key: string]: string }) => Object.keys(obj).filter(key => !!obj[key]).map(key => key + '=' + obj[key]).join('&')

export async function fetchResource<T>(url: string, bearerToken: string) {
    const response = await fetch(url, {
        method: 'GET',
        headers: { authorization: `Bearer ${bearerToken}` }
    })
    if (!response.ok) {
        generateError(response)
    }
    return response.json() as Promise<T>
}

export async function createResource<T>(url: string, bearerToken: string, body: any) {
    const response = await fetch(url, {
        method: 'POST',
        headers: { authorization: `Bearer ${bearerToken}`, 'content-type': 'application/json' },
        body: JSON.stringify(body)
    })
    if (!response.ok) {
        generateError(response)
    }
    return response.json()
        .then(data => Promise.resolve(data) as Promise<T>)
        .catch(_ => Promise.resolve())
}

export async function updateResource(url: string, bearerToken: string, body: any) {
    const response = await fetch(url, {
        method: 'PUT',
        headers: { authorization: `Bearer ${bearerToken}`, 'content-type': 'application/json' },
        body: JSON.stringify(body)
    })
    if (!response.ok) {
        generateError(response)
    }
    return Promise.resolve()
}

export async function deleteResource(url: string, bearerToken: string) {
    const response = await fetch(url, {
        method: 'DELETE',
        headers: { authorization: `Bearer ${bearerToken}` }
    })
    if (!response.ok) {
        generateError(response)
    }
    return Promise.resolve()
}

export const useDebounce = <T>(value: T, delay: number = 500) => {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value)
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler)
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue
}