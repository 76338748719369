import { ActionType, createAction } from "typesafe-actions"

export const PURCHASE_GATEWAY_LICENSE = 'purchase_gateway_license'
export const PURCHASE_GATEWAY_LICENSE_FAILED = 'purchase_gateway_license_failed'
export const GATEWAY_LICENSE_PURCHASED = 'gateway_license_purchased'
export const CLOSE_MODAL_GATEWAY_LICENSE = 'close_modal_gateway_license'

export const purchaseGatewayLicense = createAction(PURCHASE_GATEWAY_LICENSE, (action) => {
    return (productId: string, description: string, panelSerialNumber: string, peripheralType: string, peripheralMacAddress: string, invoiceDescription: string,taxStampDescription: string, subType: string) =>
        action({ productId, description, invoiceDescription,taxStampDescription, addictionalData: { panelSerialNumber, peripheralType, peripheralMacAddress, subType } })
})

export const purchaseGatewayLicenseFailed = createAction(PURCHASE_GATEWAY_LICENSE_FAILED)

export const gatewayLicensePurchased = createAction(GATEWAY_LICENSE_PURCHASED, (action) => {
    return (url: string) => action({ url })
})

export const closeModalGatewayLicense = createAction(CLOSE_MODAL_GATEWAY_LICENSE)

export type GatewayLicensesActions = ActionType<typeof purchaseGatewayLicense | typeof purchaseGatewayLicenseFailed |
    typeof gatewayLicensePurchased>