import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import { useRequest } from '../hooks/request'
import { useTranslation } from 'react-i18next'

export interface File {
    id: string
    recommendedDescription: string
    type: string
    subType: string
    contentType: string
    lastModified: string //ISO-8601
    bytes: number
    url: string
    version?: string
}

export type FileListItem = Omit<File, 'url'>
export type FileList = FileListItem[]

export type FilesSubtype = {
    key: string,
    files: FileList
}

const groupDownloads = (files: FileList) => {
    const groups: { [key: string]: FilesSubtype } = {}
    files && files.forEach((file) => {
        const { subType } = file
        const acc = groups[subType]?.files || []
        groups[subType] = { key: subType, files: [...acc, file] }
    })
    const values = Object.values(groups)
    return values.sort((a, b) => a.key.localeCompare(b.key))
}

export const useDownloads = (params: {
    type: string,
    subType?: string,
    onLoadFileUrl?: (url: string) => void,
    listStaleTime?: number,
    path?: string
    auth?: boolean
}) => {

    const { path = '/downloads', type, subType, onLoadFileUrl, listStaleTime = 15 * (60 * 1000), auth = true } = params

    const { i18n } = useTranslation()

    const commonQuery = {
        preferredLanguages: i18n.language.substring(0, 2)
    }

    const itemsReqParams = {
        path,
        auth,
        query: {
            ...commonQuery,
            type,
            subType
        }
    }
    const [items, setItems] = useState<FilesSubtype[]>([])
    const { request: itemsRequest } = useRequest<FileList>(itemsReqParams)
    const { isFetching: loadingItems, data: fetchedItems } = useQuery(
        [itemsReqParams],
        itemsRequest,
        {
            staleTime: listStaleTime,
            refetchOnWindowFocus: false
        }
    )
    useEffect(() => {
        setItems(groupDownloads(fetchedItems?.body || []))
    }, [setItems, fetchedItems])

    const [id, setId] = useState<string>('')
    const urlReqParams = {
        path,
        auth,
        query: {
            ...commonQuery,
            id
        }
    }
    const { request: urlRequest } = useRequest<File[]>(urlReqParams)
    const { isFetching: loadingUrl, data: singleItem } = useQuery(
        [urlReqParams],
        urlRequest,
        {
            enabled: !!id,
            refetchOnWindowFocus: false
        },
    )
    useEffect(() => {
        if (onLoadFileUrl && singleItem && singleItem?.body.length) {
            onLoadFileUrl(singleItem.body[0].url)
            setId('')
        }
    }, [onLoadFileUrl, singleItem])

    return {
        items,
        loadingItems,
        selectFile: setId,
        loadingUrl
    }
}