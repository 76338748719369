import { createSelector } from 'reselect'
import { ModalMode } from "../types"
import { License } from './licenseActions'
import { State } from '../configureStore'
import _ from 'lodash'

export const selectedLicenseSelector = createSelector(
    (state: State) => state.selectedLicense, function (license: License): License & { mode: ModalMode } {
        if (license.alreadyRegistered) {
            return {
                mode: 'edit',
                ...license
            }
        } else if (!license.voices) {
            return {
                mode: 'notvisible',
                ...license
            }
        } else {
            return {
                mode: 'add',
                ...license
            }
        }
    }
)

export const getPurchasedVoices = createSelector(
    [(state: State) => state.licenses.value], (licenses) => {
        return _.flatMap(licenses, license => license.voices)
    }
)

export const getPurchasableVoices = createSelector(
    [getPurchasedVoices, (state: State) => state.licenses.availableVoices],
    (purchasedVoices, allVoices) => {
        const filteredVoices = allVoices.filter(v => !purchasedVoices.includes(v))
        return filteredVoices.sort()
    }
)