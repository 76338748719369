import React from 'react'
import { useTranslation } from 'react-i18next'
import IconTitleSubtitle from './IconTitleSubtitle'
import { ReactComponent as FinishLineSvg } from '../../images/finish-line.svg'
import { ReactComponent as UfoTakenSvg } from '../../images/ufo-taken.svg'
import { Button } from '../../common/Button'
import Card from '../../common/Card/Card'

export default function Confirmation(props: {
    existingCompany?: { _id: string, businessName: string, vatNumber: string }
    onHandleClick: () => void
}) {
    const { t } = useTranslation()

    const { existingCompany, onHandleClick } = props

    return (<div data-testid="confirm" className="flex items-center justify-center h-full">
        <div className="flex justify-center">
            {existingCompany?._id ?
                <Card className="shadow-none py-4">
                    <Card.Body full>
                        <div>
                            <IconTitleSubtitle
                                icon={<UfoTakenSvg className="h-[250px]" />}
                                title={existingCompany.businessName + ' - ' + existingCompany.vatNumber}
                                subtitle={t('confirmationExistingCompanyTitle')} />
                        </div>
                        <p className="text-center break-word max-w-md mx-auto my-2">
                            <span className="break-normal text-gray-500">
                                {t('confirmationExistingCompanySubtitle')}
                            </span>
                        </p>
                        <p className="text-center break-word max-w-md mx-auto my-2">
                            <span className="break-normal text-gray-500">
                                {t('existingCompanyDisclaimerPanels')}
                            </span>
                        </p>
                        <div className="flex justify-center">
                            <Button className="" type="button" text={t('sendRequestToJoin')} color="primary" size="lg" onClick={onHandleClick} />
                        </div>
                    </Card.Body>
                </Card> :
                <IconTitleSubtitle
                    icon={<FinishLineSvg className="h-[250px]" />}
                    title={t('confirmationCreatedCompanyTitle')}
                    data-testid='confirmation_created_company'
                    subtitle={t('confirmationCreatedCompanySubtitle')} />
            }
        </div>
    </div>)
}