import { createReducer } from "typesafe-actions"
import * as Actions from './voicesActions'
import { saveVoices } from './licenseActions'

export interface VoicesState {
    openModal: boolean,
    pending: boolean
}

export const voicesInitialState: VoicesState = {
    openModal: false,
    pending: false
}

export const voicesReducer = createReducer(voicesInitialState)
    .handleAction(Actions.openModalVoice, (state, action) => ({
        ...state,
        openModal: true,
        pending: true
    }))
    .handleAction(saveVoices, (state, action) => ({
        ...state,
        openModal: true,
        pending: false
    }))
    .handleAction(Actions.closeModalVoice, (state, action) => ({
        ...state,
        openModal: false,
        pending: false
    }))