import React from 'react'
import { DownloadList } from '../../../downloads/DownloadsList'

export function Products() {
    return <DownloadList
        type='products'
        titleLabel='downloads'
        requireAuth={false}
    />
}

export default Products