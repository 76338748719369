import { createReducer } from 'typesafe-actions'
import { discardNotify, notify } from './actions'
import { logoutCompleted } from '../logout/actions'
import _ from 'lodash'

export type Notification = {
    type: 'error' | 'info',
    message: string,
    duration: number,
    errorCode?: string
}

const initialState: Array<Notification> = []

export const notificationReducer = createReducer(initialState)
    .handleAction(notify, (state, action) => {
        const newState = _.cloneDeep(state)
        newState.push(action.payload)
        return newState
    })
    .handleAction(discardNotify, (state, action) => {
        const newState = _.cloneDeep(state)
        newState.shift()
        return newState
    })
    .handleAction(logoutCompleted, (state, action) => initialState)

