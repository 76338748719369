import React from 'react'

export function EditField(props: {
    label: string,
    value: string,
    rows?: number,
    edit?: boolean,
    invalid?: boolean,
    invalidLabel?: string,
    onChange?: (value: string) => void
}) {
    const { label, value, rows = 1, edit, invalid, invalidLabel, onChange } = props
    return <div className="py-3 flex-col">
        <p className="text-sm font-medium text-gray-500 truncate">{label}</p>
        <div className="flex-col">
            <div className="flex justify-between mt-2">
                {edit ?
                    <div className="w-full h-auto">
                        <div className="relative w-full h-auto">
                            <textarea
                                data-testid={`panel-edit-${label}`}
                                rows={rows}
                                className="w-full shadow-sm focus:ring-gray-500 focus:border-gray-500 border-gray-300 rounded-md"
                                value={value}
                                onChange={(e) => {
                                    if (onChange)
                                        onChange(e.target.value)
                                }}
                            />
                        </div>
                    </div>
                    :
                    <p className="text-sm text-gray-900">
                        <span className="overflow-ellipsis">{value}</span>
                    </p>
                }
            </div>
            {invalid && invalidLabel ? <p className="mt-2 text-sm text-red-600" id="email-error">
                {invalidLabel}
            </p> : null}
        </div>
    </div>
}