import { createAction, ActionType } from 'typesafe-actions'
import { UserGroups } from '../auth/amplify'

export const LOGIN = 'login'
export const LOGIN_COMPLETED = 'login_completed'
export const LOGIN_FAILED = 'login_failed'
export const CHECK_LOGIN = 'check_login'
export const CHECK_LOGIN_COMPLETE = 'check_login_complete'
export const USER_GROUPS = 'user_groups'
export const USER_GROUPS_REMOVE = 'user_groups_remove'

interface UserCredentials {
    username: string
    password: string
}

export const login = createAction(LOGIN, (action) => {
    return (credentials: UserCredentials) => action(credentials)
})

export const loginFailed = createAction(LOGIN_FAILED, (action) => {
    return (error: string) => action({ error })
})

export const loginCompleted = createAction(LOGIN_COMPLETED)

export const checkLogin = createAction(CHECK_LOGIN)

export const checkLoginComplete = createAction(CHECK_LOGIN_COMPLETE)

export const userGroups = createAction(USER_GROUPS, (action) => {
    return (groups: UserGroups[]) => action(groups)
})

export const userGroupsRemove = createAction(USER_GROUPS_REMOVE)

export type LoginActions = ActionType<typeof login | typeof  loginFailed | typeof loginCompleted | typeof checkLogin | typeof checkLoginComplete | typeof userGroups | typeof userGroupsRemove>