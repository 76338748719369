import { useMemo, useState } from "react"
import Fuse from 'fuse.js'

const MIN_CHAR_LENGTH = 3

const useShuttleSearch = (items: any[], searchKeys?: string[]) => {
    const [query, setQuery] = useState<string>('')
    const fuse = useMemo(() => {
        return new Fuse(items, {
            threshold: 0.3,
            minMatchCharLength: MIN_CHAR_LENGTH,
            keys: searchKeys,
            ignoreLocation: true
        })
    }, [items, searchKeys])

    const results = useMemo(() => {
        if (query.length >= MIN_CHAR_LENGTH) {
            return fuse.search(query).map(i => i.item)
        }
        return items
    }, [fuse, query, items])

    return {
        results,
        setQuery,
    } as const
}

export default useShuttleSearch