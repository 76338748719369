import { createReducer } from 'typesafe-actions'
import { skipCompanyStep, FirstStepsActions } from './actions'

export interface FirstStepsState {
    skipCompanyStep: boolean
}

export const firstStepsInitialState = {
    skipCompanyStep: false
}

export const firstStepsReducer = createReducer<FirstStepsState, FirstStepsActions>(firstStepsInitialState)
    .handleAction(skipCompanyStep, (state) => ({ ...state, skipCompanyStep: true }))
