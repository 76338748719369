import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLoading } from '../hooks/loading'
import { useHistory } from "react-router-dom";
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ForgotPasswordForm } from '../login/ForgotPassword'
import Input from '../common/Input/Input'
import { Button } from '../common/Button'
import { notify } from '../notification/actions'
import { useAuth } from '../auth/amplify'

type FormData = {
    username: string;
}

export function ForgotPassword() {
    const { t } = useTranslation()
    const history = useHistory();
    const { register, handleSubmit } = useForm<FormData>()
    const dispatch = useDispatch()
    const { forgotPassword, setUsername, username } = useAuth()
    const { loading, execute } = useLoading()
    const [step, setStep] = useState<'ask-username' | 'new-password'>('ask-username')

    const onSubmit = handleSubmit(async () => {
        try {
            await execute(forgotPassword)
            setStep('new-password')
        } catch (e: any) {
            dispatch(notify('error', 'usernameNotFound'))
        }
    })

    return (
        <div>
            <h2 className="mt-6 text-center text-3xl text-gray-900">
                {t('forgotPasswordTitle')}
            </h2>
            <p className="text-center text-gray-500 mt-2">{t('forgotPasswordDesc')}</p>
            {step === 'ask-username' && (
                <form className="mt-8" autoComplete="off" onSubmit={onSubmit}>
                    <Input
                        label={t('email')}
                        name='username'
                        type="email"
                        required
                        ref={register()}
                        value={username}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(e.target.value)
                        }}
                    />
                    <div className="mt-4">
                        <Button pending={loading} disabled={!username} color="primary" full type="submit" text={t('nextStep')} />
                    </div>
                </form>
            )}

            {step === 'new-password' && (
                <ForgotPasswordForm
                    username={username}
                    onResetComplete={() => {
                        history.push('/login')
                    }}
                />
            )}

            <div className="relative my-4">
                <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">{t('or')}</span>
                </div>
            </div>
            <Button type="button" text={t('goToLogin')} onClick={() => history.push('/login')} full />
        </div>
    )
}

export default ForgotPassword