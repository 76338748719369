import React from 'react'
import { useTranslation } from "react-i18next"
import { LockClosedIcon, TrashIcon, UserAddIcon, UserRemoveIcon } from '@heroicons/react/outline'
import { ItemProps, MenuDropDown } from "../common/MenuDropDown"


export const CompanyMembersMenuDropDown = (props: {
    canEdit: boolean
    isActive: boolean
    isAdmin: boolean
    onRemoveClick: () => void
    onRoleChangeClick: () => void
    onAclClick: () => void
}) => {
    const { t } = useTranslation()
    const {
        canEdit, isActive, isAdmin,
        onRemoveClick, onRoleChangeClick, onAclClick
    } = props

    const actionRemove: ItemProps = {
        label: isActive ? t('remove') : t('delete'),
        onClick: onRemoveClick,
        icon: TrashIcon,
        disabled: false
    }

    const actionRoleChange: ItemProps = {
        label: isAdmin ? t('removeCompanyAdmin') : t('assignCompanyAdmin'),
        onClick: onRoleChangeClick,
        icon: isAdmin ? UserRemoveIcon : UserAddIcon,
        disabled: false
    }

    const actionAcl: ItemProps = {
        label: t('handleACL'),
        onClick: onAclClick,
        icon: LockClosedIcon,
        disabled: false
    }

    let menu: ItemProps[] = []
    if (!isAdmin)
        menu = [actionRemove, ...menu]
    if (!isAdmin && isActive)
        menu = [actionAcl, ...menu]
    if (isActive)
        menu = [actionRoleChange, ...menu]

    return canEdit ? <MenuDropDown items={menu} /> : null
}