import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserCompanyProfile } from '../selectors'
import { Divider } from '../../common/Divider'
import { UserProfileForm } from './UserProfileForm'
import { UserProfileFormPrivacy } from './UserProfileFormPrivacy'
import ToConfirmFields from './ToConfirmFields'
import { UserProfileChangePassword } from './UserProfileChangePassword'

type Props = UserCompanyProfile

export function UserProfile(props: Props) {
    const { t } = useTranslation()
    return (
        <>
            <div className="mt-10 sm:mt-0 md:grid md:grid-cols-4 md:gap-6">
                {/* User profile data */}
                <div className="md:col-span-1">
                    <div className="px-1 md:px-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profileSectionInfoTitle')}</h3>
                        <p className="mt-1 text-sm text-gray-600">{t('profileSectionsInfoSubtitle')}</p>
                    </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-3">
                    <UserProfileForm {...props} />
                </div>
            </div>
            <Divider/>
            <ToConfirmFields email={props.email} phoneNumber={props.phoneNumber}/>
            <Divider/>
            <UserProfileChangePassword/>
            <Divider/>
            <div className="mt-10 sm:mt-0 md:grid md:grid-cols-4 md:gap-6">
                {/* Privacy options */}
                <div className="md:col-span-1">
                    <div className="px-1 md:px-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profileUserPrivacyTitle')}</h3>
                    </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-3">
                    <UserProfileFormPrivacy {...props} />
                </div>
            </div>
        </>
    )
}
