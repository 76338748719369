import { Connection, Model, Panel } from './actions'
import { compare, coerce } from 'semver'

export type ConnectionType = 'ETHERNET' | 'MOBILE_2G' | 'MOBILE_3G' | 'MOBILE_4G' | 'Notavailable'

export function mapPanelConnectionStatus(connection?: Connection) {
    return connection ? connection.status === 'READY' ? 'Online' : 'Offline' : 'Offline'
}
export function mapPanelConnectionType(connection?: Connection): ConnectionType {
    return connection ? (connection.type || 'Notavailable') : 'Notavailable'
}

const updatedVersion = '1.74.20'
const suggestedToUpdateVersion = '1.70.27'
const laresWlsModel = 'Centrale Ksenia wls'

function isALares4(model?: Model) {
    return !!model && model !== laresWlsModel
}

export function isALaresWls(model?: Model) {
    return !!model && model === laresWlsModel
}

const validFwVersion = (fw?: string) => fw && fw !== 'NA'

export function compareFwVersions(aFw: string, bFw: string): number {
    if (!validFwVersion(aFw) && !validFwVersion(aFw)) {
        return 0
    }
    if (!validFwVersion(aFw) ) {
        return -1
    }
    if (!validFwVersion(bFw) ) {
        return 1
    }
    return compare(coerce(aFw), coerce(bFw))
}

export function getAge(panel: Pick<Panel, 'firmwareVersion' | 'model'>) {
    const { firmwareVersion, model } = panel as { firmwareVersion: string, model: Model }
    if (!validFwVersion(firmwareVersion) || !isALares4(model))
        return null
    if (compareFwVersions(firmwareVersion, updatedVersion) >= 0)
        return 'updated'
    if (compareFwVersions(firmwareVersion, suggestedToUpdateVersion) > 0)
        return 'old'
    return 'veryOld'
}
