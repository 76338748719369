import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Spinner } from '../common/Spinner'
import { CompanyRole, getCompanyRole } from '../profile/selectors'

export function Loading(props: PropsWithChildren<{}>) {
    const companyRole = useSelector(getCompanyRole)
    const userIsLoggedButTypeUnknown = () => companyRole === CompanyRole.Unknown

    return userIsLoggedButTypeUnknown() ? <Spinner /> : <>{props.children}</>
}

export default Loading