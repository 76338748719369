import { createSelector } from 'reselect'
import { State } from '../configureStore'
import _ from 'lodash'
import { getUserCompanyProfile } from '../profile/selectors'
import { RankWithPrize } from './actions'
import { rankPrizes } from './utils'
const getRanking = (state: State) => state.loyaltyProgram.ranking

const rankingMappingByCertification: { [key: string]: Array<string> } = {
    '0': ['overall', 'selected', 'silver', 'gold', 'specialist'],
    '1': ['overall', 'selected'],
    '2': ['overall', 'silver'],
    '3': ['overall', 'gold'],
    '4': ['overall', 'specialist']
}

export const isLoyaltyProgramOpened = createSelector(
    (state: State) => state.loyaltyProgram, loyaltyProgram => loyaltyProgram.open)

export const isLoyaltyProgramLoading = createSelector(
    (state: State) => state.loyaltyProgram, loyaltyProgram => loyaltyProgram.loading)

export const getInstallerCertification = createSelector(getUserCompanyProfile, (profile) => {
    let level = 0
    if (profile && profile.certificationLevel)
        level = profile.certificationLevel
    return level
})

export const loyaltyProgramRanking = createSelector(
    [getRanking, getInstallerCertification],
    (ranking, certificationLevel) => {
        return _.pick(ranking, rankingMappingByCertification[certificationLevel.toString()])
    })

export const loyaltyProgramRankingWithPrizes = createSelector(
    [getRanking],
    (rankings) => {
        const withPrizes: {[key: string] : RankWithPrize[]} = {}
        Object.entries(rankings).forEach(([level, items]) => {
            withPrizes[level] = items.map((item) => {
                const prize = rankPrizes[level].find((prize) => prize.position === item.position)
                return {...item, ...prize}
            })
        });
        return withPrizes
    }
)