
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon, QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { getFaqExternalLink } from '../../../config'

const MenuBticinoWorld: React.VFC = () => {
    const { t } = useTranslation()

    return (
        <Disclosure defaultOpen>
            {({ open }) => (
                <>
                    <Disclosure.Button className="flex justify-between w-full py-3 pb-0 px-2">
                        <span className="uppercase text-gray-500 text-xs">{t('usefulLinks')}</span>
                        <ChevronUpIcon
                            className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-brand-300`}
                        />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pl-0 pt-4 pb-2 text-sm text-gray-100">
                        <ul className="space-y-2">
                            <li>
                                <a
                                    className="w-full flex items-center pl-2 pr-1 py-2 text-left text-sm border-l-[6px] border-brand-600 hover:bg-brand-600"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={getFaqExternalLink()}
                                >
                                    <QuestionMarkCircleIcon className="text-white mr-3 flex-shrink-0 h-6 w-6" />
                                    <span className="flex-1">{t('faq')}</span>
                                </a>
                            </li>
                        </ul>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    )
}

export default MenuBticinoWorld