import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, ConnectedProps } from 'react-redux'
import { Dispatch } from 'redux'
import { useHistory } from 'react-router-dom'
import { State } from '../../configureStore'
import { StepsProgressBar } from '../../common/StepsProgressBar'
import { FirstStepsMarketing } from './FirstStepsMarketing'
import { FirstStepsPhone } from './FirstStepsPhone'
import { FirstStepWizard, firstStepWizardStatus, hasFirstStepsWizardActive } from './selectors'
import { getUserPhoneNumber } from '../selectors'
import FirstStepsPersonalData from './FirstStepsPersonalData'
import FirstStepsCompany from './FirstStepsCompany'

type FirstStepsProps = PropsFromRedux & {}

const mapStepsToArray = (wizardStep: FirstStepWizard) => (Object.keys(wizardStep) as Array<keyof typeof wizardStep>).map(k => ({ name: k, status: wizardStep[k] }))

export const FirstSteps: React.FC<FirstStepsProps> = (props) => {
    const { t } = useTranslation()
    const { wizardStep, hasFirstWizardSteps, currentPhone } = props
    const history = useHistory()

    useEffect(() => {
        if (!hasFirstWizardSteps) {
            history.push('/')
        }
    }, [hasFirstWizardSteps, history])

    return (
        <div aria-label="first-steps" className="m-auto max-w-4xl relative">
            <div className="text-center">
                <p className='text-2xl leading-relaxed'>{t('firstStepTitle')}</p>
                <p className="text-brand">{t('firstStepSubTitle')}</p>
            </div>
            <div className='border-b border-gray-100'>
                <StepsProgressBar data={mapStepsToArray(wizardStep)} />
            </div>
            <div className="pr-4 pt-8">
                {wizardStep?.personalData === 'current' && <FirstStepsPersonalData />}
                {wizardStep?.marketing === 'current' && <FirstStepsMarketing />}
                {wizardStep?.phone === 'current' && <FirstStepsPhone currentPhone={currentPhone} />}
                {wizardStep?.company === 'current' && <FirstStepsCompany />}
            </div>
        </div>
    )
}

export function mapStateToProps(state: State) {
    return {
        currentPhone: getUserPhoneNumber(state),
        wizardStep: firstStepWizardStatus(state),
        hasFirstWizardSteps: hasFirstStepsWizardActive(state)
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(FirstSteps)