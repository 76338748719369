import { createSelector } from 'reselect'
import { Prices } from '../billing/actions'
import { State } from '../configureStore'

const findLoquendo = (billing: Prices) => billing.prices.find((p) => p.type === 'loquendo')

export const getLoquendoVoicePrice = createSelector((state: State) => state.billing,
    billing => {
        let price = findLoquendo(billing)
        return price ? parseFloat(price.subTotalAmount) / 100 : undefined
    }
)

export const getLoquendoProductId = createSelector((state: State) => state.billing,
    billing => {
        return (findLoquendo(billing) || {}).productId
    })