import { ActionType, createAction } from 'typesafe-actions'
import { notify } from '../notification/actions'
import { BillingForm } from './types'

export const OPEN_BILLING_PROFILE = 'open_billing_profile'
export const READ_BILLING_PROFILE_FAILED = 'read_billing_profile_failed'
export const READ_INVOICES_FAILED = 'read_invoices_failed'
export const RELOAD_INVOICES = 'reload_invoices'
export const SAVE_BILLING_PROFILE = 'save_billing_profile'
export const SAVE_INVOICES = 'save_invoices'
export const UPDATE_BILLING_PROFILE = 'update_billing_profile'
export const UPDATE_BILLING_PROFILE_FAILED = 'update_billing_profile-failed'
export const CREATE_BILLING_PROFILE = 'create_billing_profile'
export const CREATE_BILLING_PROFILE_COMPLETED = 'create_billing_profile_completed'
export const CREATE_BILLING_PROFILE_FAILED = 'create_billing_profile_failed'
export const READ_ALL_PRICES = 'read_all_prices'
export const SAVE_PRICES = 'save_prices'
export const OPEN_INVOICES = 'open_invoices'

export type Price = {
    type: 'loquendo' | 'backup' | 'gateway-http'
    subType: string,
    id: string,
    productId: string,
    subTotalAmount: string,
    applicableForPeripherals?: Array<string>
}

export type Tax = {
    id: string,
    percentage: string,
}

export type TaxStamp = {
    type: 'tax-stamp',
    'id': string,
    'productId': string,
    'subTotalAmount': string
}

export type Prices = {
    tax: Tax
    taxStamp?: TaxStamp
    prices: Array<Price>
}


export const createBillingProfile = createAction(CREATE_BILLING_PROFILE, (action) => {
    return (profile: BillingForm) => {
        return action({
            ...profile
        })
    }
})

export const createBillingProfileCompleted = createAction(CREATE_BILLING_PROFILE_COMPLETED, (action) => {
    return (billingId: string) => action({ billingId })
})

export const createBillingProfileFailed = createAction(CREATE_BILLING_PROFILE_FAILED, (action) => {
    return (error: string) => action({ error })
})

export const savePrices = createAction(SAVE_PRICES, (action) => {
    return (prices: Prices) => action({ ...prices })
})

export const openBillingProfile = createAction(OPEN_BILLING_PROFILE)

export const readBillingProfileFailed = createAction(READ_BILLING_PROFILE_FAILED)
export const readInvoicesFailed = createAction(READ_INVOICES_FAILED)
export const reloadInvoices = createAction(RELOAD_INVOICES)

export const saveBillingProfile = createAction(SAVE_BILLING_PROFILE, (action) => {
    return (profile?: object) => action({ profile })
})

export const updateBillingProfile = createAction(UPDATE_BILLING_PROFILE, (action) => {
    return (profile: BillingForm) => action(profile)
})

export const updateBillingProfileFailed = createAction(UPDATE_BILLING_PROFILE_FAILED)

export const openInvoices = createAction(OPEN_INVOICES)

export const saveInvoices = createAction(SAVE_INVOICES, (action) => {
    return (invoices: Array<object>) => action({ invoices })
})

export const readAllPrices = createAction(READ_ALL_PRICES)

export type CreateOrUpdateBillingActions = ActionType<typeof createBillingProfile | typeof updateBillingProfile>
export type BillingActions = ActionType<typeof createBillingProfile |
    typeof createBillingProfileCompleted | typeof createBillingProfileFailed |
    typeof openBillingProfile | typeof readBillingProfileFailed | typeof readInvoicesFailed |
    typeof reloadInvoices | typeof updateBillingProfile | typeof updateBillingProfileFailed |
    typeof savePrices | typeof saveBillingProfile | typeof saveInvoices | typeof notify | typeof readAllPrices
    | typeof openInvoices>
