import { useTranslation } from 'react-i18next'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { checkLogin, loginCompleted } from './actions'
import { loginSelector } from './selector'
import { defaultLoginState, LoginState } from './reducer'
import Notifications from '../notification/Notifications'
import { NavLink } from 'react-router-dom'
import { Button } from '../common/Button'
import { useLoading } from '../hooks/loading'
import PinCodeLayer from '../common/PinCodeLayer'
import { ForgotPasswordForm } from './ForgotPassword'
import { notify } from '../notification/actions'
import { Spinner } from '../common/Spinner'
import { useAuth } from '../auth/amplify'

export function LoginForm(props: {
    onLoginCompleted: () => void
}) {

    const { t } = useTranslation()
    const {
        userStatus,
        userShouldConfirmCredentials,
        username,
        password,
        setUsername,
        setPassword,
        signIn,
        confirmSignUp,
        resendCode,
        cancelForgotPassword,
        passwordResetCompleted,
        forgotPassword
    } = useAuth()

    const dispatch = useDispatch()
    const { loading, execute } = useLoading()
    const { onLoginCompleted } = props


    useEffect(() => {
        if (userStatus === "loggedIn") {
            onLoginCompleted()
        }
    }, [userStatus, onLoginCompleted, forgotPassword])

    async function tryTo(fn: () => Promise<void>): Promise<void> {
        try {
            await execute(fn)
        } catch (error: any) {
            dispatch(notify('error', error.code))
        }
    }

    const onPinFilled = useCallback(async (code: string) => {
        try {
            await execute(() => confirmSignUp(code))
        } catch (error: any) {
            dispatch(notify('error', error.code))
        }

    }, [confirmSignUp, dispatch, execute])

    useEffect(() => {
        if (userStatus === 'passwordResetRequired') {
            setPassword('')
        }
    }, [userStatus, setPassword])

    return (
        <>
            {userStatus === 'passwordResetRequired' ?
                <>
                    <div className="relative text-center text-gray-500 border-gray-200 border-b-2 pb-8 px-4 mt-4">
                        <p className="text-xl text-brand-500 mb-2">{t('resetPasswordAlertTitle')}</p>
                        <p>{t('resetPasswordAlertContent1')} <span className="text-brand-500">{t('secureWeb')}</span> {t('resetPasswordAlertContent2')}.</p>
                        <p className="font-bold mt-2">{t('resetPasswordAlertContent3')}</p>
                    </div>
                    <div className="relative flex justify-center">
                        <ForgotPasswordForm
                            onResetComplete={passwordResetCompleted}
                            username={username}
                        />
                    </div>
                    <div className="relative my-4">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">{t('or')}</span>
                        </div>
                    </div>
                    <Button type="button" text={t('goToLogin')} onClick={() => cancelForgotPassword()} full />
                </>
                :
                <div>
                    <h2 className="mt-6 text-center text-3xl text-gray-900">{t('signin')}</h2>
                    <div className="relative">
                        <PinCodeLayer
                            showTransition={userShouldConfirmCredentials()}
                            help={t('codeViaEmailVerificationHelp')}
                            onFilled={onPinFilled}
                            onResend={resendCode}
                        />
                        <form className="mt-8 space-y-6" action="#" method="POST" onSubmit={async (e) => {
                            e.preventDefault()
                            await tryTo(signIn)
                        }}>
                            <div className="rounded-md shadow-sm -space-y-px">
                                <div>
                                    <label htmlFor="email-address" className="sr-only">
                                        {t('email')}
                                    </label>
                                    <input
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        autoFocus
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:z-10 sm:text-sm"
                                        placeholder={t('emailPlaceholder')}
                                        value={username}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setUsername(e.target.value)
                                        }}
                                    />
                                </div>
                                <div className="relative">
                                    <label htmlFor="password" className="sr-only">
                                        {t('password')}
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-brand-500 focus:border-brand-500 focus:z-10 sm:text-sm"
                                        placeholder={t('passwordPlaceholder')}
                                        value={password}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            setPassword(e.target.value)
                                        }}
                                    />
                                    {!userShouldConfirmCredentials() ? <NavLink
                                        className="absolute right-2 top-1/2 uppercase text-xs text-brand-500 transform -translate-y-1/2 z-20"
                                        to="/forgot-password"
                                    >
                                        {`${t('forgotPassword')}?`}
                                    </NavLink> : null}
                                </div>
                            </div>
                            <Button pending={loading} text={t('login')} color="primary" full type="submit" />
                        </form>
                    </div>
                    <div className="relative my-4">
                        <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-300" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">{t('or')}</span>
                        </div>
                    </div>
                    <Button as={NavLink} text={t('notRegistered')} full to={'/register'} />
                </div>
            }
        </>
    )
}

const Login: React.FC = () => {
    const dispatch = useDispatch()
    const loginState: LoginState = useSelector(loginSelector) || defaultLoginState

    useEffect(() => {
        dispatch(checkLogin())
    }, [dispatch])
    return (
        <div>
            <Notifications />
            {loginState.checking ? <Spinner containerClass='mt-4' /> :
                <LoginForm
                    onLoginCompleted={() => {
                        dispatch(loginCompleted())
                    }}
                />
            }
        </div>
    )
}

export default Login