import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Toggle } from '../common/Toggle'
import { Controller, useForm, useWatch } from 'react-hook-form'
import Shuttle from '../common/Shuttle/Shuttle'
import { usePanelAcl } from '../hooks/acl/usePanelAcl'
import { usePanels } from '../hooks/usePanels'
import { Button, Card } from '../common'

type FormData = {
    aclEnabled: boolean
    panels: string[]
}

export function CompanyMembersACLManagement(props: {
    aclEnabled: boolean
    memberId: string
    onSubmit: (updateArgs: { aclEnabled: boolean, panels: string[] }) => void
    onClose: () => void
}) {
    const { t } = useTranslation()
    const { onClose, onSubmit, memberId } = props
    const { userPanels, loading: aclLoading } = usePanelAcl(memberId)
    const { panels, loading: panelsLoading } = usePanels()
    const defaultValues = {
        aclEnabled: props.aclEnabled || false,
        panels: userPanels
    }
    const { handleSubmit, control } = useForm<FormData>({ defaultValues })
    const enabled = useWatch({ name: 'aclEnabled', control })
    const pending = aclLoading || panelsLoading
    useEffect(() => {
        control.setValue('panels', userPanels)
    }, [control, userPanels])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <Card pending={pending}>
            <Card.Body>
                <div className="flex items-center justify-center py-4">
                    <span className="flex-grow flex flex-col">
                        <span className="text-sm font-medium text-gray-900">{t('aclEnabled')}</span>
                    </span>
                    <Toggle name="aclEnabled" control={control} />
                </div>
                <Controller
                    name="panels"
                    control={control}
                    render={({ value, onChange }) => <Shuttle
                        disabled={!enabled}
                        onChange={onChange}
                        itemPropsMap={{ id: "_id", title: "name", subtitle: "serialNumber" }}
                        items={panels}
                        selected={value}
                    />}
                />
            </Card.Body>
            <Card.Footer>
                <div className="flex justify-between">
                    <Button text={t('close')} onClick={onClose} color="white" type="button" />
                    <Button
                        color="primary"
                        text={t('update')}
                        type="submit"
                    />
                </div>
            </Card.Footer>
        </Card>
    </form>
}
