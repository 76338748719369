import { Epic } from 'redux-observable'
import { ActionTypes, State } from '../configureStore'
import { from, Observable, of } from 'rxjs'
import { catchError, filter, mergeMap } from 'rxjs/operators'
import { notify } from '../notification/actions'
import { AmplifySession } from '../panels/epics'
import { purchaseVoices, purchaseVoicesFailed, voicesPurchased } from './voicesActions'
import { AjaxResponse } from 'rxjs/ajax'
import { isActionOf } from 'typesafe-actions'
import { getLoquendoProductId } from './licenseSelectors'
import { getBillingId } from '../profile/selectors'

const STORE_URI = '/store/invoices'

// interface CreateInvoiceRequest {
//     billingId: string
//     items: Array<{
//         productId: string
//         quantity: number
//         description: string
//         metadata: { voices?: Array<string> }
//     }>
// }
//
// interface Invoice {
//     url: string
// }

export const buyVoices$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post }) =>
        action$.pipe(
            filter(isActionOf(purchaseVoices)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const body = {
                                billingId: getBillingId(state$.value),
                                invoiceDescription: action.payload.invoiceDescription,
                                taxStampDescription: action.payload.taxStampDescription,
                                items: [{
                                    productId: getLoquendoProductId(state$.value),
                                    quantity: action.payload.voices.length,
                                    description: action.payload.description,
                                    metadata: { voices: action.payload.voices }
                                }]
                            }
                            const headers = {
                                authorization: `Bearer ${res.idToken.jwtToken}`,
                                // eslint-disable-next-line
                                ['Content-Type']: 'application/json'
                            }
                            return post(STORE_URI, body, headers)
                                .pipe(
                                    mergeMap(response => of(voicesPurchased(response.response.url), notify('info', 'purchaseVoicesSuccess'))),
                                    catchError(error => of(purchaseVoicesFailed(), notify('error', 'purchaseVoicesFailed', 3000, error.status)))
                                )
                        }))
            }))