import { Epic } from 'redux-observable'
import { ActionTypes, State } from '../configureStore'
import { loginCompleted } from '../login/actions'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import { from, Observable, of, zip } from 'rxjs'
import {
    acceptCompanyInvite,
    acceptRejectCompanyInviteError,
    inviteCompanyMember,
    inviteCompanyMemberCompleted,
    inviteCompanyMemberError,
    loadCompanyProfile,
    loadCompanyProfileCompleted,
    loadCompanyProfileFailed,
    loadProfile,
    loadProfileCompleted,
    loadProfileFailed,
    rejectCompanyInvite,
    reloadProfile,
    removeCompanyMember,
    removeCompanyMemberCompleted,
    removeCompanyMemberError,
    startUploadProfileImage,
    updateCompanyMember,
    updateCompanyMemberCompleted,
    updateCompanyMemberError,
    uploadProfileImageComplete
} from './actions'

import { notify } from '../notification/actions'
import { AmplifySession } from '../panels/epics'
import { AjaxResponse } from 'rxjs/ajax'
import { Company, UserCompanyProfile } from './selectors'

const USERPROFILE_URI = 'users/me'
const USERLOGO_URI = 'users/logo'
const COMPANYPROFILE_URI_BASE = 'companies/'

export const loadProfile$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<UserCompanyProfile>
    }> = (action$, state$, { currentSession, fetchJson }) =>
        action$.pipe(
            filter(isActionOf([loginCompleted, loadProfile, reloadProfile])),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(mergeMap(res =>
                        fetchJson(USERPROFILE_URI, { authorization: `Bearer ${res.idToken.jwtToken}` })
                            .pipe(
                                map(response => loadProfileCompleted(response)),
                                catchError(error => of(notify('error', 'profileLoadError', 3000, error.status), loadProfileFailed()))
                            )
                    ))
            }))

export const loadCompanyProfile$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<Company>
    }> = (action$, state$, { currentSession, fetchJson }) =>
        action$.pipe(
            filter(isActionOf([loadCompanyProfile])),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res =>
                            fetchJson(COMPANYPROFILE_URI_BASE + action.payload.companyId, { authorization: `Bearer ${res.idToken.jwtToken}` })
                                .pipe(
                                    map(response => loadCompanyProfileCompleted(response)),
                                    catchError(error => of(notify('error', 'companyProfileLoadError', 3000, error.status), loadCompanyProfileFailed()))
                                )
                        ))
            }))

export const uploadProfileImage$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        postFile: (url: string, name: string, file: Buffer, type: string, headers?: Object) => Observable<string>
    }> = (action$, state$, { currentSession, postFile }) =>
        action$.pipe(
            filter(isActionOf(startUploadProfileImage)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res =>
                            postFile(USERLOGO_URI, 'logo', action.payload.content, action.payload.type, { authorization: `Bearer ${res.idToken.jwtToken}` })
                                .pipe(
                                    mergeMap(response => of(uploadProfileImageComplete(), reloadProfile())),
                                    catchError(error => of(notify('error', 'uploadProfileImageError', 3000, error.status), reloadProfile()))
                                )
                        ))
            }))

export const inviteCompanyMember$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post }) =>
        action$.pipe(
            filter(isActionOf([inviteCompanyMember])),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            return post(
                                `companies/${action.payload.companyId}/members`,
                                { email: action.payload.email, aclEnabled: action.payload.aclEnabled },
                                {
                                    'Content-Type': 'application/json',
                                    authorization: `Bearer ${res.idToken.jwtToken}`
                                }
                            )
                                .pipe(
                                    mergeMap(response => of(inviteCompanyMemberCompleted(), loadCompanyProfile(action.payload.companyId), notify('info', 'inviteCompanyMemberSuccess'))),
                                    catchError(error => of(inviteCompanyMemberError(), notify('error', 'inviteCompanyMemberError', 3000, error.status)))
                                )
                        })
                    )
            }))

export const removeCompanyMember$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        deleteEntity: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, deleteEntity }) =>
        action$.pipe(
            filter(isActionOf([removeCompanyMember])),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mergeMap(res => {
                            const headers = {
                                authorization: `Bearer ${res.idToken.jwtToken}`
                            }
                            return deleteEntity(
                                `companies/${action.payload.companyId}/members?email=${action.payload.email}`,
                                headers
                            )
                                .pipe(
                                    mergeMap(response => of(removeCompanyMemberCompleted(), loadCompanyProfile(action.payload.companyId), notify('info', action.payload.type === 'invitation' ? 'deleteCompanyMemberInvitationSuccess' : 'removeCompanyMemberSuccess'))),
                                    catchError(error => of(removeCompanyMemberError(), notify('error', action.payload.type === 'invitation' ? 'deleteCompanyMemberInvitationError' : 'removeCompanyMemberError', 3000, error.status)))
                                )
                        })
                    )
            }))

export type UpdateMember = { role?: string, aclEnabled?: boolean }

export const updateMember$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        put: (url: string, body: UpdateMember, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, put }) => action$.pipe(
        filter(isActionOf([updateCompanyMember])),
        mergeMap(action => zip(of(action), from(currentSession()))),
        mergeMap(([action, session]: any[]) => {
            const { companyId, memberId, ...body } = action.payload
            return zip(of(companyId), put(
                `companies/${companyId}/members/${memberId}`,
                body,
                {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${session.idToken.jwtToken}`
                }
            ))
        }),
        mergeMap(([companyId]) => of(updateCompanyMemberCompleted(), loadCompanyProfile(companyId), notify('info', 'updateCompanyMemberSuccess'))),
        catchError(error => of(updateCompanyMemberError(), notify('error', 'updateCompanyMemberError', 3000, error.status))))

export const acceptCompanyInvite$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post }) => action$.pipe(
        filter(isActionOf(acceptCompanyInvite)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                        const headers = {
                            authorization: `Bearer ${res.idToken.jwtToken}`
                        }
                        return post(
                            `companies/${action.payload.companyId}/invitation/accept`,
                            {},
                            headers
                        )
                            .pipe(
                                mergeMap(response => of(loginCompleted())),
                                catchError(error => of(acceptRejectCompanyInviteError(), notify('error', 'acceptCompanyInviteError', 3000, error.status)))
                            )
                    })
                )
        })
    )

export const rejectCompanyInvite$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post }) => action$.pipe(
        filter(isActionOf(rejectCompanyInvite)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                        const headers = {
                            authorization: `Bearer ${res.idToken.jwtToken}`
                        }
                        return post(
                            `companies/${action.payload.companyId}/invitation/reject`,
                            {},
                            headers
                        )
                            .pipe(
                                mergeMap(response => of(loadProfile())),
                                catchError(error => of(acceptRejectCompanyInviteError(), notify('error', 'rejectCompanyInviteError', 3000, error.status)))
                            )
                    })
                )
        })
    )