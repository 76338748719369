import React, { cloneElement, forwardRef } from 'react'
import clsx from 'clsx'
import { Spinner } from './Spinner'

type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref']
interface Props<C extends React.ElementType> {
    as?: C
    text?: string,
    color?: 'primary' | 'secondary' | 'grey' | 'white',
    disabled?: boolean,
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl',
    icon?: JSX.Element,
    pending?: boolean,
    full?: boolean,
    link?: boolean,
    otherButtonClasses?: string,
    otherIconClasses?: string,
    otherTextClasses?: string,
    ref?: PolymorphicRef<C>
    children?: React.ReactNode
}

type ButtonProps<C extends React.ElementType> = Props<C> & Omit<React.ComponentPropsWithoutRef<C>, keyof Props<C>>

type ButtonComponent = <C extends React.ElementType = 'button'>(
    props: ButtonProps<C>
) => React.ReactElement | null

export const Button: ButtonComponent = forwardRef(
    <C extends React.ElementType = 'button'>(props: ButtonProps<C>, ref?: PolymorphicRef<C>) => {
        const {
            as = 'button',
            size = 'base',
            color = 'white',
            disabled,
            full = false,
            icon,
            pending = false,
            text,
            otherButtonClasses,
            otherIconClasses,
            otherTextClasses,
            link = false,
            children,
            ...otherProps
        } = props
        const classes = clsx(
            'relative inline-flex justify-center items-center py-2 px-4 border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50',
            size === 'xs' && 'px-2.5 py-1.5 rounded text-xs',
            size === 'sm' && 'px-3 py-2 rounded-md text-sm leading-4',
            (size === 'base' || !size) && 'px-4 py-2 rounded-md text-sm',
            size === 'lg' && 'px-4 py-2 rounded-md text-base',
            size === 'xl' && 'px-6 py-3 rounded-md text-base',
            size === '2xl' && 'px-10 py-5 rounded-md text-xl',
            color === 'white' && 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-brand-500',
            color === 'primary' && 'border-transparent text-white bg-brand-600 hover:bg-brand-700 focus:ring-brand-500 disabled:hover:bg-brand-600',
            color === 'secondary' && 'border-transparent text-brand-700 bg-brand-100 hover:bg-brand-200 focus:ring-brand-500 disabled:hover:bg-brand-100',
            color === 'grey' && 'border-transparent text-gray-700 bg-gray-100 hover:bg-gray-200 focus:ring-gray-300 disabled:hover:bg-gray-100',
            link && 'bg-transparent border-transparent hover:bg-transparent px-0 py-0 focus:ring-0 focus:ring-opacity-0 focus:ring-offset-0',
            full && 'w-full',
            otherButtonClasses && otherButtonClasses
        )

        const iconClasses = clsx(
            size === 'xs' && 'h-4 w-4',
            size === 'sm' && 'h-5 w-5',
            size === 'base' && 'h-5 w-5',
            size === 'lg' && 'h-5 w-5',
            size === 'xl' && 'h-5 w-5',
            otherIconClasses && otherIconClasses
        )

        const textClasses = clsx(
            (size === 'xs' && icon) && 'ml-2',
            (size === 'sm' && icon) && 'ml-2',
            (size === 'base' && icon) && 'ml-3',
            (size === 'lg' && icon) && 'ml-3',
            (size === 'xl' && icon) && 'ml-3',
            pending ? 'invisible' : 'visible',
            otherTextClasses && otherTextClasses
        )

        let styledIcon
        if (icon) {
            styledIcon = cloneElement(
                icon,
                { className: iconClasses }
            )
        }

        const Component = as

        return (
            <Component
                {...otherProps}
                className={classes}
                disabled={disabled || pending}
                ref={ref}
            >
                {icon && styledIcon}
                {text && <span className={textClasses}>{text}</span>}
                <Spinner show={pending} containerClass="bg-transparent" size={4} inverted={['primary', 'secondary'].includes(color) ? true : false} />
                {children}
            </Component>
        )
    })
