import { countries as allCountries } from 'countries-list'
import mime from 'mime-types'

interface LanguageMapper {
    [key: string]: string;
}

const kseniaSecuritySupportedLocales = ['it', 'de', 'fr', 'es', 'ru', 'en']
const kseniaSecurityReleaseNoteSupportedLocales = ['it', 'en', 'fr']

const talentLanguageMapping: LanguageMapper = {
    'ar': 'arabic',
    'cs': 'czech',
    'da': 'danish',
    'de': 'german',
    'en': 'english',
    'el': 'greek',
    'es': 'spanish',
    'fr': 'french',
    'hu': 'hungarian',
    'it': 'italian',
    'nl': 'dutch',
    'pl': 'polish',
    'pt': 'portuguese',
    'ru': 'russian',
    'sl': 'slovenian',
    'sr': 'serbian',
    'sv': 'swedish',
    'zh': 'chinese'
}
export function getTalentLmsUrl(language?: string) {
    let lang = language || getBrowserLanguage()
    return `https://${talentLanguageMapping[lang]}-kseniasecurity.talentlms.com`
}

export function getPrivacyPolicyUrl(language?: string) {
    let lang = (language || getBrowserLanguage()) !== 'it' ? 'en' : 'it'
    return `https://www.kseniasecurity.com/${lang}/privacy-info`
}

export function getFindAnInstallerUrl(language?: string) {
    let lang = (language || getBrowserLanguage()) !== 'it' ? 'en' : 'it'
    if (lang === 'it') {
        return 'https://www.kseniasecurity.com/installatori/'
    } else {
        return 'https://www.kseniasecurity.com/en/installatori/'
    }
}

export function getCertificationGuideUrl(language?: string) {
    let lang = language || getBrowserLanguage()
    if (!kseniaSecuritySupportedLocales.includes(lang)) {
        lang = 'en'
    }
    return `https://www.kseniasecurity.com/${lang}/pag/categories-of-certification-learning-management-system-239`
}

export function getSignupUrl(language?: string) {
    let lang = language || getBrowserLanguage()
    if (!kseniaSecuritySupportedLocales.includes(lang)) {
        lang = 'en'
    }
    return `https://www.kseniasecurity.com/${lang}/registration/`
}

export const getReleaseNotesUrl = (language?: string) => {
    let lang = language || getBrowserLanguage()
    if (!kseniaSecurityReleaseNoteSupportedLocales.includes(lang)) {
        lang = 'en'
    }
    return `https://s3.eu-west-1.amazonaws.com/kseniasecurity.ireland/releasenotes/release-note-iot-lares-4-0-${lang}.pdf`
}

export const corceBrowserLanguage = (browserLanguage: string) => {
    if (browserLanguage.indexOf('ar') >= 0)
        return 'ar' //arabic
    else if (browserLanguage.indexOf('cs') >= 0)
        return 'cs' //czech
    else if (browserLanguage.indexOf('da') >= 0)
        return 'da' //danish
    else if (browserLanguage.indexOf('de') >= 0)
        return 'de' //german
    else if (browserLanguage.indexOf('el') >= 0)
        return 'el' //greek
    else if (browserLanguage.indexOf('es') >= 0)
        return 'es' //spanish
    else if (browserLanguage.indexOf('fr') >= 0)
        return 'fr' //french
    else if (browserLanguage.indexOf('hu') >= 0)
        return 'hu' //hungarian
    else if (browserLanguage.indexOf('it') >= 0)
        return 'it' //italian
    else if (browserLanguage.indexOf('nl') >= 0)
        return 'nl' //dutch
    else if (browserLanguage.indexOf('pl') >= 0)
        return 'pl' //polish
    else if (browserLanguage.indexOf('pt') >= 0)
        return 'pt' //portuguese
    else if (browserLanguage.indexOf('ru') >= 0)
        return 'ru' //russian
    else if (browserLanguage.indexOf('sl') >= 0)
        return 'sl' //slovenian
    else if (browserLanguage.indexOf('sr') >= 0)
        return 'sr' //serbian
    else if (browserLanguage.indexOf('sv') >= 0)
        return 'sv' //swedish
    else if (browserLanguage.indexOf('zh') >= 0)
        return 'zh' //chinese
    return 'en'
}

export function getBrowserLanguage() {
    const browserLanguage = (navigator.languages && navigator.languages[0]) || navigator.language
    return corceBrowserLanguage(browserLanguage)
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const formatContentType = (contentType?: string) => mime.extension(contentType) || 'bin'

export function getVersion(text?: string): string {
    const version = text || ''
    const regex = /v(\d+\.)?(\d+\.)?(\*|\d+)(-(\d+))?/
    const matched = version.match(regex)
    if (!matched) {
        return ''
    }
    return matched[0]
}

export const countries = Object.keys(allCountries).map((key: string) => {
    return ({
        //@ts-ignore
        label: allCountries[key].native,
        value: key
    })
}).sort((country1, country2) => {
    if (country1.label < country2.label)
        return -1

    if (country1.label > country2.label)
        return 1

    // a must be equal to b
    return 0
})

export const countriesWithEnglishName = Object.keys(allCountries).map((key: string) => {
    return ({
        //@ts-ignore
        label: allCountries[key].name,
        value: key
    })
}).sort((country1, country2) => {
    if (country1.label < country2.label)
        return -1

    if (country1.label > country2.label)
        return 1

    // a must be equal to b
    return 0
})

export const testCuu = (cuu: string) => {
    return /^[a-zA-Z0-9]{7}$/.test(cuu)
}

export const testEmail = (email: string) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{1,61}$/i.test(email)
}

export const testPhone = (phone: string) => {
    return /^\d{8,}$/i.test(phone)
}

export function paramsToObject(entries: any): Record<string, string> {
    const result: any = {}
    for (const [key, value] of entries) {
        result[key] = decodeURIComponent(value)
    }
    return result;
}