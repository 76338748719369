import React, { FC, Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import { MenuNavButton } from './MenuNavButton'
import { Disclosure } from '@headlessui/react'
import clsx from 'clsx'

export interface Navigation {
    name: string,
    navlink?: string,
    externalLink?: string
    icon?: (props: React.ComponentProps<'svg'>) => JSX.Element,
    onClick?: () => void,
    children?: {
        name: string,
        onClick?: () => void,
        icon?: (props: React.ComponentProps<'svg'>) => JSX.Element,
        navlink?: string
        externalLink?: string
    }[]
}


interface Props {
    menu: Navigation[],
    setSidebarOpen: (open: boolean) => void
}

export const BaseMenuNav: FC<Props> = (props) => {
    const { menu, setSidebarOpen } = props
    const location = useLocation()

    const isCurrent = (itemPath?: string) => {
        return itemPath === location.pathname
    }
    return (
        <nav className="flex-1 px-2 py-4 space-y-1">
            {menu.map((item) => (
                <Fragment key={item.name}>
                    {/* Separator with label */}
                    {Object.keys(item).length === 1 && (
                        <div className="uppercase text-gray-500 text-xs py-3 pl-2">
                            {item.name}
                        </div>
                    )}

                    {/* Without children */}
                    {!!item.navlink && (
                        <NavLink to={item.navlink} onClick={() => setSidebarOpen(false)}>
                            <MenuNavButton {...item} />
                        </NavLink>
                    )}

                    {/* With children */}
                    {item.children && item.children?.length > 0 && (
                        <Disclosure as="div" key={item.name} className="space-y-1">
                            {({ open }) => (
                                <>
                                    <Disclosure.Button
                                        className={clsx(
                                            isCurrent(item.navlink) ? 'bg-gray-900 text-white' : 'text-gray-200 hover:bg-gray-700 hover:text-white',
                                            'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md focus:outline-none'
                                        )}
                                    >
                                        {item.icon ? <item.icon
                                            className="mr-3 flex-shrink-0 h-6 w-6 text-gray-400"
                                            aria-hidden="true"
                                        /> : null}
                                        <span className="flex-1">{item.name}</span>
                                        <svg
                                            className={clsx(
                                                open ? 'text-gray-400 rotate-90' : 'text-gray-300',
                                                'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150'
                                            )}
                                            viewBox="0 0 20 20"
                                            aria-hidden="true"
                                        >
                                            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                                        </svg>
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="space-y-1">
                                        {(item.children || []).map((subItem) => {
                                            return subItem.navlink ? <NavLink key={subItem.name} to={subItem.navlink}
                                                onClick={() => setSidebarOpen(false)}>
                                                <MenuNavButton {...subItem} />
                                            </NavLink> :
                                                <a href={subItem.externalLink}
                                                    target="_blank" rel="noopener noreferrer">
                                                    <MenuNavButton {...subItem} />
                                                </a>
                                        })}
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    )}
                </Fragment>
            ))}
            {props.children || null}
        </nav>
    )
}