import React from 'react'

export default function CeralaccaIconTitleSubtitle(props: {
    icon: string,
    title: string
    subtitle: string
}) {
    const { title, subtitle, icon } = props

    return <div className="flex flex-col justify-center items-center">
        <img className="w-24 mb-4" src={icon} aria-hidden alt="" />
        <span className="uppercase text-[0.8rem] text-gray-500 leading-none">{title}</span>
        <span className="text-2xl uppercase">{subtitle}</span>
    </div>
}
