import React, { VFC } from 'react'
import clsx from 'clsx'
import { Switch } from '@headlessui/react'
import { Control, Controller } from "react-hook-form";
export interface ToggleProps {
    value: boolean,
    disabled?: boolean,
    onChange: (value: boolean) => void,
    title?: string,
    subtitle?: string
}

const ToggleInput: VFC<ToggleProps> = (props) => {
    const { disabled = false, value, onChange } = props

    return (
        <Switch
            checked={value}
            disabled={disabled}
            onChange={onChange}
            className={clsx(
                value ? (disabled ? 'bg-brand-300' : 'bg-brand-600') : (disabled ? 'bg-gray-100' : 'bg-gray-200'),
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500'
            )}
        >
            <span
                aria-hidden="true"
                className={clsx(
                    value ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                )}
            />
        </Switch>
    )
}

const Toggle = (props: Omit<ToggleProps, 'onChange' | 'value'> & { name: string, control: Control }) => {
    const { control, name, ...toggleProps } = props
    return (
        <Controller
            control={control}
            name={name}
            render={({ onChange, value }) => {
                return (
                    <ToggleInput
                        {...toggleProps}
                        onChange={onChange}
                        value={value}
                    />
                )
            }}
        />
    )
}

export { ToggleInput, Toggle }
