import React, { FC } from 'react'
import CardHeader from './CardHeader'
import CardBody from './CardBody'
import CardFooter from './CardFooter'
import { Spinner } from '../Spinner'
import clsx from 'clsx'

type Props = {
    pending?: boolean;
    className?: string;
}

type Sub = {
    Header: typeof CardHeader;
    Body: typeof CardBody;
    Footer: typeof CardFooter;
}

const Card: FC<Props> & Sub = (props) => {
    const { children, pending = false, className } = props
    return (
        <div
            className={clsx(["flex flex-col bg-white shadow rounded-lg divide-y divide-gray-100 relative"], className)}
        >
            {children}
            <Spinner show={pending} />
        </div>
    )
}

Card.Header = CardHeader
Card.Body = CardBody
Card.Footer = CardFooter

export default Card