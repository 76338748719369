import React from 'react'
import { useTranslation } from 'react-i18next'
import { SubmitHandler, useForm } from "react-hook-form"
import { Button, Card } from '../../../common'
import { Consent } from '../useTermsAndConditions'
import moment from 'moment'

export function TermsAndConditionsForm(props: { loading: boolean, consents: Consent[], consent: () => Promise<void> }) {
    const { t } = useTranslation()
    const { consents, consent } = props
    const { handleSubmit, register } = useForm<FormData>({})

    const onSubmit: SubmitHandler<FormData> = async (_form: FormData) => {
        consent()
    }

    const consentRequiringActions = consents.filter(c => !!c.actionRequired)

    return (
        <form className="block sm:w-1/2" onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <Card.Body full>
                    {consentRequiringActions.map((consent, index) => {
                        return <div key={consent.type} className="flex items-center justify-center px-5 py-4 space-x-4">
                            <input
                                required
                                id={consent.type}
                                aria-label={consent.type}
                                aria-describedby={`${consent.type}-description`}
                                name={consent.type}
                                type="checkbox"
                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded disabled:opacity-30"
                                ref={register}
                            />
                            <span className="flex-grow flex flex-col">
                                <span className="text-sm font-medium text-gray-900">{t(`${consent.type}Title`)}</span>
                                {consent.date ? <span className="text-sm text-gray-500">{`${t('lastApproval')} - ${moment(consent.date).format("DD/MM/YYYY")}`}</span> : null}
                            </span>
                            <div className="text-sm">
                                <p id={`consent-${index}-description`} className="text-brand-500">
                                    <a rel="noreferrer" href={`${consent.url}`} target="_blank">{t('open')}</a>
                                </p>
                            </div>
                        </div>
                    })}
                </Card.Body>
                <Card.Footer otherClasses="flex items-center justify-end">
                    <Button type="submit" color="primary" text={t('accept')} otherTextClasses="first-letter:uppercase" />
                </Card.Footer>
            </Card>
        </form>
    )
}