import { useAuth as useAmplifyAuth } from './amplify'
import { useAuth as useMsalAuth } from './msal'

export interface BaseAuthContext {
    getIdToken: () => Promise<string>
    signOut: () => Promise<void>
}

export const useAuth = () => {
    const amplifyAuth = useAmplifyAuth()
    const msalAuth = useMsalAuth()
    return (amplifyAuth || msalAuth) as BaseAuthContext
}