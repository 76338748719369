import { ActionType, createAction } from 'typesafe-actions'
import { notify } from '../notification/actions'
import { Filters } from './reducer'

export const SAVE_PANELS = 'save_panels'
export const SEARCH_PANELS = 'search_panels'
export const RELOAD_PANELS = 'reload_panels'
export const LOAD_PANELS = 'load_panels'
export const LOAD_PANELS_ERROR = 'load_panels_error'
export const SELECT_PANEL = 'select_panel'
export const ADD_PANEL = 'add_panel'
export const CHANGE_PANEL = 'change_panel'
export const CANCEL_SELECTED_PANEL = 'cancel_selected_panel'
export const DELETE_PANEL = 'delete_panel'
export const DELETE_PANEL_COMPLETE = 'delete_panel_complete'
export const SAVE_PANEL = 'save_panel'
export const SAVE_PANEL_COMPLETE = 'save_panel_complete'
export const PANEL_ACTIVATION = 'panel_activation'
export const PANEL_ACTIVATION_COMPLETE = 'panel_activation_complete'
export const CHANGE_PAGE_LIMIT = 'change_page_limit'
export const CHANGE_PAGE = 'change_page'
export const ADD_FILTERS = 'add_filters'
export const REMOVE_FILTERS = 'remove_filters'

export interface Connection {
    type?: connectionType,
    status: string
}

export interface Location {
    type: 'Point',
    coordinates: [number, number]
}

export type Age = 'updated' | 'old' | 'veryOld'

export type Model =
    'Centrale Ksenia wls'
    | 'lares 4.0 16IP'
    | 'lares 4.0 40IP'
    | 'lares 4.0 40IP wls'
    | 'lares 4.0 wls 96'
    | 'lares 4.0 140IP wls'
    | 'lares 4.0 644IP wls'
    | ''

export type connectionStatus = 'online' | 'offline'
export type connectionType = 'ETHERNET' | 'MOBILE_2G' | 'MOBILE_3G' | 'MOBILE_4G'

export type environmentType = 'www.kseniasecureweb.com' | 'test.kseniasecureweb.com'
export type panelActivationType = {
    points: number
    date: number
}
export type serviceType = 'http' | 'supervision' | 'backup'
export type Service = {
    type: serviceType,
    activeFrom?: number,
    peripheral?: {
        mac: string,
        type: string
    }
}
export interface Panel {
    serialNumber: string,
    mac: string,
    environment?: environmentType,
    name: string,
    description: string,
    beta?: boolean,
    connection?: Connection
    firmwareVersion?: string,
    build?: string,
    brand?: string,
    model?: Model | string,
    location?: Location,
    softwareVersion?: string,
    connectionAddress?: string,
    activation?: panelActivationType,
    ownerEmail?: string,
    _id?: string,
    services?: Service[]
}

export const savePanels = createAction(SAVE_PANELS, (action) => {
    return (panels: Panel[]) => action(panels)
})

export const searchPanels = createAction(SEARCH_PANELS, (action) => {
    return (searchString: string) => action(searchString)
})

export const reloadPanels = createAction(RELOAD_PANELS)

export const loadPanelsError = createAction(LOAD_PANELS_ERROR)
export const loadPanels = createAction(LOAD_PANELS, (action) => {
    return (query: Object) => action(query)
})

export const selectPanel = createAction(SELECT_PANEL, (action) => {
    return (panel: Panel) => action(panel)
})

export const addPanel = createAction(ADD_PANEL)

export const changePanel = createAction(CHANGE_PANEL, (action) => {
    return (key: string, value?: string | object) => action({
        key,
        value
    })
})

export const cancelSelectedPanel = createAction(CANCEL_SELECTED_PANEL)

export const deletePanel = createAction(DELETE_PANEL, (action) => {
    return (mac: string) => action(mac)
})

export const deletePanelComplete = createAction(DELETE_PANEL_COMPLETE, (action) => {
    return (mac: string) => action(mac)
})

export const savePanel = createAction(SAVE_PANEL, (action) => {
    return (panel: Panel, mode: 'add' | 'edit') => action({
        panel,
        mode
    })
})

export const savePanelComplete = createAction(SAVE_PANEL_COMPLETE, (action) => {
    return (panel: Panel) => action(panel)
})

export const panelActivation = createAction(PANEL_ACTIVATION, (action) => {
    return (mac: string) => action(mac)
})

export const panelActivationComplete = createAction(PANEL_ACTIVATION_COMPLETE, (action) => {
    return (mac: string, activation: { points: number, date: number }) => action({ mac, activation })
})

export const changePageLimit = createAction(CHANGE_PAGE_LIMIT, action => {
    return (limit: string) => action(limit)
})

export const changePage = createAction(CHANGE_PAGE, action => {
    return (page?: number) => action(page)
})

export const addFilters = createAction(ADD_FILTERS, action => {
    return (filters: Filters) => action(filters)
})

export const removeFilters = createAction(REMOVE_FILTERS)

export type PanelActions = ActionType<
      typeof savePanels
    | typeof searchPanels
    | typeof reloadPanels
    | typeof loadPanels
    | typeof loadPanelsError
    | typeof selectPanel
    | typeof changePanel
    | typeof cancelSelectedPanel
    | typeof deletePanel
    | typeof deletePanelComplete
    | typeof savePanel
    | typeof savePanelComplete
    | typeof panelActivation
    | typeof panelActivationComplete
    | typeof notify
    | typeof changePageLimit
    | typeof addFilters
    | typeof removeFilters
>