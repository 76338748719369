import React, { useEffect } from 'react'
import { Dispatch } from 'redux'
import { connect, ConnectedProps } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { loadLoyaltyProgram, RankWithPrize } from './actions'
import { isLoyaltyProgramLoading, loyaltyProgramRankingWithPrizes } from './selectors'
import { State } from '../configureStore'
import Leaderboard from './Leaderboard'
import { LOYALTYPROGRAM_YEAR } from './epics'

type LeaderboardsProps = PropsFromRedux & {}

export const Leaderboards: React.VFC<LeaderboardsProps> = (props) => {
    const { t } = useTranslation()
    const { onLoadLoyaltyProgram, rankings, pending } = props
    
    useEffect(() => {
        onLoadLoyaltyProgram()
    }, [onLoadLoyaltyProgram])

    return (
        <>
            <h2 className="title-page">
                {t('loyaltyLeaderboards')} <span className='text-brand-500'>{LOYALTYPROGRAM_YEAR}</span>
            </h2>
            <div className="page-container">
                <div className='mb-4'>
                    <Leaderboard
                        rankings={rankings.overall}
                        title={t('levelOverall')}
                        pending={pending}
                        level="overall"
                    />
                </div>
                <div className='grid sm:grid-cols-2 sm:grid-rows-2 gap-4'>
                    <Leaderboard
                        rankings={rankings.specialist}
                        title={t('levelSpecialist')}
                        pending={pending}
                        level="specialist"
                    />
                    <Leaderboard
                        rankings={rankings.gold}
                        title={t('levelGold')}
                        pending={pending}
                        level="gold"
                    />
                    <Leaderboard
                        rankings={rankings.silver}
                        title={t('levelSilver')}
                        pending={pending}
                        level="silver"
                    />
                    <Leaderboard
                        rankings={rankings.selected}
                        title={t('levelSelected')}
                        pending={pending}
                        level="selected"
                    />
                </div>
            </div>
        </>
    )
}

export function mapStateToProps(state: State) {
    return {
        rankings: loyaltyProgramRankingWithPrizes(state) as { [key: string]: RankWithPrize[] },
        pending: isLoyaltyProgramLoading(state)
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onLoadLoyaltyProgram() {
            dispatch(loadLoyaltyProgram())
        },
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Leaderboards)
