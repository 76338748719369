import { useCallback } from "react"
import urlJoin from 'url-join'
import { useAuth } from "../../auth"

export const buildError = (response: Response) => {
    const error = new Error(response.statusText)
    error.name = response.status.toString()
    return error
}

const encodeQueryParams = (queryParams: Query) => Object.fromEntries(
    Object.entries(queryParams).map(
        ([k, v]) => [k, encodeURIComponent(v as string)]
    )
)

const queryToString = (queryParams: Query) => Object.keys(queryParams).filter(key => !!queryParams[key]).map(key => key + '=' + encodeQueryParams(queryParams)[key]).join('&')

const extractHeaders = (value: Response['headers']) => {
    const headers: Headers = {}
    value.forEach((v, k) => {
        headers[k] = v
    })
    return headers
}

export type Method = 'GET' | 'POST' | 'PUT' | 'DELETE'
export type Query = Record<string, string | number | undefined>
export type Headers = Record<string, string>

export type RequestParams = {
    method?: Method
    path: string
    auth?: boolean
    basePath?: string
    query?: Query
    headers?: Headers
    body?: any
}

const defaultParams = {
    method: 'GET' as Method,
    path: '',
    auth: true,
    basePath: '',
    query: {},
    headers: {}
}

const authorizationHeader = 'authorization'

const contentTypeHeader = 'content-type'
const contentTypeJson = 'application/json'

export const useRequest = <T>(params: RequestParams = defaultParams) => {
    const authProvider = useAuth()
    const { method, path, auth, basePath, query: paramsQuery, headers: paramsHeaders, body } = { ...defaultParams, ...params }

    const request = useCallback(async () => {
        const query = queryToString(paramsQuery)
        const url = urlJoin(basePath, path, query ? '?' + query : '')
        let headers = { ...paramsHeaders } as Headers
        if (auth) {
            const token = await authProvider.getIdToken()
            headers[authorizationHeader] = `Bearer ${token}`
        }
        if (body) {
            headers = { ...headers, [contentTypeHeader]: contentTypeJson }
        }
        let response: Response
        try {
            response = await fetch(url, {
                method,
                headers,
                body: body ? JSON.stringify(body) : undefined
            })
        } catch (err) {
            return Promise.reject(err)
        }
        if (!response.ok) {
            return Promise.reject(buildError(response))
        }
        if ((response.headers.get(contentTypeHeader) || '').includes(contentTypeJson)) {
            const resBody = await response.json() as T
            const resHeaders = extractHeaders(response.headers)
            return { body: resBody, headers: resHeaders }
        }
        if ((response.headers.get(contentTypeHeader) || '').startsWith('text')) {
            const resBody = await response.text() as T
            const resHeaders = extractHeaders(response.headers)
            return { body: resBody, headers: resHeaders }
        }
        const resBody = await response.blob() as T
        const resHeaders = extractHeaders(response.headers)
        return { body: resBody, headers: resHeaders }
    }, [authProvider, method, path, auth, basePath, paramsQuery, paramsHeaders, body])

    const requestBuilder = useCallback(async (builderParams: RequestParams) => {
        const { method, path, auth, basePath, query: paramsQuery, headers: paramsHeaders, body } = { ...defaultParams, ...params, ...builderParams }
        let headers = { ...paramsHeaders } as Headers
        const query = queryToString(paramsQuery)
        const url = urlJoin(basePath, path, query ? '?' + query : '')
        if (auth) {
            const token = await authProvider.getIdToken()
            headers[authorizationHeader] = `Bearer ${token}`
        }
        if (body) {
            headers = { ...headers, [contentTypeHeader]: contentTypeJson }
        }
        let response: Response
        try {
            response = await fetch(url, {
                method,
                headers,
                body: body ? JSON.stringify(body) : undefined
            })
        } catch (err) {
            return Promise.reject(err)
        }
        if (!response.ok) {
            return Promise.reject(buildError(response))
        }
        if ((response.headers.get(contentTypeHeader) || '').includes(contentTypeJson)) {
            return response.json() as Promise<T>
        }
        if ((response.headers.get(contentTypeHeader) || '').startsWith('text')) {
            return response.text()
        }
        return response.blob()
    }, [authProvider, params])

    return {
        request,
        requestBuilder
    }
}