import { useCallback, useMemo, useReducer } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { PANELS_PATH, useDebounce } from '../utils'
import { RequestParams, useRequest } from '../request'
import {
    Panel,
    addFilters,
    changePage,
    changePageLimit,
    searchPanels,
    removeFilters as removeFiltersAction,
    cancelSelectedPanel
} from '../../panels/actions'
import { notify } from '../../notification/actions'
import { extractCurrent, extractPage, extractUrls } from './utils'
import { Filters, panelsInitialState, panelsReducer } from '../../panels/reducer'

type CreatePanel = Pick<Panel, 'serialNumber' | 'name' | 'description' | 'location'>
export type EditPanel = Pick<Panel, | 'name' | 'description' | 'location'> & { mac: string }

export interface Pagination {
    prev: string
    next: string
    first: string
    last: string,
    pages: number,
    current: number,
    count: number
}

interface usePanelsArgs {
    paginated?: boolean
    enabledQuery?: boolean
    staleTimeQuery?: number,
    projection?: 'full'
}

const SEARCH_MIN_LENGTH = 3
const SEARCH_DEBOUNCE_DELAY = 500

const GET_PANELS_QUERY_NAME = 'get-panels'

export const usePanels = (args: usePanelsArgs = {}) => {
    const { paginated = false, enabledQuery = true, staleTimeQuery = 30 * 1000, projection } = args
    const [panelsState, panelsStateDispatch] = useReducer(panelsReducer, panelsInitialState)
    const reduxDispatch = useDispatch()
    const debouncedSearch = useDebounce(panelsState.search, SEARCH_DEBOUNCE_DELAY);
    const queryClient = useQueryClient()

    const requestParams: RequestParams = useMemo(() => {
        return {
            path: PANELS_PATH,
            query: {
                projection,
                search: debouncedSearch.length >= SEARCH_MIN_LENGTH ? debouncedSearch : '',
                ...panelsState.filters,
                ...(paginated || projection ? {
                    limit: panelsState.pageLimit,
                    page: panelsState.page ? panelsState.page.toString() : '',
                } : {})
            }
        }
    }, [debouncedSearch, paginated, projection, panelsState])

    const { request: fetchPanels } = useRequest<Panel[]>(requestParams)
    const { requestBuilder: mutationPanel } = useRequest<Panel>({ path: requestParams.path })

    const result = useQuery(
        [GET_PANELS_QUERY_NAME, requestParams],
        fetchPanels,
        {
            enabled: enabledQuery,
            staleTime: staleTimeQuery,
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            onError: () => {
                reduxDispatch(notify('error', 'panelLoadError', 3000))
            }
        }
    )
    const { data, isFetching, isSuccess, isError, refetch } = result

    const pagination = useMemo<Pagination | undefined>(() => {
        if (data && Object.hasOwn(data.headers, 'link')) {
            const urls = extractUrls(data.headers.link)
            const pages = extractPage(urls.last)
            const current = extractCurrent(urls.prev)
            const count = parseInt(data.headers.count)
            return { ...urls, pages, current, count } as Pagination
        }
        return undefined
    }, [data])

    const invalidateQueries = useCallback(() => {
        queryClient.invalidateQueries(GET_PANELS_QUERY_NAME)
    }, [queryClient])

    const setSearch = useCallback((search: string) => {
        panelsStateDispatch(changePage())
        panelsStateDispatch(searchPanels(search))
    }, [panelsStateDispatch])

    const setFilters = useCallback((filters: Filters) => {
        panelsStateDispatch(changePage())
        panelsStateDispatch(addFilters(filters))
    }, [panelsStateDispatch])

    const removeFilters = useCallback(() => {
        panelsStateDispatch(changePage())
        panelsStateDispatch(removeFiltersAction())
    }, [panelsStateDispatch])

    const setPageLimit = useCallback((limit: string) => {
        panelsStateDispatch(changePage())
        panelsStateDispatch(changePageLimit(limit))
    }, [panelsStateDispatch])

    const setPage = useCallback((page?: number) => {
        panelsStateDispatch(changePage(page))
    }, [panelsStateDispatch])

    const createPanelFn = useCallback(
        async (panel: CreatePanel) => {
            return mutationPanel({
                method: 'POST',
                path: `${PANELS_PATH}`,
                body: panel
            })
        }, [mutationPanel])

    const createPanelMutation = useMutation(
        'create-panel',
        createPanelFn,
        {
            onSuccess: async (_, panel) => {
                invalidateQueries()
                reduxDispatch(notify('info', 'panelAddSuccess', 3000))
                reduxDispatch(cancelSelectedPanel())
            },
            onError: (error) => {
                //@ts-ignore
                const msg = error?.name === '409' ? 'panelAddAlreadyRegisteredError' : 'panelAddError'
                let timeMs = 3000
                //@ts-ignore
                if (error?.name === '409')
                    timeMs *= 2
                reduxDispatch(notify('error', msg, timeMs))
            }
        }
    )

    const createPanel = useCallback(
        async (panel: CreatePanel) => {
            try {
                await createPanelMutation.mutateAsync(panel)
            } catch {
            }
        }, [createPanelMutation]
    )

    const updatePanelFn = useCallback(
        async (panel: EditPanel) => {
            const { name, description, location } = panel
            const body = {
                name,
                description,
                location
            }
            return mutationPanel({
                method: 'PUT',
                path: `${PANELS_PATH}/${panel.mac}`,
                body
            })
        }, [mutationPanel])

    const updatePanelMutation = useMutation(
        'update-panel',
        updatePanelFn,
        {
            onSuccess: async (_, panel) => {
                invalidateQueries()
                reduxDispatch(notify('info', 'panelUpdateSuccess', 3000))
                reduxDispatch(cancelSelectedPanel())
            },
            onError: (error) => {
                reduxDispatch(notify('error', 'panelUpdateError', 3000))
            }
        }
    )

    const updatePanel = useCallback(
        async (panel: EditPanel) => {
            try {
                await updatePanelMutation.mutateAsync(panel)
            } catch {
            }
        }, [updatePanelMutation]
    )

    const deletePanelFn = useCallback(
        async (mac: string) => {
            return mutationPanel({
                method: 'DELETE',
                path: `${PANELS_PATH}/${mac}`,
            })
        }, [mutationPanel])

    const deletePanelMutation = useMutation(
        'delete-panel',
        deletePanelFn,
        {
            onSuccess: async (_, mac) => {
                invalidateQueries()
                reduxDispatch(notify('info', 'panelDeleteSuccess', 3000))
                reduxDispatch(cancelSelectedPanel())
            },
            onError: (error) => {
                reduxDispatch(notify('error', 'panelDeleteError', 3000))
            }
        }
    )

    const deletePanel = useCallback(
        async (mac: string) => {
            try {
                await deletePanelMutation.mutateAsync(mac)
            } catch {
            }
        }, [deletePanelMutation])

    return {
        panels: data && data?.body ? data.body : [],
        pagination,
        loading: isFetching
            || createPanelMutation.isLoading
            || updatePanelMutation.isLoading
            || deletePanelMutation.isLoading,
        isSuccess,
        isError,
        refetch: () => refetch({ cancelRefetch: true }),
        setSearch,
        search: panelsState.search,
        setPageLimit,
        pageLimit: panelsState.pageLimit,
        setPage,
        page: panelsState.page,
        setFilters,
        removeFilters,
        filters: panelsState.filters,
        createPanel,
        updatePanel,
        deletePanel,
    } as const
}