import { Epic } from 'redux-observable'
import { AjaxResponse } from 'rxjs/ajax'
import {
    deletePanel,
    deletePanelComplete,
    loadPanels,
    loadPanelsError,
    Panel,
    PanelActions,
    panelActivation,
    panelActivationComplete,
    RELOAD_PANELS,
    savePanel,
    savePanelComplete,
    savePanels
} from './actions'
import { catchError, filter, map, mergeMap } from 'rxjs/operators'
import { from, Observable, of } from 'rxjs'
import { ActionTypes, State } from '../configureStore'
import { isActionOf } from 'typesafe-actions'
import { notify } from '../notification/actions'

export interface AmplifySession {
    accessToken?: { jwtToken: string },
    idToken: { jwtToken: string }
}

const PANELS_URI = 'users/panels'
const DASHBOARD_PANELS_URI = 'dashboard/panels'

export const getPanels$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<Panel[]>
    }> = (action$, state$, { currentSession, fetchJson }) =>
    action$.pipe(
        filter(act => act.type === RELOAD_PANELS),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res =>
                        fetchJson(PANELS_URI, { authorization: `Bearer ${res.idToken.jwtToken}` })
                            .pipe(
                                map(response => savePanels(response)),
                                catchError(error => of(notify('error', 'panelLoadError', 3000, error.status), loadPanelsError()))
                            )
                    ))
        }))

export const loadPanels$: Epic<ActionTypes,
    ActionTypes,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        fetchJson: (url: string, headers?: Object) => Observable<Panel[]>
    }> = (action$, state$, { currentSession, fetchJson }) =>
    action$.pipe(
        filter(isActionOf(loadPanels)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                            const query = action.payload
                            const queryString = Object.keys(query)
                                .map(key => `${key}=${(query as any)[key]}`)
                                .join('&')
                            const url = queryString === '' ? DASHBOARD_PANELS_URI : `${DASHBOARD_PANELS_URI}?${queryString}`
                            return fetchJson(url, { authorization: `Bearer ${res.idToken.jwtToken}` })
                                .pipe(
                                    map(response => savePanels(response)),
                                    catchError(error => of(
                                        notify('error', 'panelLoadError', 3000, error.status),
                                        loadPanelsError()))
                                )
                        }
                    ))
        }))

export const deletePanel$: Epic<ActionTypes,
    PanelActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        deleteEntity: (url: string, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, deleteEntity }) =>
    action$.pipe(
        filter(isActionOf(deletePanel)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res =>
                        deleteEntity(`${PANELS_URI}/${action.payload}`,
                            { authorization: `Bearer ${res.idToken.jwtToken}` })
                            .pipe(
                                mergeMap(response => of(deletePanelComplete(action.payload), notify('info', 'panelDeleteSuccess'))),
                                catchError(error => of(notify('error', 'panelDeleteError', 3000, error.status)))
                            )
                    ))
        }))


export const savePanel$: Epic<ActionTypes,
    PanelActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>,
        put: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post, put }) =>
    action$.pipe(
        filter(isActionOf(savePanel)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                        const headers = {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${res.idToken.jwtToken}`
                        }
                        if (action.payload.mode === 'add') {
                            return post(PANELS_URI,
                                action.payload.panel,
                                headers)
                                .pipe(
                                    mergeMap(response => of(savePanelComplete(response.response), notify('info', 'panelAddSuccess'))),
                                    catchError(error => of(notify('error', 'panelAddError', 3000, error.status)))
                                )
                        } else {
                            const { name, description, location } = action.payload.panel
                            return put(`${PANELS_URI}/${action.payload.panel.mac}`,
                                {
                                    name,
                                    description,
                                    location
                                },
                                headers)
                                .pipe(
                                    mergeMap(response => of(savePanelComplete(action.payload.panel), notify('info', 'panelUpdateSuccess'))),
                                    catchError(error => of(notify('error', 'panelUpdateError', 3000, error.status)))
                                )
                        }
                    }))
        }))


export const panelActivation$: Epic<ActionTypes,
    PanelActions,
    State,
    {
        currentSession: () => Promise<AmplifySession>,
        post: (url: string, body: any, headers?: Object) => Observable<AjaxResponse>
    }> = (action$, state$, { currentSession, post }) =>
    action$.pipe(
        filter(isActionOf(panelActivation)),
        mergeMap(action => {
            return from(currentSession())
                .pipe(
                    mergeMap(res => {
                        const headers = {
                            'Content-Type': 'application/json',
                            authorization: `Bearer ${res.idToken.jwtToken}`
                        }
                        return post(PANELS_URI + '/activations',
                            { mac: action.payload },
                            headers)
                            .pipe(
                                mergeMap(response => of(panelActivationComplete(action.payload, response.response), notify('info', 'panelActivationSuccess'))),
                                catchError(error => of(notify('error', 'panelActivationError', 3000, error.status)))
                            )
                    }))
        }))
