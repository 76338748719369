import clsx from 'clsx';
import React, { ReactNode } from 'react';

type DescriptionListItemProps = {
    label: string
    value?: ReactNode,
    show?: boolean,
    valueClass?: string
}

const DescriptionListItem: React.VFC<DescriptionListItemProps> = (props) => {
    const { label, value = '-', valueClass, show = true } = props
    const valueClasses = clsx('mt-1 text-sm text-gray-900 whitespace-pre-wrap sm:mt-0 sm:col-span-2', valueClass && valueClass)
    if (!show)
        return null

    return (
        <div className="py-4 px-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">{label}</dt>
            <dd className={valueClasses}>{value}</dd>
        </div>
    )
}

export default DescriptionListItem