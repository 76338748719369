import React from 'react'
import { useTranslation } from 'react-i18next'
import Input from './Input/Input'

export function NewPassword(props: { labelStyle?: string, label?: string, newPassword: { onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; value: string } }) {
    const { t } = useTranslation()
    const { newPassword } = props
    return <Input
        id="newPassword"
        containerClass="col-span-1"
        name="newPassword"
        type="password"
        autoComplete="new-password"
        required
        label={props.label}
        placeholder={t('newPasswordPlaceholder')}
        passwordVisibility
        {...newPassword}
    >
        <Input.PasswordValidator
            password={newPassword.value}
        />
    </Input>
}