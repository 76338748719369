import React from 'react'
import { State } from '../../configureStore'
import { connect, ConnectedProps } from 'react-redux'
import { ProfileHeader } from '../ProfileHeader'
import { UserProfile } from './UserProfile'
import { getUserCompanyProfile, UserCompanyProfile } from '../selectors'
import { useTranslation } from 'react-i18next'

type Props = PropsFromRedux & {}

export function User(props: Props) {
    const { t } = useTranslation()
    const { profile } = props
    let headerTitle
    if (profile.name) {
        headerTitle = profile.name + ' '
    }
    if (profile.surname) {
        headerTitle += profile.surname
    }
    return (
        <div className="flex flex-col relative">
            <ProfileHeader
                title={headerTitle}
                subtitle={profile.company?.businessName}
                certification={profile.certificationLevel}
                certificationLevelDetailLabel={t('user')}
            />
            <div className="page-container mt-36 md:mt-12">
                <UserProfile {...profile}/>
            </div>
        </div>
    )
}

export function mapStateToProps(state: State) {
    return {
        profile: getUserCompanyProfile(state) as UserCompanyProfile
    }
}

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(User)
