import { createSelector } from "reselect"
import { State } from "../../configureStore"

const getPrices = (state: State) => state.billing.prices

export const getGatewayPrices = createSelector(
    getPrices,
    prices => (prices || [])
        .filter(p => p.type === 'gateway-http')
        .map(p => ({ ...p, subTotalAmount: parseFloat(p.subTotalAmount) / 100 }))
)