import _ from 'lodash'
import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import { Company, CompanyStatus } from '../../profile/selectors'
import { RequestParams, useRequest } from '../request'
import { COMPANIES_PATH } from '../utils'

type CompaniesSearchQuery = Partial<{
    email: string,
    businessName: string,
    country: string,
    province: string,
    status: CompanyStatus,
    limit: string
}>

const fetchOptions = {
    refetchOnWindowFocus: false,
    retry: false
}

export const useAdminCompaniesSearch = (query: { limit: number }) => {
    const [searchValues, setSearchValues] = useState<CompaniesSearchQuery>({})
    const [companies, setCompanies] = useState<Company[]>([])
    const { limit } = query
    const enabled = !_.isEmpty(searchValues)

    const resetSearch = useCallback(() => {
    }, [])

    const requestParams: RequestParams = {
        path: COMPANIES_PATH,
        query: {
            ...searchValues,
            limit: String(limit)
        }
    }

    const { request: searchCompanies } = useRequest<Company[]>(requestParams)

    const { refetch, isFetching, error } = useQuery(
        [requestParams],
        searchCompanies,
        {
            onSuccess: (data) => {
                if (data && data?.body) setCompanies(data.body)
            },
            enabled,
            ...fetchOptions
        }
    )

    return {
        setSearchValues,
        companies,
        refetch,
        enabled,
        loading: isFetching,
        error,
        resetSearch
    }
}
