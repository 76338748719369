import React from 'react'
import { ChevronRightIcon, ChevronLeftIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { Select } from '../common'
import { extractPage } from '../hooks/usePanels/utils'

const PANELS_PER_PAGE = 10
const LIMITS = ['10', '20', '50', '100']
interface PanelsListFooterProps {
    pages: number,
    current: number,
    first: string,
    last: string,
    prev?: string
    next?: string,
    count: number,
    onPageLimitChange: (limit: string) => void
    pageLimit: string,
    onPageChange: (page: number) => void
}

function getPagesToRender(currentIndex: number, totalPages: number) {
    const maxRenderedPage = 10
    return {
        min: Math.max(1, Math.min(currentIndex - 4, totalPages - (maxRenderedPage - 1))),
        max: Math.min(Math.max(currentIndex + 5, maxRenderedPage), totalPages)
    }
}

export function PanelsListFooter(props: PanelsListFooterProps) {
    const { pages, current, last, first, prev, next, count, onPageLimitChange, pageLimit, onPageChange } = props
    const { t } = useTranslation()
    const styles = makeStyles()

    const pagesToRenders = getPagesToRender(1, pages)
    const pageNumbers = Array(pagesToRenders.max - pagesToRenders.min + 1)
        .fill(0)
        .map((v, index) => index + pagesToRenders.min)

    const prevDisabled = !prev
    const nextDisabled = !next

    const firstInPage = Math.max((current - 1) * PANELS_PER_PAGE, 1)
    const lastInPage = Math.min((current) * PANELS_PER_PAGE, count)

    const handlePageLimitChange = (e: React.ChangeEvent<HTMLSelectElement>) => onPageLimitChange(e.currentTarget.value)

    const handlePageChange = (value?: string | number) => () => {
        if (typeof value === 'string') {
            const page = extractPage(value)
            if (page) onPageChange(page)
        }
        if (typeof value === 'number') {
            onPageChange(value)
        }
    }

    return (
        <div className="flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden">
                <button
                    onClick={handlePageChange(prev)}
                    disabled={prevDisabled}
                    className={styles.button}
                >
                    Previous
                </button>
                <button
                    onClick={handlePageChange(next)}
                    disabled={nextDisabled}
                    className={styles.button}
                >
                    Next
                </button>
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="text-sm text-gray-700">
                    {pages > 1 ? <>
                        {t('results')}: <span className="font-bold">{firstInPage}</span> - <span className="font-bold">{lastInPage + ' / '}</span>
                    </> : null}
                    {t('total')}: <span className="font-bold">{count}</span>
                </div>
                <div className='flex gap-4'>
                    <div className="flex gap-2 items-center">
                        <span className="text-sm">{t('panelsPerPage')}</span>
                        <Select
                            data-testid="page-limit-select"
                            value={pageLimit}
                            onChange={handlePageLimitChange}
                        >
                            {LIMITS.map((limit) => <option key={limit} value={limit}>{limit}</option>)}
                        </Select>
                    </div>
                    <nav className={styles.pagination}>
                        <button
                            onClick={handlePageChange(first)}
                            disabled={prevDisabled}
                            className={styles.first}
                        >
                            <ChevronDoubleLeftIcon className={styles.icon} aria-hidden="true" />
                        </button>
                        <button
                            onClick={handlePageChange(prev)}
                            disabled={prevDisabled}
                            className={styles.button}
                        >
                            <ChevronLeftIcon className={styles.icon} aria-hidden="true" />
                        </button>
                        {pageNumbers.map((page, index) => {
                            return <button
                                key={`page-nav-${index}`}
                                onClick={handlePageChange(page)}                                    
                                className={page === current ? styles.pageCurrent : styles.page}
                            >
                                {page}
                            </button>
                        })}
                        <button
                            onClick={handlePageChange(next)}
                            disabled={nextDisabled}
                            className={styles.button}
                        >
                            <ChevronRightIcon className={styles.icon} aria-hidden="true" />
                        </button>
                        <button
                            onClick={handlePageChange(last)}
                            disabled={nextDisabled}
                            className={styles.last}
                        >
                            <ChevronDoubleRightIcon className={styles.icon} aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    )
}

const makeStyles = () => {
    const buttonBase = 'relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed'
    const pageBase = 'relative inline-flex items-center px-4 py-2 border text-sm font-medium'
    return {
        pagination: 'relative z-0 inline-flex rounded-md shadow-sm -space-x-px',
        icon: 'h-4 w-4',
        pageCurrent: clsx(pageBase, 'z-10 bg-brand-50 border-brand-500 text-brand-600'),
        page: clsx(pageBase, 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50'),
        first: clsx(buttonBase, 'rounded-l-md'),
        last: clsx(buttonBase, 'rounded-r-md'),
        button: buttonBase
    }
}