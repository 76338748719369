import clsx from 'clsx'
import React from 'react'

type DividerProps = {
    size?: 'sm' | 'lg' | 'xl'
    border?: boolean 
}

export const Divider: React.VFC<DividerProps> = (props) => {
    const { border = true, size } = props
    const dividerClasses = clsx({
        'py-5': !size,
        'py-2': size === 'sm',
        'py-6 sm:py-8': size === 'lg',
        'py-8 sm:py-12': size === 'xl'
    })
    return (
        <div className={dividerClasses} aria-hidden="true">
            {border && <div className="border-t border-gray-100" />}
        </div>
    )
}
