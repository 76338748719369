import { createSelector } from 'reselect'
import { State } from '../../configureStore'
import { companyStatus, explicitMarketingPreferences, getProfile, isPhoneVerified } from '../selectors'

export type FirstStepWizardStatus = 'current' | 'complete' | 'upcoming'
export type FirstStepWizard = {
    personalData: FirstStepWizardStatus,
    marketing: FirstStepWizardStatus,
    phone: FirstStepWizardStatus
    company: FirstStepWizardStatus
}

const getCompanyStepStatus = (state: State) => state.firstSteps.skipCompanyStep

const personalDataComplete = createSelector(
    getProfile,
    profile => !!profile.name && !!profile.surname
)

const marketingStepStatus = createSelector(
    personalDataComplete, explicitMarketingPreferences,
    (isPersonalDataComplete, marketingPreferences) => {
        if (marketingPreferences)
            return 'complete'
        if (!isPersonalDataComplete)
            return 'upcoming'
        return 'current'
    }
)

const phoneStepStatus = createSelector(
    marketingStepStatus, isPhoneVerified,
    (marketingPreferences, phoneVerified) => {
        if (!!phoneVerified)
            return 'complete'
        if (marketingPreferences === 'complete')
            return 'current'
        return 'upcoming'
    }
)

const companyStepStatus = createSelector(
    phoneStepStatus, companyStatus, getCompanyStepStatus,
    (phoneStepStatus, companyStatus, skipCompany) => {
        if (skipCompany)
            return 'complete'
        if (companyStatus)
            return 'complete'
        if (phoneStepStatus === 'complete')
            return 'current'
        return 'upcoming'
    }
)

export const firstStepWizardStatus = createSelector(
    [personalDataComplete, marketingStepStatus, phoneStepStatus, companyStepStatus],
    (personalDataComplete, marketingStatus, phoneStepStatus, companyStepStatus): FirstStepWizard => ({
        personalData: personalDataComplete ? 'complete' : 'current',
        marketing: marketingStatus,
        phone: phoneStepStatus,
        company: companyStepStatus
    })
)

export const hasFirstStepsWizardActive = createSelector(
    [firstStepWizardStatus],
    (steps) => {
        return !Object.values(steps).every(step => step === 'complete')
    }
)