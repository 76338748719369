import React from 'react'
import clsx from 'clsx'
import { Controller } from 'react-hook-form'
import ReactSelect, { Theme } from 'react-select'
import { InputSearchable } from './InputSearchable'

const customStyles = (isValid: boolean) => ({
    multiValue: (provided: any, state: any) => ({
        ...provided,
        borderRadius: '9999px',
        opacity: state.isDisabled ? 0.5 : provided.opacity,
        cursor: state.isDisabled ? 'not-allowed' : provided.cursor
    }),
    multiValueRemove: (provided: any, state: any) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '' : provided.backgroudColor,
        cursor: state.isDisabled ? 'not-allowed' : provided.cursor,
        borderWidth: state.isDisabled ? 0 : provided.borderWidth,
        borderRadius: '0.375rem',
        borderColor: isValid ? provided.borderColor : 'var(--color-red-600)'
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        display: state.isDisabled ? 'none' : provided.display
    })
})

export type FormSelectProps = {
    submit?: boolean
    label?: string
    labelClassName?: string
    invalid?: boolean
    invalidMessage?: string
    helpMessage?: string
    show?: boolean
} & React.ComponentProps<typeof Controller> & React.ComponentProps<typeof ReactSelect>

const FormSelect = React.forwardRef((props: FormSelectProps, ref: any) => {
    const {
        id,
        name,
        control,
        isMulti = true,
        isClearable = true,
        maxMenuHeight,
        options,
        submit = false,
        label,
        labelClassName,
        placeholder,
        primaryColor,
        disabled,
        className,
        noOptionsMessage,
        isLoading = false,
        onInputChange,
        loadingMessage,
        rules,
        invalid,
        invalidMessage,
        helpMessage,
        show = true,
        containerClass = ''
    } = props

    const currentPlaceholder = disabled || options?.keys?.length ? '' : placeholder
    const noOptionMessafeFn = () => !isLoading ? noOptionsMessage : undefined
    const loadingMessageFn = () => loadingMessage

    return show ? (<div className={containerClass}>
        {label &&
            <label htmlFor={name} className={clsx(labelClassName || 'block text-sm font-medium text-gray-700 mb-1')}>
                {label}
            </label>}
        <Controller
            aria-label={id}
            rules={rules}
            ref={ref}
            id={id}
            name={name}
            control={control}
            as={ReactSelect}
            isLoading={isLoading}
            className={className}
            isDisabled={disabled}
            placeholder={currentPlaceholder}
            noOptionsMessage={noOptionMessafeFn}
            loadingMessage={loadingMessageFn}
            theme={(theme?: Theme) => ({
                ...theme,
                borderRadius: 2,
                colors: {
                    ...(theme?.colors || {}),
                    primary: primaryColor || theme?.colors?.primary
                }
            } as Theme)}
            styles={customStyles(!invalid)}
            isMulti={isMulti}
            isClearable={isClearable}
            options={options}
            maxMenuHeight={maxMenuHeight}
            onInputChange={onInputChange}
            onKeyDown={(e: any) => {
                if (!submit && e.key === 'Enter') e.preventDefault()
            }}
        />
        {helpMessage && (<InputSearchable.Help>{helpMessage}</InputSearchable.Help>)}
        {invalid && invalidMessage && (
            <InputSearchable.Alert>{invalidMessage}</InputSearchable.Alert>
        )}
    </div>) : null
})

export default FormSelect