import React from 'react'
import useShuttleSearch from './useShuttleSearch'
import { ShuttleListSearch, ShuttleListItem, ShuttleListEmpty, ShuttleListFooter } from './'
import { ShuttleListItemI } from './ShuttleListItem'
import { ItemPropsMapI } from './Shuttle'
import _ from 'lodash'

interface ShuttleListProps {
    items: ShuttleListItemI[],
    disabled: boolean,
    onClick: (id: string) => void,
    title?: string,
    icon: React.ReactNode
    itemPropsMap: ItemPropsMapI
}

const ShuttleList = (props: ShuttleListProps) => {
    const { items = [], title = '', disabled, itemPropsMap, onClick, icon } = props
    const searchKeys = _.values(_.omit(itemPropsMap, ['id']))
    const { results, setQuery } = useShuttleSearch(items, searchKeys)

    const onSearchHandler = (value: string) => {
        setQuery(value)
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.list}>
                <ShuttleListSearch onSearch={onSearchHandler} disabled={disabled} />
                {results.length === 0 ? (
                    <ShuttleListEmpty />
                ) : (
                    <div className={styles.items}>
                        {results.map(result => (
                                <ShuttleListItem
                                    key={result[itemPropsMap.id]}
                                    id={result[itemPropsMap.id]}
                                    title={result[itemPropsMap.title]}
                                    subtitle={result[itemPropsMap.subtitle]}
                                    onClick={onClick}
                                    icon={icon}
                                    disabled={disabled}
                                />
                            )
                        )}
                    </div>
                )}
                <ShuttleListFooter total={items.length} />
            </div>
        </div>
    )
}

const styles = {
    container: '',
    title: 'hidden sm:block text-brand-500 mb-2',
    list: 'grid grid-rows-[min-content,1fr] h-64 border border-gray-300 rounded-lg overflow-hidden',
    items: 'bg-gray-50 overflow-x-hidden overflow-y-auto',
}

export default ShuttleList