import React, { VFC } from 'react'
import { QrcodeIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import Card from '../common/Card/Card'
import { NoItem } from '../common/NoItem'

interface Props {
    items: any[] | undefined
    isLoading: boolean
}

const Gateway: VFC<Props> = (props) => {
    const { items = [], isLoading } = props

    const { t } = useTranslation()
    return (
        <Card className="page-container" pending={isLoading}>
            <Card.Header>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="flex flex-col flex-1">
                        <div className="font-bold">{t('gateway')}</div>
                        <div className="subheader">
                            {t('gatewaySubheader')}
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body full>
                {items.length ? (
                    <div>
                        <ul className="rounded-md divide-y divide-gray-100">
                            {items.filter(item => !!item.panel).map((gateway, index) => {
                                const { subType = 'default' } = gateway
                                return (
                                    <li key={`${gateway.ser}-${index}`}
                                        className="px-4 sm:px-6 py-3 text-sm text-gray-500">
                                        <div className="flex">
                                            <span className="text-brand-500 uppercase">{gateway.type}</span>
                                            {subType !== 'default' ? <span className="capitalize">&nbsp;-&nbsp;{subType}</span> : null}
                                        </div>
                                        <div className="flex flex-col sm:flex-row justify-between">
                                            <span>{t('activeOn')}&nbsp;<span className="text-brand-500">{gateway.panel.name}</span></span>
                                            <span className="flex font-mono items-center text-xs"><QrcodeIcon className="mr-1.5 h-5 w-5 text-gray-200" aria-hidden="true" />{gateway.panel.serialNumber}</span>
                                        </div>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                ) : (
                    <NoItem
                        image={<img src={require('../images/ksenia-porta.svg').default} className="mx-auto h-20 object-contain" alt="" aria-hidden="true" />}
                        title={t('noGatewayTitle')}
                        additionalClasses="m-4"
                    />
                )}
            </Card.Body>
        </Card>
    )
}

export default Gateway