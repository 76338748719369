import React, { useEffect } from 'react'
import { State } from '../configureStore'
import { connect, ConnectedProps, useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import { CompanyLogo } from './CompanyLogo'
import { CompanyProfile } from './CompanyProfile'
import { Divider } from '../common/Divider'
import { Spinner } from '../common/Spinner'
import { CompanyBilling } from './CompanyBilling'
import { Avatar, startUploadProfileImage } from './actions'
import { createBillingProfile, openBillingProfile, updateBillingProfile } from '../billing/actions'
import { getBillingFormMode, getUserCompanyProfile, UserCompanyProfile, hasAdminRole, getBillingId } from './selectors'
import { getBillingProfileForm, isLoadingBillingProfile } from '../billing/selectors'
import { BillingForm } from '../billing/types'
import { companyCouldChangeAvatar, allowedUser } from './utils'
import { ProfileHeader } from './ProfileHeader'

type Props = PropsFromRedux & {}

export function Company(props: Props) {
    const { profile, isAdmin } = props
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const billingId = useSelector(getBillingId)
    useEffect(() => {
        if (billingId) {
            dispatch(openBillingProfile())
        }
    }, [billingId, dispatch])

    const getProfileSubTitle = (profile: UserCompanyProfile) => {
        let headerSubtitle
        if (profile.name)
            headerSubtitle = profile.name + ' '
        if (profile.surname)
            headerSubtitle += profile.surname
        return headerSubtitle
    }

    return (
        <div className="flex flex-col relative">
            <ProfileHeader
                title={profile.company?.businessName}
                subtitle={getProfileSubTitle(profile)}
                certification={profile.company?.certificationLevel}
                certificationScaled={profile.company?.scaledCertificationLevel}
                score={profile.company?.loyaltyProgramScore}
                avatar={profile.company?.logoUrl}
            />

            <div className="md:mt-12">
                <div className="page-container">
                    <CompanyLogo
                        avatarUrl={profile.company?.logoUrl}
                        onSubmit={props.onAvatarRead}
                        couldChange={companyCouldChangeAvatar(profile.company?.certificationLevel)}
                        allowedUser={allowedUser(profile.role)}
                    />
                    {profile.company && (
                        <>
                            <Divider />
                            <div className="mt-10 sm:mt-0 md:grid md:grid-cols-4 md:gap-6">
                                <div className="md:col-span-1">
                                    <div className="px-1 md:px-4">
                                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('companyProfileSectionInfoTitle')}</h3>
                                        <p className="mt-1 text-sm text-gray-600">{t('profileSectionsInfoSubtitle')}</p>
                                    </div>
                                </div>
                                <div className="mt-5 md:mt-0 md:col-span-3">
                                    <CompanyProfile  {...profile.company} companyAdmin={isAdmin} />
                                </div>
                            </div>
                        </>
                    )}
                    <Divider />
                    {props.isBillingLoading ? <Spinner />
                        :
                        <CompanyBilling
                            pending={false}
                            isModal={false}
                            mode={props.billingFormMode}
                            billing={props.billing}
                            onSubmit={(form) => props.billingFormMode === 'add' ? props.onRegister(form) : props.onUpdate(form)}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export function mapStateToProps(state: State) {
    return {
        profile: getUserCompanyProfile(state) as UserCompanyProfile,
        billing: getBillingProfileForm(state),
        billingFormMode: getBillingFormMode(state),
        isBillingLoading: isLoadingBillingProfile(state),
        isAdmin: hasAdminRole(state)
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onAvatarRead(data: Avatar) {
            dispatch(startUploadProfileImage(data.content, data.type))
        },
        onUpdate(user: BillingForm) {
            dispatch(updateBillingProfile(user))
        },
        onRegister(user: BillingForm) {
            dispatch(createBillingProfile(user))
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export default connector(Company)
