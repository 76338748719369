import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, NoItem } from '../common'
import { CompanyMembersItem } from './CompanyMembersItem'
import { UsersIcon } from '@heroicons/react/outline'

type CompanyMembersListProps = {
    members: any[]
    companyId: string
    showHeaders: boolean
    canEdit?: boolean
    profileEmail?: string
}

function CompanyMembersList(props: CompanyMembersListProps) {
    const { members, companyId, canEdit, showHeaders, profileEmail } = props
    const thClassName = "px-6 py-3 text-left text-xs text-gray-500 uppercase whitespace-nowrap truncate"
    const { t } = useTranslation()

    return <Card className="shadow-none bg-transparent">
        {showHeaders && <Card.Header>
            <div className="font-bold">{t('members')}</div>
        </Card.Header>}
        <Card.Body full>
            {members.length > 0 ?
                <div className="divide-y divide-gray-100" role="table">
                   {showHeaders && <div className="hidden sm:grid grid-cols-[120px,1fr,15%,15%,20%,40px] uppercase" role="row">
                        <span className={thClassName} role="columnheader">
                            {t('status')}
                        </span>
                        <span className={thClassName} role="columnheader">
                            {t('name')}
                        </span>
                        <span className={thClassName} role="columnheader">
                            {t('role')}
                        </span>
                        <span className={thClassName} role="columnheader">
                            {t('panelsAccess')}
                        </span>
                        <span className={thClassName} role="columnheader">
                            {t('certificationLevel')}
                        </span>
                        <span className="relative px-6 py-3" role="columnheader">
                            <span className="sr-only"></span>
                        </span>
                    </div>}
                    <div className="divide-y divide-gray-100" role="rowgroup">
                        {members.map((member, index) =>
                            <CompanyMembersItem
                                key={member.id || member.email}
                                companyId={companyId}
                                member={member}
                                canEdit={canEdit === undefined ? !(member.email === profileEmail) : canEdit}
                            />
                        )}
                    </div>
                </div>
                : <NoItem additionalClasses="m-4" icon={UsersIcon} title={t('noMembersTitle')} subTitle={showHeaders ? t('noMembersSubtitle') : ''} />
            }
        </Card.Body>
    </Card >
}

export default CompanyMembersList