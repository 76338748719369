import React from 'react'
import { useTranslation } from 'react-i18next'
import { InboxIcon } from '@heroicons/react/outline'

const ShuttleListEmpty = () => {
    const { t } = useTranslation()

    return (
        <div className={styles.container}>
            <InboxIcon className={styles.icon} aria-hidden="true" />
            <p className={styles.label}>{t('shuttleListNoData')}</p>
        </div>
    )
}

const styles = {
    container: 'flex flex-col items-center justify-center',
    icon: 'h-14 w-14 text-gray-200 mb-2',
    label: 'text-gray-500'
}

export default React.memo(ShuttleListEmpty)