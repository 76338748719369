export const extractUrls = (urls: string) => {
    const urlArray = urls.split(',')
    return urlArray.reduce<Record<string, string>>((acc, cur: string) => {
        const pattern = /<([^>]+)>; rel="(\w+)"/
        const match = cur.match(pattern);
        if (match) return { ...acc, [match[2]]: match[1] }
        return { ...acc }
    }, {})
}

export const extractPage = (url: string) => {
    const theUrl = new URL(url)
    const page = theUrl.searchParams.get('page');
    if (page) return parseInt(page)
}

export const extractCurrent = (url?: string) => {
    if (url) {
        const page = extractPage(url)
        if (page) return page + 1
    }
    return 1
}