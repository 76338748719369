import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { msalConfig } from "../brand/bticino/authConfig"
import { PublicClientApplication, AccountInfo, InteractionRequiredAuthError } from "@azure/msal-browser"
import { MsalProvider, useMsal } from '@azure/msal-react'

export type ContextType = {
    name?: string
    lastName?: string
    signOut: () => Promise<void>
    getIdToken: () => Promise<string>
    getAccessToken: () => Promise<string>
}

export const useMsAuth = (): ContextType => {
    const { accounts, instance } = useMsal()
    const [name, setName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')

    useEffect(() => {
        if (accounts.length) {
            const account: AccountInfo = accounts[0]
            instance.setActiveAccount(account)
            // @ts-ignore
            setName(account.idTokenClaims?.given_name || '')
            // @ts-ignore
            setLastName(account.idTokenClaims?.family_name || '')
        }
    }, [instance, accounts])


    const signOut = useCallback(async () => {
        await instance.logoutRedirect({
            account: instance.getActiveAccount()
        })
    }, [instance])

    const getToken = async (scopes: string[]) => instance.acquireTokenSilent({
        scopes,
        account: instance.getActiveAccount() as AccountInfo
    }).catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
            signOut()
        }
    })

    const getIdToken = async () => {
        return (await getToken([]))?.idToken as string
    }

    const getAccessToken = async () => {
        return (await getToken([process.env.REACT_APP_AUTH_ACCESS_TOKEN_SCOPE as string]))?.accessToken as string
    }

    return {
        name,
        lastName,
        signOut,
        getIdToken,
        getAccessToken
    }
}

export const AuthContext = createContext<ContextType | null>(null)

export const useAuth = () => {
    return useContext(AuthContext) as ContextType
}

const Consumer = ({ children }: PropsWithChildren<{}>) => {
    const auth = useMsAuth() as ContextType
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const PCA = new PublicClientApplication(msalConfig)

export const AuthWrapper = ({ children }: PropsWithChildren<{}>) => <MsalProvider instance={PCA}>
    <Consumer>{children}</Consumer>
</MsalProvider>