import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import {
    CheckCircleIcon,
    XCircleIcon
} from '@heroicons/react/solid'

interface Props {
    password: string
    className?: string;
}

const minNumberChars = 8

export const reSpecialChar = new RegExp(/(?=.*[\^$*.[\]{}()?\-“!@#%&/\\,><’'":;|_~`])/)
export const reUpperCase = new RegExp("[A-Z]{1,}")
export const reLowerCase = new RegExp("[a-z]{1,}")
export const reDigit = new RegExp("[0-9]{1,}")
export const reMinNumberChars = new RegExp("^.{" + minNumberChars + ",99}$")

const conditions = [reSpecialChar, reUpperCase, reLowerCase, reDigit, reMinNumberChars]

export const validatePassword = (password: string) => {
    return conditions.map(c => c.test(password)).every(r => r)
}

const InputPasswordValidator = (props: Props): JSX.Element => {
    const { password, className } = props
    const classes = clsx('mt-2 text-sm flex flex-col flex-wrap sm:flex-row mt-4', className)

    const { t } = useTranslation()

    const validSpecialChar = reSpecialChar.test(password)
    const validUpperCase = reUpperCase.test(password)
    const validLowerCase = reLowerCase.test(password)
    const validDigit = reDigit.test(password)
    const validMinNumberChars = reMinNumberChars.test(password)

    return (
        <ul className={classes} role="alert">
            <Rule valid={validUpperCase} label={t("passwordValidatorupperCase")} />
            <Rule valid={validLowerCase} label={t("passwordValidatorlowerCase")} />
            <Rule valid={validSpecialChar} label={t("passwordValidatorspecialChar")} />
            <Rule valid={validDigit} label={t("passwordValidatordigit")} />
            <Rule valid={validMinNumberChars} label={t("passwordValidatorminNumberChars") + " " + minNumberChars} />
        </ul>
    )
}

interface ItemProps {
    label: string,
    valid: boolean
}

const Rule = (props: ItemProps) => {
    const { label, valid } = props
    return (
        <li className={clsx('flex items-center flex-none sm:w-1/2')}>
            <CheckCircleIcon className={clsx("h-4 mr-2 text-green-500", !valid && 'hidden')} />
            <XCircleIcon className={clsx("h-4 mr-2 text-gray-400", valid && 'hidden')} />
            <span className="text-gray-500">{label}</span>
        </li>
    )
}

export default InputPasswordValidator