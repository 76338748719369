import React, { useState, useMemo } from 'react'
import clsx from 'clsx'
import { ViewListIcon, MapIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { generateAmplifyAnalyticsTags } from '../auth/amplify'

type TabTypes = 'list' | 'map'
type Tab = { name: TabTypes, href: string, icon: any }

const tabs: Tab[] = [
    { name: 'list', href: '/#/panels/#list', icon: ViewListIcon },
    { name: 'map', href: '/#/panels/#map', icon: MapIcon }
]

const usePanelsListTabs = (hasMap?: boolean) => {
    const { t } = useTranslation()
    const [tab, setTab] = useState<TabTypes>(tabs[0].name)
    const enabledTabs = tabs.filter(t => t.name !== 'map' || (t.name === 'map' && hasMap))

    const TabNav = useMemo(() => {
        const isCurrentTab = (tb: Tab) => tb.name === tab
        const TabNavComponent = () => {
            return (
                <>
                    <div className="sm:hidden mx-4 md:mx-8">
                        <select
                            id="tabs"
                            name="tabs"
                            className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                            defaultValue={tab}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                const value = e.target.value as TabTypes
                                setTab(value)
                                window.location.href = `/#/panels/#${value}`
                            }}
                        >
                            {enabledTabs.map((tb, index) => (
                                <option key={`tab-${index}`} value={tb.name}>{t(tb.name)}</option>
                            ))}
                        </select>
                    </div>

                    <div className="hidden sm:block">
                        <div className="border-b border-gray-200 mx-4 md:mx-8">
                            <nav className="-mb-px flex items-center space-x-8" aria-label="Tabs">
                                <p className="my-2">{t('viewAs')}</p>
                                {enabledTabs.map((tb) => (
                                    <a
                                        key={tb.name}
                                        href={tb.href}
                                        className={clsx(
                                            isCurrentTab(tb)
                                                ? 'border-brand-500 text-brand-600'
                                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                            'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                                        )}
                                        aria-current={isCurrentTab(tb) ? 'page' : undefined}
                                        {...generateAmplifyAnalyticsTags(`action:switch_panels_view,view:${tb.name}`)}
                                        onClick={() => { setTab(tb.name) }}
                                    >
                                        <tb.icon
                                            className={clsx(
                                                isCurrentTab(tb) ? 'text-brand-500' : 'text-gray-400 group-hover:text-gray-500',
                                                '-ml-0.5 mr-2 h-5 w-5'
                                            )}
                                            aria-hidden="true"
                                        />
                                        <span>{t(tb.name)}</span>
                                    </a>
                                ))}
                            </nav>
                        </div>
                    </div>
                </>
            )
        }
        return enabledTabs.length > 1 ? TabNavComponent : () => null
    }, [enabledTabs, tab, t])

    return {
        tab,
        setTab,
        TabNav,
    } as const
}

export default usePanelsListTabs