import { useCallback, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { COMPANIES_PATH, updateResource } from '../utils'
import { Company } from '../../profile/selectors'
import { RequestParams, useRequest } from '../request'
import { useAuth } from '../../auth'

const fetchOptions = {
    refetchOnWindowFocus: false
}

type AdminApproveInstallerCompany = {
    id: string
    type: 'installer'
    businessName: string
    address: string
    zipCode: string
    city: string
    country: string
    province: string
    email: string
    phoneNumber: string
    vatNumber?: string
    pec?: string
    cuu?: string
    distributors?: string[]
    actualBrands?: string[]
    status: 'verified' | 'invalid'
    note?: string
}

export type AdminApproveCompany = {
    id: string,
    data: Partial<Omit<AdminApproveInstallerCompany, 'type'>>
}

const approveAdminCompany = (authToken: string) => async (params: AdminApproveCompany) => {
    return await updateResource(`${COMPANIES_PATH}/${params.id}`, authToken, params.data)
}

export const useAdminCompanyMutation = () => {
    const authClass = useAuth()

    const updateFn = useCallback(async (body: AdminApproveCompany) => {
        const token = await authClass.getIdToken()
        return approveAdminCompany(token)(body)
    }, [authClass])

    const updateMutation = useMutation(updateFn)
    const update = (body: AdminApproveCompany) => updateMutation.mutateAsync(body)

    return { update }
}

export const useAdminCompanies = (params: { status?: string, types?: string, limit?: string, sortBy?: string } = {}) => {
    const [companies, setCompanies] = useState<Company[]>([])

    const requestParams: RequestParams = {
        path: COMPANIES_PATH,
        query: params
    }

    const { request: searchCompanies } = useRequest<Company[]>(requestParams)

    const { refetch, isFetching, error } = useQuery(
        [requestParams],
        searchCompanies,
        {
            onSuccess: (data) => {
                if (data && data?.body) setCompanies(data.body)
            },
            ...fetchOptions
        }
    )

    return {
        error,
        companies,
        loading: isFetching,
        refetch
    } as {
        error: ErrorConstructor
        companies: typeof companies
        loading: typeof isFetching
        refetch: typeof refetch
    }
}
