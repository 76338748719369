

import { MenuNav as KseniaMenuNav } from '../../brand/ksenia/Menu/MenuNav'
import { MenuNav as BticinoMenuNav } from '../../brand/bticino/Menu/MenuNav'
import { MenuLogo as KseniaMenuLogo } from  '../../brand/ksenia/Menu/MenuLogo'
import { MenuLogo as BticinoMenuLogo } from  '../../brand/bticino/Menu/MenuLogo'
import { isBticino } from '../../config'

export const MenuNav = isBticino() ? BticinoMenuNav : KseniaMenuNav

export const MenuLogo = isBticino() ? BticinoMenuLogo : KseniaMenuLogo