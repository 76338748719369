import React, { VFC } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

type StepType = {
    id: string
    title: string
    subtitle: string
    status: 'current' | 'complete' | 'upcoming' | 'skip'
}

export const WizardHeaderStepper: VFC<{
    steps: StepType[]
}> = (props) => {
    const { t } = useTranslation()
    const stepperClasses = clsx(
        'grid gap-4 w-full',
        props.steps.length === 1 && 'grid-cols-[repeat(1,minmax(200px,auto))]',
        props.steps.length === 2 && 'grid-cols-[repeat(2,minmax(200px,auto))]',
        props.steps.length === 3 && 'grid-cols-[repeat(3,minmax(200px,auto))]',
        props.steps.length === 4 && 'grid-cols-[repeat(4,minmax(200px,auto))]',
        props.steps.length === 5 && 'grid-cols-[repeat(5,minmax(200px,auto))]'
    )
    return <nav aria-label="Progress" className="flex items-center overflow-hidden overflow-x-auto h-full">
        <ol className={stepperClasses}>
            {props.steps.map((step, i) => (
                <li key={`step-${i}`} className="md:flex-1">
                    {step.status === 'complete' ? (
                        <span
                            className="group pl-4 py-2 flex flex-col border-l-4 border-brand-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                        >
                            <span className="text-xs text-brand-600 font-semibold tracking-wide uppercase">{t(step.title)}</span>
                            <span className="text-sm font-medium">{t(step.subtitle)}</span>
                        </span>
                    ) : step.status === 'current' ? (
                        <span
                            className="pl-4 py-2 flex flex-col border-l-4 border-brand-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                            aria-current="step"
                        >
                            <span className="text-xs text-brand-600 font-semibold tracking-wide uppercase">{t(step.title)}</span>
                            <span className="text-sm font-medium">{t(step.subtitle)}</span>
                        </span>
                    ) : (
                        <span
                            className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                        >
                            <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase">{t(step.title)}</span>
                            <span className="text-sm font-medium">{t(step.subtitle)}</span>
                        </span>
                    )}
                </li>
            ))}
        </ol>
    </nav>
}