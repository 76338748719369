import React, { VFC } from 'react'
import { PlusIcon, RefreshIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import Card from '../common/Card/Card'
import { Button } from '../common/Button'
import { NoItem } from '../common/NoItem'
import { Avatar } from '../common/Avatar'
import { mapVoice } from './ModalPurchaseVoice'
import { generateAmplifyAnalyticsTags } from '../auth/amplify'

interface Props {
    canManageStore: boolean
    items: string[] | undefined
    pending: boolean,
    purchaseHandler: () => void
    reloadHandler: () => void
}

const Voices: VFC<Props> = (props) => {
    const { canManageStore, items = [], pending, purchaseHandler, reloadHandler } = props
    const { t } = useTranslation()
    return (
        <Card className="page-container" pending={pending}>
            <Card.Header>
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="flex flex-col flex-1">
                        <div className="font-bold capitalize">{t('loquendo')}</div>
                        <div className="subheader">
                            {t('loquendoSubheader')}
                        </div>
                    </div>
                    <div className="mt-3 flex space-x-2 sm:mt-0 sm:ml-4">
                        <Button
                            type="button"
                            onClick={reloadHandler}
                            icon={<RefreshIcon className={'btn-icon'} />}
                        />
                        <Button
                            type="button"
                            color={'primary'}
                            text={t('add')}
                            {...generateAmplifyAnalyticsTags("action:add_license,product:loquendo")}
                            icon={<PlusIcon />}
                            disabled={!canManageStore}
                            onClick={purchaseHandler}
                        />
                    </div>
                </div>
            </Card.Header>
            <Card.Body full>
                {items.length > 0 ? <div>
                    <ul className="rounded-md divide-y divide-gray-100">
                        {items.map((voice, index) => {
                            const translatedVoice = mapVoice(voice, t)
                            return <li key={`${voice}-${index}`}
                                className="px-4 sm:px-6 py-3 flex items-center justify-between text-sm">
                                <div className="flex-1 flex items-center">
                                    <span
                                        className="flex-1 w-0 truncate capitalize text-brand-500">{translatedVoice.voice}
                                    </span>
                                </div>
                                <div className="ml-4 flex-shrink-0">
                                    <span className="mx-2 flex-1 w-0 capitalize text-gray-500">{translatedVoice.language}</span>
                                    <Avatar
                                        size={4}
                                        src={require(`../images/flags/${translatedVoice.voiceCode.substr(0, 3)}.svg`)?.default}
                                        alt={translatedVoice.language}
                                        aria-hidden
                                    />
                                </div>
                            </li>
                        })}
                    </ul>
                </div> :
                    <NoItem
                        image={<img src={require('../images/ksenia-loquendo.svg').default} className="mx-auto h-20 object-contain" alt="" aria-hidden="true" />}
                        title={t('noVoicesTitle')}
                        subTitle={t('noVoicesSubtitle')}
                        additionalClasses="m-4"
                    />}
            </Card.Body>
        </Card>
    )
}

export default Voices