import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { BillingForm } from '../billing/types'
import { getPrivacyPolicyUrl, testEmail } from '../common/utils'
import Input from '../common/Input/Input'
import clsx from 'clsx'
import { connect, ConnectedProps } from 'react-redux'
import { State } from '../configureStore'
import { getBillingProfileForm, isLoadingBillingProfile } from '../billing/selectors'
import { createBillingProfile } from '../billing/actions'
import { Dispatch } from 'redux'
import { Button } from '../common/Button'
import Card from '../common/Card/Card'
import { getBillingFormMode } from './selectors'
import { PhoneWithCountry } from '../common/PhoneWithCountry'

type Props = PropsFromRedux & {
    isModal: boolean
}

export function CompanyBilling(props: Props) {
    const { t } = useTranslation()

    // eslint-disable-next-line
    const [_, setUser] = useState<{ [x: string]: string }>()
    const setDefaultUser = useCallback(async () => {
        await setUser(getValues())
        trigger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setDefaultUser()
    }, [setDefaultUser])

    const { onSubmit, billing, mode, pending, isModal } = props
    const {
        register,
        handleSubmit,
        errors,
        getValues,
        trigger,
        control
    } = useForm({
        defaultValues: {
            ...billing
        }
    })

    const isReadOnlyMode = mode === 'show'

    const [acceptPrivacy, setAcceptPrivacy] = useState(false)


    const doSubmit = (data: any) => {
        onSubmit({ ...data } as BillingForm)
    }

    const onChange = async (k: string, v: string) => {
        //don't change the order!!
        await setUser({ [k]: v })
        await trigger()
    }

    return <div className="mt-10 sm:mt-0">
        <div className="md:grid md:grid-cols-4 md:gap-6">
            {!props.isModal ? <div className="md:col-span-1">
                <div className="px-1 md:px-4">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">{t('profileBillingTitle')}</h3>
                    <p className="mt-1 text-sm text-gray-600">{t('profileBillingSubtitle')}</p>
                </div>
            </div> : null}
            <div className={clsx('mt-5 md:mt-0', props.isModal ? 'md:col-span-6' : 'md:col-span-3')}>
                <form onSubmit={handleSubmit(doSubmit)}>
                    <Card className={clsx(props.isModal && 'shadow-none sm:rounded-none')}>
                        <Card.Body>
                            <div className="grid grid-cols-6 gap-4">
                                <Input
                                    containerClass="col-span-6 lg:col-span-4"
                                    disabled={isReadOnlyMode}
                                    id="billing-address"
                                    label={t('registrationStreetAddress')}
                                    name="address"
                                    onChange={(e) => onChange('address', e.target.value)}
                                    ref={register({ required: true })}
                                    type="text"
                                >
                                    {errors.address && (
                                        <Input.Alert>
                                            {t('invalidAddress')}
                                        </Input.Alert>
                                    )}
                                </Input>
                                <Input
                                    containerClass="col-span-6 sm:col-span-3 lg:col-span-2"
                                    disabled={isReadOnlyMode}
                                    id="billing-city"
                                    label={t('registrationCity')}
                                    name="city"
                                    onChange={(e) => onChange('city', e.target.value)}
                                    ref={register({ required: true })}
                                    type="text"
                                >
                                    {errors.city && (
                                        <Input.Alert>
                                            {t('invalidCity')}
                                        </Input.Alert>
                                    )}
                                </Input>
                                <Input
                                    containerClass="col-span-6 sm:col-span-3"
                                    disabled={isReadOnlyMode}
                                    id="billing-province"
                                    label={t('registrationProvince')}
                                    name="province"
                                    onChange={(e) => onChange('province', e.target.value)}
                                    ref={register({ required: true })}
                                    type="text"
                                >
                                    {errors.province && (
                                        <Input.Alert>
                                            {t('invalidProvince')}
                                        </Input.Alert>
                                    )}
                                </Input>
                                <Input
                                    containerClass="col-span-6 sm:col-span-3"
                                    disabled={isReadOnlyMode}
                                    id="billing-zipcode"
                                    label={t('registrationZipCode')}
                                    name="zipCode"
                                    onChange={(e) => onChange('zipCode', e.target.value)}
                                    ref={register({ required: true })}
                                    type="text"
                                >
                                    {errors.zipCode && (
                                        <Input.Alert>
                                            {t('invalidZipCode')}
                                        </Input.Alert>
                                    )}
                                </Input>
                                <Input
                                    containerClass={clsx('col-span-6 sm:col-span-3', isModal && 'sm:col-span-6')}
                                    disabled={isReadOnlyMode}
                                    label={t('registrationEmail')}
                                    id="billing-email"
                                    name="email"
                                    onChange={(e) => onChange('email', e.target.value)}
                                    ref={register({
                                        required: true,
                                        validate: testEmail
                                    })}
                                    type="email"
                                >
                                    {errors.email && (
                                        <Input.Alert>
                                            {t('invalidEmail')}
                                        </Input.Alert>
                                    )}
                                </Input>
                                <PhoneWithCountry
                                    id="billing-phone"
                                    label={t('registrationPhone')}
                                    containerClass={clsx('col-span-6 sm:col-span-3', isModal && 'sm:col-span-6')}
                                    name="phone"
                                    rules={{ required: true }}
                                    control={control}
                                    disabled={isReadOnlyMode}
                                />
                                {!isReadOnlyMode ?
                                    <div className="col-span-6 relative flex items-start">
                                        <div className="flex items-center h-5">
                                            <input
                                                required
                                                id="privacy"
                                                aria-describedby="privacy-description"
                                                name="privacy"
                                                type="checkbox"
                                                className="focus:ring-brand-500 h-4 w-4 text-brand-600 border-gray-300 rounded disabled:opacity-30"
                                                disabled={mode !== 'add'}
                                                checked={mode === 'edit' || acceptPrivacy}
                                                onChange={(e) => {
                                                    setAcceptPrivacy(!acceptPrivacy)
                                                    trigger()
                                                }}
                                            />
                                        </div>
                                        <div className="ml-3 text-sm">
                                            <label htmlFor="privacy" className="font-medium text-gray-700">
                                                {t('privacy')}
                                            </label>
                                            <p id="privacy-description" className="text-brand-500">
                                                <span className="text-gray-500">{t('readThe')} </span> <a
                                                href={getPrivacyPolicyUrl()} target="_blank"
                                                rel="noreferrer">{t('privacyConditions')}</a>
                                            </p>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            {!isReadOnlyMode && (
                                <div className="flex justify-end">
                                    <Button
                                        pending={pending}
                                        color="primary"
                                        text={mode === 'edit' ? t('save') : t('register')}
                                        type="submit"
                                    />
                                </div>
                            )}
                        </Card.Footer>
                    </Card>
                </form>
            </div>
        </div>
    </div>
}

export function mapStateToProps(state: State) {
    return {
        billing: getBillingProfileForm(state),
        pending: isLoadingBillingProfile(state),
        mode: getBillingFormMode(state)
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onSubmit(user: BillingForm) {
            dispatch(createBillingProfile(user))
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export const CompanyBillingContainer = connector(CompanyBilling)
