import React from 'react'
import { SearchIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import Input from '../Input/Input'

interface ShuttleListSearchProps {
    show?: boolean,
    onSearch: (value: string) => void,
    disabled: boolean
}

const ShuttleListSearch = (props: ShuttleListSearchProps) => {
    const { disabled, onSearch } = props
    const { t } = useTranslation()

    const keyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
        }
    }

    return (
        <div className={styles.container}>
            <Input
                inputClasses="border-0 shadow-none focus:ring-2 focus:ring-inset focus:ring-brand-100 py-2 pr-16 sm:pl-16 rounded-b-none md:text-base"
                icon={<SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />}
                iconClass="pl-4 sm:pl-6"
                type="text"
                autoComplete="off"
                onKeyDown={keyDownHandler}
                onChange={e => {onSearch(e.target.value)}}
                placeholder={t('shuttleSearchPlaceholder')}
                disabled={disabled}
            />
        </div>
    )
}

const styles = {
    container: 'border-b border-gray-300',
    icon: 'w-5 h-5 text-gray-500',
    input: 'w-full border-0'
}

export default ShuttleListSearch