import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import clsx from 'clsx'

type ModalProps = {
    open: boolean
    onClose: (status: boolean) => void
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '4xl' | 'full',
    title?: string
    modalClass?: string
}

export const Modal: React.FC<ModalProps> = (props) => {
    const { open, onClose, children, size = 'md', title, modalClass } = props

    const containerClasses = clsx(
        'flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0',
        size === 'full' && 'sm:px-4'
    )

    const modalClasses = clsx(
        'inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:w-full sm:p-6 sm:my-8 sm:align-middle',
        size === 'sm' && 'sm:max-w-sm',
        size === 'md' && 'sm:max-w-md',
        size === 'lg' && 'sm:max-w-lg',
        size === 'xl' && 'sm:max-w-xl',
        size === '2xl' && 'sm:max-w-2xl',
        size === '4xl' && 'sm:max-w-4xl',
        size === 'full' && 'sm:max-w-none',
        modalClass && modalClass
    )

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={onClose}>
                <div className={containerClasses}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className={modalClasses}>
                            {title && (
                                <Dialog.Title as="h3" className="text-3xl leading-6 font-medium text-gray-900 text-center px-4 pt-3 pb-8">
                                    {title}
                                </Dialog.Title>
                            )}
                            {children}
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    )
}