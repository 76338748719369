import React, { useState, useEffect } from 'react'
import { State } from '../configureStore'
import { connect } from 'react-redux'
import { getPurchasedVoices } from './voicesSelectors'
import { Dispatch } from 'redux'
import { closeModalVoice, openModalVoice } from './voicesActions'
import { ModalPurchaseVoiceContainer } from './ModalPurchaseVoice'
import { useTranslation } from 'react-i18next'
import { reloadLicenses } from './licenseActions'
import { useLocation } from 'react-router-dom'
import { ModalGatewayLicenseContainer } from './gatewayLicenses/ModalGatewayLicense'
import { closeModalGatewayLicense } from './gatewayLicenses/actions'
import Voices from './Voices'
import Gateway from './Gateway'
import { Company, canManageStore } from '../profile/selectors'
import { useServices } from './useServices'
export const directLicensePurchasePath = '/store/buy/gateway-http'

interface Props {
    canManageStore: boolean
    voices?: Array<string>
    pending: boolean
    company?: Company
    onShowPurchasableVoices: () => void,
    onReloadVoices: () => void,
    onClosePurchasableVoices: () => void,
    onClosePurchasableGateway: () => void
}

export function Services(props: Props) {
    const [purchaseVoiceOpen, setPurchaseVoiceOpen] = useState(false)
    const [purchaseLicenseOpen, setPurchaseLicenseOpen] = useState(false)
    const { t } = useTranslation()
    const location = useLocation()
    const { services, isLoading } = useServices()

    useEffect(() => {
        if (location.pathname === directLicensePurchasePath) {
            setPurchaseLicenseOpen(true)
            setPurchaseVoiceOpen(false)
        } else {
            setPurchaseLicenseOpen(false)
        }
    }, [location])


    const onCloseHandler = () => {
        if (purchaseVoiceOpen) {
            setPurchaseVoiceOpen(false)
            props.onClosePurchasableVoices()
        } else if (purchaseLicenseOpen) {
            setPurchaseLicenseOpen(false)
            props.onClosePurchasableGateway()
        }
    }

    return (<>
        <h2 className="title-page">{t('servicesTitle')}</h2>
        <Voices
            canManageStore={props.canManageStore}
            items={props.voices}
            pending={props.pending}
            purchaseHandler={() => {
                props.onShowPurchasableVoices()
                setPurchaseVoiceOpen(true)
            }}
            reloadHandler={props.onReloadVoices}
        />
        <Gateway items={services} isLoading={isLoading} />
        <ModalPurchaseVoiceContainer
            open={purchaseVoiceOpen}
            onClose={onCloseHandler}
        />
        <ModalGatewayLicenseContainer
            open={purchaseLicenseOpen}
            onClose={onCloseHandler}
        />
    </>
    )
}

export function mapStateToProps(state: State) {
    return {
        canManageStore: canManageStore(state),
        voices: getPurchasedVoices(state),
        pending: state.licenses.pending
    }
}

export function mapDispatchToProps(dispatch: Dispatch) {
    return {
        onShowPurchasableVoices() {
            dispatch(openModalVoice())
        },
        onClosePurchasableVoices() {
            dispatch(closeModalVoice())
        },
        onClosePurchasableGateway() {
            dispatch(closeModalGatewayLicense())
        },
        onReloadVoices() {
            dispatch(reloadLicenses())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Services)