import clsx from 'clsx'
import React from 'react'

const logo = require('../../../images/logo-secure-web-notag.svg').default

type MenuLogoProps = {
    otherClass?: string
}

export const MenuLogo: React.VFC<MenuLogoProps> = (props) => {
    const { otherClass = ''} = props
    const imgClass = clsx('w-auto text-white', otherClass)
    return (
        <img
            className={imgClass}
            src={logo}
            alt="Logo"
        />
    )
}