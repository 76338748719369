import { useMsal } from '@azure/msal-react'
import React, { useEffect } from 'react'
import { loginRequest } from './authConfig'

export const Authenticate = () => {

    const { instance } = useMsal()

    useEffect(() => {
        instance.loginRedirect(loginRequest)
    }, [instance])

    return <div>redirecting...</div>
}