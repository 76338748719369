export const conditions = {
    it: {
        conditions: `Queste Condizioni generali ('Condizioni generali') stabiliscono i termini e le condizioni che regolano l'utilizzo del portale di configurazione e monitoraggio www.kseniaSecureWeb.com (‘SecureWeb’), delle app Ksenia PRO e lares 4.0, un servizio di gestione remota basato sul Web (i 'Servizi'), che consente la presentazione, raccolta  e gestione dei dati delle centrali della serie lares 4.0 (le 'Unità'). Come qui utilizzati, i termini noi, ci e i nostri si riferiscono a Ksenia Security S.p.A., alle entità affiliate e ad ogni agente, contraente indipendente, designato o cessionario che Ksenia Security può, a propria discrezione, coinvolgere nella fornitura dei Servizi. I termini Utente o suo/proprio: la persona fisica o giuridica che ha acquistato o a cui sono stati concessi in licenza i Servizi (il 'Cliente') o l'individuo che ha accesso o utilizza i Servizi, sia esso il distributore, il rivenditore, l’installatore o l’utente finale.
        
Contratto di servizio

Il presente Contratto è un accordo legale. Quando si utilizza o si accede, o si consente ad altre persone fisiche o giuridiche di utilizzare o accedere ai Servizi, l'Utente accetta i termini e le condizioni delle presenti Condizioni generali, personalmente o per conto del Cliente, come pertinente. Utilizzando o accedendo ai Servizi, l'Utente dichiara e garantisce di avere autorizzato il Cliente, o un dipendente, un contraente o un agente del Cliente ad utilizzare il Servizio per conto del Cliente.
Abbiamo facoltà di modificare o cambiare, di volta in volta, le presenti Condizioni generali (imponendo le commissioni o gli oneri applicabili) a nostra esclusiva discrezione, pubblicando le Condizioni generali aggiornate sui siti web che forniscono i Servizi (il 'Sito'). Si prega di accedere e rivedere regolarmente le presenti Condizioni generali. Nel caso in cui l'Utente trovi queste Condizioni generali (inclusa ogni disposizioni modificata del presente documento) personalmente inaccettabili, in qualsiasi momento, avrà la facoltà interrompere l'utilizzo del Sito e dei Servizi. L'utilizzo del Sito o dei Servizi, una volta messe a disposizione tali modifiche, sarà considerato un'accettazione delle modifiche apportate. In caso di modifiche materiali o delle presenti Condizioni generali, le Condizioni modificate saranno pubblicate sul Sito ai fini dell'accettazione o della conferma, e all'Utente sarà richiesto di accettare o confermare tali modifiche prima di procedere all'utilizzo continuato del Sito o dei Servizi.
I Servizi sono una risorsa online che consentono la presentazione, raccolta e gestione dei dati delle Unità. Collegandosi a questa risorsa di monitoraggio, l'Utente accetta che il distributore o l'installatore abbiano accesso alle proprie Unità allo scopo di fornire servizi di supporto tecnico. Dati aggiuntivi forniti da terzi possono inoltre essere forniti all'Utente attraverso i Servizi. Ksenia Security e i suoi licenziatari possiedono il Sito e i Servizi, inclusi tutti i diritti di proprietà intellettuale in tutto il mondo in essi contenuti. Con la presente si concede all'Utente, per finalità commerciali personali o esclusivamente interne, un diritto di licenza non esclusivo, limitato, revocabile e non subappaltabile per accedere e utilizzare i Servizi in connessione all'utilizzo consentito delle Unità. 
L'Utente si impegna a non utilizzare i Servizi per qualsiasi altro scopo, senza alcuna limitazione per scopi commerciali, e in nessun caso potrà direttamente o indirettamente impegnarsi in attività di co-branding, framing, collegamento o rivendita di qualsiasi parte del Sito o dei Servizi senza il nostro previo consenso scritto. Ad eccezione di quanto espressamente consentito nel presente documento, non è consentito copiare, sviluppare ulteriormente, riprodurre, ripubblicare, modificare, alterare, scaricare, inviare, trasmettere o altrimenti utilizzare il Sito o i Servizi. L'Utente non dovrà rimuovere, alterare o nascondere alcun diritto d'autore, marchio di fabbrica, marchio di servizio o altri diritti di proprietà incorporati nel Sito o nei Servizi.

Installazione, manutenzione e gestione delle apparecchiature

L'Utente è responsabile per l'ottenimento, il pagamento, l'installazione, la manutenzione e il funzionamento tutte le apparecchiature, dei servizi di comunicazione e delle apparecchiature informatiche necessarie per accedere ai Servizi, in conformità ai requisiti che possono essere (i) forniti dai produttori delle apparecchiature e (ii) ogni altra documentazione da noi fornita all'Utente in relazione alle Unità, in qualsiasi momento. Inoltre, l'Utente riconosce di essere l'unico responsabile di tutte le spese relative alla comunicazione delle Unità con i Servizi. Noi non siamo responsabili per eventuali errori o problemi che sorgono dal malfunzionamento o dal guasto delle apparecchiature o di eventuali servizi di comunicazione ottenuti dall'Utente.
Come parte delle Condizioni generali ivi definite, l'Utente concede a Ksenia Security o ai suoi agenti di mantenere residenti alcuni strumenti preposti alla diagnosi e alla manutenzione del sistema e della rete (gli "Strumenti di supporto") al fine di facilitare il supporto e la manutenzione remoti sul prodotto dell'Utente ("Supporto remoto").  Gli Strumenti di supporto sono di sola ed esclusiva proprietà di Ksenia Security.  Inoltre, l'Utente concede a Ksenia Security di utilizzare gli strumenti di supporto e di manutenzione remoti a sua discrezione per l'installazione remota di software e la configurazione di patch, aggiornamenti e il passaggio a nuove versioni del Prodotto.  Ksenia Security può anche utilizzare gli Strumenti di supporto e di manutenzione remoti per trasmettere informazioni di Ksenia Security riguardanti le prestazioni del Prodotto.  Ksenia Security può, a propria esclusiva discrezione, scegliere di interrompere il Supporto remoto o rendere un servizio a pagamento in qualsiasi momento. 

Accesso al browser e servizi Internet

L'Utente può accedere ai Servizi utilizzando il software standard per la navigazione Internet. L'Utente riconosce che non siamo responsabili per la notifica di eventuali aggiornamenti, correzioni o miglioramenti o di fornire Supporto tecnico o di altro tipo per qualsiasi browser o per qualsiasi compromissione dei dati trasmessi attraverso le reti di computer o i servizi di telecomunicazione, inclusa, ma non limitata a, la rete. Non facciamo approvazione di alcun software di browser specifico. L'utilizzo di qualsiasi browser può essere inoltre soggetto agli accordi di licenza del licenziante del browser, e l'Utente è l'unico responsabile dell'ottemperanza dei termini di tale licenziante, in aggiunta alle disposizioni delle presenti Condizioni generali.
L'Utente è inoltre responsabile per l'ottenimento di servizi Internet tramite il provider di servizi Internet (ISP) di propria scelta, per ogni e tutti i piani tariffari imposti da tale ISP e le eventuali spese per i provider di servizi di comunicazione associati. L'Utente riconosce che sono presenti taluni rischi per la sicurezza, la corruzione, l'errore di trasmissione e la disponibilità di accesso associati all'uso di reti aperte come Internet, e con il presente si assume esplicitamente tali rischi (nella misura consentita dalla legge). L'Utente dichiara di avere richiesto i Servizi per la propria convenienza, di avere valutato in maniera indipendente l'adeguatezza di Internet come meccanismo di consegna per l'accesso alle informazioni e l'avvio di istruzioni, e che si ritiene soddisfatto di tale valutazione. Noi non siamo responsabili per eventuali servizi di accesso a Internet, o per eventuali rischi disponibilità di sicurezza, corruzione, errore di trasmissione e di accesso associati all'uso di Internet.

Contenuti

I Servizi contengono opere grafiche, applicazioni e altri materiali di Ksenia Security (i 'Contenuti Ksenia Security'). I Contenuti Ksenia Security sono protetti da copyright, marchi, brevetti, segreti commerciali e altre leggi, e Ksenia Security possiede e conserva tutti i suoi diritti sui Contenuti Ksenia Security, il Sito e i Servizi. Salvo quanto espressamente consentito nel presente documento o dalla legge applicabile, l'Utente non può, direttamente o indirettamente, copiare, sviluppare ulteriormente, decodificare, riprodurre, ripubblicare, scaricare, pubblicare, trasmettere o utilizzare in altro modo i Contenuti Ksenia Security.
L'Utente può inviare determinati contenuti al Sito per essere mostrati al momento della visualizzazione del pannello e dati della Unità, tra cui foto del sito e alcuni dettagli amministrativi. L'Utente dichiara e garantisce: (i) di possedere il contenuto pubblicato dall'Utente su o tramite il Sito o i Servizi o di avere altrimenti il diritto illimitato di concedere licenze in quanto tali, senza la necessità di ottenere alcun consenso, e senza corrispettivo, e ( ii) che la pubblicazione dei propri contenuti su o tramite il Sito o i Servizi non viola le leggi applicabili o il diritto alla privacy, i diritti di pubblicità, i diritti d'autore, i diritti contrattuali o qualsiasi altro diritto di qualsiasi persona fisica o giuridica. L'Utente accetta di indennizzare noi per eventuali rivendicazioni da parte di terzi a seguito della violazione delle presenti dichiarazioni e garanzie.
Alcuni dati o altre informazioni di terze parti possono essere forniti dal Sito ('Contenuti di terze parti'). Ksenia Security non è responsabile per eventuali Contenuti di terze parti errati o imprecisi pubblicati su o resi disponibile tramite il Sito o in connessione con i Servizi. I Contenuti di terze parti possono contenere link ad altri siti web. Ksenia Security non è responsabile per l'accuratezza del contenuto o per le opinioni espresse dai Contenuti di terze parti o attraverso tali siti collegati, e tali Contenuti di terze parti e i siti web collegati non sono in alcun modo indagati, monitorati o controllati ai fini dell'accuratezza o della completezza da parte di Ksenia Security.

Password

Per impedire l'accesso non autorizzato ai dati e per impedire l'uso non autorizzato dei Servizi, l'Utente accetta di proteggere e mantenere riservati il seriale della Unità (ID Utente) e la password (PIN) utilizzati per accedere ai Servizi ('Password'), o altri mezzi di accesso ai dati o alle informazioni tramite i Servizi. La perdita, il furto o l'utilizzo non autorizzato del proprio ID utente e della propria password potrebbe causare il danneggiamento o la perdita dei dati delle Unità. L'Utente non può fornire l'ID utente e la password di qualsiasi altro individuo. Divulgando gli ID utente o le password a qualsiasi persona fisica o giuridica, l'Utente si assume tutti i rischi e tutte le perdite associati a tale divulgazione. Laddove l'Utente consenta a qualsiasi altra persona fisica o giuridica di utilizzare i Servizi o di accedere a o utilizzare i propri ID utente, password o altri mezzi per accedere ai dati, l'Utente sarà ritenuto responsabile di tutte le attività svolte e di qualsiasi utilizzo di tali dati da parte di tali persone fisiche o giuridiche. Noi possiamo a nostra scelta modificare i parametri per la password, senza alcun preavviso, e in tal caso, l'Utente sarà invitato a cambiare la password.

Avvisi

L'Utente può scegliere di ricevere determinate segnalazioni e notifiche i dati e le prestazioni delle Unità, così come altri dati e informazioni relativi al Sito, ai Servizi, alle unità e le relative prestazioni. Tali relazioni saranno inviate elettronicamente tramite notifica push sulle app Ksenia PRO e lares 4.0. L'Utente accetta che utilizzando i Servizi, tutti gli Avvisi o le ulteriori comunicazioni che possano rendersi necessarie al fine di ottemperare ai nostri obblighi derivanti ai sensi delle presenti Condizioni generali o dei Servizi possano essere inviati all'Utente tramite notifica push o con l'indirizzo e-mail fornito dall'Utente stesso al momento della registrazione ai Servizi o a un altro indirizzo e-mail che l'Utente abbia eventualmente specificato. Inoltre, l'Utente acconsente a ricevere comunicazioni elettroniche da parte nostra, tra cui, senza limitazioni, le comunicazioni o altri materiali che riteniamo possano essere di interesse per l'Utente per l’utilizzo del servizio. Laddove l'Utente non desiderasse ricevere tali comunicazioni, può scegliere di non riceverne dandocene notifica a mezzo di posta elettronica all'indirizzo info@kseniasecurity.com
I Servizi possono includere una funzione che consente la comunicazione dei dati raccolti dalle Unità al fine di essere trasmessi direttamente agli enti governativi di terze parti o a determinati destinatari inclusi nella lista di distribuzione a cui gli utenti potrebbero avere un interesse a divulgare tali dati ("Rapporto"). Questa funzione può essere disponibile per gli utenti solo residenti in alcuni luoghi designati. L'Utente è autorizzato ad utilizzare i Servizi per la segnalazione di tali dati in siffatta maniera, previa attivazione di tale funzione. Questa funzione viene offerta all'Utente come vantaggio, e, senza limitare l'effetto dei termini stabiliti nel paragrafo “Limitazione della responsabilità - Nessuna garanzia” di seguito riportato, Ksenia Security declina ogni responsabilità per eventuali inesattezze nei dati o qualsiasi fallimento di questa caratteristica dei Servizi di riferire dati accurati (o tutti i dati). Ksenia Security non potrà in alcun caso essere ritenuta responsabile per l'Utente, qualsiasi cliente o terzi in relazione alle informazioni trasmesse oppure non trasmesse a enti governativi o ai destinatari inclusi nella lista di distribuzione dalle Unità o tramite i Servizi, e l'Utente accetta di manlevare e tenere Ksenia Security indenne da o verso qualsiasi perdita, pretesa, danno o responsabilità derivante dallo stesso. L'uso del rapporto dei dati raccolti è limitato a un uso personale, aziendale o di marketing. Fatta eccezione per tale utilizzo espressamente consentito in questo paragrafo, l'Utente non può modificare, alterare scaricare, il Rapporto. l'Utente non può rimuovere, alterare o nascondere qualsiasi diritto d'autore, marchio di fabbrica, marchio di servizio o altri diritti di proprietà incorporati nel Rapporto.

Nuove funzionalità

Di volta in volta, è possibile che siano introdotte nuove funzionalità ai Servizi o modificate o eliminate le funzionalità esistenti a nostra esclusiva discrezione. Invieremo notifica di una qualsiasi di queste modifiche alle funzionalità, laddove fossimo legalmente obbligati a farlo. Utilizzando tutte le funzionalità, nuove o modificate, non appena divenute disponibili, l'Utente accetta di essere vincolato dalle norme disciplinanti tali funzionalità.

Tariffe dei Servizi

L'accesso generale ai Servizi è attualmente fornito all'Utente senza costi aggiuntivi. L'Utente riconosce che Ksenia Security si riserva il diritto di addebitare i costi correlati alla fornitura dell'accesso al Sito e ai Servizi, e di avere la facoltà di modificare, di volta in volta, il proprio piano tariffario, a propria esclusiva discrezione. Le Tariffe dovute per il Sito e i Servizi, nonché i termini e le condizioni ad esse applicabili, devono essere enunciati in un accordo separato da negoziare tra Ksenia Security e un cliente specifico. I termini di tali accordi possono differire in modo sostanziale tra i diversi clienti.

Limitazione di responsabilità - Nessuna garanzia

SALVO QUANTO SPECIFICATAMENTE IVI DELINEATO O RICHIESTO DA OGNI ALTRA NORMATIVA, KSENIA SECURITY NON SARÀ RESPONSABILE PER PERDITE, DANNI O LESIONI O PER DANNI DIRETTI, INDIRETTI, SPECIALI, INCIDENTALI, ESEMPLARI O CONSEGUENTI, INCLUSI I MANCATI PROFITTI, DERIVANTI DA O RELATIVI ALLE UNITÀ O AL MALFUNZIONAMENTI DELLE UNITÀ, ALLE APPARECCHIATURE, AI BROWSER E/O ALL'INSTALLAZIONE O ALLA MANUTENZIONE DEGLI STESSI, ALL'ACCESSO O ALL'UTILIZZO DEI SERVIZI, AL GUASTO DI APPARECCHIATURE ELETTRONICHE O MECCANICHE, DELLE LINEE DI COMUNICAZIONE O TELEFONICHE O AD ALTRI PROBLEMI DI INTERCONNESSIONE, BUG, ERRORI, VIRUS, CAVALLI DI TROIA E PROBLEMI DI INCOMPATIBILITÀ DI HARDWARE O SOFTWARE, GUASTO O INDISPONIBILITÀ DELLA CONNETTIVITÀ INTERNET, PROBLEMI CON I PROVIDER DEI SERVIZI INTERNET, PROBLEMI O RITARDI CON RETI INFORMATICHE O SERVIZI DI COMUNICAZIONE, PROBLEMI CON SERVIZI DI TRASMISSIONE DATI O QUALSIASI ALTRO PROBLEMA RISCONTRATO DALL'UTENTE PER CAUSE DI FORZA MAGGIORE. KSENIA SECURITY NON SARÀ RESPONSABILE PER ERRORI ALL'INTERNO DEL SITO O DEI SERVIZI, IVI COMPRESE MISURAZIONI DEI DATI INESATTE O MANCANTI DA PARTE DEI SERVIZI, O NEL CASO IN CUI I SERVIZI MANCHINO DI RILEVARE, O RILEVINO IN MODO ERRATO, EVENTUALI GUASTI NELLE UNITÀ.
AD ECCEZIONE DI QUANTO ESPRESSAMENTE PREVISTO IN QUALSIASI CONTRATTO APPLICABILE, L'UTENTE RICONOSCE E ACCETTA CHE L'UTILIZZO DEI SERVIZI È A PROPRIO RISCHIO E CHE I SERVIZI E TUTTE LE INFORMAZIONI, I PRODOTTI E ALTRI CONTENUTI (IVI COMPRESI INFORMAZIONI, PRODOTTI E CONTENUTI DI TERZE PARTI) INCLUSI NEI O ACCESSIBILI DAI SITI, SONO FORNITI COME SONO, DOVE SONO E IN BASE ALLA DISPONIBILITÀ.
L'UTENTE RICONOSCE DI NON RICEVERE ALCUNA GARANZIA CHE I SERVIZI SIANO ININTERROTTI, TEMPESTIVI, SICURI O PRIVI DI ERRORI SE NON DIVERSAMENTE INDICATO NEL SITO O IN QUALSIASI CONTRATTO APPLICABILE. NELLA MISURA MASSIMA CONSENTITA DALLA LEGGE, DECLINIAMO TUTTE LE DICHIARAZIONI, GARANZIE E CONDIZIONI DI QUALSIASI TIPO (ESPRESSE, IMPLICITE, LEGALI O ALTRIMENTI, INCLUSE, MA NON LIMITATE A, LE GARANZIE DI COMMERCIABILITÀ E IDONEITÀ PER UN PARTICOLARE SCOPO, TITOLO E NON VIOLAZIONE DI DIRITTI DI PROPRIETÀ) NEI CONFRONTI DEI SERVIZI E DI TUTTE LE INFORMAZIONI, I PRODOTTI E GLI ALTRI CONTENUTI (IVI COMPRESI LE INFORMAZIONI, I PRODOTTI E I CONTENUTI DI TERZE PARTI) INCLUSI O ACCESSIBILI DAI SITI. NESSUNA LICENZA È IMPLICITAMENTE CONCESSA ALL'UTENTE IN TALI RINUNCE.

Altri accordi od obblighi

In aggiunta alle presente Condizioni generali, l'Utente accetta di essere vincolato da e di rispettare ogni altro requisito scritto da noi eventualmente fornito all'Utente in correlazione sia ai Servizi e/o alle Unità, e di tutte le leggi e le normative nazionali ed estere applicabili. L'Utente è obbligato a rivedere e utilizzare le Unità e i Servizi in conformità alla documentazione del Prodotto o di ogni altra documentazione da noi eventualmente o resa disponibile all'Utente.

Diritto di risoluzione

Possiamo interrompere o risolvere le presenti Condizioni generali, o interrompere, sospendere o limitare i privilegi di accesso ai Servizi, in tutto o in parte, in qualsiasi momento, per qualsiasi motivo, con o senza preavviso. I clienti riconoscono che il Sito ed i Servizi possono essere sospesi per motivi indipendenti dalla volontà di Ksenia Security. Ksenia Security non avrà alcuna responsabilità per qualsiasi sospensione temporanea del Sito e dei Servizi di durata inferiore a trenta (30) giorni, e Ksenia Security non avrà alcuna responsabilità di fornire ai clienti un preavviso di tale sospensione. L'Utente sarà informato con 90 giorni di preavviso per iscritto prima di qualsiasi cessazione dei Servizi, salvo nei casi in cui la cessazione del Sito o dei Servizi si riferiscano alla cessazione dell'utilizzo delle Unità, all'incapacità dell'Utente di soddisfare i termini di pagamento o gli altri obblighi relativi alle Unità o alla violazione delle presenti Condizioni generali o di altro accordo intercorrente tra l'Utente e i sottoscritti o la parte da cui le Unità sono state acquistate (incluso qualsiasi accordo di pagamento), nel qual caso non è previsto alcun preavviso. Gli obblighi e le responsabilità delle parti sostenute prima della data di cessazione devono sopravvivere alla risoluzione delle presenti Condizioni generali a tutti gli effetti.

Risarcimento

L'Utente riconosce e accetta di essere personalmente responsabile della propria condotta durante l'utilizzo del Sito e dei Servizi e accetta di indennizzare i sottoscritti e i nostri funzionari, dirigenti, dipendenti e agenti da e contro qualsiasi perdita, danno, responsabilità, costo o spesa di qualsiasi tipo (ivi comprese, ma non limitatamente a, le ragionevoli spese legali) in cui i sottoscritti possano incorrere in relazione a una richiesta di terze parti o in altro modo, in relazione all'utilizzo del Sito e dei Servizi o all'utilizzo del Sito e dei Servizi da parte di chiunque a nome dell'Utente con il rispettivo nome utente o la rispettiva password o la violazione delle presenti Condizioni generali o dei diritti di terzi (ivi compreso, ma non limitatamente a, il diritto alla privacy). Gli obblighi di cui al presente paragrafo sopravvivranno alle presenti Condizioni generali.

Comunicazioni dei registri

La nostra Informativa sulla privacy è disponibile all'indirizzo http://www.kseniasecurity.com/it/privacy-info. Come meglio specificato in essa, manterremo riservati i dati commerciali e le registrazioni relative all'account dell'utente, ivi comprese tutte le informazioni raccolte dalle Unità e tutte le informazioni presenti sul Sito e raccolte tramite i Servizi. Ogni altra comunicazione o materiale trasmessoci tramite il Sito o posta elettronica (tra cui eventuali osservazioni e suggerimenti riguardanti il Sito e i Servizi) saranno considerati non riservati, ad eccezione di quanto vietato ai sensi di legge, e i sottoscritti potranno utilizzare tali comunicazioni o materiali per qualsiasi scopo coerente con la normativa sulla privacy, ivi comprese la riproduzione, la pubblicazione, la trasmissione e la pubblicazione.
Se un ID utente o una password da noi fornito/a all'Utente, in qualità di rappresentante del cliente registrato ai Servizi, non possa essere trattenuto/a dall'Utente dopo l'interruzione del proprio rapporto con tale cliente, l'Utente accetta di darcene immediata comunicazione. L'Utente accetta altresì di informarci tempestivamente in merito ad ogni persona con accesso ad una password che non sia più alle dipendenze del soggetto a cui sia stata assegnata la Password.

Software open source

Alcuni elementi di codice indipendente che sono inclusi nei software di seguito menzionati sono soggetti a varie licenze di software gratuito oppure open source (i “Software open source”). I Software open source elencati nell'Appendice A, come eventualmente aggiornati, di volta in volta, dandone comunicazione sul sito web di Ksenia Security o con preavviso all'Utente, sono concessi in licenza secondo i termini delle rispettive licenze per gli utenti finali. Nessuna disposizione del presente Contratto limita i diritti dell’Utente di cui alla, o concede diritti in sostituzione della, licenza per l'Utente finale applicabile per tale Software open source. I termini del presente Contratto, diversi da quelli di responsabilità e limitazione di responsabilità, non si applicheranno ai Software open source. L’Utente si impegna inoltre a non utilizzare alcun Software gratuito e open source (Free and Open Source Software, FOSS), come di seguito definito, in modo tale da causare che le porzioni non FOSS del Software siano oggetto di eventuali termini o obblighi di licenza del Software open source. Per FOSS si intende qualsiasi software che sia soggetto a termini che, come condizione per l'uso, la copia, la modifica o la ridistribuzione, richiedano che tale software e/o le opere derivate siano divulgati o distribuiti in forma di codice sorgente, per essere concessi in licenza allo scopo di renderli opere derivate, o per essere ridistribuiti gratuitamente, ivi compresi, senza limitazioni, i software distribuiti con licenza GNU General Public License, Affero GPL o Lesser/Library GPL.

Collegamenti ipertestuali dei Siti

I Siti possono contenere link ad altri siti web di terze parti. Noi sottoscritti non siamo responsabili per, né abbiamo il controllo su, i contenuti, i prodotti i o servizi forniti da siti collegati. Noi non approviamo né forniamo alcuna garanzia relativa ai prodotti, alle informazioni o alle raccomandazioni fornite da siti collegati, e non siamo responsabili per qualsiasi guasto di prodotti o servizi pubblicizzati su quei siti. Inoltre, ogni sito di terze parti può fornire un livello di sicurezza inferiore rispetto al livello di sicurezza da noi fornito e adottare una politica sulla privacy diversa dalla nostra. L'accesso, l’uso e l’affidamento riposto su tali contenuti, prodotti o servizi sono a rischio dell’Utente e soggetti ai termini di utilizzo di tali siti.

Scelta della giurisdizione/Successori - Rinuncia - Separabilità

Le presenti Condizioni generali e la loro esecuzione forzata sono disciplinate dalle leggi dello Stato Italiano, a prescindere da qualsiasi scelta di disposizione di legge, e sono in vigore a beneficio dei nostri successori e aventi causa, sia per fusione, consolidamento o altro. Accedendo a questo Sito da un'altra giurisdizione, l’Utente è responsabile di garantire il rispetto delle leggi locali in materia di accesso e utilizzo di questo Sito. Non saremo ritenuti rinunciatari di alcuno dei nostri diritti o rimedi contemplati nelle presenti Condizioni generali, salve che tale rinuncia avvenga in forma scritta e sia firmata dai sottoscritti. Nessun ritardo o nessuna omissione da parte nostra nell'esercizio eventuali diritti o rimedi varrà come rinuncia a tali diritti o rimedi o a qualsiasi altro diritto o rimedio. Una rinuncia in qualsiasi occasione non dovrà essere interpretata come un’eccezione o una rinuncia a qualsiasi diritto o rimedio in occasioni future. Se una qualsiasi disposizione delle presenti Condizioni generali dovesse entrare in conflitto con la legge in base alla quale le presenti Condizioni generali sono da intendersi o se qualsiasi disposizione delle presenti Condizioni generali dovesse essere ritenuta non valida o non applicabile da un tribunale competente, tale disposizione dovrà essere rideterminata al fine di riflettere più fedelmente possibile le intenzioni originali delle parti in conformità alla legge applicabile. Le restanti disposizioni delle presenti Condizioni generali e l'applicazione della disposizione contestata nei confronti di persone o circostanze diverse da quelle per cui è ritenuta non valida o non applicabile non ne saranno influenzate in alcun modo, e ciascuna di suddette disposizioni sarà ritenuta valida e applicabile nella misura massima consentita dalla legge.
Per qualsiasi controversia il tribunale competente è il foro di Ascoli Piceno.

Appendice A

Software open source:
• Linux Ubuntu – GPL
• Java – Accordo di licenza Oracle per java
• Apache software foundation - Licenza Apache, Versione 2.0
• EMQ X - Licenza Apache, Versione 2.0

Revisione: Dicembre 2018`,
        title: `Portale di configurazione e monitoraggio www.kseniaSecureWeb.com, app Ksenia PRO e app lares 4.0

Condizioni generali`,
        agree: "Accetto",
        note: "Ai sensi ed agli effetti di cui agli artt 1341 e 1342 del Codice Civile italiano il Cliente dichiara di approvare specificatamente le seguenti clausole 4,6,7,8,13."
    },
    en: {
        conditions: `These General Conditions (' General Conditions ') establish the terms and conditions governing the use of the configuration and monitoring portal www.kseniasecureweb.com ( ' SecureWeb ') , of the app Ksenia PRO and lares 4.0, a web-based remote management service (the ' Services '), which allows the presentation, collection and management of data of the control panel of the lares 4.0 series (the ' Units '). As used herein, the terms us refer to Ksenia Security S.p.A. , affiliated entities and any agent, independent contractor, appointed or assignee that Ksenia Security may, at its discretion, involve in providing the Services. The terms User or his/her own: the natural or legal person who has purchased or licensed the Services (the ' Customer ') or the individual who has access to or uses the Services, whether it is the distributor, the retailer, the installer or the end user.

Service contract

This Agreement is an accord or legal agreement. When you use or log in, or allow other physical or legal persons to use or access the Services, you agree to the terms and conditions of these General Terms, either personally or on behalf of the Customer, as applicable. By using or accessing the Services, you represent and warrant that you have authorized the Customer, or an employee, contractor or Client of the Customer to use the Service on behalf of the Customer.
We may change or change these General Terms from time to time (by imposing the applicable fees or charges) at our sole discretion, by publishing the updated General Conditions on the websites providing the Services (the 'Site'). Please access and regularly review these Terms and Conditions. In the event that the User finds these General Conditions (including any modified provisions of this document) personally unacceptable, at any time, he will have the right to stop using the Site and the Services. The use of the Site or the Services, once these changes are made available, will be considered an acceptance of the changes made. In case of material changes or these General Conditions, the amended Conditions will be published on the Site for acceptance or confirmation, and the User will be asked to accept or confirm such changes before proceeding to the continued use of the Website or the Services.
The Services are an online resource that allows the presentation, collection and management of data and units. By connecting to this monitoring resource, the User agrees that the distributor or installer has access to its Units for the purpose of providing technical support services. Additional data provided by third parties can also be provided to the User through the Services. Ksenia Security and its licensors own the Site and the Services, including all intellectual property rights in the world they contain. We hereby grant you, for personal or internal business purposes only, a non-exclusive, limited, revocable and non-subcontracting license right to access and use the Services in connection with the permitted use of the Units.
The User agrees not to use the Services for any other purpose, without any limitation for commercial purposes, and in no case can directly or indirectly engage in activities of co- branding, framing, linking or reselling any part of the Website or Services without our prior written consent. Except as expressly permitted herein, it is not permitted to copy, further develop, reproduce, republish, modify, alter, download, send, transmit or otherwise use the Site or the Services. The User shall not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights incorporated into the Site or the Services.

Installation, maintenance and management of the equipment

The User is responsible for obtaining, payment, installation, maintenance and operation of all the equipment, communication services and computer equipment necessary to access the Services, in accordance with the requirements that may be (i) provided by the equipment manufacturers and (ii) any other documentation we provide to you in relation to the Units at any time. Furthermore, the User acknowledges that he is solely responsible for all expenses related to the communication of the Units with the Services. We are not responsible for any errors or problems that arise from the malfunction or failure of the equipment or any communication services obtained by the User.
As part of the General Terms and Conditions defined therein, the User grants Ksenia Security or its agents to keep residents some tools for diagnosing and maintaining the system and the network ( the "Support Tools") in order to facilitate support and remote maintenance on the User's product ("Remote Support").  Support Tools are the sole and exclusive property of Ksenia Security.   In addition, the User grants Ksenia Security to use remote support and maintenance tools at its discretion for remote software installation and configuration of patches, updates and upgrading to new versions of the Product.   Ksenia Security can also use remote support and maintenance tools to transmit information from Ksenia Security regarding the performance of the Product. Ksenia Security may, in its sole discretion, choose to discontinue Remote Support or make a paid service at any time.  

Access to the browser and Internet services

The User can access the Services using the standard software for Internet browsing. The User acknowledges that we are not responsible for notifying any updates, corrections or improvements or providing technical or other support for any browser or for any compromise of data transmitted through computer networks or telecommunications services, including, but not limited to, the network. We do not approve any specific browser software. The use of any browser may also be subject to the licensor agreements of the browser, and the User is solely responsible for compliance with the terms of this licensor, in addition to the provisions of these Terms and Conditions.
The User is also responsible for obtaining Internet services through the Internet service provider (ISP) of his choice, for each and all tariff plans imposed by that ISP and any expenses for the associated communication service providers. The User acknowledges that there are certain risks to security, corruption, transmission errors and the availability of access associated with the use of open networks such as the Internet, and hereby explicitly assumes these risks (to the extent permitted by law). The User declares to have requested the Services for their own convenience, to have independently assessed the adequacy of the Internet as a delivery mechanism for access to information and the start of instructions, and which is satisfied with this assessment . We are not responsible for any Internet access services, or for any risks, availability of security, corruption, transmission errors and access associated with Internet use.

Contents

The Services contain graphic works, applications and other materials from Ksenia Security (the ' Ksenia Security Content '). Ksenia Security Content is protected by copyrights, trademarks, patents, trade secrets and other laws, and Ksenia Security owns and retains all of its rights to the Ksenia Security Content, the Site and the Services. Except as expressly permitted herein or by applicable law, you may not directly or indirectly copy, further develop, reverse engineer, reproduce, republish, download, publish, transmit or otherwise use the Ksenia Security Content .
You can send certain content to the site to be shown at the time of the panel display and data of a Unit, including photos of the site and some administrative details. The User declares and guarantees: (i) to possess the content published by the User on or through the Site or the Services or to have otherwise the unlimited right to grant licenses as such, without the need to obtain any consent, and without consideration , and (ii) that the publication of its contents on or through the Site or Services does not violate applicable laws or the right to privacy, rights of publicity, copyrights, contractual rights or any other right of any person physical or legal. The User accepts to indemnify us for any claims by third parties following the violation of these declarations and guarantees.
Some data or other information from third parties may be provided by the Site (' Third Party Content ' ). Ksenia Security is not responsible for any incorrect or inaccurate Third Party Content published on or made available through the Site or in connection with the Services. Third Party Content may contain links to other websites. Ksenia Security is not responsible for the accuracy of the content or opinions expressed by the Third Party Content or through such linked sites, and such Third Party Content and Linked Websites are in no way investigated, monitored or controlled for the purpose of accuracy or the completeness from Ksenia Security .

Password

To prevent unauthorized access to data and to prevent unauthorized use of the Services, the User agrees to protect and keep the serial of the Unit confidential (User ID) and password (PIN) used to access the Services (' Password '), or other means of accessing data or information through the Services. Loss, theft or unauthorized use of your user ID and password may result in damage or loss of unit data. The User can not provide the user ID and password of any other individual. By divulging the user IDs or passwords to any natural or legal person, the User assumes all risks and losses associated with such disclosure. Where the User allows any other natural or legal person to use the Services or to access or use their user IDs, passwords or other means to access the data, the User will be held responsible for all activities carried out and for any use of such data by such natural or legal persons. We may at our option change the parameters for the password, without any prior notice, and in this case, the User will be asked to change the password.

Notifications

The User may choose to receive certain reports and notifications of the data and performance of the Units, as well as other data and information relating to the Site, the Services, the units and their performance. These reports will be sent electronically via push notification on the app Ksenia PRO and lares 4.0 . The User accepts that by using the Services, all Notices or other communications that may become necessary in order to comply with our obligations under these General Conditions or Services can be sent to the User via push notification or with the e-mail address provided by the User at the time of registration to the Services or another e-mail address that the User may have specified. Furthermore, the User agrees to receive electronic communications from us, including, without limitation, communications or other materials that we believe may be of interest to you for the use of the service. Where the User does not wish to receive such communications, he/she may choose not to receive it by e-mail to the address info@kseniasecurity.com
The Services may include a function that allows the communication of data collected by the Units in order to be transmitted directly to third-party government agencies or to certain recipients included in the distribution list to which users may have an interest in disclosing such data ("Report "). This feature may be available to users only residing in some designated locations. The User is authorized to use the Services to report such data in such a way, after activating this function. This function is offered to the User as an advantage, and without limiting the effect of the terms established in the paragraph below " Limitation of Liability - No Warranty ", Ksenia Security declines any responsibility for any inaccuracies in the data or any failure of this feature of the Services to report accurate data (or all data). Ksenia Security can not in any case be held responsible for the User, any customer or third parties in relation to the information transmitted or not transmitted to government agencies or recipients included in the distribution list by the Units or through the Services, and the User agrees to hold harmless and keep Ksenia Security free from or to any loss, claim, damage or liability arising from the same. Use of the collected data report is limited to personal, business or marketing use. Except for this use expressly permitted in this paragraph, the User can not modify, alter download, the Report. You may not remove, alter or hide any copyright, trademark, service mark or other proprietary rights incorporated into the Report.

New features

From time to time, it is possible that new features will be introduced to the Services or changed or removed from existing features at our sole discretion. We will send notification of any of these feature changes, where we are legally obligated to do so. Using all the features, new or changed, as soon as they become available, the User agrees to be bound by the rules governing these features.

Rates of the Services

General access to the Services is currently provided to the User at no additional cost. The User acknowledges that Ksenia Security reserves the right to charge the costs related to the provision of access to the Site and the Services, and to have the right to modify, from time to time, its own tariff plan, at its sole discretion. The fees due for the Site and the Services, as well as the terms and conditions applicable to them, must be stated in a separate agreement to be negotiated between Ksenia Security and a specific customer. The terms of such agreements may differ substantially between different customers.

Disclaimer - No warranty

EXCEPT AS SPECIFICALLY STATED ABOVE OR REQUIRED BY EVERY OTHER LEGISLATION, KSENIA SECURITY WILL NOT BE LIABLE FOR LOSS, DAMAGE OR INJURY OR FOR DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, ARISING OUT OF OR RELATED TO UNITS OR MISSING UNITS, EQUIPMENT, BROWSER AND / OR ALL INSTALLATION OR MAINTENANCE OF THE SAME, ACCESS OR USE OF THE SERVICES, TROUBLESHOOTING OF ELECTRONIC OR MECHANICAL EQUIPMENT, COMMUNICATION OR TELEPHONE LINES OR OTHER PROBLEMS OF INTERCONNECTION, BUG, ERRORS, VIRUSES, TROJAN HORSES AND PROBLEMS OF INCOMPATIBILITY OF HARDWARE OR SOFTWARE, FAILURE OR INDISPONIBILITY OF INTERNET CONNECTIVITY, PROBLEMS WITH INTERNET SERVICE PROVIDERS, PROBLEMS OR DELAYS WITH COMPUTER NETWORKS OR COMMUNICATION SERVICES, PROBLEMS WITH DATA TRANSMISSION SERVICES OR ANY OTHER PROBLEM DISCLAIMED BY THE USER FOR CAUSES OF MAJOR FORCE. KSENIA SECURITY YOU WILL NOT BE LIABLE FOR ERRORS WITHIN THE SITE OR SERVICES, INCLUDING MEASUREMENTS OF DATA INJURY OR MISSING BY THE SERVICES, OR IN THE EVENT OF WHETHER THE SERVICES ARE LACKING TO DETECT, OR DETECT IN ANY INCORRECT WAY, ANY FAILURES IN THE UNITS.
EXCEPT AS EXPRESSLY PROVIDED IN ANY APPLICABLE CONTRACT, YOU ACKNOWLEDGE AND AGREE THAT THE USE OF THE SERVICES IS AT YOUR OWN RISK AND THAT THE SERVICES AND ALL THE INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING INFORMATION, PRODUCTS, AND CONTENT OF THIRD PARTY) INCLUDED IN OR ACCESSIBLE BY THE SITES, ARE PROVIDED AS IS, WHERE AND ARE ON THE BASIS OF AVAILABILITY.
THE USER RECOGNIZES NOT TO RECEIVE ANY WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE IF NOT OTHERWISE SPECIFIED IN THE SITE OR IN ANY APPLICABLE CONTRACT. TO THE MAXIMUM EXTENT PERMITTED BY LAW, WE DECLARE ALL STATEMENTS, WARRANTIES AND CONDITIONS OF ANY KIND (EXPRESS, IMPLIED, LEGAL OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS) IN RESPECT OF THE SERVICES AND OF ALL THE INFORMATION, PRODUCTS AND OTHER CONTENT (INCLUDING THE INFORMATION, THE PRODUCTS AND THE CONTENTS OF THIRD PARTIES) INCLUDED OR ACCESSIBLE BY THE SITES.NO LICENSE IS EXPRESSLY GRANTED TO USER IN THE CONDITION STATED ABOVE.

Other agreements or obligations

In addition to these General Terms, the User agrees to be bound by and to comply with any other written requirement that we may provide to the User in correlation with both the Services and / or Units, and all laws and regulations applicable. The User is obliged to review and use the Units and Services in accordance with the documentation of the Product or any other documentation that we may make available to the User.

Right of resolution

We may terminate or terminate these General Terms, or discontinue, suspend or limit the privileges of access to the Services, in whole or in part, at any time, for any reason, with or without notice. Customers acknowledge that the Site and the Services may be suspended for reasons beyond the control of Ksenia Security. Ksenia Security will have no responsibility for any temporary suspension of the Site and Services for a period of thirty (30) days, and Ksenia Security will have no responsibility to provide customers with advance notice of such suspension. The User will be informed with 90 days of written notice before any termination of the Services, except in cases where the termination of the Site or the Services refers to the termination of the use of the Units, to the User's inability to satisfy the terms of payment or other obligations relating to the Units or the violation of these General Conditions or other agreement between the User and the undersigned or the part from which the Units were purchased (including any payment agreement), in which case no advance notice is required. The obligations and responsibilities of the parties incurred before the termination date must survive the termination of these General Terms and Conditions for all purposes.

Compensation

Customer acknowledges and agrees to be personally responsible for his conduct while using the Site and the Services and agree to indemnify the undersigned and our officers, officers, employees and agents to and from any loss, damage, liability, cost or expense of any kind (including, but not limited to, the reasonable legal fees) in which the undersigned may incur in relation to a request by third parties or otherwise, in connection with the use of the Site and the Services or for the use of the Site and the Services by anyone in the name of the User with the respective user name or the respective password or the violation of these General Conditions or the rights of third parties (including, but not limited to, the right to privacy). The obligations set forth in this paragraph will survive these General Conditions.

Communications of the registers

Our privacy policy is available at   http://www.kseniasecurity.com/en/privacy-info . As better specified in it, we will keep confidential business data and records related to the user's account, including all information collected by the Units and all information on the Site and collected through the Services. Any other communication or material transmitted via the Site or electronic mail (including any comments and suggestions regarding the Site and the Services) will be considered non-confidential, except as prohibited by law, and the undersigned may use such communications or materials for any purpose consistent with the privacy policy, including the reproduction, publication, transmission and publication.
If a user ID or password that we provide to you, as a representative of the customer registered with the Services, can not be retained by you after terminating your relationship with that customer, you agree to immediately communicate it. The User also agrees to promptly inform us about any person with access to a password that is no longer dependent on the subject to whom the Password has been assigned.

Open source software

Some elements of independent code that are included in the software mentioned below are subject to various free or open source software licenses (the "Open Source Software"). The open source Software listed in Appendix A, as eventually updated, from time to time, by communicating it on the Ksenia Security website or with notice to the User, are licensed under the terms of the respective licenses for end users. Nothing in this Agreement restricts the User's rights under or grant rights in lieu of the end-user license applicable to such open source Software. The terms of this Agreement, other than those of liability and limitation of liability, will not apply to open source Software. The User also undertakes not to use any Free Software and Open Source Software (FOSS), as defined below, in such a way as to cause that the non-FOSS portions of the Software are subject to any terms or obligations of open source software license. FOSS means any software that is subject to terms that, as a condition of use, copying, modification or redistribution, require that such software and / or derivative works be disclosed or distributed in source code form, for be licensed to make them derivative works, or to be redistributed free of charge, including, without limitation, software distributed under the GNU General Public License, Affero GPL or Lesser / Library GPL.

Hyperlinks of the Sites

The Sites may contain links to other third-party websites. We, the undersigned, are not responsible for, nor do we have control over, contents, products or services provided by linked sites. We do not endorse or provide any warranty regarding the products, information or recommendations provided by linked sites, and we are not responsible for any failure of products or services advertised on those sites. In addition, each third-party site may provide a lower level of security than the level of security we provide and adopt a privacy policy different from ours. Access, use and custody placed on such content, products or services are at the risk of the User and subject to the terms of use of such sites.

Choice of jurisdiction / Successors - Renunciation - Separability

These General Conditions and their forced execution are governed by the laws of the Italian State , regardless of any choice of provision of law, and are in force for the benefit of our successors and assignees, either by merge, consolidation or otherwise. By accessing this Site from another jurisdiction, you are responsible for ensuring compliance with local laws regarding access to and use of this Site. We will not be deemed to have renounced any of our rights or remedies in these General Terms, that such renunciation takes place in written form and is signed by the undersigned. No delay or omission on our part in the exercise of any rights or remedies will be valid as a waiver of such rights or remedies or any other right or remedy. A waiver on any occasion shall not be construed as an exception or a waiver of any right or remedy on future occasions. Should any provision of these General Terms conflict with the law under which these General Terms are intended or if any provision of these General Terms is held to be invalid or unenforceable by a competent court, such provision shall be determined in order to reflect as accurately as possible the original intentions of the parties in accordance with applicable law. The remaining provisions of these General Terms and the application of the disputed provision to persons or circumstances other than those for which it is deemed invalid or unenforceable shall not be affected in any way, and each of the above provisions will be deemed valid and enforceable. to the maximum extent permitted by law.
For any controversy the competent court is the forum of Ascoli Piceno.

Appendix A

Open source software:
• Linux Ubuntu - GPL
• Java - Oracle license agreement for java
• Apache software foundation - Apache License, Version 2.0
• EMQ X - Apache License, Version 2.0

Revision: December 2018`,
        title: `Portal of configuration and monitoring www.kseniasecureweb.com, app Ksenia PRO and app lares 4.0

General conditions`,
        agree: "I Accept",
        note: "Pursuant to and for the purposes of articles 1341 and 1342 of the Italian Civil Code, the Client specifically approves the following clauses 4,6,7,8,13."
    }
}