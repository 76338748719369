import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useTranslation } from 'react-i18next'
import { Spinner } from '../common/Spinner'

export function ModalDialog(props: React.PropsWithChildren<{
    open: boolean,
    title: string,
    containerStyle?: string,
    onClose: () => void,
    pending?: boolean,
    description?: string
}>) {
    const { t } = useTranslation()
    const { description, title } = props
    return <Transition.Root show={props.open} as={Fragment}>
        <Dialog 
        data-testid="modal-dialog"
        as="div" static className="fixed z-20 inset-0 overflow-hidden" open={props.open}
            onClose={props.onClose}>
            <div className="absolute inset-0 overflow-hidden">
                <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <Dialog.Overlay className="absolute z-15 inset-0 bg-gray-800 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div className="fixed inset-y-0 right-0 pl-1 max-w-full flex sm:pl-2">
                            <div className="w-screen max-w-md">
                                <div className="h-full flex flex-col bg-white shadow-xl">
                                    <div className="px-4 py-6 border-b border-gray-100 sm:px-6">
                                        <div className="flex items-start justify-between space-x-3">
                                            <div className="space-y-1">
                                                <Dialog.Title className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
                                                {description ? <p className="text-sm text-gray-500">
                                                    <Dialog.Description>{description} </Dialog.Description>
                                                </p> : null}
                                            </div>
                                            <div className="ml-3 h-7 flex items-center">
                                                <button
                                                    className="rounded-md text-gray-300 hover:text-white focus:outline-none"
                                                    onClick={props.onClose}
                                                >
                                                    <span className="sr-only">{t('close')}</span>
                                                    <XIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {props.pending ? <Spinner containerClass="flex-1 bg-transparent !static" /> : props.children}
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </div>
        </Dialog >
    </Transition.Root >
}