import _ from 'lodash'
import { createSelector } from 'reselect'
import { isBticino } from '../config'
import { State } from '../configureStore'
import { loginSelector } from '../login/selector'
import { UserInvitation } from './actions'
import { phone } from 'phone'

export type CompanyStatus = 'unverified' | 'verified' | 'invalid'

export interface CompanyMember {
    id: string
    name: string
    surname: string
    email: string
    certificationLevel: number
    status: string
    role: string
    joinedAt: string
    creator?: boolean
    aclEnabled?: boolean
    panelsVisibleRestrictedCount?: number
}

export interface CompanyMemberInvitation {
    email: string
    rejected?: boolean
    lastResendAt?: string
    invitedAt: string
}

export type CompanyType = 'freelance' | 'installer' | 'distributor'
export type TaxExempt = 'none' | 'reverse' | 'exempt'

export interface Company {
    _id: string
    businessName: string
    description: string
    phoneNumber: string
    email: string,
    address: string
    zipCode: string
    province: string
    city: string
    country: string
    status: CompanyStatus
    type: CompanyType
    logoUrl?: string
    vatNumber?: string
    stripeId?: string
    certificationLevel?: CertificationLevel
    scaledCertificationLevel?: CertificationLevel
    fiscalCode?: string
    distributors?: Distributor[]
    distributorsNote?: string
    businessProvinces?: BusinessProvince[]
    pec?: string
    cuu?: string
    members?: CompanyMember[]
    invitations?: CompanyMemberInvitation[],
    note?: string,
    loyaltyProgramScore?: number
    loyaltyProgramScores?: { [key: string]: { score: number } },
    taxExempt?: TaxExempt
}

export enum CertificationLevel {
    None, Selected, Silver, Gold, Specialist
}

type Distributor = Pick<Company, '_id' | 'businessName' | 'phoneNumber' | 'email' | 'address'>

export type UserRole = 'admin' | 'employee'
export type BillingFormMode = 'add' | 'edit' | 'show'

export type MarketingCategory = {
    enabled: boolean,
    updatedAt?: string
}

export type UserMarketing = {
    tcn?: MarketingCategory
}

export type BusinessProvince = {
    country: string,
    province: string
}

export interface User {
    _id: string
    name: string
    surname: string
    email: string
    phoneNumber: string
    role: UserRole
    certificationLevel?: number,
    phoneNumberVerified?: boolean,
    emailVerified?: boolean,
    marketing?: UserMarketing
    createdAt?: string//2021-11-12T15:28:07.023Z
    updatedAt?: string//2021-11-12T15:28:07.023Z
    invitations?: UserInvitation[],
    country: string
}

export type UserCompanyProfile = User & {
    company?: Company
}

export const getProfile = (state: State) => state.profile
const getCountryFromPhone = (phoneNumber: string): string => {
    const evaluatedPhone = phone(phoneNumber)
    if (evaluatedPhone.isValid)
        return evaluatedPhone.countryIso2
    return 'IT'
}

export const getUserCompanyProfile = createSelector(
    [getProfile],
    (profile: UserCompanyProfile) => {
        if (!profile) return undefined
        const country = getCountryFromPhone(profile.phoneNumber)
        return _(profile).omit(['loading']).assign({ country }).value()
    }
)

export const getAllMembersFromCompany = (company: any) => {
    const mapMemberOrInvitation = (obj: any) => ({
        id: obj.id || obj.email,//if it's an invitation the email (unique field) is used as id
        surname: obj.surname || '',
        name: obj.name || '',
        email: obj.email,
        certificationLevel: obj.certificationLevel || 0,
        aclEnabled: !!obj.aclEnabled,
        panelsVisibleRestrictedCount: obj.panelsVisibleRestrictedCount,
        role: obj.role || 'employee', //if NO ROLE it is an employee (active, pending or rejected) by default
        status: obj.joinedAt ? 'active' : (!!obj.rejected ? 'rejected' : 'pending'), //if joinedAt it is a member so ACTIVE, else it is an invitation that could be in PENDING or REJECTED status
        joinedAt: obj.joinedAt || ''
    })

    //ordering by date joinedAt or invitedAt just in case, as already done in this manner by the backend
    const [admins, otherMembers] = _.partition(_.orderBy(company?.members, 'joinedAt', 'asc'), m => m.role === 'admin')
    const [creators, otherAdmins] = _.partition(admins, a => a.creator)
    const [rejected, pending] = _.partition(_.orderBy(company?.invitations, 'invitedAt', 'asc'), i => i.rejected)

    return [...creators, ...otherAdmins, ...otherMembers, ...pending, ...rejected].map(mapMemberOrInvitation)
}

export const getUserCompanyMembersOrdered = createSelector(
    [getProfile],
    (profile) => {
        if (!profile) return []
        const profileAsUserCompanyProfile = (profile as UserCompanyProfile)

        return getAllMembersFromCompany(profileAsUserCompanyProfile.company)
    }
)

export const hasAdminRole = createSelector(
    getUserCompanyProfile,
    profile => profile?.role === 'admin' || isBticino())

export const getBillingId = createSelector(
    getUserCompanyProfile,
    profile => profile?.company?.stripeId)

export const isLoadingUserCompanyProfile = createSelector(
    getProfile,
    profile => !!profile.loading)

export enum CompanyRole {
    Admin = 'admin',
    Employee = 'employee',
    Visitor = 'visitor',
    Unknown = 'unknown'
}

export enum ApplicationRole {
    NotLogged = 'notLogged',
    Unknown = 'none',
    Sales = 'sales',
    Helpdesk = 'helpdesk',
    Customer = 'customer'
}

export const hasACompany = createSelector(
    [getUserCompanyProfile],
    (profile) => {
        return !!((profile as UserCompanyProfile)?.company)
    }
)

export const getCompanyRole = createSelector(
    [getUserCompanyProfile],
    (profile) => {
        const profileAsUserCompanyProfile = (profile as UserCompanyProfile)

        if (!profile || !profile.email)
            return CompanyRole.Unknown

        if (profileAsUserCompanyProfile?.company?.status !== 'verified')
            return CompanyRole.Visitor

        const isACompany = () => !!profileAsUserCompanyProfile?.company && ['distributor', 'integrator', 'installer'].includes(profileAsUserCompanyProfile?.company?.type)

        if (profileAsUserCompanyProfile?.role === 'admin'
            && isACompany())
            return CompanyRole.Admin

        return CompanyRole.Employee
    }
)

export const getApplicationRoles = createSelector(
    [loginSelector],
    (login) => {
        if (!login?.signedIn)
            return [ApplicationRole.NotLogged]

        const roles: ApplicationRole[] = [ApplicationRole.Customer]

        if (login?.groups && login?.groups.includes('sales'))
            roles.push(ApplicationRole.Sales)

        if (login?.groups && login?.groups.includes('helpdesk'))
            roles.push(ApplicationRole.Helpdesk)

        return roles
    }
)

export const isUserVerified = createSelector(
    getCompanyRole, userAccessType => userAccessType !== CompanyRole.Visitor && userAccessType !== CompanyRole.Unknown
)

export const companyStatus = createSelector(getUserCompanyProfile, profile => profile?.company?.status)

export const companyType = createSelector(getUserCompanyProfile,
    (profile: Partial<UserCompanyProfile> | undefined): CompanyType => profile?.company?.type || 'unknown')

export const isPhoneNumberVerified = createSelector(
    getProfile,
    profile => !!(profile as UserCompanyProfile)?.phoneNumberVerified)

export const getUserPhoneNumber = createSelector(
    getProfile,
    profile => (profile as UserCompanyProfile)?.phoneNumber
)

export const isEmailVerified = createSelector(
    [getProfile],
    (profile) => !!(profile as UserCompanyProfile)?.emailVerified)

export const canManageStore = createSelector(
    [getUserCompanyProfile, hasAdminRole],
    (profile, isAdmin) => {
        const isVerifiedCompany = profile?.company?.status === 'verified'
        const isAValidCompanyType = ['installer', 'distributor'].includes(profile?.company?.type || '')
        return isAdmin && isVerifiedCompany && isAValidCompanyType
    }
)

export const getBillingFormMode = createSelector(
    [canManageStore, getBillingId],
    (canManageStore, billingId): BillingFormMode => {
        if (!canManageStore)
            return 'show'
        if (!billingId)
            return 'add'
        return 'edit'
    }
)

export const joinCompanyInvitations = createSelector(
    getProfile,
    profile => profile.invitations
)

export const explicitMarketingPreferences = createSelector(
    getProfile,
    profile => !_.isEmpty(profile.marketing)
)

export const isPhoneVerified = createSelector(
    getProfile,
    profile => profile.phoneNumberVerified
)