import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import Input, { InputProps } from './Input/Input'
import { countries as allCountries } from 'countries-list'
import { Controller, ControllerProps } from "react-hook-form";

type PhoneWithCountryInputProps = Omit<InputProps, 'passwordVisibility' | 'dropdown' | 'inputClasses' | 'icon' | 'type' | 'autoComplete' | 'defaultValue'> & {
    defaultValue?: string,
    onChange: (value: string) => void
}

const PhoneWithCountryInput = (props: PhoneWithCountryInputProps) => {
    const { defaultValue, onChange, name, ...inputProps } = props
    const splittedPhone = getPrefixAndPhoneFromPhoneNumber(defaultValue)
    const [country, setCountry] = useState(splittedPhone?.prefix)
    const [phone, setPhone] = useState(splittedPhone?.phone)

    const phoneOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e.currentTarget.value)
    }

    const countryOnChangeHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCountry(e.currentTarget.value)
    }

    useEffect(() => {
        if (onChange) {
            onChange(`${country}${phone}`)
        }
    }, [country, phone, onChange])

    return (
        <Input
            {...inputProps}
            onChange={phoneOnChangeHandler}
            inputClasses="pl-32"
            autoComplete="phone"
            pattern={"[0-9]*"}
            dropdown={{
                id: 'country',
                label: 'Country',
                emptyLabel: 'Select',
                name: 'country',
                options: generatePhoneNumberCountries(),
                props: {
                    onChange: countryOnChangeHandler,
                    required: true,
                    defaultValue: country,
                    disabled: inputProps.disabled || inputProps.readOnly
                }
            }}
            defaultValue={phone}
            type="tel"
            required
        />
    )
}

const generatePhoneNumberCountries = (): { value: string, label: string }[] => {
    // @ts-ignore
    return _.flatMap(Object.keys(allCountries).map(k => ({ countryCode: k, ...allCountries[k] })), (country) => {
        return country.phone.split(",").map((phone: string) => ({
            value: `+${phone}`, label: `${country.countryCode} (+${phone})`
        }))
    })
}

const getPrefixAndPhoneFromPhoneNumber = (phoneNumber?: string): { prefix: string, phone: string } | undefined => {
    const prefixes = generatePhoneNumberCountries()
    if (!phoneNumber)
        return { prefix: '', phone: '' }
    const country = prefixes.find(prefix => phoneNumber.indexOf(prefix.value) === 0)
    return { prefix: country?.value || '', phone: phoneNumber.replace(country?.value || '', '') }
}


const PhoneWithCountry = (props: Omit<PhoneWithCountryInputProps, 'defaultValue' | 'onChange'> & Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'>) => {
    const { control,
        show = true,
        name,
        rules,
        ...inputProps } = props
    return show ? (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ onChange, value }) => {
                return (
                    <PhoneWithCountryInput
                        {...inputProps}
                        onChange={onChange}
                        defaultValue={value}
                    />
                )
            }}
        />
    ) : null
}

export { PhoneWithCountryInput, PhoneWithCountry }
