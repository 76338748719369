import React from 'react'
import { AlertWarning } from '../../common/Alert/Alert'
import Card from '../../common/Card/Card'

type FirstStepsWrapperProps = {
    pending?: boolean
    error?: string
}

export const FirstStepsWrapper: React.FC<FirstStepsWrapperProps> = (props) => {
    const { children, pending, error = '' } = props
    return (
        <Card pending={pending} className='relative text-center'>
            <Card.Body>
                {error !== '' && <AlertWarning message={error} containerClass="shadow mb-8" />}
                {children}
            </Card.Body>
        </Card>
    )
} 