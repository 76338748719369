import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Card from '../../common/Card/Card'
import MobileNumberVerification from '../../dashboard/MobileNumberVerification';

type Props = {
    email: string
    phoneNumber: string
}

const ToConfirmFields = (props: Props) => {
    const { email, phoneNumber } = props
    const { t } = useTranslation()
    const [mobilePhoneVerificationShow, setMobilePhoneVerificationShow] = useState(false)

    return (
        <>
            <div className="mt-10 sm:mt-0 md:grid md:grid-cols-4 md:gap-6">
                {/* User profile data */}
                <div className="md:col-span-1">
                    <div className="px-1 md:px-4">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">{t('emailPhoneSectionTitle')}</h3>
                        <p className="mt-1 text-sm text-gray-600">{t('emailPhoneSectionSubtitle')}</p>
                    </div>
                </div>
                <div className="mt-5 md:mt-0 md:col-span-3">
                    <Card>
                        <Card.Body full otherClasses="divide-y divide-gray-100">
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-5">
                                <dt className="text-sm font-medium text-gray-500">{t('registrationEmail')}</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <span className="flex-grow">{email}</span>
                                    <span className="ml-4 flex-shrink-0" />
                                </dd>
                            </div>
                            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 px-5">
                                <dt className="text-sm font-medium text-gray-500">{t('mobilePhone')}</dt>
                                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <span className="flex-grow">{phoneNumber}</span>
                                    <span className="ml-4 flex-shrink-0">
                                        <button
                                            onClick={() => setMobilePhoneVerificationShow(true)}
                                            type="button"
                                            className="bg-white rounded-md font-medium text-brand-600 hover:text-brand-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-500"
                                        >
                                            {t('update')}
                                        </button>
                                    </span>
                                </dd>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <MobileNumberVerification
                show={mobilePhoneVerificationShow}
                phone={phoneNumber}
                onSuccess={() => {
                    setMobilePhoneVerificationShow(false)
                }}
                onClose={() => {
                    setMobilePhoneVerificationShow(false)
                }}
            />
        </>
    )
}

export default ToConfirmFields