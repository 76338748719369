import { LoginActions, loginCompleted, checkLogin, checkLoginComplete, userGroups, userGroupsRemove } from "./actions"
import { Epic } from "redux-observable"
import { isActionOf } from "typesafe-actions"
import { filter, mergeMap, catchError, mapTo, map } from 'rxjs/operators'
import { of, from } from "rxjs"
import { ActionTypes } from "../configureStore"
import { logout } from "../logout/actions"

export const userGroups$: Epic<
    ActionTypes,
    LoginActions,
    any,
    { currentAuthenticatedUser: () => Promise<any> }
> = (action$, state$, { currentAuthenticatedUser }) => action$.pipe(
    filter(isActionOf(loginCompleted)),
    mergeMap(action => {
        return from(currentAuthenticatedUser())
            .pipe(
                map(res => {
                    const groups = res.signInUserSession.accessToken.payload['cognito:groups'] || []
                    return userGroups(groups)
                }),
            )
    })
)

export const userGroupsRemove$: Epic<
    ActionTypes,
    LoginActions,
    any,
    { currentAuthenticatedUser: () => Promise<any> }
> = (action$, state$, { currentAuthenticatedUser }) => action$.pipe(
    filter(isActionOf(logout)),
    map(action => userGroupsRemove())
)

export const checkLogin$: Epic<
    ActionTypes,
    LoginActions,
    any,
    { currentSession: () => Promise<any> }
> = (action$, state$, { currentSession }) =>
        action$.pipe(
            filter(isActionOf(checkLogin)),
            mergeMap(action => {
                return from(currentSession())
                    .pipe(
                        mapTo(loginCompleted()),
                        catchError(error => of(checkLoginComplete()))
                    )
            })
        )