import React from 'react'
import clsx from 'clsx'

interface StepsProgressBarStep {
    name: string
    status: 'complete' | 'current' | 'upcoming',
}

interface StepsProgressBarProps {
    data: StepsProgressBarStep[],
    displayStepsTitle?: boolean
}

export const StepsProgressBar: React.VFC<StepsProgressBarProps> = (props) => {
    const { data = [] } = props
    const itemsClass = clsx('hidden sm:grid text-sm font-medium text-gray-600 mt-6', {
        'grid-cols-1': data.length === 1, 
        'grid-cols-2': data.length === 2,
        'grid-cols-3': data.length === 3,
        'grid-cols-4': data.length === 4,
        'grid-cols-5': data.length === 5,
        'grid-cols-6': data.length === 6
    })
    
    const completedSteps = data.reduce((prev, curr) => {
        if (curr.status === 'complete') {
            return prev + 1
        }
        return prev + 0
    }, 0)

    const completedStepsPercentage = (100 / data.length) * completedSteps

    return (
        <div className="mt-6" aria-hidden="true">
            <div className="bg-gray-200 rounded-full overflow-hidden">
                <div className="h-2 bg-brand-600" style={{ width: completedStepsPercentage+'%' }} />
            </div>
            {props.displayStepsTitle ? <div className={itemsClass}>
                {data.map((step, i) => {
                    const itemClass = clsx({
                        'text-brand-600': step.status === 'complete',
                        'text-right': i === data.length - 1
                    })
                    return (
                        <div key={step.name} className={itemClass}>{step.name}</div>
                    )
                })}
            </div> : null }
        </div>
    )
}