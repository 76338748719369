import { createReducer } from 'typesafe-actions'
import * as Actions from './actions'
import { logoutCompleted } from '../logout/actions'
import { startUploadProfileImage, uploadProfileImageComplete } from './actions'
import { createBillingProfileCompleted } from '../billing/actions'
import _ from 'lodash'
import { UserCompanyProfile } from './selectors'

export const profileReducer = createReducer({})
    .handleAction(Actions.loadProfileCompleted, (state, action) => ({ ...action.payload, loading: false }))
    .handleAction(Actions.loadProfile, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.loadProfileFailed, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.reloadProfile, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.loadCompanyProfile, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.loadCompanyProfileCompleted, (state, action) => {
        const srcCompanyObj = (_.cloneDeep(state) as UserCompanyProfile).company
        return { ...state, company: srcCompanyObj ? { ...srcCompanyObj, ...action.payload } : action.payload, loading: false }
    })
    .handleAction(Actions.loadCompanyProfileFailed, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.inviteCompanyMember, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.removeCompanyMember, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.updateCompanyMember, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.inviteCompanyMemberCompleted, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.removeCompanyMemberCompleted, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.updateCompanyMemberCompleted, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.inviteCompanyMemberError, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.removeCompanyMemberError, (state, action) => ({ ...state, loading: false }))
    .handleAction(Actions.updateCompanyMemberError, (state, action) => ({ ...state, loading: false }))
    .handleAction(logoutCompleted, (state, action) => ({}))
    .handleAction(startUploadProfileImage, (state, action) => ({ ...state, loading: true }))
    .handleAction(uploadProfileImageComplete, (state, action) => ({ ...state, loading: false }))
    .handleAction(createBillingProfileCompleted, (state, action) => {
        const newState = _.cloneDeep(state) as UserCompanyProfile
        newState!.company!.stripeId = action.payload.billingId
        return {
            ...newState
        }
    })
    .handleAction(Actions.acceptCompanyInvite, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.rejectCompanyInvite, (state, action) => ({ ...state, loading: true }))
    .handleAction(Actions.acceptRejectCompanyInviteError, (state, action) => ({ ...state, loading: false }))