import React from 'react'
import { testCuu, testEmail } from '../../common/utils'
import Input from '../../common/Input/Input'
import { useTranslation } from 'react-i18next'
import { UseFormMethods } from 'react-hook-form'

interface Props extends Pick<UseFormMethods, 'register' | 'errors' | 'getValues'> {
    show: boolean
}

export function PecCuu(props: Props) {
    const { t } = useTranslation()
    const { show, register, errors, getValues } = props
    const isCuuValid = (cuu: string) => {
        return (testEmail(getValues('pec') || '') && !cuu) || testCuu(cuu)
    }

    const isPecValid = (pec: string) => {
        return (testCuu(getValues('cuu') || '') && !pec) || testEmail(pec)
    }
    return show ? <>
        <Input
            containerClass="col-span-6 sm:col-span-3"
            id="company-pec"
            name="pec"
            invalid={!!errors.pec}
            label={t('registrationPec')}
            type="email"
            ref={register({
                validate: isPecValid
            })}
        >
            {errors.pec && (
                <Input.Alert>
                    {t('invalidPec')}
                </Input.Alert>
            )}
        </Input>
        <Input
            containerClass="col-span-6 sm:col-span-3"
            id="company-cuu"
            name="cuu"
            invalid={!!errors.cuu}
            label={t('registrationCuu')}
            type="text"
            ref={register({
                validate: isCuuValid
            })}
        >
            {errors.cuu && (
                <Input.Alert>
                    {t('invalidCuu')}
                </Input.Alert>
            )}
        </Input>
    </> : null
}